import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { blue, green } from '@mui/material/colors';

import ArchiveIcon from '@mui/icons-material/Archive';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import React from 'react';
import { itemTypes } from './purchase.const';
import useUser from '../../services/authentication/useUser';

export function PurchaseItems({ activeStep, purchase, sas, handleFile }) {
  const user = useUser();
  return (
    <>
      <Table
        sx={{
          [`.${tableCellClasses.head}, .${tableCellClasses.body}`]: {
            fontSize: 15,
          },
          a: {
            color: blue[700],
            textDecoration: 'none',
            ':hover': {
              textDecoration: 'underline',
            },
          },
        }}>
        <TableHead>
          <TableRow>
            <TableCell>品名</TableCell>
            <TableCell>類別</TableCell>
            <TableCell align="right">單價</TableCell>
            <TableCell>數量</TableCell>
            <TableCell>單位</TableCell>
            <TableCell align="right">金額</TableCell>
            <TableCell width="25%">備註</TableCell>
            {activeStep > 1 && <TableCell width="5%"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {purchase.items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Grid container>
                  <Grid item>
                    {!item.photo ? (
                      item.name
                    ) : (
                      <a
                        href={`${item.photo.url}?${sas}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {item.name}
                      </a>
                    )}
                  </Grid>
                  <Grid item>
                    {item.stocked && (
                      <Tooltip title="入庫品項" placement="top">
                        <ArchiveIcon
                          sx={{ color: '#909090', fontSize: '18px', ml: 0.5 }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>{itemTypes[item.itemType]}</TableCell>
              <TableCell align="right">
                ${item.price.toLocaleString()}
              </TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.unit}</TableCell>
              <TableCell align="right">
                ${Math.round(item.price * item.quantity).toLocaleString()}
              </TableCell>
              <TableCell>{item.desc}</TableCell>
              {activeStep > 1 && (
                <TableCell>
                  <label
                    {...{
                      htmlFor:
                        activeStep < 4 ? `icon-button-file-${item._id}` : '',
                    }}>
                    <CameraAltIcon
                      sx={{
                        fontSize: '175%',
                        color: item.photo ? green[700] : 'inherit',
                      }}
                    />
                  </label>
                  {user._id === purchase.created.by._id ? (
                    <input
                      id={`icon-button-file-${item._id}`}
                      accept="image/*"
                      type="file"
                      onChange={(event) =>
                        handleFile(event.target.files[0], item._id)
                      }
                      hidden
                    />
                  ) : null}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter
          sx={{
            '& span': {
              fontSize: 22,
              color: '#101010',
              sup: {
                fontSize: 13,
                marginRight: 1,
                color: '#303030',
                fontWeight: 'bold',
              },
            },
            [`.${tableRowClasses.root}`]: {
              backgroundColor: '#E9E9E9',
            },
          }}>
          <TableRow>
            <TableCell colSpan="6">
              {!purchase.taxIncluded && (
                <>
                  <span>
                    <sup>營業稅</sup>$
                    {(
                      Math.round((purchase.amount / 1.05) * 0.05 * 100) / 100
                    ).toLocaleString()}
                  </span>
                </>
              )}
            </TableCell>
            <TableCell colSpan={activeStep > 1 ? '2' : '1'} align="right">
              <span style={{ marginRight: 15 }}>
                <sup>總金額</sup>${purchase.amount.toLocaleString()}
              </span>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <TextField
        sx={{ mt: 3 }}
        variant="outlined"
        size="small"
        label="備註"
        value={purchase.desc}
        minRows={3}
        inputProps={{ disabled: true }}
        multiline
        fullWidth
      />
    </>
  );
}
