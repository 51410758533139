const url = '/api/summary';

export const querySummaries = (states) => (client) => () => {
  const params = new URLSearchParams();
  for (let key of Object.keys(states)) {
    if (!states[key]) continue;
    if (key !== 'range') {
      params.append(key, states[key]);
    }
  }
  if (states.range) {
    params.append(
      'range',
      `[${states.range[0].toMillis()},${states.range[1].toMillis()}]`
    );
  }
  return client.get(
    `${url}${params.toString() ? `?${params.toString()}` : ''}`
  );
};

export const createSummary = (client) => (states) => {
  return client.post(url, states);
};

export const querySummary = (states) => (client) => () => {
  return client.get(`${url}/${states.id}`);
};

export const updateSummary = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states);
};

export const createOrder = (client) => (states) => {
  return client.post(`/api/summary/${states.id}`, states);
};
