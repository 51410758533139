import { DateTime } from 'luxon';
import React from 'react';
import { queryReceipt } from '../../fetchers/receipt.fetch';
import styles from './style.module.scss';
import useQuery from '../../services/httpClient/useQuery';
import { useQueryClient } from 'react-query';

function PrintableReceipt({ receipt, mode }) {
  const queryClient = useQueryClient();
  const receiptQuery = useQuery(
    ['receipt', receipt._id],
    queryReceipt({ id: receipt._id, mode }),
    {
      onSuccess: (data) => {
        data.date = DateTime.fromISO(data.date);
        queryClient.setQueryData(['receipt', receipt._id], data);
      },
      staleTime: Infinity,
    }
  );

  React.useEffect(() => {
    receipt.date = DateTime.fromISO(receipt.date);
  }, [receipt]);

  if (receiptQuery.isLoading) return <p>Loading...</p>;
  if (!receipt.print) return <p>電子發票設定為不列印</p>;

  const range = () => {
    if (receipt.date.month % 2 === 0) {
      return `${receipt.date.year - 1911}年${(receipt.date.month - 1)
        .toString()
        .padStart(2, '0')}-${receipt.date.month.toString().padStart(2, '0')}月`;
    }
    return `${receipt.date.year - 1911}年${receipt.date.month
      .toString()
      .padStart(2, '0')}-${(receipt.date.month + 1)
      .toString()
      .padStart(2, '0')}月`;
  };

  return (
    <div className={styles.receipt}>
      <div className={styles.title}>
        {receipt.company === '福' ? (
          <img
            src="https://dinfucemstorage.blob.core.windows.net/image/%E9%A0%82%E7%A6%8F%E4%BA%8B%E6%A5%AD.png"
            alt="company"
          />
        ) : receipt.company === '利' ? (
          <img
            src="https://dinfucemstorage.blob.core.windows.net/image/%E9%A0%82%E5%88%A9%E4%BA%8B%E6%A5%AD.png"
            alt="company"
          />
        ) : (
          <img
            src="https://dinfucemstorage.blob.core.windows.net/image/%E8%BE%B0%E8%8C%82%E4%BA%8B%E6%A5%AD.png"
            alt="company"
          />
        )}
      </div>
      <div className={styles.type}>
        {mode === 'original' ? '電子發票證明聯' : '電子發票證明補印聯'}
      </div>
      <div className={styles.range}>{range()}</div>
      <div className={styles.sn}>{`${receipt.sn.substring(
        0,
        2
      )}-${receipt.sn.substring(2)}`}</div>
      <div className={styles.info}>
        <div className={styles.time}>
          {receipt.date.toFormat('yyyy-MM-dd HH:mm:ss')}
        </div>
        <div className={styles.panel}>
          <div className={styles.left}>
            <div>隨機碼 {receipt.random}</div>
            <div>
              賣方{' '}
              {receipt.company === '福'
                ? '27580662'
                : receipt.company === '利'
                ? '27554910'
                : '27366558'}
            </div>
          </div>
          <div className={styles.right}>
            <div>總計: {receipt.amount.toLocaleString()}</div>
            {receipt.tax && <div>買方 {receipt.tax}</div>}
          </div>
        </div>
      </div>

      <div className={styles.barcode}>
        <img
          src={`http://bwipjs-api.metafloor.com/?bcid=code128&text=${receiptQuery.data.PosBarCode}&scaleX=3&scaleY=1`}
          alt="Barcode"
        />
      </div>
      <div className={styles.qrcode}>
        <div className={styles.left}>
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${receiptQuery.data.QRCode_Left}`}
            alt="QRCode"
          />
        </div>
        <div className={styles.right}>
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${receiptQuery.data.QRCode_Right}`}
            alt="QRCode"
          />
        </div>
      </div>

      <span className={styles.fl}>.</span>
      <span className={styles.fr}>.</span>
    </div>
  );
}

export default PrintableReceipt;
