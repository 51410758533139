import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import { createFile, deleteFile } from '../../fetchers/file.fetch';
import {
  deletePurchase,
  queryPurchase,
  updatePurchase,
} from '../../fetchers/purchase.fetch';
import { statuses, titles } from './purchase.const';
import { useNavigate, useParams } from 'react-router-dom';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import EditPurchaseDialog from './EditPurchaseDialog';
import FileTable from '../../components/FileTable';
import Memos from '../../components/Memos';
import OutboxIcon from '@mui/icons-material/Outbox';
import PrintableDialog from '../../components/PrintableDialog';
import PurchaseForm from '../../components/PurchaseForm';
import { PurchaseInfo } from './PurchaseInfo';
import { PurchaseItems } from './PurchaseItems';
import { PurchaseStepper } from './PurchaseStepper';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import { queryVendor } from '../../fetchers/vendor.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useAccess from '../../services/authentication/useAccess';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useHttpClient from '../../services/httpClient/useHttpClient';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import { useQueryClient } from 'react-query';
import useSas from '../../services/authentication/useSas';
import useUser from '../../services/authentication/useUser';

function PurchaseDetail(props) {
  useCrumbs([crumbs.purchaseList, crumbs.purchaseDetail]);
  const { id } = useParams();
  const sas = useSas();
  const user = useUser();
  const navigate = useNavigate();
  const hasAccess = useAccess();
  const client = useHttpClient();
  const queryClient = useQueryClient();
  const [dialog, setDialog] = React.useState();
  const purchaseQuery = useQuery(['purchase', id], queryPurchase({ id }), {
    staleTime: 30000,
  });
  const purchaseUpdateRequest = useMutation(updatePurchase, {
    onSuccess: () => {
      purchaseQuery.refetch();
    },
  });
  const purchaseDeleteRequest = useMutation(deletePurchase);
  const fileCreateRequest = useMutation(createFile);
  const fileDeleteRequest = useMutation(deleteFile);

  if (purchaseQuery.isLoading) return <Spinner />;
  if (purchaseQuery.isError) return <p>{purchaseQuery.error.toString()}</p>;
  const purchase = purchaseQuery.data;
  const isPurchaser = purchase.created.by._id === user._id || hasAccess('Z');
  const activeStep =
    purchase?.status === 'confirmed'
      ? 5
      : Object.entries(statuses).findIndex(
          (p) => p[0] === purchase?.status || ''
        );

  async function handleMemo(memo) {
    try {
      if (typeof memo === 'object' && memo.content.length !== 0) {
        await purchaseUpdateRequest.mutateAsync({
          id,
          memos: purchase.memos.concat(memo),
        });
      } else {
        await purchaseUpdateRequest.mutateAsync({
          id,
          memos: purchase.memos.filter((m) => m._id.toString() !== memo),
        });
      }
      toast.success('成功儲存備註');
      return true;
    } catch (error) {
      return false;
    }
  }
  async function handleFile(file, tag) {
    if (!file) return;
    const item = purchase.items.find((i) => i._id === tag);
    if (item.photo) {
      await fileDeleteRequest.mutateAsync({ id: item.photo._id });
    }
    const data = new FormData();
    data.append('file', file, encodeURI(file.name));
    data.append('tag', tag);
    fileCreateRequest.mutate(data, {
      onSuccess: async (created) => {
        const updatedItem = { ...item, photo: created.data._id };
        const payload = {
          id,
          items: purchase.items.map((i) =>
            i._id === updatedItem._id ? updatedItem : i
          ),
        };
        if (payload.items.every((i) => i.photo)) {
          payload.status = 'shipment';
        }
        await purchaseUpdateRequest.mutateAsync(payload);
        toast.success('成功上傳照片');
        purchaseQuery.refetch();
      },
    });
  }
  function proceedToPayment() {
    purchaseUpdateRequest.mutate(
      { id, status: 'payment' },
      {
        onSuccess: () => {
          toast.success('成功請款');
          purchaseQuery.refetch();
        },
      }
    );
  }
  function handleClosePurchase() {
    const results = [];
    for (let item of purchase.items) {
      if (item.itemType === 'm') {
        results.push(
          client.post(`/api/item`, {
            _id: item._id,
            name: item.name,
            unit: item.unit,
            desc: item.desc,
            price: item.price,
            prices: [
              {
                price: item.price,
                date: purchase.created.date,
              },
            ],
            min: 0,
            stocked: item.stocked,
            vendor: purchase.vendor._id,
          })
        );
      } else {
        results.push(
          client.post(`/api/item/${item._id}`, {
            date: purchase.created.date,
            price: item.price,
            unit: item.unit,
          })
        );
      }
    }
    Promise.all(results)
      .then(() => {
        purchaseUpdateRequest.mutate(
          { id, status: 'closed' },
          {
            onSuccess: () => {
              toast.success('成功結案');
              purchaseQuery.refetch();
            },
          }
        );
      })
      .catch((err) => {
        alert(err);
      });
  }
  function handleDelete() {
    purchaseDeleteRequest.mutate(
      { id },
      {
        onSuccess: () => {
          if (purchase.sn) {
            toast.success('成功作廢採購單');
            return purchaseQuery.refetch();
          }

          toast.success('成功刪除採購單');
          queryClient.invalidateQueries(['quotes', {}]);
          navigate('/purchase');
        },
      }
    );
  }

  return (
    <Box>
      <PurchaseStepper
        purchase={purchase}
        activeStep={activeStep}
        setDialog={setDialog}
        isPurchaser={isPurchaser}
        hasAccess={hasAccess}
      />
      <Paper elevation={2} sx={{ p: 3 }}>
        <PurchaseInfo purchase={purchase} sas={sas} />
      </Paper>
      <Paper elevation={2} sx={{ mt: 2, p: 2 }}>
        <PurchaseItems
          activeStep={activeStep}
          purchase={purchase}
          sas={sas}
          handleFile={handleFile}
        />
      </Paper>
      <Paper elevation={1} sx={{ mt: 2 }}>
        <FileTable tag={purchase._id} lock={activeStep > 1} />
      </Paper>
      <Memos sx={{ mt: 2 }} memos={purchase.memos} onSubmit={handleMemo} />
      <Dialogs
        dialog={dialog}
        setDialog={setDialog}
        purchase={purchase}
        user={user}
        activeStep={activeStep}
        purchaseUpdateRequest={purchaseUpdateRequest}
        fileCreateRequest={fileCreateRequest}
        fileDeleteRequest={fileDeleteRequest}
        onSave={() => purchaseQuery.refetch()}
      />
      {!purchase.invalidated && (
        <SpeedDial
          actions={[
            createAction(speedDialIcons.main),
            createAction(
              speedDialIcons.edit,
              '編輯',
              () => setDialog('edit'),
              activeStep > 2 || !isPurchaser
            ),
            createAction(
              <OutboxIcon />,
              '送簽',
              () => setDialog('confirmAudit'),
              activeStep !== 0 || !isPurchaser
            ),
            createAction(
              speedDialIcons.addPayment,
              '請款',
              proceedToPayment,
              activeStep !== 4 ||
                !purchase.inspection?.checked ||
                !isPurchaser ||
                !purchase.receipt ||
                !purchase.receipt.date ||
                !purchase.items.every((i) => i.photo)
            ),
            createAction(
              <CheckCircleIcon />,
              '結案',
              handleClosePurchase,
              activeStep !== 5 ||
                !hasAccess('F') ||
                !purchase.payment?.paymentType ||
                !purchase.payment?.amount ||
                !purchase.payment?.by
            ),
            createAction(
              speedDialIcons.trash,
              purchase.sn ? '作廢' : '刪除',
              handleDelete,
              purchase?.status === 'invalidated' ||
                activeStep > 3 ||
                !isPurchaser
            ),
            createAction(
              speedDialIcons.print,
              '列印',
              () => setDialog('print'),
              activeStep < 4
            ),
          ]}
        />
      )}
    </Box>
  );
}

export default PurchaseDetail;

function Dialogs({
  purchase,
  user,
  dialog,
  setDialog,
  purchaseUpdateRequest,
  fileCreateRequest,
  fileDeleteRequest,
  onSave,
}) {
  function handleClose() {
    setDialog();
  }
  function sendToAudit() {
    purchaseUpdateRequest
      .mutateAsync({ id: purchase._id, status: 'audit' })
      .then(() => {
        toast.success('成功送簽');
        handleClose();
      });
  }
  function handleAudit(result, remark) {
    if (purchase.status !== 'audit') return;
    const checkEntry = Object.entries(purchase.audit.checks).find(
      (c) => c[1].access === user.access
    );
    if (!checkEntry) {
      toast.error('無權限簽核', { autoClose: false });
      return;
    }
    const check = { ...checkEntry[1] };
    check.checked = result;
    check.date = Date.now();
    check.name = user.name;
    check._id = user._id;

    const payload = {
      audit: {
        ...purchase.audit,
        checks: {
          ...purchase.audit.checks,
          [checkEntry[0]]: check,
        },
        remarks: (purchase.audit.remarks || []).concat({
          date: Date.now(),
          name: user.name,
          category: result ? 'approve' : 'reject',
          remark,
        }),
      },
    };

    if (remark.length > 0) {
      const memos = [...purchase.memos];
      memos.push({
        title: `採購單${result === true ? '簽核' : '退簽'}`,
        content: `註記: ${remark}`,
        date: Date.now(),
        author: {
          _id: user._id,
          name: user.name,
        },
      });
      payload.memos = memos;
    }

    if (
      Object.entries(payload.audit.checks).every((c) => c[1].checked === true)
    ) {
      payload.status = 'purchase';
      payload.issue = true;
    }

    purchaseUpdateRequest
      .mutateAsync({ id: purchase._id, ...payload })
      .then((data) => {
        if (result) {
          onSave();
          handleClose();
          return toast.success('成功簽核');
        }
        return toast.success('成功退簽');
      })
      .catch((err) => toast.error(err.toString(), { autoClose: false }));
  }
  async function handleShipment(date, receiptType, file) {
    if (!file) return;
    if (purchase.receipt && purchase.receipt.photo) {
      await fileDeleteRequest.mutateAsync({ id: purchase.receipt.photo._id });
    }
    const data = new FormData();
    data.append('file', file);
    data.append('name', receiptType);
    const created = await fileCreateRequest.mutateAsync(data);
    const id = created.data._id;
    const payload = {
      status: 'inspection',
      receipt: {
        ...purchase.receipt,
        date,
        receiptType,
        photo: id,
      },
    };
    purchaseUpdateRequest.mutateAsync(
      { id: purchase._id, ...payload },
      {
        onSuccess: () => {
          toast.success('成功儲存採購單');
          handleClose();
        },
      }
    );
  }
  function handleInspection(result, remark) {
    if (user.access === purchase.inspection?.access) {
      let payload = {
        id: purchase._id,
        inspection: {
          ...purchase.inspection,
          date: Date.now(),
          name: user.name,
          _id: user._id,
          checked: result,
        },
      };
      if (remark.length > 0) {
        const memos = [...purchase.memos];
        memos.push({
          title: `採購單驗收${result === false ? '退回' : ''}`,
          content: `註記: ${remark}`,
          date: Date.now(),
          author: {
            _id: user._id,
            name: user.name,
          },
        });
        payload.memos = memos;
      }
      purchaseUpdateRequest.mutateAsync(payload);
    }
  }
  function handlePayment(payload) {
    purchaseUpdateRequest.mutateAsync(
      { id: purchase._id, ...payload },
      {
        onSuccess: () => {
          toast.success(
            payload.status === 'shipment' ? '成功退回請款' : '完成請款流程'
          );
          onSave();
        },
      }
    );
  }

  return (
    <>
      <EditPurchaseDialog
        open={dialog === 'edit'}
        onClose={handleClose}
        purchase={purchase}
        onSave={() => onSave()}
      />
      <AuditConfirmationDialog
        open={dialog === 'confirmAudit'}
        onClose={handleClose}
        onConfirm={sendToAudit}
      />
      <AuditDialog
        open={dialog === 'audit'}
        onClose={handleClose}
        purchase={purchase}
        onAudit={handleAudit}
      />
      <ShipmentDialog
        open={dialog === 'shipment'}
        purchase={purchase}
        onClose={handleClose}
        onUpdate={handleShipment}
      />
      <InspectionDialog
        open={dialog === 'inspection'}
        purchase={purchase}
        onClose={handleClose}
        onUpdate={handleInspection}
      />
      <PaymentDialog
        open={dialog === 'payment'}
        purchase={purchase}
        user={user}
        onClose={handleClose}
        onUpdate={handlePayment}
      />
      <PrintableDialog
        open={dialog === 'print'}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth>
        <PurchaseForm purchase={purchase} />
      </PrintableDialog>
    </>
  );
}
function AuditConfirmationDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>確定送簽？</DialogTitle>
      <Grid
        container
        sx={{ width: '300px', padding: '10px 20px 30px' }}
        justifyContent="center">
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onClose}>
            取消
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            onClick={() => {
              onConfirm();
              onClose();
            }}>
            確定
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
function AuditDialog({ open, onClose, purchase, onAudit }) {
  const [remark, setRemark] = React.useState('');

  function handleAudit(result) {
    if (result === false && remark.trim().length === 0) {
      return alert('請輸入註記');
    }
    onAudit(result, remark);
    setRemark('');
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Stepper
            nonLinear
            activeStep={Object.entries(purchase.audit.checks).findIndex(
              (c) => c[1].checked === false || c[1].checked === undefined
            )}>
            {Object.keys(purchase.audit.checks).map((key) => {
              const check = purchase.audit.checks[key];
              function Icon({ active, completed, className }) {
                let icon = (
                  <CheckBoxOutlineBlankIcon
                    color={active ? 'primary' : 'disabled'}
                  />
                );
                if (check.checked === true) {
                  icon = <CheckBoxIcon color="success" />;
                }
                if (check.checked === false) {
                  icon = <DisabledByDefaultIcon color="error" />;
                }
                return icon;
              }
              return (
                <Step key={key} completed={check.checked}>
                  <StepLabel
                    StepIconComponent={Icon}
                    optional={
                      (check.checked || check.checked === false) && (
                        <Typography variant="caption">
                          {check.date?.toDateTime()}
                        </Typography>
                      )
                    }>
                    {titles[key] || key}
                    {(check.checked || check.checked === false) &&
                      `: ${check.name}`}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        {purchase.status === 'audit' && !purchase.invalidated && (
          <>
            <Grid item xs={12}>
              <TextField
                label="註記"
                minRows={3}
                value={remark}
                onChange={(event) => setRemark(event.target.value)}
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAudit(false)}>
                    退簽
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: 2 }}
                    onClick={() => handleAudit(true)}>
                    簽核
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
}
function ShipmentDialog({ open, onClose, purchase, onUpdate }) {
  const initialState = {
    date: purchase.receipt?.date ?? DateTime.now(),
    receiptType: purchase.receipt?.receiptType ?? '發票',
  };
  const [state, setState] = React.useState(initialState);
  const fileInput = React.useRef();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>到貨</DialogTitle>
      <Box sx={{ p: 2 }}>
        <input
          ref={fileInput}
          type="file"
          onChange={(event) =>
            onUpdate(state.date, state.receiptType, event.target.files[0])
          }
          hidden
        />
        <Grid container spacing={3} direction="column">
          <Grid item>
            <DatePicker
              label="到貨日期"
              value={state.date}
              onChange={(event) =>
                setState({ ...state, date: event.startOf('day') })
              }
              inputFormat="yyyy-MM-dd"
              renderInput={(params) => (
                <TextField size="small" fullWidth {...params} />
              )}
            />
          </Grid>
          <Grid item>
            <FormControl size="small" fullWidth>
              <InputLabel>收據類型</InputLabel>
              <Select
                label="收據類型"
                value={state.receiptType}
                onChange={(event) =>
                  setState({ ...state, receiptType: event.target.value })
                }>
                <MenuItem value="發票">發票</MenuItem>
                <MenuItem value="收據">收據</MenuItem>
                <MenuItem value="出貨單">出貨單</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="secondary" onClick={onClose}>
              取消
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="primary"
              onClick={() => fileInput.current.click()}>
              上傳收據
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
function InspectionDialog({ open, onClose, purchase, onUpdate }) {
  const [remark, setRemark] = React.useState('');
  function handleInspection(result) {
    if (result === false && remark.trim().length === 0) {
      return alert('請輸入註記');
    }
    onUpdate(result, remark);
    setRemark('');
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>驗收</DialogTitle>
      <Grid container spacing={3} sx={{ p: 2 }}>
        {purchase.status === 'inspection' && !purchase.invalidated && (
          <>
            <Grid item xs={12}>
              <TextField
                label="註記"
                minRows={3}
                value={remark}
                onChange={(event) => setRemark(event.target.value)}
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleInspection(false)}>
                    退回
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: 2 }}
                    onClick={() => handleInspection(true)}>
                    驗收
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
}
function PaymentDialog({ open, onClose, purchase, user, onUpdate }) {
  const initialState = {
    date: purchase.payment?.date
      ? DateTime.fromISO(purchase.payment.date)
      : DateTime.now(),
    paymentType: purchase.payment?.paymentType ?? '匯款',
    amount: 0,
    sn: '',
  };
  const [state, setState] = React.useState(initialState);
  const vendorQuery = useQuery(
    ['vendor', purchase.vendor._id],
    queryVendor({ id: purchase.vendor._id })
  );

  function handleSave() {
    onUpdate({
      payment: {
        ...state,
        sn: state.sn.replace(/\s/g, '').split(/[,，]/),
        by: {
          _id: user._id,
          name: user.name,
        },
      },
    });
    setState(initialState);
    onClose();
  }
  function handleConfirm() {
    onUpdate({
      status: 'confirmed',
    });
    setState(initialState);
    onClose();
  }
  function handleReject() {
    onUpdate({
      status: 'shipment',
    });
    setState(initialState);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>付款記錄</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <DatePicker
              label="付款日期"
              value={state.date}
              onChange={(v) => {
                setState({ ...state, date: v.startOf('day') });
              }}
              inputFormat="yyyy-MM-dd"
              renderInput={(params) => (
                <TextField size="small" fullWidth {...params} />
              )}
            />
          </Grid>
          <Grid item>
            <FormControl size="small" fullWidth>
              <InputLabel>付款方式</InputLabel>
              <Select
                label="付款方式"
                value={state.paymentType}
                onChange={(event) =>
                  setState({ ...state, paymentType: event.target.value })
                }>
                <MenuItem value="匯款">匯款</MenuItem>
                <MenuItem value="現金">現金</MenuItem>
                <MenuItem value="支票">支票</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              label="金額"
              value={state.amount}
              onChange={(event) =>
                setState({ ...state, amount: event.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              label="發票號碼"
              placeholder="請輸入發票號碼，以逗號分隔"
              value={state.sn}
              onChange={(event) =>
                setState({ ...state, sn: event.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleReject}>
                  退回
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={onClose}>
                  取消
                </Button>
                {vendorQuery.data && (
                  <Button
                    variant="contained"
                    sx={{ ml: 2 }}
                    onClick={handleConfirm}>
                    月結
                  </Button>
                )}
                {Number(state.amount) === Math.round(purchase.amount) && (
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={handleSave}>
                    儲存
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
