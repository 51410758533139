import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export function EditInfo({ item, onClose, onSave }) {
  const [payload, setPayload] = React.useState({
    name: item.name,
    unit: item.unit,
    spec: item.spec,
    desc: item.desc,
    min: item.min ?? 0,
    stocked: item.stocked,
    error: {},
  });

  async function handleSave() {
    const updated = { ...payload };
    if (payload.name.trim().length === 0)
      updated.error.name = { message: '請輸入品名' };
    if (payload.unit.trim().length === 0)
      updated.error.unit = { message: '請輸入單位' };
    if (Object.keys(updated.error).length > 0) setPayload(updated);

    const result = await onSave(payload);
    if (result) {
      updated.error.api = { message: result };
      return setPayload(updated);
    }
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm">
      <DialogTitle>編輯基本資料</DialogTitle>
      <Box sx={{ m: 3, mt: 1 }}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="品名"
              fullWidth
              value={payload.name}
              onChange={(event) =>
                setPayload({ ...payload, name: event.target.value })
              }
              error={!!payload.error.name}
              helperText={payload.error.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="單位"
              fullWidth
              value={payload.unit}
              onChange={(event) =>
                setPayload({ ...payload, unit: event.target.value })
              }
              error={!!payload.error.unit}
              helperText={payload.error.unit?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="規格"
              fullWidth
              value={payload.spec}
              onChange={(event) =>
                setPayload({ ...payload, spec: event.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="備註"
              fullWidth
              value={payload.desc}
              onChange={(event) =>
                setPayload({ ...payload, desc: event.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="安全庫存"
              fullWidth
              value={payload.min}
              onChange={(event) =>
                setPayload({ ...payload, min: event.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  checked={payload.stocked}
                  onChange={(event) =>
                    setPayload({ ...payload, stocked: event.target.checked })
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="是否入庫"
              labelPlacement="start"
              sx={{ ml: 0.5 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 1 }}>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={onClose}>
              取消
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSave}>
              儲存
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
