import React from 'react';
import useUser from './useUser';

function useAccess() {
  const user = useUser();

  const hasAccess = React.useCallback(
    (accessString) => {
      if (!user) return false;
      if (accessString) {
        const department = user.access.substring(0, 1);
        if (department === 'Z') return true;

        const position = user.access.substring(1);
        const dict = {};
        accessString
          .match(/([AFECSZ][hratcpspm]*)/g)
          .map((m) => (dict[m.substring(0, 1)] = m.substring(1)));

        if (position === 'm') {
          return dict[department] != null;
        }
        if (dict[department] !== undefined) {
          if (dict[department].length === 0) return true;
          return dict[department].includes(position);
        }
      }
      return false;
    },
    [user]
  );

  return hasAccess;
}

export default useAccess;
