import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { createDeceased, updateDeceased } from '../../fetchers/deceased.fetch';
import { getErrors, ssn } from '../../validations/yupSchemas';
import { mixed, object, string } from 'yup';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import EEForm from '../EEForm';
import FileTable from '../FileTable';
import Memos from '../Memos';
import PrintableDialog from '../PrintableDialog';
import React from 'react';
import Tabs from '../Tabs';
import TempRecords from './TempRecords';
import { createFile } from '../../fetchers/file.fetch';
import { ssnMask } from '../../validations/imask';
import { toast } from 'react-toastify';
import useMutation from '../../services/httpClient/useMutation';

const schema = object({
  _id: string(),
  name: string()
    .transform((value) => value.trim().replace(' ', ''))
    .required('請輸入姓名'),
  ssn: ssn.when(
    ['deceasedType', '$legacy', '$hasFile'],
    (deceasedType, legacy, hasFile, sch) => {
      if (
        deceasedType === '靈位' ||
        deceasedType === '光明燈' ||
        legacy ||
        hasFile
      ) {
        return sch.nullable();
      }
      return sch.nullable().required('請輸入身分證字號');
    }
  ),
  deceasedType: string().required('請輸入類別'),
  sex: string().when('deceasedType', (deceasedType, sch) => {
    if (deceasedType === '靈位' || deceasedType === '光明燈') {
      return sch.nullable();
    }
    return sch.required('請輸入性別');
  }),
  birth: string()
    .nullable()
    .when(
      ['deceasedType', '$legacy', '$hasFile'],
      (deceasedType, legacy, hasFile, sch) => {
        if (
          deceasedType === '靈位' ||
          deceasedType === '光明燈' ||
          legacy ||
          hasFile
        ) {
          return sch;
        }
        return sch.required('請輸入出生日期');
      }
    ),
  death: string()
    .nullable()
    .when(
      ['deceasedType', '$legacy', '$hasFile'],
      (deceasedType, legacy, hasFile, sch) => {
        if (
          deceasedType === '靈位' ||
          deceasedType === '光明燈' ||
          legacy ||
          hasFile
        ) {
          return sch;
        }
        return sch.required('請輸入死亡日期');
      }
    ),
  movein: mixed()
    .transform((value) => {
      if (!value) return null;
      return DateTime.fromISO(value).startOf('day');
    })
    .nullable(),
  moveout: mixed()
    .transform((value) => {
      if (!value) return null;
      return DateTime.fromISO(value).startOf('day');
    })
    .test('afterMovein', '遷出日期必須晚於遷入日期', function (moveout) {
      if (!moveout) return true;
      return DateTime.fromISO(moveout) > DateTime.fromISO(this.parent.movein);
    })
    .test('date', '請檢查日期', (value) => {
      if (!value) return true;
      return value instanceof DateTime
        ? value.isValid
        : DateTime.fromISO(value).isValid;
    }),
});
export function deceasedInfoPanelReducer(state, action) {
  switch (action.type) {
    case 'insert':
      return {
        ...state,
        deceased: {
          name: '',
          ssn: '',
          sex: '',
          birth: '',
          death: '',
          movein: '',
          moveout: '',
          deceasedType: '',
          product: action.product,
        },
      };
    case 'open':
      return { ...state, deceased: action.payload };
    case 'set':
      return { ...state, deceased: { ...state.deceased, ...action.payload } };
    case 'close':
      return { ...state, refresh: Date.now(), deceased: null };
    default:
      throw new Error('請檢查deceasedInfoReducer');
  }
}

function DeceasedInfoPanel({ control, onSave }) {
  const [state, dispatch] = control;
  const [errors, setErrors] = React.useState({});
  const [form, setForm] = React.useState(false);
  const fileInput = React.useRef();
  const createRequest = useMutation(createDeceased, {
    onSuccess: () => {
      toast.success('成功新增使用人');
    },
  });
  const updateRequest = useMutation(updateDeceased, {
    onSuccess: (data) => {
      dispatch({ type: 'set', payload: data });
      toast.success('成功儲存使用人');
    },
  });
  const fileCreateRequest = useMutation(createFile);

  if (!state.deceased) return null;
  const deceased = state.deceased;
  const archived =
    deceased.moveout &&
    DateTime.fromISO(deceased.moveout) < DateTime.now().startOf('month');
  function validate() {
    return schema
      .validate(deceased, {
        abortEarly: false,
        context: {
          legacy:
            deceased._id &&
            (!deceased.movein ||
              DateTime.fromISO(deceased.movein) < DateTime.local(2022, 1, 1)),
          hasFile: !!deceased.file || (!!deceased._id && !!deceased.affidavit),
        },
      })
      .then(() => {
        setErrors({});
        return true;
      })
      .catch((err) => {
        console.log(err);
        setErrors(getErrors(err));
        return false;
      });
  }
  async function handleMemo(memo) {
    try {
      if (typeof memo === 'object' && memo.content.length !== 0) {
        await updateRequest.mutateAsync({
          id: deceased._id,
          memos: deceased.memos.concat(memo),
        });
      } else {
        await updateRequest.mutateAsync({
          id: deceased._id,
          memos: deceased.memos.filter((m) => m._id !== memo),
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  function handleTempSave(payload) {
    let updated;
    if (payload._id) {
      updated = deceased.temps.map((record) => {
        if (record._id === payload._id) {
          return payload;
        }
        return record;
      });
    } else {
      updated = deceased.temps.concat(payload);
    }
    updateRequest.mutate({ id: deceased._id, temps: updated });
  }
  async function handleSave() {
    if (!(await validate(deceased))) return;
    if (deceased._id) {
      updateRequest.mutate({ id: deceased._id, ...deceased });
    } else {
      if (deceased.file) deceased.affidavit = true;
      createRequest.mutate(
        { ...deceased },
        {
          onSuccess: (response) => {
            if (deceased.file) {
              const data = new FormData();
              const ext = deceased.file.name.split('.').pop();
              data.append('file', deceased.file, encodeURI(`切結書.${ext}`));
              data.append('tag', response._id.toString());
              data.append('lock', true);
              fileCreateRequest.mutate(data);
            }
            dispatch({ type: 'set', payload: response });
          },
        }
      );
    }
    dispatch({ type: 'close' });
  }
  function handleClose() {
    dispatch({ type: 'close' });
    setErrors({});
  }
  function handleDelete() {}

  return (
    <Dialog open={!!deceased} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>歿者資料</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              size="small"
              label="姓名"
              value={deceased.name || ''}
              onChange={(e) =>
                dispatch({
                  type: 'set',
                  payload: { name: e.target.value, stale: true },
                })
              }
              error={errors.name}
              helperText={errors.name?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl size="small" fullWidth error={!!errors.deceasedType}>
              <InputLabel>類別</InputLabel>
              <Select
                label="類別"
                value={deceased.deceasedType || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'set',
                    payload: { deceasedType: e.target.value, stale: true },
                  })
                }>
                <MenuItem value="骨灰">骨灰</MenuItem>
                <MenuItem value="骨罈">骨罈</MenuItem>
                <MenuItem value="靈位">靈位</MenuItem>
                <MenuItem value="土葬">土葬</MenuItem>
                <MenuItem value="光明燈">光明燈</MenuItem>
              </Select>
              <FormHelperText>{errors.deceasedType?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl size="small" fullWidth error={!!errors.sex}>
              <InputLabel>性別</InputLabel>
              <Select
                label="性別"
                value={deceased.sex || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'set',
                    payload: { sex: e.target.value, stale: true },
                  })
                }>
                <MenuItem value="m">男</MenuItem>
                <MenuItem value="f">女</MenuItem>
              </Select>
              <FormHelperText>{errors.sex?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              size="small"
              label="身分證字號"
              value={deceased.ssn || ''}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                ssnMask.onChange(e);
                dispatch({
                  type: 'set',
                  payload: { ssn: e.target.value, stale: true },
                });
              }}
              error={errors.ssn}
              helperText={errors.ssn?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="出生日期"
              value={deceased.birth}
              onChange={(v) =>
                dispatch({
                  type: 'set',
                  payload: { birth: v.startOf('day'), stale: true },
                })
              }
              inputFormat="yyyy-MM-dd"
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                  error={!!errors.birth}
                  helperText={errors.birth?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="死亡日期"
              value={deceased.death}
              onChange={(v) =>
                dispatch({
                  type: 'set',
                  payload: { death: v.startOf('day'), stale: true },
                })
              }
              inputFormat="yyyy-MM-dd"
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                  error={!!errors.death}
                  helperText={errors.death?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="遷入日期"
              value={deceased.movein}
              onChange={(v) =>
                dispatch({
                  type: 'set',
                  payload: { movein: v.startOf('day'), stale: true },
                })
              }
              inputFormat="yyyy-MM-dd"
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                  error={!!errors.movein}
                  helperText={errors.movein?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="遷出日期"
              value={deceased.moveout}
              onChange={(v) =>
                dispatch({
                  type: 'set',
                  payload: { moveout: v.startOf('day'), stale: true },
                })
              }
              inputFormat="yyyy-MM-dd"
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                  error={!!errors.moveout}
                  helperText={errors.moveout?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      {deceased._id && (
        <Tabs
          sx={{ m: 2 }}
          tabs={[
            {
              title: '備註',
              content: <Memos memos={deceased.memos} onSubmit={handleMemo} />,
              marginless: true,
            },
            {
              title: '暫厝紀錄',
              content: (
                <TempRecords
                  deceased={deceased}
                  records={deceased.temps}
                  onSave={handleTempSave}
                />
              ),
            },
            {
              title: '檔案清單',
              content: <FileTable tag={deceased._id} />,
            },
          ]}
        />
      )}
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {!deceased._id && (
              <Grid
                container
                sx={{
                  mt: 0.5,
                  cursor: 'pointer',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => fileInput.current.click()}>
                <Grid item>
                  <CheckCircleIcon
                    sx={{
                      color: (theme) =>
                        deceased.file
                          ? theme.palette.success.main
                          : theme.palette.grey[500],
                      mr: 1,
                    }}
                  />
                </Grid>
                <Grid item>上傳切結書</Grid>
                <input
                  ref={fileInput}
                  accept="*"
                  type="file"
                  onChange={(event) =>
                    dispatch({
                      type: 'set',
                      payload: { file: event.target.files[0], stale: true },
                    })
                  }
                  hidden
                />
              </Grid>
            )}
            {(deceased.deceasedType === '靈位' ||
              deceased.deceasedType === '光明燈') &&
              !!deceased.movein &&
              !deceased.moveout &&
              !deceased.stale && (
                <ShowEEForm deceased={deceased} form={form} setForm={setForm} />
              )}
            {!deceased.stale &&
              deceased.deceasedType !== '靈位' &&
              deceased.deceasedType !== '光明燈' &&
              (deceased.temps?.length > 0 || !!deceased.movein) &&
              !deceased.moveout &&
              (deceased.affidavit ? (
                <ShowEEForm deceased={deceased} form={form} setForm={setForm} />
              ) : !!deceased.movein &&
                deceased.sex &&
                deceased.ssn &&
                deceased.birth &&
                deceased.death ? (
                <ShowEEForm deceased={deceased} form={form} setForm={setForm} />
              ) : null)}
            {!archived &&
              deceased.movein &&
              deceased.moveout &&
              deceased.deceasedType &&
              deceased.sex &&
              !deceased.stale && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setForm(true);
                    }}>
                    遷出憑證
                  </Button>
                  <PrintableDialog
                    open={form}
                    onClose={() => setForm(false)}
                    maxWidth="lg"
                    fullWidth>
                    <EEForm
                      deceased={deceased}
                      mode="out"
                      onClose={() => setForm(false)}
                    />
                  </PrintableDialog>
                </Grid>
              )}
            {deceased._id && false && (
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}>
                  刪除
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              取消
            </Button>
            {!archived && (
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 2 }}
                onClick={handleSave}>
                儲存
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

function ShowEEForm({ deceased, form, setForm }) {
  return (
    <Grid item>
      <Button
        variant="contained"
        color="error"
        onClick={() => {
          setForm(true);
        }}>
        使用憑證
      </Button>
      <PrintableDialog
        open={form}
        onClose={() => setForm(false)}
        maxWidth="lg"
        fullWidth>
        <EEForm deceased={deceased} mode="in" onClose={() => setForm(false)} />
      </PrintableDialog>
    </Grid>
  );
}

export default DeceasedInfoPanel;
