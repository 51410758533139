import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { Grid, Paper, TextField, buttonClasses } from '@mui/material';
import { grey, indigo, red } from '@mui/material/colors';

import React from 'react';
import localeText from '../utils/dataGridLocale';

export function ListPage({
  columns,
  rows,
  onCellDoubleClick,
  getRowClassName,
  getCellClassName,
  onSearch,
  children,
  striped = true,
  selection = true,
  getDetailPanelHeight,
  getDetailPanelContent,
  getRowId,
  apiRef,
  pageSize: initialSize = 10,
  page: initialPage = 0,
  onPageSizeChange,
  onPageChange,
  sx,
}) {
  const [keyword, setKeyword] = React.useState('');
  const [page, setPage] = React.useState(initialPage);
  const [pageSize, setPageSize] = React.useState(initialSize);

  const styles = {
    '& .MuiDataGrid-root': {
      borderColor: 'transparent',
      fontSize: '105%',
    },
    [`& .${gridClasses.columnHeader}`]: {
      px: 2,
      color: (theme) => theme.palette.gray.main,
    },
    [`& .${gridClasses.cell}`]: {
      px: 2,
      color: (theme) => theme.palette.gray.darker,
    },
    [`& .${gridClasses.row}`]: {
      '&.open': {
        bgcolor: 'rgba(227, 242, 253, 0.7)',
      },
      '&.invalidated, &.detail-header': {
        bgcolor: grey[50],
      },
      '&.paid': {
        bgcolor: 'rgba(0, 150, 0, 0.12)',
      },
      '&.error': {
        bgcolor: red[50],
      },
    },
    '& a': {
      color: (theme) => theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    [`& .${buttonClasses.root}`]: {
      p: 0,
      fontSize: 16,
      justifyContent: 'flex-start',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    ...sx,
    [`& .${gridClasses.row}:hover`]: {
      bgcolor: indigo[50],
    },
  };
  if (striped) {
    styles[
      '& .MuiDataGrid-row:nth-of-type(even):not(:hover):not(.Mui-selected)'
    ] = {
      backgroundColor: striped === true ? '#F9F9F9;' : 'inherit',
    };
  }

  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && keyword.length > 0) {
      event.preventDefault();
      onSearch(keyword);
      setKeyword('');
    }
  }

  return (
    <Paper elevation={1} sx={styles}>
      {onSearch && (
        <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
          <Grid item>
            <TextField
              vairant="contained"
              size="small"
              placeholder="查詢..."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Grid>
        </Grid>
      )}
      {children}
      <DataGridPro
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        sx={{ fontSize: (theme) => theme.typography.body1 }}
        getRowId={(params) => (getRowId ? getRowId(params) : params._id)}
        localeText={localeText}
        onCellDoubleClick={onCellDoubleClick}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        disableSelectionOnClick={!selection}
        pageSize={pageSize || 10}
        onPageSizeChange={(newSize) => {
          setPageSize(newSize);
          onPageSizeChange && onPageSizeChange(newSize);
        }}
        page={page}
        onPageChange={(newPage) => {
          setPage(newPage);
          onPageChange && onPageChange(newPage);
        }}
        rowsPerPageOptions={[10, 50, 100]}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        pagination
        autoHeight
      />
    </Paper>
  );
}
