import {
  blue,
  green,
  grey,
  lightGreen,
  lime,
  orange,
  teal,
  yellow,
} from '@mui/material/colors';

export const statuses = {
  draft: { label: '草稿', color: yellow[800] },
  audit: { label: '送簽', color: orange[700] },
  purchase: { label: '採購', color: lime[700] },
  shipment: { label: '到貨', color: lightGreen[700] },
  inspection: { label: '驗收', color: green[700] },
  payment: { label: '請款', color: teal[700] },
  confirmed: { label: '月結', color: teal[700] },
  closed: { label: '結案', color: blue[700] },
  invalidated: { label: '作廢', color: grey[700] },
};

export const itemTypes = {
  t: '品項',
  u: '預設',
  m: '自行輸入',
};

export const titles = {
  dep: '部門',
  admin: '覆核',
  gm: '總經理',
};
