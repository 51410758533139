import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';

import React from 'react';

const initialState = {
  open: false,
  errors: {},
  vendor: {
    name: '',
    title: '',
    tax: '',
    address: '',
    phone: '',
    website: '',
  },
};

function AddVendorDialog({ state, setState, onAdd }) {
  React.useEffect(() => {
    setState(initialState);
  }, [setState, initialState]);

  function handleField(field) {
    return {
      value: state.vendor[field],
      onChange: (event) =>
        setState({
          ...state,
          vendor: { ...state.vendor, [field]: event.target.value },
        }),
    };
  }
  function handleAdd() {
    const err = {};
    if (state.vendor.title.trim().length === 0) {
      err.title = { message: '請輸入公司抬頭' };
    }
    if (state.vendor.name.trim().length === 0) {
      err.name = { message: '請輸入簡稱' };
    }
    if (Object.keys(err).length > 0) {
      return setState({ ...state, errors: err });
    }
    onAdd(state.vendor);
  }

  if (!state) return null;

  return (
    <Dialog
      open={state.open}
      onClose={() => setState(initialState)}
      maxWidth="xs"
      fullWidth>
      <DialogTitle>新增廠商</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              label="公司抬頭"
              {...handleField('title')}
              error={!!state.errors.title}
              helperText={state.errors.title?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              label="簡稱"
              error={!!state.errors.name}
              helperText={state.errors.name?.message}
              {...handleField('name')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              label="統編"
              {...handleField('tax')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              label="電話"
              {...handleField('phone')}
              fullWidth
            />
          </Grid>
        </Grid>
        {state.error && <Alert severity="error">{state.error}</Alert>}
        <div style={{ textAlign: 'right', marginTop: 20 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mr: 2 }}
            onClick={() => setState(initialState)}>
            取消
          </Button>
          <Button variant="contained" color="primary" onClick={handleAdd}>
            新增
          </Button>
        </div>
      </Box>
    </Dialog>
  );
}

export default AddVendorDialog;
