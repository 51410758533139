import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { mobileMask, phoneMobileMask } from '../../validations/imask';

import { Box } from '@mui/system';
import React from 'react';

function AddBrokerDialog({ state, setState, initialState, onSave }) {
  function reset() {
    setState((prev) => ({ ...prev, broker: initialState }));
  }
  function handleClose() {
    reset();
    setState((prev) => ({ ...prev, open: false }));
  }
  function switchMode(e) {
    setState({
      ...state,
      mode: e.target.value,
      broker: { ...state.broker, phone: '' },
    });
  }
  function canSave() {
    const broker = state.broker;
    if (broker.name.length === 0) return false;
    if (phoneMobileMask.unmask(broker.phone).length < 8) return false;
    if (state.mode === '公司') {
      if (broker.title.length === 0) return false;
      if (broker.address.length === 0) return false;
    }
    return true;
  }

  return (
    <Dialog open={state.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>新增仲介來源</DialogTitle>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <FormControl size="small" fullWidth>
            <InputLabel>公司</InputLabel>
            <Select label="公司" value={state.mode} onChange={switchMode}>
              <MenuItem value="公司">公司</MenuItem>
              <MenuItem value="個人">個人</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {state.mode === '公司' && (
            <CompanyForm
              broker={state.broker}
              setBroker={(update) => setState({ ...state, broker: update })}
            />
          )}
          {state.mode === '個人' && (
            <IndividualForm
              broker={state.broker}
              setBroker={(update) => setState({ ...state, broker: update })}
            />
          )}
        </Grid>
      </Grid>
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          取消
        </Button>
        {canSave() && (
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            onClick={onSave}>
            儲存
          </Button>
        )}
      </Box>
    </Dialog>
  );
}

function CompanyForm({ broker, setBroker }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          size="small"
          label="公司抬頭"
          value={broker.title}
          onChange={(e) => setBroker({ ...broker, title: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          size="small"
          label="簡稱"
          value={broker.name}
          onChange={(e) => setBroker({ ...broker, name: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          size="small"
          label="電話"
          value={broker.phone}
          onChange={(e) =>
            setBroker({
              ...broker,
              phone: phoneMobileMask.mask(e.target.value),
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          size="small"
          label="地址"
          value={broker.address}
          onChange={(e) => setBroker({ ...broker, address: e.target.value })}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

function IndividualForm({ broker, setBroker }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          size="small"
          label="姓名"
          value={broker.name}
          onChange={(e) => setBroker({ ...broker, name: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          size="small"
          label="手機"
          value={broker.phone}
          onChange={(e) =>
            setBroker({ ...broker, phone: mobileMask.mask(e.target.value) })
          }
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default AddBrokerDialog;
