import { Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { Box } from '@mui/system';
import React from 'react';

function Tabs({ tabs, sx, elevation = 1, onChange }) {
  const [selectedTab, setSelectedTab] = React.useState(
    tabs.length > 0 ? tabs[0].title : ''
  );
  return (
    <Paper elevation={elevation} sx={sx}>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(e, val) => {
              setSelectedTab(val);
              if (onChange) {
                onChange(val);
              }
            }}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.title} value={tab.title} />
            ))}
          </TabList>
        </Box>
        {tabs.map(
          (tab, index) =>
            !tab.hidden && (
              <TabPanel
                key={index}
                value={tab.title}
                sx={{ p: tab.marginless ? 0 : null }}>
                {tab.content}
              </TabPanel>
            )
        )}
      </TabContext>
    </Paper>
  );
}

export default Tabs;
