import { Button } from '@mui/material';
import React from 'react';
import { indigo } from '@mui/material/colors';

export function TabButton({ selected, index, content, area, sx }) {
  const [selectedIndex, setSelectedIndex] = selected;
  return (
    <Button
      variant="contained"
      sx={{
        // color: (theme) =>
        //   selectedIndex === index
        //     ? theme.palette.primary.main
        //     : theme.palette.gray.light,
        // textDecoration: selectedIndex === index ? 'underline' : 'none',
        color: 'white',
        backgroundColor: (theme) =>
          selectedIndex === index
            ? area === '頂福陵園'
              ? theme.palette.primary.light
              : indigo[300]
            : '#A0A0A0',
        fontSize: '15px',
        ':hover': {
          textDecoration: 'underline',
          backgroundColor: area !== '頂福陵園' ? indigo[500] : null,
        },
        boxShadow: 'none',
        py: 0.05,
        px: 1,
        mr: 1,
        ...sx,
      }}
      onClick={() => setSelectedIndex(index)}>
      {content}
    </Button>
  );
}
