import { Grid, TextField } from '@mui/material';

import { CollapseControl } from '../../components/CollapseControl';
import React from 'react';
import { phoneMobileMask } from '../../validations/imask';

export function BrokerInfo({ state, setState }) {
  const [collapsed, setCollapsed] = React.useState(true);
  return (
    <>
      {state.company && (
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="公司"
            value={state.company.name || ''}
            onChange={(e) =>
              setState({
                ...state,
                company: { ...state.company, name: e.target.value },
              })
            }
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label={state.company ? '簡稱' : '姓名'}
          value={state.name || ''}
          onChange={(e) =>
            setState({
              ...state,
              name: e.target.value,
            })
          }
        />
      </Grid>
      {state.company && (
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="統編"
            value={state.company.tax || ''}
            onChange={(e) =>
              setState({
                ...state,
                company: { ...state.company, tax: e.target.value },
              })
            }
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="電話"
          value={phoneMobileMask.maskDefault(state.phone || '')}
          onChange={(e) =>
            setState({
              ...state,
              phone: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="地址"
          value={state.address || ''}
          onChange={(e) =>
            setState({
              ...state,
              address: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="網頁"
          value={state.website || ''}
          onChange={(e) =>
            setState({
              ...state,
              website: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CollapseControl
          title="* 匯款資訊"
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </Grid>
      {!collapsed && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="銀行"
                value={state.banking.bank}
                onChange={(e) =>
                  setState({
                    ...state,
                    banking: {
                      ...state.banking,
                      bank: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="戶名"
                value={state.banking.accountName}
                onChange={(e) =>
                  setState({
                    ...state,
                    banking: {
                      ...state.banking,
                      accountName: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="帳號"
                value={state.banking.account}
                onChange={(e) =>
                  setState({
                    ...state,
                    banking: {
                      ...state.banking,
                      account: e.target.value,
                    },
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
