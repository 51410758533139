import {
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import {
  gridFilteredSortedRowEntriesSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  queryRegistrations,
  serializeRegistration,
} from '../../fetchers/event.fetch';

import AddRegistrationDialog from './AddRegistrationDialog';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import EditRegistrationDialog from './EditRegistrationDialog';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import ShuttleIcon from '@mui/icons-material/AirportShuttle';
import crumbs from '../../services/crumbs/crumbs.const';
import { handleParams } from '../../utils/handleParams';
import loadScript from '../../utils/loadScript';
import { saveAs } from 'file-saver';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import { useSearchParams } from 'react-router-dom';

const events = [];
for (
  let year = DateTime.now().year - 1911 - 3;
  year <= DateTime.now().year - 1911 + 3;
  year++
) {
  events.push(`${year}新春`, `${year}清明`, `${year}中元`);
}
function processParams(params) {
  const keys = Object.keys(params);
  if (keys.length === 0) {
    let year = DateTime.now().year - 1911;
    let event = '新春';
    const month = DateTime.now().month;

    if (month === 11 || month === 12) year = year + 1;
    if (month >= 2 && month <= 5) event = '清明';
    if (month >= 6 && month <= 9) event = '中元';
    return { event: `${year}${event}` };
  }
  return params;
}
function processQuery(query) {
  const keys = Object.keys(query);
  if (keys.length === 0) return null;
  if (keys.length === 1 && query.event) return null;
  if (keys.length === 1 && (query.item || query.registry)) return null;
  return query;
}
const items = [
  '如意套組',
  '吉祥套組',
  '思親套組',
  '思親套組(2)',
  '甲種牌位',
  '特種牌位',
  '專區牌位',
  '供燈',
  '接駁車',
  '光明燈',
  '吉祥金紙套組',
  '思親金紙套組',
  '黃金套組',
  '富貴套組',
];
const columns = [
  {
    field: 'event',
    headerName: '法會',
    flex: 1,
  },
  {
    field: 'sn',
    headerName: '回條序號',
    flex: 1,
    renderCell: (params) => (
      <span style={{ color: params.row.photo ? 'green' : null }}>
        {params.value}
      </span>
    ),
  },
  {
    field: 'itemName',
    headerName: '品項',
    flex: 1,
  },
  {
    field: 'location',
    headerName: '位置',
    flex: 1,
  },
  {
    field: 'name',
    headerName: '姓名',
    flex: 1,
  },
  {
    field: 'deceased1',
    headerName: '歿者(左)',
    renderCell: (params) =>
      params.row.itemName !== '接駁車' ? (
        <span style={{ color: 'rgb(96, 10, 13)', fontWeight: 'bold' }}>
          {params.row['deceaseds'].at(0) || ''}
        </span>
      ) : (
        <span>{params.row.shuttle.date === 0 ? '第一天' : '第二天'}</span>
      ),
    flex: 1.5,
  },
  {
    field: 'deceased2',
    headerName: '歿者(右)',
    renderCell: (params) =>
      params.row.itemName !== '接駁車' ? (
        <span style={{ color: 'rgb(96, 10, 13)', fontWeight: 'bold' }}>
          {params.row['deceaseds'].at(1) || ''}
        </span>
      ) : (
        <span>{params.row.shuttle.station}</span>
      ),
    flex: 1.5,
  },
  {
    field: 'family1',
    headerName: '陽上1',
    valueGetter: (params) =>
      params.row.itemName !== '接駁車'
        ? params.row['family'].at(0) || ''
        : params.row.shuttle.time,
    flex: 0.8,
  },
  {
    field: 'family2',
    headerName: '陽上2',
    valueGetter: (params) =>
      params.row.itemName !== '接駁車'
        ? params.row['family'].at(1) || ''
        : params.row.shuttle.party + '人',
    flex: 0.8,
  },
  {
    field: 'family3',
    headerName: '陽上3',
    valueGetter: (params) => params.row['family'].at(2) || '',
    flex: 0.8,
  },
  {
    field: 'family4',
    headerName: '陽上4',
    valueGetter: (params) => params.row['family'].at(3) || '',
    flex: 0.8,
  },
  {
    field: 'family5',
    headerName: '陽上5',
    valueGetter: (params) => params.row['family'].at(4) || '',
    flex: 0.8,
  },
];
function RegistrationList(props) {
  useCrumbs([crumbs.eventRegistrationList]);
  const [params, setParams] = useSearchParams();
  const [query, setQuery] = React.useState(processParams(handleParams(params)));
  const [keywords, setKeywords] = React.useState(processQuery(query));
  const [registrations, setRegistrations] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [serializeDialog, setSerializeDialog] = React.useState(false);
  const apiRef = useGridApiRef();
  const registrationsQuery = useQuery(
    ['event', 'orders', keywords],
    queryRegistrations(keywords),
    {
      enabled: !!keywords,
      placeholderData: [],
      staleTime: 1000 * 30,
    },
    {
      timeout: 1000 * 15,
    }
  );

  React.useEffect(() => {
    if (!window.ExcelJS) {
      loadScript(
        'https://cdnjs.cloudflare.com/ajax/libs/exceljs/4.3.0/exceljs.min.js'
      );
    }
  }, []);

  React.useEffect(() => {
    if (registrationsQuery.data) {
      processRegistrations(registrationsQuery.data);
    }
  }, [keywords, registrationsQuery.data]);

  function processRegistrations(data) {
    let registrations = [];
    for (let reg of data) {
      const common = {
        sn: reg.sn,
        user: reg.user,
        name: reg.name,
        phone: reg.phone,
        registry: reg.registry,
        history: reg.history,
        created: reg.created,
        receipts: reg.receipts,
        regId: reg._id,
        carrier: reg.carrier,
        donation: reg.donation,
        photo: reg.photo,
      };
      for (let item of reg.items) {
        registrations.push({
          ...common,
          _id: item._id,
          event: item.event,
          itemName: item.name,
          location: item.location,
          deceaseds: item.deceaseds,
          family: item.family,
          shuttle: item.shuttle,
          desc: item.desc,
          itemHistory: item.history,
        });
      }
    }
    setRegistrations(registrations);
  }
  function filterRegistrations() {
    let filtered = registrations.slice();
    if (params.get('event')) {
      filtered = filtered.filter((reg) => reg.event === params.get('event'));
    }
    if (params.get('item')) {
      filtered = filtered.filter((r) => r.itemName === params.get('item'));
    }
    if (params.get('registry')) {
      filtered = filtered.filter((r) => r.registry === params.get('registry'));
    }
    if (params.get('desc')) {
      filtered = filtered.filter((r) =>
        new RegExp(params.get('desc')).test(r.desc)
      );
    }
    if (params.get('customer')) {
      const regex = new RegExp(params.get('customer'));
      filtered = filtered.filter(
        (r) => regex.test(r.name) || regex.test(r.phone)
      );
    }
    if (params.get('tablet')) {
      const regex = new RegExp(params.get('tablet'));
      filtered = filtered.filter(
        (r) =>
          r.deceaseds.find((d) => regex.test(d)) ||
          r.family.find((f) => regex.test(f))
      );
    }
    if (params.get('location')) {
      filtered = filtered.filter((r) => r.location === params.get('location'));
    }
    return filtered;
  }
  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function handleSearch() {
    const updated = { ...query };
    const keys = Object.keys(query);
    if (keys.length === 1 && (keys[0] === 'item' || keys[0] === 'registry')) {
      return setRegistrations([]);
    }
    for (let key of keys) {
      if (!updated[key]) delete updated[key];
    }
    setKeywords(updated);
    setParams(updated);
  }
  function handleEdit(params) {
    const registration = { ...params.row };
    registration.records = registrations.filter(
      (r) => r.regId === registration.regId
    );
    setEdit(registration);
  }
  function handleLoadItem({ action }) {
    const list = gridFilteredSortedRowEntriesSelector(apiRef);
    const index = list.findIndex((r) => r.id === edit._id);
    if (action === 'next') {
      if (index < list.length - 1) {
        const registration = { ...list[index + 1].model };
        registration.records = registrations.filter(
          (r) => r.regId === registration.regId
        );
        setEdit(registration);
      }
    }
    if (action === 'prev') {
      if (index > 0) {
        const registration = { ...list[index - 1].model };
        registration.records = registrations.filter(
          (r) => r.regId === registration.regId
        );
        setEdit(registration);
      }
    }
  }

  return (
    <>
      <ListPage
        apiRef={apiRef}
        columns={columns}
        rows={filterRegistrations()}
        getRowId={(params) => params._id}
        onCellDoubleClick={handleEdit}>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              value={query.event || ''}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setQuery({ ...query, event: newValue });
                } else if (newValue && newValue.inputValue) {
                  setQuery({ ...query, event: newValue.inputValue });
                } else {
                  setQuery({ ...query, event: newValue });
                }
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={events}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="法會" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel>品項</InputLabel>
              <Select
                label="品項"
                value={query.item || ''}
                onChange={(event) =>
                  setQuery({ ...query, item: event.target.value })
                }>
                <MenuItem value="">全部</MenuItem>
                {items.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl size="small" fullWidth>
              <InputLabel>報名方式</InputLabel>
              <Select
                label="報名方式"
                value={query.registry || ''}
                onChange={(event) =>
                  setQuery({ ...query, registry: event.target.value })
                }>
                <MenuItem value="">全部</MenuItem>
                <MenuItem value="台北">台北</MenuItem>
                <MenuItem value="林口">林口</MenuItem>
                <MenuItem value="劃撥">劃撥</MenuItem>
                <MenuItem value="網路">網路</MenuItem>
                <MenuItem value="預繳">預繳</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="回條序號"
              size="small"
              value={query.sn}
              onChange={(event) =>
                setQuery({ ...query, sn: event.target.value })
              }
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="備註"
              size="small"
              value={query.desc || ''}
              onChange={(event) =>
                setQuery({ ...query, desc: event.target.value })
              }
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="位置"
              size="small"
              value={query.location}
              onChange={(event) =>
                setQuery({ ...query, location: event.target.value })
              }
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="客戶資料"
              size="small"
              value={query.customer || ''}
              onChange={(event) =>
                setQuery({ ...query, customer: event.target.value })
              }
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="牌位資料"
              size="small"
              value={query.tablet || ''}
              onChange={(event) =>
                setQuery({ ...query, tablet: event.target.value })
              }
              onKeyDown={handleKeyDown}
              fullWidth
            />
          </Grid>
        </Grid>
      </ListPage>
      <EditRegistrationDialog
        events={events}
        items={items}
        registration={edit}
        setRegistration={setEdit}
        refetch={registrationsQuery.refetch}
        onLoadItem={handleLoadItem}
      />
      <SerializeDialog
        open={serializeDialog}
        setOpen={setSerializeDialog}
        event={query.event}
      />
      <AddRegistrationDialog open={add} onClose={() => setAdd(false)} />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
          createAction(speedDialIcons.add, '新增報名紀錄', () => setAdd(true)),
          createAction(speedDialIcons.report, '下載報表', () =>
            generateReport(filterRegistrations())
          ),
          createAction(speedDialIcons.list, '匯出報名紀錄', () =>
            setSerializeDialog(true)
          ),
          createAction(
            <ShuttleIcon />,
            '匯出接駁車名單',
            () => generateShuttleList(filterRegistrations()),
            params.get('item') !== '接駁車'
          ),
        ]}
      />
    </>
  );
}
function generateReport(registrations) {
  const wb = new window.ExcelJS.Workbook();
  const ws = wb.addWorksheet();

  const title = ws.getRow(1);

  title.alignment = {
    horizontal: 'center',
    vertical: 'middle',
  };
  title.font = {
    bold: true,
    size: 16,
  };
  title.values = [
    '法會',
    '回條序號',
    '品項',
    '客戶姓名',
    '位置',
    '歿者1',
    '歿者2',
    '陽上1',
    '陽上2',
    '陽上3',
    '陽上4',
    '陽上5',
  ];
  ws.getColumn(1).width = 10;
  ws.getColumn(2).width = 30;
  ws.getColumn(3).width = 12;
  ws.getColumn(4).width = 12;
  ws.getColumn(5).width = 10;
  ws.getColumn(6).width = 22;
  ws.getColumn(7).width = 22;
  for (let i = 0; i < registrations.length; i++) {
    const row = ws.getRow(i + 2);
    if ((i + 2) % 2 !== 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFCF0E4' },
      };
    }
    const reg = registrations[i];
    let values;
    if (reg.itemName !== '接駁車') {
      values = [
        reg.event,
        reg.sn,
        reg.itemName,
        reg.name,
        reg.location,
        reg.deceaseds[0],
        reg.deceaseds[1] || '',
        reg.family[0] || '',
        reg.family[1] || '',
        reg.family[2] || '',
        reg.family[3] || '',
        reg.family[4] || '',
      ];
    } else {
      values = [
        reg.event,
        reg.sn,
        reg.itemName,
        reg.name,
        reg.location,
        reg.shuttle.date === 0 ? '第一天' : '第二天',
        reg.shuttle.station,
        reg.shuttle.time,
        reg.shuttle.party,
      ];
    }
    row.font = {
      size: 14,
    };
    row.values = values;
  }
  wb.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    saveAs(blob, '報名紀錄');
  });
}
function generateShuttleList(registrations) {
  const wb = new window.ExcelJS.Workbook();
  function addWorkSheet(date, station, time) {
    const ws = wb.addWorksheet(
      `${date === 0 ? '1-' : '2-'} ${station} ${time.replace(':', '')}`
    );
    const regs = registrations.filter(
      (reg) =>
        reg.itemName === '接駁車' &&
        reg.shuttle.date === date &&
        reg.shuttle.station === station &&
        reg.shuttle.time === time
    );
    const title = ws.getRow(1);
    ws.mergeCells(1, 1, 1, 4);
    title.alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    title.font = {
      bold: true,
      size: 20,
    };
    title.getCell(1).value = `${
      date === 0 ? '第一天' : '第二天'
    } ${station}站 ${time} 接駁車名單`;
    const header = ws.getRow(2);
    header.alignment = {
      horizontal: 'center',
    };
    header.font = {
      size: 20,
    };
    header.values = ['姓名', '聯絡電話', '人數', '備註'];
    ws.getColumn(1).width = 13;
    ws.getColumn(2).width = 16;
    ws.getColumn(3).width = 13;
    ws.getColumn(3).alignment = {
      horizontal: 'center',
    };
    ws.getColumn(4).width = 25;
    for (let reg of regs) {
      const row = ws.getRow(ws.lastRow.number + 1);
      row.font = {
        size: 16,
      };
      row.values = [reg.name, reg.phone, reg.shuttle.party];
    }
  }

  addWorkSheet(0, '博愛', '07:30');
  addWorkSheet(0, '博愛', '08:30');
  addWorkSheet(0, '博愛', '10:00');
  addWorkSheet(0, '行天宮', '07:30');
  addWorkSheet(0, '行天宮', '08:30');
  addWorkSheet(0, '行天宮', '10:00');
  addWorkSheet(0, '板橋', '07:30');
  addWorkSheet(0, '板橋', '08:30');
  addWorkSheet(0, '板橋', '10:00');
  addWorkSheet(0, '桃園', '08:00');
  addWorkSheet(1, '博愛', '07:30');
  addWorkSheet(1, '博愛', '08:30');
  addWorkSheet(1, '博愛', '10:00');
  addWorkSheet(1, '行天宮', '07:30');
  addWorkSheet(1, '行天宮', '08:30');
  addWorkSheet(1, '行天宮', '10:00');
  addWorkSheet(1, '板橋', '07:30');
  addWorkSheet(1, '板橋', '08:30');
  addWorkSheet(1, '板橋', '10:00');
  addWorkSheet(1, '桃園', '08:00');

  wb.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    saveAs(blob, '接駁車名單');
  });
}
function SerializeDialog({ open, setOpen, event }) {
  const initialState = { event, itemType: '供品' };
  const [state, setState] = React.useState({
    event,
    itemType: '供品',
  });
  const registrationsSerializeRequest = useMutation(serializeRegistration, {
    onSuccess: async (response) => {
      const { result, data } = response;
      setOpen(false);
      setState(initialState);
      if (state.itemType === '牌位') {
        generateTabletsExcel(data);
        toast.success('成功排序牌位');
      }
      if (state.itemType === '牌位加印') {
        generateExtraTabletExcel(data);
        toast.success('成功加印牌位');
      }
      if (state.itemType === '供品') {
        generateOfferingsExcel(data);
        toast.success(
          `成功排序${state.itemType}：如意 ${result[0]} 組、吉祥 ${result[1]} 組、思親 ${result[2]} 組、思親(2) ${result[3]} 組、富貴 ${result[4]} 組`,
          {
            autoClose: false,
          }
        );
      }
    },
  });
  function handleClose() {
    setOpen(false);
    setState(initialState);
  }
  function generateOfferingsExcel(data) {
    const wb = new window.ExcelJS.Workbook();
    const ws = wb.addWorksheet();
    ws.getRow(1).values = [
      '編號1',
      '套組1',
      '殁者1',
      '陽上1',
      '編號2',
      '套組2',
      '殁者2',
      '陽上2',
      '編號3',
      '套組3',
      '殁者3',
      '陽上3',
      '編號4',
      '套組4',
      '殁者4',
      '陽上4',
    ];
    let rowNum = 2;
    let columnNum = 1;
    for (let group of data) {
      for (let record of group) {
        const row = ws.getRow(rowNum);
        row.getCell(columnNum++).value = record.location;
        row.getCell(columnNum++).value = record.item;
        row.getCell(columnNum++).value = record.deceaseds.join('\n');
        row.getCell(columnNum++).value = record.family.join(' ');
        if (columnNum > 16) {
          rowNum += 1;
          columnNum = 1;
        }
      }
    }
    wb.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, '供品清單');
    });
  }
  function generateTabletsExcel(array) {
    const wb = new window.ExcelJS.Workbook();
    //const maxIndex = 7;
    let rowNum = 2;

    for (let type = 0; type < 2; type++) {
      const data = array[type];
      const maxIndex = type === 0 ? 20 : 10;
      const maxRow = type === 0 ? 12 : 10;
      for (let index = 0; index < maxIndex; index++) {
        const ws = wb.addWorksheet(
          (type === 0 ? '甲' : '特') + '第' + (index + 1) + '組'
        );
        ws.getRow(1).values = [
          '流水1',
          '歿者1',
          '陽上1',
          '流水2',
          '歿者2',
          '陽上2',
          '流水3',
          '歿者3',
          '陽上3',
          '流水4',
          '歿者4',
          '陽上4',
          '流水5',
          '歿者5',
          '陽上5',
        ];
        rowNum = 2;
        for (let row = maxRow; row >= 1; row--) {
          let count = 1;

          for (let num = 1 + 15 * index; num <= 15 + 15 * index; num++) {
            const reg = data.find(
              (x) =>
                x.location ===
                row.toString().padStart(2, '0') +
                  num.toString().padStart(3, '0')
            );

            if (reg != null) {
              let cell = 1 + (count - 1) * 3;
              ws.getCell(rowNum, cell).value = reg.location;
              ws.getCell(rowNum, cell + 1).value = reg.deceaseds.join('\n');
              ws.getCell(rowNum, cell + 2).value = reg.family.join(' ');
              count += 1;

              if (count > 5) {
                count = 1;
                rowNum += 1;
              }
            }
          }
        }
      }
    }
    wb.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, '牌位清單');
    });
  }
  function generateExtraTabletExcel(array) {
    const wb = new window.ExcelJS.Workbook();
    let rowNum = 2;

    for (let type = 0; type < 2; type++) {
      const ws = wb.addWorksheet(type === 0 ? '甲' : '特');
      ws.getRow(1).values = [
        '流水1',
        '歿者1',
        '陽上1',
        '流水2',
        '歿者2',
        '陽上2',
        '流水3',
        '歿者3',
        '陽上3',
        '流水4',
        '歿者4',
        '陽上4',
        '流水5',
        '歿者5',
        '陽上5',
      ];
      rowNum = 2;
      let cell = 1;
      for (let item of array[type]) {
        if (cell > 15) {
          rowNum += 1;
          cell = 1;
        }
        ws.getCell(rowNum, cell++).value = item.location;
        ws.getCell(rowNum, cell++).value = item.deceaseds.join('\n');
        ws.getCell(rowNum, cell++).value = item.family.join(' ');
      }
    }
    wb.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      saveAs(blob, '牌位清單');
    });
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>匯出報名紀錄</DialogTitle>
      <Box sx={{ color: 'red', px: 3, pb: 1.5 }}>
        匯出作業可能需要大約30秒，確定後請耐心等候，不要離開頁面。
      </Box>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>法會</InputLabel>
            <Select
              label="法會"
              value={state.event}
              onChange={(event) =>
                setState({ ...state, event: event.target.value })
              }>
              {events.map((event) => (
                <MenuItem value={event}>{event}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>類別</InputLabel>
            <Select
              label="類別"
              value={state.itemType || ''}
              onChange={(event) =>
                setState({ ...state, itemType: event.target.value })
              }>
              <MenuItem value="供品">供品</MenuItem>
              <MenuItem value="牌位">牌位</MenuItem>
              <MenuItem value="牌位加印">牌位加印</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={registrationsSerializeRequest.isLoading}
            onClick={() => registrationsSerializeRequest.mutate(state)}>
            確定
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default RegistrationList;
