import { Grid, TextField } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { ListPage } from '../components/ListPage';
import React from 'react';
import Spinner from '../components/Spinner';
import crumbs from '../services/crumbs/crumbs.const';
import { queryVisit } from '../fetchers/visit.fetch';
import useCrumbs from '../services/crumbs/useCrumbs';
import useQuery from '../services/httpClient/useQuery';

const visitTypes = {
  p: '開塔',
  z: '忠區',
  r: '仁區',
  a: '愛區',
};
const columns = [
  {
    field: 'date',
    headerName: '日期',
    flex: 1,
    valueGetter: (params) => params.row.date?.toDate(),
  },
  {
    field: 'name',
    headerName: '姓名',
    flex: 1,
    valueGetter: (params) => params.row.customer.name,
    renderCell: (params) => {
      return (
        <Link to={`/customer/${params.row.customer._id}`}>{params.value}</Link>
      );
    },
  },
  {
    field: 'type',
    headerName: '類別',
    flex: 1,
    valueGetter: (params) => visitTypes[params.row.visitType] || '',
  },
  {
    field: 'products',
    headerName: '產品',
    flex: 1,
    valueGetter: (params) =>
      params.row.products.map((product) => product.sn).join(', '),
  },
];

function VisitList(props) {
  useCrumbs([crumbs.visitList]);
  const [keyword, setKeyword] = React.useState(DateTime.now());
  const visitsQuery = useQuery(['visits', keyword], queryVisit(keyword), {
    staleTime: 1000 * 30,
  });

  if (visitsQuery.isLoading) return <Spinner />;
  return (
    <ListPage columns={columns} rows={visitsQuery.data}>
      <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
        <Grid item>
          <DatePicker
            inputFormat="yyyy-MM-dd"
            value={keyword}
            onChange={(v) => {
              setKeyword(v.startOf('day'));
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </Grid>
      </Grid>
    </ListPage>
  );
}

export default VisitList;
