import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { CollapseControl } from '../../components/CollapseControl';
import React from 'react';
import { phoneMobileMask } from '../../validations/imask';
import registerState from '../../utils/registerState';

export function VendorInfo({ vendor, setVendor }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const register = registerState(vendor, setVendor, errors);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="公司抬頭"
          {...register('title')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="簡稱"
          {...register('name')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="統一編號"
          {...register('tax')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="地址"
          {...register('address')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="電話"
          {...register('phone', (val) => phoneMobileMask.mask(val))}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          size="small"
          label="網址"
          {...register('website')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <CollapseControl
          title="* 請款方式"
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </Grid>
      {!collapsed && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl size="small" fullWidth>
                <InputLabel>請款方式</InputLabel>
                <Select
                  label="請款方式"
                  value={vendor.paymentMethod.term}
                  onChange={(event) =>
                    setVendor({
                      ...vendor,
                      paymentMethod: {
                        ...vendor.paymentMethod,
                        term: event.target.value,
                      },
                    })
                  }>
                  <MenuItem value="月結">月結</MenuItem>
                  <MenuItem value="請款">請款單</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl size="small" fullWidth>
                <InputLabel>付款方式</InputLabel>
                <Select
                  label="付款方式"
                  value={vendor.paymentMethod.paymentType}
                  onChange={(event) =>
                    setVendor({
                      ...vendor,
                      paymentMethod: {
                        ...vendor.paymentMethod,
                        paymentType: event.target.value,
                      },
                    })
                  }>
                  <MenuItem value="現金支付">現金支付</MenuItem>
                  <MenuItem value="匯款">匯款</MenuItem>
                  <MenuItem value="支票">支票</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="銀行"
                size="small"
                value={vendor.paymentMethod.banking.bank}
                onChange={(event) =>
                  setVendor({
                    ...vendor,
                    paymentMethod: {
                      ...vendor.paymentMethod,
                      banking: {
                        ...vendor.paymentMethod.banking,
                        bank: event.target.value,
                      },
                    },
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="戶名"
                size="small"
                value={vendor.paymentMethod.banking.accountName}
                onChange={(event) =>
                  setVendor({
                    ...vendor,
                    paymentMethod: {
                      ...vendor.paymentMethod,
                      banking: {
                        ...vendor.paymentMethod.banking,
                        accountName: event.target.value,
                      },
                    },
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="帳號"
                size="small"
                value={vendor.paymentMethod.banking.account}
                onChange={(event) =>
                  setVendor({
                    ...vendor,
                    paymentMethod: {
                      ...vendor.paymentMethod,
                      banking: {
                        ...vendor.paymentMethod.banking,
                        account: event.target.value,
                      },
                    },
                  })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
