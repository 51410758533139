export default function registerState(state, setState, errors) {
  return (field, transform) => {
    return {
      value: state[field],
      onChange: (e) => {
        let value = e.target.value;
        if (transform) {
          value = transform(value);
        }
        setState((prev) => ({ ...prev, [field]: value }));
      },
      error: !!errors[field],
      helperText: errors[field]?.message,
    };
  };
}
