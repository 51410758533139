import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  amount,
  date,
  getErrors,
  paymentType,
} from '../../validations/yupSchemas';
import { deletePayment, updatePayment } from '../../fetchers/payment.fetch';
import { object, string } from 'yup';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import React from 'react';
import ReceiptPanel from './ReceiptPanel';
import Tabs from '../Tabs';
import TagPanel from '../TagPanel';
import { blue } from '@mui/material/colors';
import { toast } from 'react-toastify';
import useMutation from '../../services/httpClient/useMutation';

const schema = object({
  company: string().required('請輸入公司'),
  date: date.required('請檢查日期'),
  paymentType: paymentType,
  amount: amount,
  desc: string().required('請輸入內容'),
});

function PaymentInfoDialog({ payment, setPayment, onChange }) {
  const [current, setCurrent] = React.useState();
  const [errors, setErrors] = React.useState({});
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    desc: '',
  });
  const deleteRequest = useMutation(deletePayment, {
    onSuccess: (data) => {
      setCurrent(data);
      onChange(data);
      handleDeleteDialog(false, true);
    },
    onError: (err) => {
      setDeleteState({ ...deleteState, err });
    },
  });
  const updateRequest = useMutation(updatePayment, {
    onSuccess: (data) => {
      setErrors({});
      onChange(data);
      toast.success('成功儲存付費紀錄');
    },
    onError: (err) => {
      setErrors({ api: err });
    },
  });

  React.useEffect(() => {
    if (!payment) return;
    payment.date = DateTime.fromISO(payment.date);
    payment.printStatus = false;
    setCurrent(payment);
  }, [payment]);

  const start = DateTime.now().startOf('week');
  const end = DateTime.now().endOf('week');

  const archived =
    payment &&
    (payment.desc.match('土地款') ||
      payment.deleted ||
      payment.date < start ||
      payment.date > end ||
      payment.receipts.some((r) => !r.invalidated));

  function handleClose() {
    setPayment(null);
    setErrors({});
  }
  function handleDeleteDialog(open, clear) {
    const newState = { ...deleteState, open };
    if (clear) newState.desc = '';
    setDeleteState(newState);
  }
  function handleField(field) {
    const handleFieldChange = (field) => (event) => {
      if (event instanceof DateTime) {
        return setCurrent({ ...current, [field]: event.startOf('day') });
      }
      setCurrent({ ...current, [field]: event.target.value });
    };
    return {
      value: current[field],
      onChange: (event) => handleFieldChange(field)(event),
    };
  }
  function handleDelete() {
    if (deleteState.desc.length > 0) {
      deleteRequest.mutate({
        id: current._id,
        payload: { desc: deleteState.desc },
      });
    } else {
      setDeleteState({ ...deleteState, error: '請輸入刪除原因。' });
    }
  }
  function handleSave() {
    schema
      .validate(current, { abortEarly: false })
      .then((res) => {
        updateRequest.mutate({ id: current._id, payload: current });
      })
      .catch((err) => {
        setErrors(getErrors(err));
      });
  }

  if (!payment || !current) return null;

  return (
    <Dialog open={!!payment} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            a: {
              color: blue[700],
              fontSize: 18,
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            },
          }}>
          <Grid item>付費紀錄</Grid>
          <Grid item>
            <Link to={`/customer/${payment.payer._id}`}>
              {current.payer.name}
            </Link>
          </Grid>
        </Grid>
      </DialogTitle>
      {current.deleted && (
        <Alert severity="warning" style={{ marginBottom: 30 }}>
          付費紀錄已於{' '}
          {DateTime.fromISO(current.deleted.date).toFormat('yyyy-MM-dd')} 由{' '}
          {current.deleted.by.name} 刪除。({current.deleted.desc})
        </Alert>
      )}
      <div style={{ padding: '0 20px 0 20px' }}>
        <Grid container spacing={2.5} style={{ marginBottom: 30 }}>
          <Grid item xs={12} sm={6}>
            <FormControl variant="filled" fullWidth>
              <InputLabel>付費識別碼</InputLabel>
              <FilledInput
                id="outlined-adornment-password"
                value={current._id}
                inputProps={{ readOnly: true }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => navigator.clipboard.writeText(current._id)}
                      edge="end">
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="filled"
              label="收費人員"
              value={`${current.cashier.name} [${DateTime.fromISO(
                current.created
              ).toFormat('yyyy-MM-dd HH:mm')}]`}
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2.5} style={{ marginBottom: 30 }}>
          <Grid item xs={12} sm={6}>
            <FormControl error={errors.company} size="small" fullWidth>
              <InputLabel>公司</InputLabel>
              <Select
                label="公司"
                {...handleField('company')}
                inputProps={{ readOnly: !!archived }}>
                <MenuItem value="福">頂福事業</MenuItem>
                <MenuItem value="利">頂利事業</MenuItem>
                <MenuItem value="茂">辰茂事業</MenuItem>
              </Select>
              <FormHelperText>{errors.company?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="日期"
              inputFormat="yyyy-MM-dd"
              {...handleField('date')}
              readOnly={!!archived}
              renderInput={(params) => (
                <TextField
                  size="small"
                  error={errors.date}
                  helperText={errors.date?.message}
                  fullWidth
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl error={errors.paymentType} size="small" fullWidth>
              <InputLabel>付費方式</InputLabel>
              <Select
                label="付費方式"
                {...handleField('paymentType')}
                inputProps={{ readOnly: !!archived }}>
                <MenuItem value="現金">現金</MenuItem>
                <MenuItem value="匯款">匯款</MenuItem>
                <MenuItem value="信用卡">信用卡</MenuItem>
                <MenuItem value="票據">票據</MenuItem>
                <MenuItem value="超商代碼">超商代碼</MenuItem>
                <MenuItem value="虛擬帳號">虛擬帳號</MenuItem>
                <MenuItem value="劃撥">劃撥</MenuItem>
              </Select>
              <FormHelperText>{errors.paymentType?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              label="金額"
              inputProps={{ readOnly: archived }}
              {...handleField('amount')}
              error={errors.amount}
              helperText={errors.amount?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="內容"
              {...handleField('desc')}
              inputProps={{ readOnly: archived }}
              size="small"
              error={errors.desc}
              helperText={errors.desc?.message}
              fullWidth
            />
          </Grid>
          {errors.api && (
            <Grid item xs={12}>
              <Alert severity="error">{errors.api}</Alert>
            </Grid>
          )}
        </Grid>
        <Tabs
          tabs={[
            {
              title: '發票',
              content: payment.desc.match('土地款') ? (
                <p>土地款不需要開立發票</p>
              ) : (
                <ReceiptPanel payment={current} onChange={onChange} />
              ),
            },
            {
              title: '標籤',
              content: <TagPanel payment={payment} onChange={onChange} />,
            },
          ]}
        />
      </div>
      <div style={{ padding: 20 }}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={6}>
            {!archived && (
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDeleteDialog(true)}>
                刪除
              </Button>
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              取消
            </Button>
            {!archived && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ ml: 2 }}>
                儲存
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={deleteState.open}
        maxWidth="xs"
        handleClose={() => handleDeleteDialog(false)}
        fullWidth>
        <DialogTitle>刪除紀錄</DialogTitle>
        <div style={{ padding: '10px 20px 20px 20px' }}>
          <TextField
            variant="outlined"
            label="原因"
            size="small"
            value={deleteState.desc}
            onChange={(event) =>
              setDeleteState({
                ...deleteState,
                desc: event.target.value,
              })
            }
            autoFocus
            fullWidth
          />
          {deleteState.error && (
            <Alert severity="error" style={{ marginTop: 20, marginBottom: 20 }}>
              {deleteState.error}
            </Alert>
          )}
          <div style={{ marginTop: 30, textAlign: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mr: current.deleted ? 0 : 2 }}
              onClick={() => {
                handleDeleteDialog(false, true);
              }}>
              取消
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete}>
              確定刪除
            </Button>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
}

export default PaymentInfoDialog;
