import { Grid, TextField } from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import { createSummary, querySummaries } from '../../fetchers/summary.fetch';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AddSummaryDialog from './AddSummaryDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { green } from '@mui/material/colors';
import { handleParams } from '../../utils/handleParams';
import { phoneMobileMask } from '../../validations/imask';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'names',
    headerName: '姓名',
    flex: 1,
    valueGetter: (params) => params.value.join(', '),
  },
  {
    field: 'phones',
    headerName: '電話',
    flex: 1,
    valueGetter: (params) =>
      params.value.map((v) => phoneMobileMask.mask(v)).join(', '),
  },
  {
    field: 'date',
    headerName: '日期',
    flex: 1,
    valueGetter: (params) => handleDate(params.row.interactions[0]?.date),
  },
  {
    field: 'status',
    headerName: '狀態',
    flex: 1,
    renderCell: (params) => {
      if (params.value === '成交') {
        return (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              color: green[700],
            }}>
            <CheckCircleIcon sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }} />
            {params.value}
          </span>
        );
      }
      return params.value;
    },
  },
  {
    field: 'deceaseds',
    headerName: '歿者',
    flex: 1,
    valueGetter: (params) => params.value?.map((d) => d.name).join(', '),
  },
  {
    field: 'sales',
    headerName: '業務',
    flex: 1,
    valueGetter: (params) =>
      params.row.interactions[0] ? params.row.interactions[0].by.name : '',
  },
];
function handleDate(date) {
  if (date) {
    return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
  }
  return '';
}

function SummaryList(props) {
  useCrumbs([crumbs.summaryList]);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [insert, setInsert] = React.useState();
  const [query, setQuery] = React.useState(handleParams(params));
  const [keywords, setKeywords] = React.useState(() => {
    if (params.getAll('range').length !== 0) {
      return query;
    }
    return {};
  });
  const summariesQuery = useQuery(
    ['summaries', { ...keywords }],
    querySummaries(keywords),
    { placeholderData: [], staleTime: 1000 * 30 }
  );
  const summaryCreateRequest = useMutation(createSummary, {
    onSuccess: (data) => navigate(`/summary/${data._id}`),
  });

  function handleChange(field) {
    return (event) => {
      if (field === 'range') {
        return setQuery({ ...query, [field]: event });
      }
      setQuery({ ...query, [field]: event.target.value });
    };
  }
  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }
  function handleOpen() {
    setKeywords({ open: true });
  }
  function queryInput(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            value={query.keyword || ''}
            onChange={handleChange('keyword')}
            onKeyDown={handleKeyDown}
            variant="outlined"
            label="查詢"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  if (summariesQuery.isLoading) return <Spinner />;

  return (
    <>
      <ListPage
        columns={columns}
        rows={summariesQuery.data}
        striped={false}
        onCellDoubleClick={(params) => navigate(`/summary/${params.row._id}`)}
        getRowClassName={(params) =>
          params.row.interactions.length === 0 ? 'error' : null
        }
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={queryInput}
        />
      </ListPage>
      <AddSummaryDialog
        state={insert}
        setState={setInsert}
        onAdd={(response) => summaryCreateRequest.mutate(response)}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
          createAction(speedDialIcons.add, '新增追蹤紀錄', () =>
            setInsert(true)
          ),
          createAction(<FindInPageIcon />, '持續追蹤案', handleOpen),
        ]}
      />
    </>
  );
}

export default SummaryList;
