import { toast } from 'react-toastify';
import useHttpClient from './useHttpClient';
import { useMutation as useReactQueryMutation } from 'react-query';

function useMutation(callback, options, axiosConfigs) {
  const client = useHttpClient(axiosConfigs);
  return useReactQueryMutation((states) => callback(client)(states), {
    onError: (err) => {
      toast.error(`發生錯誤：${err}`, {
        autoClose: false,
      });
    },
    ...options,
  });
}

export default useMutation;
