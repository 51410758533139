import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  tableCellClasses,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';

import { Box } from '@mui/system';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { ListPage } from '../../components/ListPage';
import PaidIcon from '@mui/icons-material/Paid';
import React from 'react';
import Spinner from '../../components/Spinner';
import { blue } from '@mui/material/colors';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { handleParams } from '../../utils/handleParams';
import { queryOrders } from '../../fetchers/order.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'sn',
    headerName: '訂單編號',
    flex: 1,
    renderCell: (params) => (
      <Link to={`/order/${params.row._id}`}>{params.row.sn}</Link>
    ),
  },
  {
    field: 'date',
    headerName: '日期',
    flex: 1,
    valueGetter: (params) => params.value?.toDate(),
  },
  {
    field: 'buyers',
    headerName: '訂購人',
    flex: 1,
    renderCell: (params) =>
      params.value.map((buyer) => (
        <Link
          style={{ marginRight: 8 }}
          key={buyer._id}
          to={`/customer/${buyer._id}`}>
          {buyer.name}
        </Link>
      )),
  },
  {
    field: 'saleAttribution',
    headerName: '業務',
    flex: 1,
    valueGetter: (params) => params.value?.main?.name,
  },
  {
    field: 'broker',
    headerName: '仲介來源',
    flex: 1,
    renderCell: (params) => (
      <>
        <Link to={`/broker/${params.row._id}`}>{params.value?.name}</Link>
        {!!params.value &&
          params.value.commission.commissionType !== 0 &&
          (params.value.commission.amount ? (
            <Tooltip
              placement="top"
              title={`金額：${params.value.commission.amount}${
                params.value.commission.date
                  ? `\n完佣日期：${params.value.commission.date?.toDate()}`
                  : ''
              }`}>
              <PaidIcon sx={{ ml: 0.5, fontSize: 20 }} color="success" />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="未完佣">
              <PaidIcon sx={{ ml: 0.5, fontSize: 20 }} color="disabled" />
            </Tooltip>
          ))}
      </>
    ),
  },
  {
    field: 'desc',
    headerName: '備註',
    flex: 1,
  },
];
function getOrderDetail(order) {
  return (
    <Box sx={{ height: 200, overflow: 'auto', px: 5.5 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>產品編號</TableCell>
            <TableCell>權狀編號</TableCell>
            <TableCell>售價</TableCell>
            <TableCell>管理費</TableCell>
            <TableCell>備註</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            [`& .${tableCellClasses.body}, & .${tableCellClasses.head}`]: {
              fontSize: '16px',
            },
            '& a': {
              color: blue[700],
              textDecoration: 'none',
            },
            '& a:hover': {
              textDecoration: 'underline',
            },
          }}>
          {order.items.map((item) => (
            <TableRow key={`${item.sn}`}>
              <TableCell>
                <Link to={`/product/${item._id}`}>{item.sn}</Link>
              </TableCell>
              <TableCell>{item.certificate.sn}</TableCell>
              <TableCell>{item.price + item.const + item.additional}</TableCell>
              <TableCell>{item.strata}</TableCell>
              <TableCell>{item.desc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

function OrderList(props) {
  useCrumbs([crumbs.orderList]);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [keywords, setKeywords] = React.useState(handleParams(params));
  const [query, setQuery] = React.useState(
    keywords || {
      sn: '',
      name: '',
      product: '',
      range: [DateTime.now().startOf('month'), DateTime.now().endOf('month')],
    }
  );
  const ordersQuery = useQuery(['orders', keywords], queryOrders(keywords), {
    placeholder: [],
  });

  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function getInputs(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="訂單編號"
            value={query.sn || ''}
            onChange={handleChange('sn')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="客戶姓名"
            value={query.name || ''}
            onChange={handleChange('name')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="產品編號"
            value={query.product || ''}
            onChange={handleChange('product')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    );
  }
  function handleChange(field) {
    return (event) => {
      setQuery({
        ...query,
        [field]: field === 'range' ? event : event.target.value,
      });
    };
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }

  if (ordersQuery.isLoading) return <Spinner />;
  return (
    <>
      <ListPage
        columns={columns}
        rows={ordersQuery.data}
        striped={false}
        onCellDoubleClick={(params) => navigate(`/order/${params.row._id}`)}
        getDetailPanelContent={(params) => getOrderDetail(params.row)}
        getDetailPanelHeight={() => 200}
        getRowClassName={(params) =>
          params.row.closed ? 'paid' : 'detail-header'
        }
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={getInputs}
        />
      </ListPage>
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
        ]}
      />
    </>
  );
}

export default OrderList;
