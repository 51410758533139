import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Chip as MuiChip,
  Select,
  TextField,
} from '@mui/material';

import Panel from '../../components/Panel';
import React from 'react';
import { phoneMobileMask } from '../../validations/imask';
import { ssn } from '../../validations/yupSchemas';
import { styled } from '@mui/material/styles';

const initialStates = {
  insert: {
    names: [],
    phones: [],
    deceaseds: [],
    source: '親友介紹',
  },
  inputs: {
    name: '',
    phone: '',
    deceased: '',
    ssn: '',
  },
};
const Chip = styled(MuiChip)((params) => ({
  fontSize: 15,
  marginTop: 10,
  marginRight: 10,
}));

function AddSummaryDialog({ state, setState, onAdd }) {
  const [insert, setInsert] = React.useState(initialStates.insert);
  const [inputs, setInputs] = React.useState(initialStates.inputs);
  const [errors, setErrors] = React.useState({});

  function handleKeyDown(field) {
    return (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (field === 'name' && inputs.name.length === 0) return;
        if (field === 'phone' && inputs.phone.length === 0) return;
        if (
          (field === 'deceased' || field === 'ssn') &&
          inputs.deceased.length === 0
        )
          return;
        event.preventDefault();
        if (field === 'name') {
          setInsert({ ...insert, names: [...insert.names, inputs['name']] });
          setInputs({ ...inputs, name: '' });
        }
        if (field === 'phone') {
          setInsert({ ...insert, phones: [...insert.phones, inputs['phone']] });
          setInputs({ ...inputs, phone: '' });
        }
        if (field === 'deceased' || field === 'ssn') {
          if (inputs.ssn !== 0) {
            try {
              ssn.validateSync(inputs.ssn);
              setErrors({});
            } catch (error) {
              return setErrors({ ssn: { message: error.message } });
            }
          }
          setInsert({
            ...insert,
            deceaseds: [
              ...insert.deceaseds,
              { name: inputs.deceased, ssn: inputs.ssn ? inputs.ssn : null },
            ],
          });
          setInputs({ ...inputs, deceased: '', ssn: '' });
        }
      }
    };
  }
  function handleFieldChange(field) {
    return (event) => {
      if (field === 'phone') phoneMobileMask.onChange(event);
      if (field === 'ssn')
        event.target.value = event.target.value.toUpperCase();
      setInputs({ ...inputs, [field]: event.target.value.trim() });
    };
  }
  function handleDelete(field) {
    return (text) => {
      if (field === 'name') {
        setInsert({
          ...insert,
          names: insert.names.filter((name) => name !== text),
        });
      }
      if (field === 'phone') {
        setInsert({
          ...insert,
          phones: insert.phones.filter((phone) => phone !== text),
        });
      }
      if (field === 'deceased') {
        setInsert({
          ...insert,
          deceaseds: insert.deceaseds.filter(
            (deceased) => !text.includes(deceased.name)
          ),
        });
      }
    };
  }
  function handleClose() {
    setInsert(initialStates.insert);
    setInputs(initialStates.inputs);
    setState(false);
  }
  function handleSave() {
    onAdd(insert);
    handleClose();
  }

  return (
    <Dialog open={!!state} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>新增客戶追蹤</DialogTitle>
      <div style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">客戶來源</InputLabel>
              <Select
                label="客戶來源"
                value={insert.source}
                onChange={(event) =>
                  setInsert({ ...insert, source: event.target.value })
                }>
                <MenuItem value="親友介紹">親友介紹</MenuItem>
                <MenuItem value="舊客戶">舊客戶</MenuItem>
                <MenuItem value="網路">網路</MenuItem>
                <MenuItem value="禮儀公司">禮儀公司</MenuItem>
                <MenuItem value="自來">自來</MenuItem>
                <MenuItem value="里長">里長</MenuItem>
                <MenuItem value="其他">其他</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Panel title="客戶資料" minHeight={150}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="姓名"
                    value={inputs.name}
                    onChange={handleFieldChange('name')}
                    onKeyDown={handleKeyDown('name')}
                  />
                  <div style={{ marginTop: 10 }}>
                    {insert.names.map((name) => (
                      <Chip
                        key={name}
                        label={name}
                        onDelete={() => handleDelete('name')(name)}
                      />
                    ))}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      height: 110,
                      border: '1px dashed rgb(190, 190, 190)',
                      position: 'absolute',
                      left: 2,
                    },
                  }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="電話"
                    value={inputs.phone}
                    onChange={handleFieldChange('phone')}
                    onKeyDown={handleKeyDown('phone')}
                  />
                  <div style={{ marginTop: 10 }}>
                    {insert.phones.map((phone) => (
                      <Chip
                        key={phone}
                        label={phone}
                        onDelete={() => handleDelete('phone')(phone)}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Panel>
          </Grid>
          <Grid item xs={12}>
            <Panel title="歿者資料" minHeight={150}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="姓名"
                    value={inputs.deceased}
                    onChange={handleFieldChange('deceased')}
                    onKeyDown={handleKeyDown('deceased')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="身分證字號"
                    value={inputs.ssn}
                    error={!!errors.ssn}
                    helperText={errors.ssn?.message}
                    onChange={handleFieldChange('ssn')}
                    onKeyDown={handleKeyDown('ssn')}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: 10 }}>
                {insert.deceaseds.map((deceased) => (
                  <Chip
                    key={deceased.name}
                    label={`${deceased.name}${
                      deceased.ssn ? ` (${deceased.ssn})` : ''
                    }`}
                    onDelete={() => handleDelete('deceased')(deceased.name)}
                  />
                ))}
              </div>
            </Panel>
          </Grid>
        </Grid>
        <div style={{ textAlign: 'right', marginTop: 20 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mr: insert.names.any() ? 2 : 0 }}
            onClick={handleClose}>
            取消
          </Button>
          {insert.names.any() && (
            <Button variant="contained" color="primary" onClick={handleSave}>
              新增
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default AddSummaryDialog;
