import { Grid, Paper, Tab, Tabs, TextField } from '@mui/material';
import { mobileMask } from '../../validations/imask';
import { Box } from '@mui/system';
import ImageSlider from '../../components/ImageSlider';
import React from 'react';

export function ContactsPanel(
  selected,
  setSelected,
  setContact,
  broker,
  contact
) {
  return (
    <Paper sx={{ mt: 2 }}>
      <Tabs
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        value={selected}
        onChange={(e, val) => {
          setSelected(val);
          setContact({ ...broker.company?.contacts[val] });
        }}>
        {broker.company.contacts.map((contact, index) => (
          <Tab label={contact.name} key={index}></Tab>
        ))}
      </Tabs>
      <Box sx={{ p: 2 }}>
        {contact ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="姓名"
                value={contact.name || ''}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    name: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="職稱"
                value={contact.position || ''}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    position: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="手機"
                value={mobileMask.maskDefault(contact.mobile || '')}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    mobile: mobileMask.mask(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ImageSlider
                tag={broker.company.contacts[selected]._id}
                disabled={!!broker.closed}
              />
            </Grid>
          </Grid>
        ) : (
          <p>無聯絡人</p>
        )}
      </Box>
    </Paper>
  );
}
