import {
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Popover,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { Box } from '@mui/system';
import { CollapseControl } from '../../components/CollapseControl';
import { ContactTable } from '../../components/ContactTable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DateTime } from 'luxon';
import Detail from '../../components/Detail';
import Panel from '../../components/Panel';
import React from 'react';
import { commissionTypes } from './order.const';

export function OrderInfo({
  order,
  total,
  receivable,
  setOrder,
  onDeleteBuyer,
  discount,
}) {
  const [anchorEl, setAnchorEl] = React.useState();
  const [collapsed, setCollapsed] = React.useState(true);
  const navigate = useNavigate();

  if (!order) return null;

  const strata = order?.items.reduce((sum, item) => sum + item.strata, 0);

  function handlePopover(event) {
    if (anchorEl) {
      return setAnchorEl(null);
    }
    setAnchorEl(event.currentTarget);
  }

  return (
    <Paper elevation={2} sx={{ mt: 2 }}>
      <Grid container spacing={2} sx={{ padding: '5px 20px 20px 20px' }}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>訂單識別碼</InputLabel>
            <FilledInput
              id="outlined-adornment-password"
              value={order._id}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => navigator.clipboard.writeText(order._id)}
                    edge="end">
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="filled"
            label="訂單編號"
            value={order.sn}
            readOnly
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: 1 }}>
          {!order.legacy ? (
            <Panel title="訂單資料" padding="25px">
              <Detail title="訂單日期" value={order.date?.toDate()} />
              <Detail title="類別" value={order.orderType} />
              <Detail title="總金額" value={total.toLocaleString()} />
              <Detail title="待收款" value={receivable.toLocaleString()} />
              <Detail
                title="優惠"
                value={
                  discount === 0
                    ? '原價'
                    : total - discount - strata === 0
                    ? '贈送'
                    : `折扣 ${discount.toLocaleString()}`
                }
              />
              {order.closed && (
                <Detail
                  title="訂單完款"
                  value={`${order.closed?.date.toDate()} 由 ${
                    order.closed?.by.name
                  } 完款。`}
                />
              )}
            </Panel>
          ) : (
            <Panel title="訂單資料" padding="25px">
              <Detail title="訂單日期" value={order.date?.toDate()} />
              <Detail title="類別" value={order.orderType} />
              <Detail
                title="總金額"
                value={
                  order.legacy.price
                    ? order.legacy.price.toLocaleString()
                    : total.toLocaleString()
                }
              />
              <Detail
                title="待收款"
                value={
                  order.legacy.receivable
                    ? order.legacy.receivable.toLocaleString()
                    : receivable.toLocaleString()
                }
              />
              <Detail
                title="優惠"
                value={`${order.legacy.discount?.discountType || ''}${
                  order.legacy.discount?.amount
                    ? ' ' + order.legacy.discount?.amount.toLocaleString()
                    : ''
                }`}
              />
              {order.closed && (
                <Detail
                  title="訂單完款"
                  value={`${order.closed?.date.toDate()} 由 ${
                    order.closed?.by.name
                  } 完款。`}
                />
              )}
            </Panel>
          )}
        </Grid>
        <Grid item xs={12} md={5} sx={{ mt: 1 }}>
          <Panel title="訂購人">
            <Buyers
              buyers={order.buyers}
              orderClosed={!!order.closed}
              onPopover={handlePopover}
            />
          </Panel>
        </Grid>
        <Grid item xs={12} md={3} sx={{ mt: 1 }}>
          <Panel title="備註" padding={0}>
            <Box sx={{ px: 2, pt: 2 }}>
              <Detail
                title="限直系轉讓"
                marginless
                value={
                  <Checkbox
                    checked={order.promotional}
                    disabled={!!order.closed}
                    onChange={(event) =>
                      setOrder({
                        ...order,
                        promotional: event.target.checked,
                      })
                    }
                  />
                }
              />
            </Box>
            <TextField
              multiline
              rows={8}
              value={order.desc || ''}
              onChange={(event) =>
                setOrder({ ...order, desc: event.target.value })
              }
              fullWidth
              inputProps={{ readOnly: !!order.closed }}
              sx={{
                '& fieldset': {
                  border: 'none',
                },
              }}
            />
          </Panel>
        </Grid>
        <Grid item xs={12}>
          <CollapseControl
            title="* 其他資訊"
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        </Grid>
        {!collapsed && (
          <>
            <Grid item xs={12} md={4}>
              <Panel
                title={
                  <span>
                    客戶追蹤
                    {order.summary && <small> (雙擊前往客戶追蹤)</small>}
                  </span>
                }
                padding="25px"
                onClick={(e) => {
                  if (!order.summary) return;
                  if (e.detail !== 2) return;
                  navigate(`/summary/${order.summary._id}`);
                }}>
                <Detail title="來源" value={order.summary?.source} />
                {order.summary && <p style={{ fontWeight: 'bold' }}>時序</p>}
                {order.timeline?.map((event) => (
                  <div>
                    <small style={{ marginRight: 14 }}>
                      {DateTime.fromISO(event.date).toFormat(
                        'yyyy-MM-dd HH:mm'
                      )}
                    </small>
                    {`${event.type}${
                      event.broker ? ` (${event.broker.name})` : ''
                    }`}
                  </div>
                ))}
              </Panel>
            </Grid>
            <Grid item xs={12} md={4}>
              <Panel title="業績歸屬" padding="25px">
                <Detail
                  title="主辦業務"
                  value={order.saleAttribution?.main?.name}
                />
                <Detail
                  title="帶看業務"
                  value={order.saleAttribution?.viewing?.name}
                />
                <Detail
                  title="簽約業務"
                  value={order.saleAttribution?.contract?.name}
                />
              </Panel>
            </Grid>
            <Grid item xs={12} md={4}>
              <Panel title="仲介來源" padding="25px">
                <Detail title="仲介" value={order.broker?.name} />
                {order.broker?.commission && (
                  <>
                    <Detail
                      title="佣金"
                      value={
                        commissionTypes[order.broker.commission.commissionType]
                      }
                    />
                    <Detail
                      title="金額"
                      value={order.broker.commission.amount?.toLocaleString()}
                    />
                    <Detail
                      title="發佣日期"
                      value={order.broker.commission.date?.toDate()}
                    />
                  </>
                )}
              </Panel>
            </Grid>
          </>
        )}
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Box
          sx={{
            p: '10px',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Button
            variant="text"
            size="small"
            component={Link}
            to={`/customer/${anchorEl?.getAttribute('data-id')}`}>
            客戶頁面
          </Button>
          {!order.closed && (
            <Button
              variant="text"
              size="small"
              onClick={() => {
                onDeleteBuyer(anchorEl.getAttribute('data-id'));
                setAnchorEl();
              }}>
              刪除
            </Button>
          )}
        </Box>
      </Popover>
    </Paper>
  );
}

function Buyers({ buyers, orderClosed, onPopover }) {
  return (
    <ContactTable>
      <TableHead>
        <TableRow>
          <TableCell sx={{ pl: 3 }}>姓名</TableCell>
          <TableCell>身分證字號</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {buyers.map((contact) => (
          <TableRow key={contact._id}>
            <TableCell>
              {orderClosed ? (
                <Button
                  variant="text"
                  size="small"
                  component={Link}
                  to={`/customer/${contact._id}`}>
                  {contact.name}
                </Button>
              ) : (
                <Button
                  variant="text"
                  size="small"
                  data-id={contact._id}
                  onClick={onPopover}>
                  {contact.name}
                </Button>
              )}
            </TableCell>
            <TableCell>{contact.ssn}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </ContactTable>
  );
}
