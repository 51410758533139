import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Popover,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';

import { Card } from './Card';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ContactTable } from '../../components/ContactTable';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import Detail from '../../components/Detail';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';
import Panel from '../../components/Panel';
import React from 'react';
import { TabButton } from './TabButton';
import TabPanel from '../../components/TabPanel';
import { lotMessage } from './ProductDetail';
import { queryOrders } from '../../fetchers/order.fetch';
import useQuery from '../../services/httpClient/useQuery';

export default function ProductInfo({
  product,
  contactFilter,
  selected,
  deceasedFilter,
  onDeleteContact,
  deceasedControl,
  setCert,
  onMembershipStart,
}) {
  const [contactToDelete, setContactToDelete] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState();
  function handlePopover(event) {
    if (anchorEl) {
      return setAnchorEl(null);
    }
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl();
    setContactToDelete();
  }
  const type = product.sn.substring(0, 1);
  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <Card area={product.area}>
            <div className="title">{product.area}</div>
            <div className="content">{product.sn}</div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card area={product.area}>
            <div className="title">權狀編號</div>
            <div
              className="content"
              style={{
                cursor:
                  product.area === '頂福陵園' && type !== 'D' && type !== 'F'
                    ? 'pointer'
                    : 'inherit',
              }}
              onClick={() => {
                if (
                  product.area === '頂福陵園' &&
                  type !== 'D' &&
                  type !== 'F'
                ) {
                  setCert(true);
                }
              }}>
              {product.certificate?.sn}
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card area={product.area}>
            <div className="title">使用狀態</div>
            <div className="content">{product.status}</div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card area={product.area}>
            {type !== 'F' ? (
              <>
                <div className="title">管理費</div>
                <div className="content">
                  {product.strata.status === '繳清' ? (
                    <Tooltip
                      title={product.strata?.fee || '無資料'}
                      placement="top">
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckCircleIcon sx={{ mr: 1, color: green[700] }} />
                        繳清
                      </Box>
                    </Tooltip>
                  ) : (
                    product.strata.status
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="title">佛事服務</div>
                <div className="content">
                  <MembershipStatus
                    memberships={product.memberships}
                    onStart={onMembershipStart}
                  />
                </div>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={5}>
          <Panel title="產品資料">
            <Detail title="產品類別" value={product.productType} />
            <Detail title="位置" value={product.location} />
            <Detail title="地號" value={lotMessage(product.lot)} />
            <Detail
              title="過戶"
              value={product.lot?.isTransferred ? '已過戶' : ''}
            />
            {product.area === '頂福陵園' && (
              <>
                <Detail title="訂單" value={<OrderLink sn={product.sn} />} />
                <Detail
                  title="轉讓備註"
                  value={
                    product.certificate?.promotional && (
                      <span style={{ color: 'red' }}>僅可轉讓直系親屬</span>
                    )
                  }
                />
                <Detail
                  title="轉讓備註"
                  value={
                    product.certificate?.untransferrable && (
                      <span style={{ color: 'red' }}>不可轉讓</span>
                    )
                  }
                />
              </>
            )}
          </Panel>
        </Grid>
        <Grid item xs={12} md={7}>
          <Panel sx={{ pt: 0.5 }}>
            <Box sx={{ py: 1 }}>
              {contactFilter.owners.length ? (
                <TabButton
                  selected={selected}
                  index={0}
                  content={`權益人(${contactFilter.owners.length})`}
                  area={product.area}
                />
              ) : null}
              {contactFilter.poas.length ? (
                <TabButton
                  selected={selected}
                  index={1}
                  content={`被授權人(${contactFilter.poas.length})`}
                  area={product.area}
                />
              ) : null}
              {contactFilter.contacts.length ? (
                <TabButton
                  selected={selected}
                  index={2}
                  content={`聯絡人(${contactFilter.contacts.length})`}
                  area={product.area}
                />
              ) : null}
              {product.deceaseds.length ? (
                <TabButton
                  selected={selected}
                  index={3}
                  content={`使用人(${product.deceaseds.count(
                    (d) => !d.moveout
                  )}/${product.deceaseds.length})`}
                  area={product.area}
                />
              ) : null}
              {(selected[0] === 3 || selected[0] === 4) &&
                deceasedFilter.hasHidden && (
                  <TabButton selected={selected} index={4} content="顯示全部" />
                )}
            </Box>
            <Owners
              owners={contactFilter.owners}
              selected={selected}
              index={0}
            />
            <Contacts
              contacts={contactFilter.poas}
              selected={selected}
              index={1}
              handlePopover={handlePopover}
            />
            <Contacts
              contacts={contactFilter.contacts}
              selected={selected}
              index={2}
              handlePopover={handlePopover}
            />
            <Deceaseds
              deceaseds={deceasedFilter.hide}
              control={deceasedControl}
              selected={selected}
              index={3}
            />
            <Deceaseds
              deceaseds={product.deceaseds}
              control={deceasedControl}
              selected={selected}
              index={4}
            />
          </Panel>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <div style={{ padding: 10 }}>
          <Button
            variant="text"
            size="small"
            component={Link}
            to={`/customer/${anchorEl?.getAttribute('data-id')}`}>
            客戶頁面
          </Button>
          {anchorEl && !anchorEl.getAttribute('data-type') && (
            <Button
              variant="text"
              size="small"
              onClick={() =>
                setContactToDelete(
                  product.contacts.find(
                    (contact) =>
                      contact._id === anchorEl.getAttribute('data-id')
                  )
                )
              }>
              刪除
            </Button>
          )}
        </div>
      </Popover>
      {contactToDelete && (
        <Dialog open={true} onClose={handleClose}>
          <DialogTitle>確定刪除{contactToDelete.contactType}?</DialogTitle>
          <Box sx={{ px: 7, pt: 1.5, pb: 4 }}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onDeleteContact(contactToDelete);
                    handleClose();
                  }}>
                  確認
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}>
                  取消
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
    </Paper>
  );
}
function Owners({ owners, selected, index }) {
  return (
    <TabPanel selected={selected} index={index} marginless>
      <ContactTable>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>姓名</TableCell>
            <TableCell>身分證字號</TableCell>
            <TableCell>手機</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {owners.map((contact) => (
            <TableRow key={contact._id}>
              <TableCell>
                <Button
                  variant="text"
                  size="small"
                  component={Link}
                  to={`/customer/${contact._id}`}>
                  {contact.name}
                </Button>
              </TableCell>
              <TableCell>{contact.ssn}</TableCell>
              <TableCell>{contact.mobile?.toPhone()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ContactTable>
    </TabPanel>
  );
}
function Contacts({ contacts, selected, index, handlePopover }) {
  return (
    <TabPanel selected={selected} index={index} marginless>
      <ContactTable>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>姓名</TableCell>
            <TableCell>身分證字號</TableCell>
            <TableCell>手機</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact._id}>
              <TableCell>
                <Button
                  variant="text"
                  size="small"
                  data-id={contact._id}
                  onClick={handlePopover}>
                  {contact.name}
                </Button>
              </TableCell>
              <TableCell>{contact.ssn}</TableCell>
              <TableCell>{contact.mobile?.toPhone()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ContactTable>
    </TabPanel>
  );
}
function Deceaseds({ deceaseds, control, selected, index }) {
  const [state, dispatch] = control;
  return (
    <TabPanel selected={selected} index={index} marginless>
      <ContactTable>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>姓名</TableCell>
            <TableCell>身分證字號</TableCell>
            <TableCell>遷入日期</TableCell>
            <TableCell>遷出日期</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deceaseds.map((deceased) => (
            <TableRow
              key={deceased._id}
              sx={{
                '& *': {
                  color: (theme) =>
                    deceased.moveout ? theme.palette.grey[500] : null,
                  fontStyle: deceased.moveout ? 'italic' : 'normal',
                },
              }}>
              <TableCell>
                <Button
                  onClick={() => dispatch({ type: 'open', payload: deceased })}>
                  {deceased.name}
                </Button>
              </TableCell>
              <TableCell>{deceased.ssn}</TableCell>
              <TableCell>
                {deceased.movein?.luxon().toFormat('yyyy-MM-dd')}
              </TableCell>
              <TableCell>
                {deceased.temps.find((t) => !t.moveout)
                  ? (function () {
                      const temp = deceased.temps.find((t) => !t.moveout);
                      return (
                        <Tooltip
                          placement="top"
                          title={`日期：${temp.date.toDate()}、類別：${
                            temp.tempType
                          }、暫厝編號：${
                            temp.sn
                          }、繳費至：${temp.paidUntil.toDate()}`}
                          sx={{ fontSize: 16 }}>
                          <span
                            style={{
                              color: red[500],
                              fontWeight: 600,
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}>
                            <ErrorOutlineIcon sx={{ mr: 0.3, fontSize: 18 }} />{' '}
                            暫厝中
                          </span>
                        </Tooltip>
                      );
                    })()
                  : deceased.moveout?.luxon().toFormat('yyyy-MM-dd')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ContactTable>
    </TabPanel>
  );
}
function MembershipStatus({ memberships, onStart }) {
  const [dialog, setDialog] = React.useState({
    open: false,
    date: DateTime.now(),
  });

  if (memberships.length === 0) return '無資料';
  const active = memberships.find(
    (m) =>
      !!m.start &&
      DateTime.fromISO(m.start).plus({ years: m.timespan }) > DateTime.now()
  );
  if (active)
    return (
      <Tooltip
        placement="top"
        title={`啟用日期：${active.start.toDate()}、結束日期：${DateTime.fromISO(
          active.start
        )
          .plus({ year: active.timespan })
          .toFormat('yyyy-MM-dd')}`}>
        <div style={{ cursor: 'pointer' }}>正常</div>
      </Tooltip>
    );

  function handleCancel() {
    setDialog({ open: false, date: DateTime.now() });
  }
  function handleSubmit() {
    onStart(dialog.date);
    handleCancel();
  }
  if (memberships.find((m) => !m.start))
    return (
      <>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => setDialog({ ...dialog, open: true })}>
          未啟用
        </Box>
        <Dialog
          open={dialog.open}
          onClose={handleCancel}
          maxWidth="xs"
          fullWidth>
          <DialogTitle>啟用佛事</DialogTitle>
          <Box sx={{ p: 2 }}>
            <DatePicker
              label="日期"
              inputFormat="yyyy-MM-dd"
              value={dialog.date}
              onChange={(val) => setDialog({ ...dialog, date: val })}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button
                variant="contained"
                color="warning"
                sx={{ mr: 1 }}
                onClick={handleCancel}>
                取消
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}>
                確定
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  return <div style={{ color: red[500] }}>過期</div>;
}

function OrderLink({ sn }) {
  const ordersQuery = useQuery(
    ['orders', { product: sn }],
    queryOrders({ product: sn }),
    {
      enabled: !!sn,
      placeholder: [],
    }
  );

  if (
    ordersQuery.isLoading ||
    !ordersQuery.data ||
    ordersQuery.data.length === 0
  )
    return null;
  return (
    <>
      {ordersQuery.data.map((order) => (
        <Link
          key={order._id}
          to={`/order/${order._id}`}
          style={{
            marginRight: 10,
            color: !!order.canceled ? grey[600] : undefined,
            textDecoration: !!order.canceled ? 'line-through' : undefined,
          }}>
          {order.sn}
        </Link>
      ))}
    </>
  );
}
