import { Box } from '@mui/material';
import React from 'react';

function TabPanel({
  children,
  index,
  selected,
  marginless,
  height = null,
  sx,
  ...other
}) {
  const [selectedIndex] = selected;
  return (
    <div
      style={{ overflow: 'hidden' }}
      hidden={selectedIndex !== index}
      id={`tabpanel-${index}`}
      {...other}>
      {selectedIndex === index && (
        <Box
          sx={{ p: marginless ? 0 : 3, position: 'relative', height, ...sx }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
