import { DateTime } from 'luxon';

export function handleParams(params) {
  const response = {};
  if (params.getAll('range').length !== 0) {
    response['range'] = [
      DateTime.fromMillis(Number(params.getAll('range')[0])),
      DateTime.fromMillis(Number(params.getAll('range')[1])),
    ];
  }
  const entries = params.entries();
  for (let entry of entries) {
    if (entry[0] !== 'range') {
      response[entry[0]] = entry[1];
    }
  }
  return response;
}
