const departments = {
  A: '行政部',
  F: '財務部',
  E: '管理部',
  C: '工務部',
  S: '業務部',
  Z: '系統',
};

export default departments;
