import { useNavigate, useSearchParams } from 'react-router-dom';

import React from 'react';
import Spinner from '../../components/Spinner';
import { lineLogin } from '../../fetchers/auth.fetch';
import useMutation from '../../services/httpClient/useMutation';
import useSignUser from '../../services/authentication/useSignUser';

function LineLogin() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const signUser = useSignUser();
  const firstRender = React.useRef(() => true);
  const signinRequest = useMutation(lineLogin, {
    onSuccess: (user) => {
      signUser(user);
      navigate('/');
    },
    onError: (err) => {
      console.log('error', err);
      navigate(`/signin?error=${err}`);
    },
  });

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      signinRequest.mutate({ code: searchParams.get('code') });
    }
  }, []);

  return <Spinner />;
}

export default LineLogin;
