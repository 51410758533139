import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import Detail from '../Detail';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './style.module.scss';

function PrintableQuote({ quote, page = 1 }) {
  const ref = React.useRef();
  const length = React.useRef(quote.items.length);
  const [cutoff, setCutoff] = React.useState(quote.items.length - 1);

  React.useEffect(() => {
    if (length.current !== quote.items.length) {
      length.current = quote.items.length;
      setCutoff(quote.items.length - 1);
    }
    if (!ref.current?.clientHeight) return;
    if (ref.current.clientHeight > 550) {
      setCutoff(cutoff - 1);
    }
  }, [ref, ref.current?.clientHeight, quote]);

  if (!quote) return null;
  return (
    <div
      className={
        page === 1 ? `${styles.form}` : `${styles.form} ${styles.pageBreak}`
      }>
      <div style={{ padding: '30px' }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ fontSize: 18 }}>
          <Grid item>
            <span style={{ fontSize: 23, fontWeight: 'bold', marginRight: 10 }}>
              頂福陵園
            </span>
            維修工程報價單
          </Grid>
          <Grid item>{quote.created.date?.toDate()}</Grid>
        </Grid>
        <hr />
        <Grid container sx={{ mt: 1, mb: 1.5 }} spacing={3}>
          <Grid item xs={4}>
            <Detail
              width="40%"
              fs="14px"
              title="報價單號"
              value={`DFC${quote.sn.toString().padStart(5, '0')}`}
            />
            <Detail
              width="40%"
              fs="14px"
              title="客戶專員"
              value={quote.created.by.name}
            />
          </Grid>
          <Grid item xs={4}>
            <Detail
              width="40%"
              fs="14px"
              title="姓名"
              value={quote.customer.name}
            />
            <Detail
              width="40%"
              fs="14px"
              title="聯絡電話"
              value={quote.customer.phone.toPhone()}
              showTitle
            />
          </Grid>
          <Grid item xs={4}>
            <Detail
              width="45%"
              fs="14px"
              title="產品編號"
              value={quote.product.sn}
            />
            <Detail
              width="45%"
              fs="14px"
              title="報價單效期"
              value={DateTime.fromISO(quote.created.date)
                .plus({ weeks: 2 })
                .toDate()}
            />
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>品項</TableCell>
              <TableCell>單價</TableCell>
              <TableCell width={100} align="center">
                數量
              </TableCell>
              <TableCell>金額</TableCell>
              <TableCell>備註</TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={ref}>
            {cutoff === quote.items.length - 1
              ? quote.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>${item.price.toLocaleString()}</TableCell>
                    <TableCell align="center">{item.quantity}</TableCell>
                    <TableCell>
                      ${Math.round(item.price * item.quantity).toLocaleString()}
                    </TableCell>
                    <TableCell>{item.desc}</TableCell>
                  </TableRow>
                ))
              : quote.items.slice(0, cutoff).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>${item.price.toLocaleString()}</TableCell>
                    <TableCell align="center">{item.quantity}</TableCell>
                    <TableCell>
                      ${Math.round(item.price * item.quantity).toLocaleString()}
                    </TableCell>
                    <TableCell>{item.desc}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
          {(cutoff !== quote.items.length - 1 || page !== 1) && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} align="right">
                  第 {page} 頁
                  {cutoff !== quote.items.length - 1 && ' - 以下空白'}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </div>
      {cutoff === quote.items.length - 1 ? (
        <div
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '30px',
          }}>
          <Box sx={{ mb: 2, fontSize: '14px' }}>{quote.desc}</Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.total}
            sx={{
              backgroundColor: '#E9E9E9',
              fontSize: 20,
              padding: '20px 15px',
              borderRadius: 0.5,
            }}>
            <Box>
              {quote.discount && (
                <>
                  <Item
                    sup="小計"
                    value={(
                      quote.amount + quote.discount.amount
                    ).toLocaleString()}
                  />
                  <RemoveIcon sx={{ margin: '0 15px' }} />
                  <Item
                    sup="折扣"
                    value={quote.discount.amount.toLocaleString()}
                  />
                </>
              )}
            </Box>
            <Item
              sup="總金額"
              value={quote.amount.toLocaleString()}
              marginLeft={30}
              bold
              lg
            />
          </Grid>
          <div className={styles.footer}>
            <div>
              {quote.company === '茂' ? (
                <>
                  <div>戶名：辰茂事業股份有限公司</div>
                  <div>銀行：土地銀行-台北分行</div>
                  <div>帳號：005-051-13796-7</div>
                </>
              ) : (
                <>
                  <div>戶名：頂福事業股份有限公司</div>
                  <div>銀行：土地銀行-台北分行</div>
                  <div>帳號：005-051-13765-7</div>
                </>
              )}
            </div>
            <hr />
            <div>頂福陵園</div>
            <div className={styles.details}>
              <div className={styles.detail}>
                <div>
                  <LocationOnIcon sx={{ fontSize: '13px' }} />
                  <span>244 新北市林口區頂福里89號</span>
                </div>
              </div>
              <div className={styles.detail}>
                <div>
                  <LocalPhoneIcon sx={{ fontSize: '13px' }} />
                  <span>(02) 2609-2988</span>
                </div>
              </div>
              <div className={styles.detail}>
                <div>
                  <LanguageIcon sx={{ fontSize: '13px' }} />
                  <span>www.dinfu.com.tw</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PrintableQuote
          quote={{ ...quote, items: quote.items.slice(cutoff) }}
          page={page + 1}
        />
      )}
    </div>
  );
}

function Item({ sup, value, marginLeft, bold, lg }) {
  return (
    <Box
      sx={{
        display: 'inline-block',
        sup: {
          display: 'block',
          color: '#505050',
          fontSize: '12px',
        },
      }}>
      <sup>{sup}</sup>
      <span
        style={{
          marginLeft: marginLeft ?? 15,
          fontWeight: bold && 'bold',
          fontSize: lg && '23px',
        }}>
        ${value.toLocaleString()}
      </span>
    </Box>
  );
}

export default PrintableQuote;
