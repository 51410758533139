import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import {
  getCheckStatus,
  getRequiredCheckRegistration,
  updateRegistration,
} from '../../fetchers/event.fetch';

import { DateTime } from 'luxon';
import LightGallery from 'lightgallery/react';
import { ListPage } from '../../components/ListPage';
import crumbs from '../../services/crumbs/crumbs.const';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import useSas from '../../services/authentication/useSas';
import { useState } from 'react';

const columns = [
  {
    field: 'event',
    headerName: '年度',
    flex: 1,
  },
  {
    field: 'name',
    headerName: '產品',
    flex: 1,
  },
  {
    field: 'deceased1',
    headerName: '歿者(左)',
    renderCell: (params) =>
      params.row.name !== '接駁車' ? (
        <span style={{ color: 'rgb(96, 10, 13)', fontWeight: 'bold' }}>
          {params.row['deceaseds'].at(0) || ''}
        </span>
      ) : (
        <span>{params.row.shuttle.date === 0 ? '第一天' : '第二天'}</span>
      ),
    flex: 1.5,
  },
  {
    field: 'deceased2',
    headerName: '歿者(右)',
    renderCell: (params) =>
      params.row.name !== '接駁車' ? (
        <span style={{ color: 'rgb(96, 10, 13)', fontWeight: 'bold' }}>
          {params.row['deceaseds'].at(1) || ''}
        </span>
      ) : (
        <span>{params.row.shuttle.station}</span>
      ),
    flex: 1.5,
  },
  {
    field: 'family1',
    headerName: '陽上1',
    valueGetter: (params) =>
      params.row.name !== '接駁車'
        ? params.row['family'].at(0) || ''
        : params.row.shuttle.time,
    flex: 0.8,
  },
  {
    field: 'family2',
    headerName: '陽上2',
    valueGetter: (params) =>
      params.row.name !== '接駁車'
        ? params.row['family'].at(1) || ''
        : params.row.shuttle.party + '人',
    flex: 0.8,
  },
  {
    field: 'family3',
    headerName: '陽上3',
    valueGetter: (params) => params.row['family'].at(2) || '',
    flex: 0.8,
  },
  {
    field: 'family4',
    headerName: '陽上4',
    valueGetter: (params) => params.row['family'].at(3) || '',
    flex: 0.8,
  },
  {
    field: 'family5',
    headerName: '陽上5',
    valueGetter: (params) => params.row['family'].at(4) || '',
    flex: 0.8,
  },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));
function getEvent() {
  let year = DateTime.now().year - 1911;
  let event = '新春';
  const month = DateTime.now().month;

  if (month === 11 || month === 12) year = year + 1;
  if (month >= 2 && month <= 5) event = '清明';
  if (month >= 6 && month <= 9) event = '中元';
  return `${year}${event}`;
}

function CheckRegistration(props) {
  useCrumbs([crumbs.eventRegistrationList, crumbs.checkRegistration]);
  const sas = useSas();
  const [inUse, setInUse] = useState(false);
  const checkStatusQuery = useQuery(['event', 'status'], getCheckStatus(), {
    placeholderData: [0, 0, 0],
    staleTime: 1000 * 60 * 1,
  });
  const requireCheckQuery = useQuery(
    ['event', 'requireCheck'],
    getRequiredCheckRegistration(),
    {
      staleTime: 1000 * 60 * 10,
      onSuccess: () => {
        setInUse(false);
      },
    }
  );
  const registrationUpdateRequest = useMutation(updateRegistration, {
    onSuccess: () => {
      toast.success('完成複核');
      checkStatusQuery.refetch();
      requireCheckQuery.refetch();
    },
  });

  function handleSave() {
    if (inUse) return;
    setInUse(true);
    registrationUpdateRequest.mutate({
      id: requireCheckQuery.data._id,
      confirm: true,
    });
  }

  function getProgress() {
    const counts = checkStatusQuery.data?.count;
    if (!counts) return 0;
    return (
      ((counts[1] + counts[2] * 2) /
        ((counts[0] + counts[1] + counts[2]) * 2)) *
      100
    );
  }

  if (!requireCheckQuery.data) return <p>報名紀錄已完成複核</p>;

  return (
    <ListPage
      title=""
      columns={columns}
      rows={requireCheckQuery.data ? requireCheckQuery.data.items : []}
      sx={{ fontSize: 20, fontWeight: 'bold' }}>
      <Box sx={{ padding: 2 }}>
        <Typography>進度 {getProgress().toFixed(2)}%</Typography>
        <BorderLinearProgress
          variant="determinate"
          value={getProgress().toFixed(0)}
        />
      </Box>
      <Grid container spacing={2}>
        {requireCheckQuery.data.registry !== '網路' && (
          <Grid item sx={12} md={6}>
            <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
              <a
                href={`https://dinfucemstorage.blob.core.windows.net/file/${requireCheckQuery.data.photo}.jpg?${sas}`}>
                <img
                  src={`https://dinfucemstorage.blob.core.windows.net/file/${requireCheckQuery.data.photo}.jpg?${sas}`}
                  width="100%"
                  alt="post"
                />
              </a>
            </LightGallery>
          </Grid>
        )}
        <Grid item sx={12} md={6}>
          <Box sx={{ p: 7 }}>
            <p>報名方式：{requireCheckQuery.data.registry}</p>
            <p>回條序號：{requireCheckQuery.data.sn}</p>
            <p>客人姓名：{requireCheckQuery.data.name}</p>
            <p>手機：{requireCheckQuery.data.phone}</p>
            <br />
            {requireCheckQuery.data?.checks.map((check) => (
              <p>{`[${DateTime.fromISO(check.date).toFormat(
                'yyyy-MM-dd HH:mm'
              )}] ${check.by.name} 複核`}</p>
            ))}
          </Box>
        </Grid>
      </Grid>
      <SpeedDial
        actions={[
          createAction(speedDialIcons.save, null, handleSave),
          createAction(speedDialIcons.edit, null, () =>
            window.open(
              `/event/registration?event=${getEvent()}&sn=${
                requireCheckQuery.data.sn
              }`,
              '_blank',
              'rel=noopener noreferrer'
            )
          ),
        ]}
      />
    </ListPage>
  );
}

export default CheckRegistration;
