import { Dialog } from '@mui/material';
import React from 'react';
import styles from './style.module.scss';

function PrintableDialog({ children, open, onClose, maxWidth, fullWidth }) {
  return (
    <Dialog
      className={styles.printable}
      open={!!open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={!!fullWidth}>
      {children}
    </Dialog>
  );
}

export default PrintableDialog;
