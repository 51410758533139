import 'react-toastify/dist/ReactToastify.css';

import { Dialog, DialogTitle, Link } from '@mui/material';

import { AppBar } from './AppBar';
import Box from '@mui/material/Box';
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorIcon from '@mui/icons-material/Error';
import { Outlet } from 'react-router-dom';
import React from 'react';
import Sidebar from './Sidebar';
import { ToastContainer } from 'react-toastify';
import { red } from '@mui/material/colors';
import useHttpClient from '../services/httpClient/useHttpClient';
import useUser from '../services/authentication/useUser';

export const CrumbsContext = React.createContext();

function DefaultLayout() {
  const user = useUser();
  const client = useHttpClient();
  const [crumbs, setCrumbs] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  function toggleSidebar() {
    setOpen((prev) => !prev);
  }
  return (
    <Box
      height="100%"
      sx={{
        backgroundColor: (theme) => theme.palette.appBackground,
        p: {
          xs: 1,
          sm: 2,
        },
      }}>
      <CrumbsContext.Provider value={{ crumbs, setCrumbs }}>
        <AppBar toggleSidebar={toggleSidebar} />
        <Sidebar open={open} toggleSidebar={toggleSidebar} />
        {process.env.NODE_ENV === 'production' && (
          <Dialog open={!user.notify} maxWidth="xs" fullWidth>
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 18,
              }}>
              <ErrorIcon sx={{ mr: 1, pb: 0.2, color: red[500] }} />
              請綁定 LINE Notify
            </DialogTitle>
            <Box sx={{ p: 3, pt: 0 }}>
              <p>網頁使用 LINE Notify 服務來傳送通知，請依照指示綁定服務：</p>
              <ol>
                <li>請點選下方連結</li>
                <li>登入LINE帳號</li>
                <li>選擇「透過1對1聊天接收 LINE Notify 通知」</li>
                <li>完成後網頁會自動登出，請重新登入</li>
              </ol>
              <div style={{ textAlign: 'center' }}>
                <Link
                  href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=Lj7okY1sbaqQOKQTMmxWHM&redirect_uri=${encodeURIComponent(
                    `${process.env.REACT_APP_API_URL}/notify/login-connect?id=${user._id}`
                  )}&state=00000&scope=notify&response_mode=form_post`}>
                  <img
                    src="https://dinfucemstorage.blob.core.windows.net/image/line-notify-logo.png"
                    alt="line login"
                    width="150px"
                  />
                </Link>
              </div>
            </Box>
          </Dialog>
        )}
        <ErrorBoundary client={client} user={user}>
          <Box
            sx={{
              py: 12,
              minHeight: '97vh',
            }}>
            <Outlet />
          </Box>
        </ErrorBoundary>
        <ToastContainer autoClose={5000} />
      </CrumbsContext.Provider>
    </Box>
  );
}

export default DefaultLayout;
