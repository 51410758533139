import { useEffect, useMemo, useRef, useState } from 'react';

import crypto from 'crypto-js';

const SECRET = process.env.REACT_APP_JWT_SECRET;

function fromStorage(value) {
  return value !== null ? JSON.parse(value) : null;
}
function readItem(storage, key) {
  try {
    const bytes = crypto.AES.decrypt(storage.getItem(key), SECRET);
    const storedValue = bytes.toString(crypto.enc.Utf8);
    return fromStorage(storedValue);
  } catch (e) {
    return null;
  }
}
function toStorage(value) {
  return JSON.stringify(value);
}
function writeItem(storage, key, value) {
  try {
    if (value !== null) {
      if (toStorage(value) !== JSON.stringify(readItem(storage, key))) {
        const encrypted = crypto.AES.encrypt(toStorage(value), SECRET);
        storage.setItem(key, encrypted.toString());
      }
    } else {
      storage.removeItem(key);
    }
    return Promise.resolve();
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export function useInitialState(storage, key, defaultState) {
  const defaultStateRef = useRef(defaultState);

  return useMemo(
    () => readItem(storage, key) ?? defaultStateRef.current,
    [key, storage]
  );
}
export function useStorageWriter(storage, key, state) {
  const [writeError, setWriteError] = useState(undefined);

  useEffect(() => {
    writeItem(storage, key, state).catch((error) => {
      if (!error || !error.message || error.message !== writeError?.message) {
        setWriteError(error);
      }
    });

    if (writeError) {
      return () => {
        setWriteError(undefined);
      };
    }
  }, [state, key, writeError, storage]);

  return writeError;
}
export function useStorageListener(storage, key, defaultState, onChange) {
  const defaultStateRef = useRef(defaultState);
  const onChangeRef = useRef(onChange);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    onChangeRef.current(readItem(storage, key) ?? defaultStateRef.current);
  }, [key, storage]);

  useEffect(() => {
    function onStorageChange(event) {
      if (event.key === key) {
        onChangeRef.current(readItem(storage, key) ?? defaultStateRef.current);
      }
    }

    if (
      typeof window !== 'undefined' &&
      typeof window.addEventListener !== 'undefined'
    ) {
      window.addEventListener('storage', onStorageChange);
      return () => {
        window.removeEventListener('storage', onStorageChange);
      };
    }
  }, [key, storage]);
}
