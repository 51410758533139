import { Button, Dialog, DialogTitle } from '@mui/material';

import AddPaymentDialog from './AddPaymentDialog';
import React from 'react';

export function addTaggedPaymentDialogReducer(state, action) {
  switch (action.type) {
    case 'close':
      return { ...state, open: false };
    case 'open':
      return { ...state, open: true };
    case 'refresh':
      return { ...state, refresh: Date.now() };
    default:
      throw new Error('請檢查addTaggedPaymentTableReducer');
  }
}

function AddTaggedPaymentDialog({
  state,
  contacts,
  tag,
  tagType,
  tagLabel,
  onSubmit,
}) {
  const [payer, setPayer] = React.useState();

  return (
    <>
      <Dialog
        open={state.open}
        onClose={() => state.setOpen(false)}
        maxWidth="xs"
        fullWidth>
        <DialogTitle>選擇付款人</DialogTitle>
        <div style={{ padding: '0 30px 30px 30px' }}>
          {contacts.map((contact) => (
            <Button
              key={contact._id}
              sx={{ fontSize: '16px', marginRight: '10px' }}
              onClick={() => {
                setPayer(contact);
                state.setOpen(false);
              }}>
              {contact.name}
            </Button>
          ))}
        </div>
      </Dialog>
      {payer && tag && (
        <AddPaymentDialog
          state={{ isOpen: !!payer, setOpen: () => setPayer(null) }}
          payer={{ _id: payer._id, name: payer.name }}
          onSubmit={onSubmit}
          tag={{
            _id: tag,
            tagType: tagType,
            label: tagLabel,
          }}
        />
      )}
    </>
  );
}

export default AddTaggedPaymentDialog;
