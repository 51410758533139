import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { Box } from '@mui/system';
import CustomerSearchDialog from './CustomerSearchDialog';
import Detail from './Detail';
import React from 'react';
import useHttpClient from '../services/httpClient/useHttpClient';

function AddContactDialog({ state, onChange, contactTypes, title }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState();
  const initialState = {
    id: '',
    contactType: contactTypes[0],
    error: null,
    customer: null,
  };
  const [insert, setInsert] = React.useState(initialState);
  const client = useHttpClient();

  function handleKeyDown(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      insert.id.length > 0
    ) {
      event.preventDefault();
      handleQuery();
      event.target.blur();
    }
  }
  function handleQuery() {
    client
      .get(`/api/customer/${insert.id}?select=name+ssn+mobile`)
      .then((response) => {
        setInsert({ ...insert, customer: response });
      })
      .catch((err) => {
        setInsert({ ...insert, error: err });
      });
  }
  function handleClose() {
    state.setOpen(false);
    setInsert(initialState);
    setError(null);
  }
  async function handleSave() {
    const error = await onChange({
      _id: insert.customer._id,
      name: insert.customer.name,
      ssn: insert.customer.ssn,
      mobile: insert.customer.mobile,
      contactType: insert.contactType,
    });
    if (error) {
      return setError(error);
    }
    setError(null);
    setInsert({ ...initialState, contactType: insert.contactType });
  }

  return (
    <Dialog open={state.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{title ?? '選擇客戶資料'}</DialogTitle>
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">類別</InputLabel>
              <Select
                label="類別"
                value={insert.contactType}
                onChange={(event) =>
                  setInsert({ ...insert, contactType: event.target.value })
                }>
                {contactTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="客戶識別碼"
              value={insert.id}
              onChange={(event) =>
                setInsert({ ...insert, id: event.target.value })
              }
              InputProps={{ readOnly: insert.customer !== null }}
              onKeyDown={handleKeyDown}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <>
          {!insert.customer ? (
            <div style={{ textAlign: 'right' }}>
              <Button onClick={() => setOpen(true)}>選擇客戶資料</Button>
            </div>
          ) : (
            <>
              <div style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => {
                    setInsert({ ...insert, customer: null, id: '' });
                    setError(null);
                  }}>
                  重置客戶
                </Button>
              </div>
              <Detail title="姓名" value={insert.customer.name} />
              <Detail title="身分證字號" value={insert.customer.ssn} />
              <Detail title="行動電話" value={insert.customer.mobile} />
              {error && <Alert severity="error">{error}</Alert>}
              <Grid container justifyContent="flex-end" sx={{ mt: 5 }}>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    sx={{ mr: 2 }}>
                    取消
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}>
                    儲存
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </Box>
      <CustomerSearchDialog
        state={{ open, setOpen }}
        onSelect={(response) => {
          setInsert({ ...insert, id: response._id, customer: response });
        }}
      />
    </Dialog>
  );
}

export default AddContactDialog;
