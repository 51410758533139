import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';
import { blue, green } from '@mui/material/colors';

import AddQuoteDialog from './AddQuoteDialog';
import { Box } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import companies from '../../utils/companies.const';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { handleParams } from '../../utils/handleParams';
import { queryQuotes } from '../../fetchers/quote.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'sn',
    headerName: '報價單編號',
    flex: 1,
    renderCell: (params) => (
      <Link to={`/quote/${params.row._id}`}>{params.row.sn}</Link>
    ),
  },
  {
    field: 'company',
    headerName: '公司',
    flex: 1,
    renderCell: (params) => (
      <span style={{ color: companies[params.value].color }}>
        {params.value}
      </span>
    ),
  },
  {
    field: 'customer',
    headerName: '客戶姓名',
    flex: 1,
    valueGetter: (params) => params.value.name,
  },
  {
    field: 'product',
    headerName: '產品',
    flex: 1,
    renderCell: (params) => (
      <Link to={`/product/${params.value._id}`}>{params.value.sn}</Link>
    ),
  },
  {
    field: 'amount',
    headerName: '金額',
    flex: 1,
    type: 'number',
    valueGetter: (params) => params.value.toLocaleString(),
  },
  {
    field: 'status',
    headerName: '狀態',
    flex: 1,
    renderCell: (params) => {
      if (params.value === '完款') {
        return (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              color: green[700],
            }}>
            <CheckCircleIcon sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }} />{' '}
            {params.value}
          </span>
        );
      }
      return params.value;
    },
  },
];
const classNames = {
  完款: 'paid',
  作廢: 'invalidated',
};
function getDetail(order) {
  return (
    <Box sx={{ height: 300, overflow: 'auto', px: 5.5 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>品名</TableCell>
            <TableCell>單價</TableCell>
            <TableCell>數量</TableCell>
            <TableCell>金額</TableCell>
            <TableCell>備註</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            [`& .${tableCellClasses.body}, & .${tableCellClasses.head}`]: {
              fontSize: '16px',
            },
            '& a': {
              color: blue[700],
              textDecoration: 'none',
            },
            '& a:hover': {
              textDecoration: 'underline',
            },
          }}>
          {order.items.map((item, index) => (
            <TableRow key={`${item._id}-${index}`}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.price.toLocaleString()}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>
                {Math.floor(item.price * item.quantity).toLocaleString()}
              </TableCell>
              <TableCell>{item.desc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

function QuoteList(props) {
  useCrumbs([crumbs.quoteList]);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [addQuoteDialog, setAddQuoteDialog] = React.useState(false);
  const [keywords, setKeywords] = React.useState(handleParams(params));
  const [query, setQuery] = React.useState(keywords);
  const quotesQuery = useQuery(['quotes', keywords], queryQuotes(keywords), {
    placeholder: [],
  });

  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function getQueryInputs(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="報價單編號"
            value={query.sn || ''}
            type="number"
            onChange={handleChange('sn')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="客戶姓名"
            value={query.customer || ''}
            onChange={handleChange('customer')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="產品編號"
            value={query.product || ''}
            onChange={handleChange('product')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>公司</InputLabel>
            <Select
              label="公司"
              value={query.company || ''}
              onChange={(event) =>
                setQuery({ ...query, company: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="福">頂福事業</MenuItem>
              <MenuItem value="利">頂利事業</MenuItem>
              <MenuItem value="茂">辰茂事業</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  function handleChange(field) {
    return (event) => {
      setQuery({
        ...query,
        [field]: field === 'range' ? event : event.target.value,
      });
    };
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }

  if (quotesQuery.isLoading) return <Spinner />;
  if (quotesQuery.isError) return <p>{quotesQuery.error.toString()}</p>;

  return (
    <>
      <ListPage
        columns={columns}
        rows={quotesQuery.data}
        striped={false}
        onCellDoubleClick={(params) => navigate(`/quote/${params.row._id}`)}
        getDetailPanelContent={(params) => getDetail(params.row)}
        getDetailPanelHeight={() => 300}
        getRowClassName={(params) => classNames[params.row.status]}
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={getQueryInputs}
        />
      </ListPage>
      <AddQuoteDialog
        open={addQuoteDialog}
        redirectOnSubmitted
        onClose={() => setAddQuoteDialog(false)}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
          createAction(speedDialIcons.add, '新增報價單', () =>
            setAddQuoteDialog(true)
          ),
        ]}
      />
    </>
  );
}

export default QuoteList;
