import { Link, useNavigate } from 'react-router-dom';
import { green, red } from '@mui/material/colors';

import { Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import { queryProductsBySerialNumber } from '../../fetchers/product.fetch';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'sn',
    headerName: '產品編號',
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/product/${params.id}`}>{params.value}</Link>;
    },
  },
  { field: 'area', headerName: '區域', flex: 1 },
  { field: 'productType', headerName: '類別', flex: 1 },
  { field: 'status', headerName: '使用情形', flex: 1 },
  {
    field: 'strata',
    headerName: '管理費',
    flex: 1,
    renderCell: (params) => {
      if (params.value.fee === 0) return '';
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: params.value.status === '繳清' ? green[700] : red[700],
          }}>
          {params.value.status === '繳清' && (
            <CheckCircleIcon sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }} />
          )}
          {params.value.status === '缺繳' && (
            <DoDisturbOnIcon sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }} />
          )}
          {params.value.status === '未繳' && (
            <CancelIcon sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }} />
          )}
          {params.value.fee}
        </Box>
      );
    },
  },
];

function ProductList(props) {
  useCrumbs([crumbs.productList]);
  const navigate = useNavigate();
  const [keyword, setKeyword] = React.useState('');
  const productsQuery = useQuery(
    ['products', { sn: keyword }],
    queryProductsBySerialNumber({ sn: keyword }),
    {
      staleTime: 1000 * 30,
    }
  );

  function handleDoubleClick(params) {
    navigate(`/product/${params.row._id}`);
  }
  function handleSearch(query) {
    setKeyword(query);
  }

  if (productsQuery.isLoading) return <Spinner />;

  return (
    <>
      <ListPage
        columns={columns}
        rows={productsQuery.isSuccess ? productsQuery.data : []}
        onSearch={handleSearch}
        onCellDoubleClick={handleDoubleClick}
      />
    </>
  );
}

export default ProductList;
