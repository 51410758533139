import AddTaggedPaymentDialog, {
  addTaggedPaymentDialogReducer,
} from '../../components/AddTaggedPaymentDialog';
import { Box, Paper } from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import { queryQuoteById, updateQuote } from '../../fetchers/quote.fetch';

import AddQuoteDialog from './AddQuoteDialog';
import { DateTime } from 'luxon';
import { DiscountDialog } from './DiscountDialog';
import { InvalidateDialog } from './InvalidateDialog';
import { ItemTable } from './ItemTable';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaymentTable from '../../components/PaymentTable';
import PrintableDialog from '../../components/PrintableDialog';
import PrintableQuote from '../../components/PrintableQuote';
import QuoteInfo from './QuoteInfo';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import { useParams } from 'react-router-dom';
import useQuery from '../../services/httpClient/useQuery';
import useUser from '../../services/authentication/useUser';

export const types = {
  t: '品項',
  u: '預設',
  m: '自行輸入',
};

function QuoteDetail(props) {
  useCrumbs([crumbs.quoteList, crumbs.quoteDetail]);
  const { id } = useParams();
  const user = useUser();
  const quoteQuery = useQuery(['quote', id], queryQuoteById({ id }), {
    onSuccess: (data) => setQuote(data),
  });
  const [quote, setQuote] = React.useState(quoteQuery.data);
  const [print, setPrint] = React.useState(false);
  const [paymentDialog, paymentDialogDispatch] = React.useReducer(
    addTaggedPaymentDialogReducer,
    { open: false, refresh: Date.now() }
  );
  const [addQuoteDialog, setAddQuoteDialog] = React.useState(false);
  const [invalidateQuoteDialog, setInvalidateQuoteDialog] = React.useState({
    open: false,
    desc: '',
  });
  const [discountDialog, setDiscountDialog] = React.useState({
    open: false,
    amount: 0,
  });
  const quoteUpdateRequest = useMutation(updateQuote, {
    onSuccess: () => {
      quoteQuery.refetch();
    },
  });

  if (quoteQuery.isLoading) return <Spinner />;
  if (quoteQuery.isError) return <p>{quoteQuery.error.toString()}</p>;

  function handleInvalidate() {
    quoteUpdateRequest
      .mutateAsync({
        id,
        invalidated: {
          by: {
            _id: user._id,
            name: user.name,
          },
          date: DateTime.now(),
          desc: invalidateQuoteDialog.desc,
        },
      })
      .then(() =>
        setInvalidateQuoteDialog({
          open: false,
          desc: '',
        })
      );
  }
  function handleDiscount() {
    quoteUpdateRequest
      .mutateAsync({
        id,
        discount: {
          by: {
            _id: user._id,
            name: user.name,
          },
          date: DateTime.now(),
          amount: discountDialog.amount,
        },
      })
      .then(() =>
        setDiscountDialog({
          open: false,
          amount: 0,
        })
      );
  }

  return (
    <Box>
      <Paper elevation={2} sx={{ p: 3 }}>
        <QuoteInfo quote={quote} />
      </Paper>
      <Paper elevation={2} sx={{ mt: 2, p: 2 }}>
        <ItemTable quote={quote} />
      </Paper>
      <Paper elevation={2} sx={{ mt: 2 }}>
        <PaymentTable tag={quote._id} flag={paymentDialog.refresh} />
      </Paper>
      <AddTaggedPaymentDialog
        state={{
          open: paymentDialog.open,
          setOpen: (open) =>
            paymentDialogDispatch({ type: open ? 'open' : 'close' }),
        }}
        contacts={[quote.customer]}
        tag={quote._id}
        tagType="Quote"
        tagLabel={quote.sn}
        open={paymentDialog.open}
        onClose={() => paymentDialogDispatch({ type: 'close' })}
        onSubmit={() => {
          toast.success('成功新增付費紀錄');
          paymentDialogDispatch({ type: 'refresh' });
        }}
      />
      <AddQuoteDialog
        open={addQuoteDialog}
        copyQuote={quote}
        onClose={() => setAddQuoteDialog(false)}
        redirectOnSubmitted
      />
      <InvalidateDialog
        id={id}
        state={invalidateQuoteDialog}
        setState={setInvalidateQuoteDialog}
        onInvalidate={handleInvalidate}
      />
      <DiscountDialog
        state={discountDialog}
        setState={setDiscountDialog}
        onDiscount={handleDiscount}
      />
      <PrintableDialog
        open={print}
        onClose={() => setPrint(false)}
        maxWidth="lg"
        fullWidth>
        <PrintableQuote quote={quote} />
      </PrintableDialog>
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(
            speedDialIcons.addPayment,
            '新增付費紀錄',
            () => paymentDialogDispatch({ type: 'open' }),
            quote.status === '' ||
              quote.status === '完款' ||
              quote.status === '作廢' ||
              quote.status === '過期'
          ),
          createAction(
            speedDialIcons.print,
            '列印',
            () => setPrint(true),
            quote.status === '' ||
              quote.status === '作廢' ||
              quote.status === '過期'
          ),
          createAction(speedDialIcons.copy, '複製', () =>
            setAddQuoteDialog(true)
          ),
          createAction(
            <MoneyOffIcon />,
            '折扣',
            () => setDiscountDialog({ ...discountDialog, open: true }),
            quote.status === '' ||
              quote.status === '完款' ||
              quote.status === '作廢' ||
              quote.status === '過期'
          ),
          createAction(
            speedDialIcons.trash,
            '作廢',
            () =>
              setInvalidateQuoteDialog({
                ...invalidateQuoteDialog,
                open: true,
              }),
            quote.status === '' ||
              quote.status === '作廢' ||
              quote.status === '過期' ||
              quote.status === '完款'
          ),
        ]}
      />
    </Box>
  );
}

export default QuoteDetail;
