import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';

function OrderTable({ orders }) {
  return (
    <Box
      sx={{
        overflow: 'auto',
        height: '390px',
        [`.${tableCellClasses.root}`]: {
          fontSize: 16,
          py: 1.3,
        },
        [`& .${tableRowClasses.root}.group-header`]: {
          backgroundColor: grey[100],
        },
      }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>訂單編號</TableCell>
            <TableCell>金額</TableCell>
            <TableCell>產品</TableCell>
            <TableCell>狀態</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <Link href={`/order/${order._id}`}>{order.sn}</Link>
              </TableCell>
              <TableCell>
                {(
                  order.items.reduce(
                    (sum, item) =>
                      sum +
                      item.price +
                      item.const +
                      item.additional -
                      item.discount,
                    0
                  ) - (order.legacy?.discount?.amount || 0)
                ).toLocaleString()}
              </TableCell>
              <TableCell>
                {order.items.map((i) => (
                  <Link sx={{ mr: 1.5 }} href={`/product/${i._id}`}>
                    {i.sn}
                  </Link>
                ))}
              </TableCell>
              <TableCell>
                {order.closed ? (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: green[700],
                    }}>
                    <CheckCircleIcon sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }} />{' '}
                    完款
                  </span>
                ) : (
                  '待完款'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default OrderTable;
