import { Button } from '@mui/material';
import React from 'react';

export function LineLoginButton({ disabled }) {
  return (
    <Button
      variant="contained"
      size="large"
      sx={{
        fontWeight: 'bold',
        backgroundColor: '#06C755',
        ':hover': {
          backgroundColor: '#009526',
        },
        padding: '3.5px 22px',
      }}
      onClick={() => {
        window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655881257&redirect_uri=${encodeURIComponent(
          `${window.location.origin}/line`
        )}&state=00000&scope=profile%20openid`;
      }}
      disabled={disabled}
      fullWidth>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src="https://dinfucemstorage.blob.core.windows.net/image/line_logo.png"
          alt="line login"
          height="35px"
        />
        <span>LOGIN</span>
      </span>
    </Button>
  );
}
