import { AuthContext } from './index';
import { DateTime } from 'luxon';
import React from 'react';
import useExpiry from './useExpiry';

function useCheckAuthInterval({ milli }) {
  const authContext = React.useContext(AuthContext);
  const expiry = useExpiry();

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (authContext.auth && expiry < DateTime.now()) {
        console.log('signed out');
        authContext.setAuth('');
      }
    }, milli);
    return () => clearInterval(interval);
  }, [authContext, expiry, milli]);
}

export default useCheckAuthInterval;
