import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';
import { createVendor, queryVendors } from '../../fetchers/vendor.fetch';

import AddVendorDialog from './AddVendorDialog';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'title',
    headerName: '公司',
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/vendor/${params.id}`}>{params.value}</Link>;
    },
  },
  { field: 'name', headerName: '簡稱', flex: 1 },
  { field: 'phone', headerName: '聯絡電話', flex: 1 },
];

function VendorList(props) {
  useCrumbs([crumbs.vendorList]);
  const navigate = useNavigate();
  const [keyword, setKeyword] = React.useState();
  const [dialog, setDialog] = React.useState();
  const vendorsQuery = useQuery(
    ['vendors', { keyword }],
    queryVendors(keyword)
  );
  const vendorCreateRequest = useMutation(createVendor, {
    onSuccess: (data) => navigate(`/vendor/${data._id}`),
  });

  if (vendorsQuery.isLoading) return <Spinner />;
  if (vendorsQuery.isError) return null;

  function handleVendor(response) {
    vendorCreateRequest.mutate(response);
  }

  return (
    <>
      <ListPage
        columns={columns}
        rows={vendorsQuery.data}
        onSearch={(keyword) => setKeyword(keyword)}
        onCellDoubleClick={(params) => navigate(`/vendor/${params.row._id}`)}
      />
      <AddVendorDialog
        state={dialog}
        setState={setDialog}
        onAdd={handleVendor}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(speedDialIcons.add, '新增廠商', () =>
            setDialog({ ...dialog, open: true })
          ),
        ]}
      />
    </>
  );
}

export default VendorList;
