import { Button, Dialog, DialogTitle, TextField } from '@mui/material';

import { Box } from '@mui/system';
import React from 'react';

export function DiscountDialog({ state, setState, onDiscount }) {
  function handleClose() {
    setState({ open: false, amount: 0 });
  }
  return (
    <Dialog open={state.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>報價單折扣</DialogTitle>
      <Box sx={{ p: 2 }}>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          label="折扣金額"
          value={state.amount}
          onChange={(event) =>
            setState({
              ...state,
              amount: event.target.value,
            })
          }
          fullWidth
        />
      </Box>
      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          取消
        </Button>
        {state.amount > 0 && (
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: '15px' }}
            onClick={onDiscount}>
            儲存
          </Button>
        )}
      </Box>
    </Dialog>
  );
}
