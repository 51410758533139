import { serialize } from '../utils/serializeProduct';

const url = '/api/product';

export const queryProductsByCustomerId = (states) => (client) => () => {
  return client.get(`${url}?id=${states.id}`);
};

export const queryProductsBySerialNumber = (states) => (client) => () => {
  if (states.sn) {
    return client.get(`${url}?sn=${serialize(states.sn)}`);
  }
  return client.get(url);
};

export const queryProductById = (states) => (client) => () => {
  const params = new URLSearchParams();
  if (states.deceaseds) {
    params.append('deceaseds', 'true');
  }
  if (states.contacts) {
    params.append('contacts', 'true');
  }
  const paramsString = params.toString();
  return client.get(
    `${url}/${states.id}${paramsString ? `?${paramsString}` : ''}`
  );
};

export const updateProduct = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states);
};
