import { blue, cyan, indigo, orange, teal } from '@mui/material/colors';

const companies = {
  福: {
    short: '頂福事業',
    full: '頂福事業股份有限公司',
    color: blue[700],
  },
  利: {
    short: '頂利事業',
    full: '頂利事業股份有限公司',
    color: orange[700],
  },
  茂: {
    short: '辰茂事業',
    full: '辰茂事業股份有限公司',
    color: cyan[700],
  },
  專: {
    short: '管理費專戶',
    full: '頂福事業股份有限公司管理費專戶',
    color: indigo[700],
  },
  企: {
    short: '頂利企業社',
    full: '頂利企業社',
    color: teal[700],
  },
};

export default companies;
