import { Link, useNavigate } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';

import AddCustomerDialog from './AddCustomerDialog';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import { queryCustomerByKeyword } from '../../fetchers/customer.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'name',
    headerName: '姓名',
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/customer/${params.id}`}>{params.value}</Link>;
    },
  },
  { field: 'ssn', headerName: '身分證字號', flex: 1 },
  { field: 'mobile', headerName: '手機', flex: 1 },
];

function CustomerList(props) {
  useCrumbs([crumbs.customerList]);
  const [keyword, setKeyword] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const customersQuery = useQuery(
    ['customers', { keyword }],
    queryCustomerByKeyword({ keyword }),
    {
      placeholderData: [],
      staleTime: 1000 * 30,
    }
  );

  function handleDoubleClick(params) {
    navigate(`/customer/${params.row._id}`);
  }
  function handleSearch(query) {
    setKeyword(query);
  }

  if (customersQuery.isLoading) return <Spinner />;

  return (
    <>
      <ListPage
        columns={columns}
        rows={customersQuery.isSuccess ? customersQuery.data : []}
        onSearch={handleSearch}
        onCellDoubleClick={handleDoubleClick}
      />
      <AddCustomerDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(speedDialIcons.add, '新增客戶', () =>
            setDialogOpen(true)
          ),
        ]}
      />
    </>
  );
}

export default CustomerList;
