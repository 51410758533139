import 'lightgallery.js/dist/css/lightgallery.css';
import 'swiper/css';
import 'swiper/css/pagination';

import { Button, Dialog, DialogTitle, IconButton } from '@mui/material';
import { Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  createFile,
  deleteFile,
  queryFilesByTag,
} from '../../fetchers/file.fetch';

import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { LightgalleryItem } from 'react-lightgallery';
import { LightgalleryProvider } from 'react-lightgallery';
import Panel from '../Panel';
import React from 'react';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import useSas from '../../services/authentication/useSas';

const swiperOptions = {
  slidesPerView: 1,
  spaceBetween: 10,
  centeredSlides: true,
  pagination: {
    clickable: true,
  },
  mousewheel: true,
  modules: [Mousewheel, Pagination],
  breakpoints: {
    600: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    850: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1100: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
};

function ImageSlider({ tag, disabled, sx }) {
  const sas = useSas();
  const [deleteDialog, setDeleteDialog] = React.useState();
  const filesQuery = useQuery(['files', tag], queryFilesByTag({ tag }), {
    placeholder: [],
  });
  const createFileRequest = useMutation(createFile, {
    onSuccess: () => {
      toast.success('成功上傳檔案');
      filesQuery.refetch();
    },
  });
  const deleteFileRequest = useMutation(deleteFile, {
    onSuccess: () => {
      setDeleteDialog();
      filesQuery.refetch();
    },
  });

  function onDrop(acceptedFiles) {
    if (!disabled) {
      for (let file of acceptedFiles) {
        const data = new FormData();
        data.append('file', file);
        data.append('tag', tag);
        createFileRequest.mutate(data);
      }
    }
  }
  function handleDelete() {
    if (!disabled) {
      deleteFileRequest.mutate({ id: deleteDialog._id });
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/jpeg,image/png',
  });

  if (filesQuery.isLoading) return <p>Loading...</p>;

  return (
    <Box sx={sx}>
      <LightgalleryProvider
        lightgallerySettings={{ addClass: styles.customGallery }}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div style={{ marginTop: 10 }}>
            <Panel title="檔案上傳(限圖片)">
              <Swiper className={styles.swiper} {...swiperOptions}>
                {filesQuery.data.map((image, index) => (
                  <SwiperSlide key={`slider-${index}`}>
                    <LightgalleryItem
                      group="group1"
                      src={`${image.url}?${sas}`}>
                      <div className={styles.imageContainer}>
                        <img src={`${image.url}?${sas}`} alt="" />
                        {!disabled && (
                          <IconButton
                            className={styles.deleteButton}
                            onClick={(event) => {
                              event.stopPropagation();
                              setDeleteDialog(image);
                            }}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </div>
                    </LightgalleryItem>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Panel>
          </div>
        </div>
      </LightgalleryProvider>
      <Dialog
        open={!!deleteDialog}
        maxWidth="xs"
        handleClose={() => setDeleteDialog()}
        fullWidth>
        <DialogTitle>確認刪除檔案？</DialogTitle>
        <Box
          sx={{
            px: 5,
            pt: 1.5,
            pb: 5,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setDeleteDialog();
            }}>
            取消
          </Button>
          <Button
            variant="contained"
            color="warning"
            sx={{ ml: 2 }}
            onClick={handleDelete}>
            確定
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
}

export default ImageSlider;
