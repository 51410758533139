import {
  Dialog,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { AccountCircle } from '@mui/icons-material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Box from '@mui/material/Box';
import CategoryIcon from '@mui/icons-material/Category';
import { Crumbs } from '../services/crumbs/Crumbs';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import HistoryIcon from '@mui/icons-material/History';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SearchInput from './SearchInput';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { blue } from '@mui/material/colors';
import { queryBrokers } from '../fetchers/broker.fetch';
import { queryCustomerByKeyword } from '../fetchers/customer.fetch';
import { queryDeceaseds } from '../fetchers/deceased.fetch';
import { queryOrders } from '../fetchers/order.fetch';
import { queryProductsBySerialNumber } from '../fetchers/product.fetch';
import { queryVendors } from '../fetchers/vendor.fetch';
import { serialize } from '../utils/serializeProduct';
import { useNavigate } from 'react-router-dom';
import useQuery from '../services/httpClient/useQuery';
import useSignUser from '../services/authentication/useSignUser';
import useStorageState from '../utils/useStorageState';
import useUser from '../services/authentication/useUser';

function Wrapper({ children, scrollPosition }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 20,
        left: 0,
        height: '80px',
        width: '100%',
        paddingX: {
          xs: 1,
          sm: 2,
        },
        zIndex: (theme) => 1000,
        '& > div': {
          width: '100%',
          height: '100%',
          borderRadius: (theme) => theme.shape.borderRadius / 2,
          backgroundColor:
            scrollPosition > 24 ? 'rgba(255,255,255,0.95)' : 'transparent',
          boxShadow: scrollPosition > 24 ? '2px 2px 5px rgba(0,0,0,0.3)' : '',
          display: 'flex',
          justifyContent: 'space-between',
          '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: 2,
          },
        },
      }}>
      <div>{children}</div>
    </Box>
  );
}

export function AppBar({ toggleSidebar }) {
  const user = useUser();
  const signUser = useSignUser();
  const navigate = useNavigate();
  const [pos, setPos] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState({
    open: false,
    keyword: null,
  });

  React.useEffect(() => {
    function handleScroll() {
      setPos(window.scrollY);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Wrapper scrollPosition={pos}>
      <Box>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ borderRadius: 1 }}
          onClick={toggleSidebar}>
          <MenuIcon sx={{ fontSize: 28 }} />
        </IconButton>
        <Crumbs />
      </Box>
      <Box>
        <SearchInput
          onSubmit={(val) => setSearch({ ...search, keyword: val, open: true })}
          onFocus={() => {
            setSearch({ ...search, open: true });
          }}
        />
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          sx={{ borderRadius: 1, ml: 1 }}
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          <AccountCircle
            sx={{ fontSize: 28, color: (theme) => theme.palette.gray.main }}
          />
          <Typography
            paragraph
            sx={{
              marginBottom: 0,
              marginLeft: 1,
              display: {
                xs: 'none',
                md: 'block',
              },
            }}>
            {user.name}
          </Typography>
        </IconButton>
      </Box>
      <SearchResult state={search} setState={setSearch} />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem
          sx={{ width: '150px' }}
          onClick={() => {
            navigate('/account');
            setAnchorEl();
          }}>
          帳號
        </MenuItem>
        <MenuItem sx={{ width: '150px' }} onClick={() => signUser()}>
          登出
        </MenuItem>
      </Menu>
    </Wrapper>
  );
}

function SearchResult({ state, setState }) {
  const [history, setHistory] = useStorageState(
    localStorage,
    'search-history',
    []
  );
  const [input, setInput] = React.useState('');
  const queryOptions = {
    placeholderData: [],
    staleTime: Infinity,
    enabled: !!state.keyword,
  };
  const customersQuery = useQuery(
    ['customers', { keyword: state.keyword }],
    queryCustomerByKeyword({ keyword: state.keyword }),
    queryOptions
  );
  const productsQuery = useQuery(
    ['products', { sn: serialize(state.keyword?.toUpperCase()) }],
    queryProductsBySerialNumber({
      sn: serialize(state.keyword?.toUpperCase()),
    }),
    queryOptions
  );
  const deceasedsQuery = useQuery(
    ['deceaseds', { name: state.keyword }],
    queryDeceaseds({ name: state.keyword }),
    queryOptions
  );
  const ordersQuery = useQuery(
    ['orders', { sn: state.keyword }],
    queryOrders({ sn: state.keyword }),
    queryOptions
  );
  const vendorsQuery = useQuery(
    ['vendors', state.keyword],
    queryVendors(state.keyword),
    queryOptions
  );
  const brokersQuery = useQuery(
    ['brokers', state.keyword],
    queryBrokers(state.keyword),
    queryOptions
  );

  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      setState({ ...state, keyword: input.trim() });
      setHistory([input].concat(history).slice(0, 5));
      setInput('');
    }
  }
  function handleClose() {
    setState({ ...state, open: false, keyword: '' });
  }

  return (
    <Dialog
      open={state.open}
      onClose={() => setState({ ...state, open: false, keyword: '' })}
      maxWidth="xs"
      fullWidth>
      <Box sx={{ height: 65, borderBottom: '1px solid #E0E0E0' }}>
        <Grid container alignItems="center" height="100%">
          <Grid item>
            <SearchIcon
              sx={{ fontSize: 30, mt: 1, mx: 1.5, color: blue[600] }}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <InputBase
              sx={{ fontSize: 20, color: '#505050' }}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3, pt: 0 }}>
        {!state.keyword ? (
          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{ color: (theme) => theme.palette.grey[700], pb: 0.5 }}>
              查詢記錄
            </Typography>
            {history.map((record, index) => (
              <Box
                key={`history-${index}`}
                sx={{
                  p: 1.5,
                  color: '#505050',
                  borderBottom: '1px solid #E0E0E0',
                  ':hover': {
                    color: blue[800],
                    border: `1.5px solid ${blue[600]}`,
                    borderRadius: 2,
                    bgcolor: blue[50],
                  },
                }}
                onClick={() => setState({ ...state, keyword: record })}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <HistoryIcon />
                  </Grid>
                  <Grid item>{record}</Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        ) : customersQuery.data?.length ||
          productsQuery.data?.length ||
          deceasedsQuery.data?.length ||
          ordersQuery.data?.length ||
          brokersQuery.data?.length ||
          vendorsQuery.data?.length ? (
          <>
            <ResultSet
              type="客戶"
              results={customersQuery.data}
              onClose={handleClose}
            />
            <ResultSet
              type="產品"
              results={productsQuery.data}
              onClose={handleClose}
            />
            <ResultSet
              type="歿者"
              results={deceasedsQuery.data}
              onClose={handleClose}
            />
            <ResultSet
              type="訂單"
              results={ordersQuery.data}
              onClose={handleClose}
            />
            <ResultSet
              type="廠商"
              results={vendorsQuery.data}
              onClose={handleClose}
            />
            <ResultSet
              type="仲介來源"
              results={brokersQuery.data}
              onClose={handleClose}
            />
          </>
        ) : (
          <div style={{ height: 100 }}>查無資料...</div>
        )}
      </Box>
    </Dialog>
  );
}

const resultTypes = {
  客戶: {
    Icon: PersonIcon,
    field: 'name',
    helperText: (record) => record.ssn,
    link: (record) => `/customer/${record._id}`,
  },
  產品: {
    Icon: CategoryIcon,
    field: 'sn',
    helperText: (record) => record.status,
    link: (record) => `/product/${record._id}`,
  },
  歿者: {
    Icon: PersonOutlineIcon,
    field: 'name',
    helperText: (record) => record.product.sn,
    link: (record) => `/product/${record.product._id}`,
  },
  訂單: {
    Icon: DocumentScannerIcon,
    field: 'sn',
    helperText: (record) => record.buyers.map((buyer) => buyer.name).join(', '),
    link: (record) => `/order/${record._id}`,
  },
  仲介來源: {
    Icon: AssignmentIndIcon,
    field: 'name',
    link: (record) => `/broker/${record._id}`,
  },
  廠商: {
    Icon: StorefrontIcon,
    field: 'name',
    helperText: (record) => record.phone,
    link: (record) => `/vendor/${record._id}`,
  },
};

function ResultSet({ type, results, onClose }) {
  const navigate = useNavigate();
  const resultType = resultTypes[type];
  const Icon = resultType.Icon;

  if (!results || results.length === 0) return null;
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ color: (theme) => theme.palette.grey[700], pb: 0.5 }}>
        {type}資料
      </Typography>
      {results.map((record, index) => (
        <Box
          key={`${type}-${index}`}
          sx={{
            p: 1.5,
            color: '#505050',
            borderBottom: '1px solid #E0E0E0',
            ':hover': {
              color: blue[800],
              border: `1.5px solid ${blue[600]}`,
              borderRadius: 2,
              bgcolor: blue[50],
            },
          }}
          onClick={() => {
            navigate(resultType.link(record));
            onClose();
          }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>{<Icon sx={{ mt: 0.55 }} />}</Grid>
            <Grid item>{record[resultType.field]}</Grid>
            {resultType.helperText && (
              <Grid item>{resultType.helperText(record)}</Grid>
            )}
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
