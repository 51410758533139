import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableBodyClasses,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';
import { labels, tagTypes } from '../../utils/tagTypes.const';

import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import Panel from '../../components/Panel';
import React from 'react';
import ReceiptInfo from '../../components/PaymentTable/ReceiptInfo';
import { blue } from '@mui/material/colors';
import { deleteReceipt } from '../../fetchers/payment.fetch';
import { toast } from 'react-toastify';
import useMutation from '../../services/httpClient/useMutation';

export function ReceiptInfoDialog({ state, setState, onDelete }) {
  const deleteRequest = useMutation(deleteReceipt, {
    onSuccess: () => {
      toast.success('成功作廢發票');
      setState({
        ...state,
        receipt: null,
        delete: {
          open: false,
          desc: '',
          check: false,
        },
      });
      onDelete();
    },
    onError: (err) => toast.error(err.toString(), { autoClose: false }),
  });
  async function handleDelete() {
    if (state.delete.desc.length === 0) {
      return setState({
        ...state,
        delete: { ...state.delete, error: '請輸入刪除原因。' },
      });
    }
    return deleteRequest.mutate({
      id: state.receipt._id,
      desc: state.delete.desc,
    });
  }
  return (
    <Dialog
      open={!!state.receipt}
      onClose={() => setState({ ...state, receipt: null })}
      maxWidth="sm"
      fullWidth>
      <Panel
        style={{
          margin: '20px 20px',
          padding: '10px 20px',
          height: '350px',
          overflow: 'hidden',
        }}>
        {state.receipt && (
          <ReceiptInfo
            receipt={state.receipt}
            onDelete={handleDelete}
            state={state.delete}
            setState={(v) => setState({ ...state, delete: v })}
          />
        )}
      </Panel>
      <Box
        sx={{
          p: '20px',
          fontSize: 20,
          [`.${tableBodyClasses.root}`]: {
            [`.${tableRowClasses.root}:last-of-type .${tableCellClasses.root}`]:
              {
                borderBottom: 'none',
              },
          },
          a: {
            color: blue[700],
            textDecoration: 'none',
            fontSize: 15,
            ':hover': {
              textDecoration: 'underline',
            },
          },
        }}>
        {state.receipt && state.receipt.payments.length > 0 && (
          <Panel title="付費紀錄" style={{ padding: 10, minHeight: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">日期</TableCell>
                  <TableCell>付費人</TableCell>
                  <TableCell>金額</TableCell>
                  <TableCell width="25%">標籤</TableCell>
                  <TableCell>備註</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.receipt.payments.map((payment) => (
                  <TableRow
                    key={payment._id}
                    className={payment.deleted && 'deleted'}>
                    <TableCell>{payment.date?.toDate()}</TableCell>
                    <TableCell>
                      <Link to={`/Customer/${payment.payer._id}`}>
                        {payment.payer.name}
                      </Link>
                    </TableCell>
                    <TableCell>{payment.amount.toLocaleString()}</TableCell>
                    <TableCell>
                      {payment.tag && (
                        <Link to={`/${payment.tag.tagType}/${payment.tag._id}`}>
                          {payment.tag.tagType === 'Product'
                            ? labels[payment.tag.label.substring(0, 1)]
                            : tagTypes[payment.tag.tagType]}
                          {payment.tag.label}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>{payment.desc}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Panel>
        )}
      </Box>
    </Dialog>
  );
}
