import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';

import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { types } from './QuoteDetail';

export function ItemTable({ quote }) {
  return (
    <>
      <Table
        sx={{
          [`.${tableCellClasses.root}`]: {
            fontSize: 16,
          },
        }}>
        <TableHead>
          <TableRow>
            <TableCell>品名</TableCell>
            <TableCell>類別</TableCell>
            <TableCell>單價</TableCell>
            <TableCell>數量</TableCell>
            <TableCell>金額</TableCell>
            <TableCell>備註</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quote.items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{types[item.itemType]}</TableCell>
              <TableCell>{item.price.toLocaleString()}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>
                {Math.round(item.price * item.quantity).toLocaleString()}
              </TableCell>
              <TableCell>{item.desc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter
          sx={{
            span: {
              fontSize: 22,
              color: '#101010',
              sup: {
                fontSize: 13,
                marginRight: 1,
                color: '#303030',
                fontWeight: 'bold',
              },
            },
            [`.${tableRowClasses.root}`]: {
              backgroundColor: '#E9E9E9',
            },
          }}>
          <TableRow>
            <TableCell colSpan="5">
              {quote.discount && (
                <>
                  <span>
                    <sup>小計</sup>
                    {(quote.amount + quote.discount.amount).toLocaleString()}
                  </span>
                  <RemoveIcon sx={{ margin: '0 15px' }} />
                  <span>
                    <sup>折扣</sup>
                    {quote.discount.amount.toLocaleString()}
                  </span>
                </>
              )}
            </TableCell>
            <TableCell>
              <span>
                <sup>總金額</sup>
                {quote.amount.toLocaleString()}
              </span>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <TextField
        sx={{ mt: 3 }}
        variant="outlined"
        size="small"
        label="備註"
        value={quote.desc}
        minRows={3}
        inputProps={{ disabled: true }}
        multiline
        fullWidth
      />
    </>
  );
}
