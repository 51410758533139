import { Button, Dialog, DialogTitle, Grid, TextField } from '@mui/material';

import React from 'react';
import { mobileMask } from '../../validations/imask';

function AddBrokerContactDialog({ state, setState, onSave }) {
  if (!state) return null;
  return (
    <Dialog open={!!state} onClose={() => setState()}>
      <DialogTitle>新增聯絡人</DialogTitle>
      <div style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="姓名"
              size="small"
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="職稱"
              size="small"
              value={state.position}
              onChange={(e) => setState({ ...state, position: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="行動電話"
              size="small"
              value={state.mobile}
              onChange={(e) => {
                mobileMask.onChange(e);
                setState({ ...state, mobile: e.target.value });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: 20 }}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setState()}>
              取消
            </Button>
            {state.name.length > 0 &&
              state.position.length > 0 &&
              mobileMask.unmask(state.mobile).length === 10 && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={onSave}>
                  新增
                </Button>
              )}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}

export default AddBrokerContactDialog;
