import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  buttonClasses,
} from '@mui/material';
import { blue, grey, red } from '@mui/material/colors';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import PaymentInfoDialog from './PaymentInfoDialog';
import React from 'react';
import companies from '../../utils/companies.const';
import { queryPaymentsByTag } from '../../fetchers/payment.fetch';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material';
import useQuery from '../../services/httpClient/useQuery';
import { useQueryClient } from 'react-query';

const StyledTableRow = styled(TableRow)(({ theme, deleted }) => ({
  '&:hover': {
    backgroundColor: blue[50],
  },
  [`& .${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    borderBottom: '2px solid #C0C0C0',
    fontSize: 15,
  },
  [`& .${tableCellClasses.body}`]: {
    fontSize: 15,
    color: deleted ? '#7E7E7E' : 'inherit',
    backgroundColor: deleted ? grey[300] : 'inherit',
  },
}));
const StyledTableCell = styled(TableCell)(({ theme, hidden }) => ({
  [theme.breakpoints.down(hidden ?? 'xs')]: {
    display: 'none',
  },
  [`.${buttonClasses.root}`]: {
    fontSize: 14.5,
    padding: 0,
  },
}));

function PaymentTable({ tag, flag, onLoaded, checkStrata = false }) {
  const [payment, setPayment] = React.useState();
  const reporting = React.useRef(false);

  const queryClient = useQueryClient();
  const paymentsQuery = useQuery(
    ['payments', tag],
    queryPaymentsByTag({ tag }),
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      onSuccess: async (data) => {
        data = data.map((payment) => {
          payment.date = DateTime.fromISO(payment.date);
          return payment;
        });
        data.sort((first, second) => {
          return first.date > second.date ? -1 : 1;
        });
        queryClient.setQueryData(['payments', tag], data);
        if (payment) {
          setPayment(data.find((p) => p._id === payment._id));
        }
        if (!reporting.current) {
          reporting.current = true;
          await report(data);
          reporting.current = false;
        }
      },
    }
  );

  React.useEffect(() => {
    paymentsQuery.refetch();
  }, [flag]);

  function handleChange() {
    paymentsQuery.refetch();
  }
  function handleOpen(payment) {
    setPayment(payment);
  }
  async function report(response) {
    if (onLoaded) {
      const payload = {};
      if (checkStrata) {
        const regex = new RegExp('^(管理費|年繳)');
        payload.strata = response.filter(
          (payment) =>
            (payment.receipts.some((r) => !r.invalidated) || payment.legacy) &&
            regex.test(payment.desc)
        );
      }
      payload.total = response
        .filter(
          (payment) =>
            !payment.deleted &&
            (payment.receipts.some((r) => !r.invalidated) ||
              payment.desc.match('土地款'))
        )
        .map((payment) => payment.amount)
        .reduce((sum, val) => sum + val, 0);
      await onLoaded(payload);
    }
  }

  if (paymentsQuery.isLoading) return <p>Loading...</p>;
  const payments = paymentsQuery.data;

  return (
    <>
      <TableContainer
        sx={{
          height: 280,
          '& .MuiTableContainer-root::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>日期</StyledTableCell>
              <StyledTableCell hidden="md">發票號碼</StyledTableCell>
              <StyledTableCell>金額</StyledTableCell>
              <StyledTableCell hidden="md">付款方式</StyledTableCell>
              <StyledTableCell align="center">標籤</StyledTableCell>
              <StyledTableCell width="20%">備註</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment, index) => {
              return (
                <StyledTableRow
                  key={payment._id}
                  deleted={payment.deleted}
                  onDoubleClick={() => {
                    if (payment.legacy) return;
                    handleOpen(payment);
                  }}>
                  <StyledTableCell>
                    {payment.deleted ? (
                      <span
                        style={{
                          color: red[700],
                          textDecoration: 'none',
                          fontSize: '90%',
                        }}>
                        刪
                      </span>
                    ) : (
                      index + 1
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{payment.date?.toDate()}</StyledTableCell>
                  <StyledTableCell hidden="md">
                    {displayReceipt(payment)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {payment.amount.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell hidden="md">
                    {payment.paymentType}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {payment.tag && (
                      <Button
                        variant="text"
                        size="small"
                        component={Link}
                        to={`/${payment.tag.tagType}/${payment.tag._id}`}
                        sx={{ fontSize: '14px' }}>{`${
                        payment.tag.tagType === 'Order'
                          ? '訂'
                          : payment.tag.tagType === 'Quote'
                          ? '報'
                          : ''
                      }${payment.tag.label}`}</Button>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{payment.desc}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: 'right', marginTop: 15 }}>
        <Typography variant="caption">{`共 ${payments.length} 筆資料`}</Typography>
      </div>
      <PaymentInfoDialog
        payment={payment}
        setPayment={setPayment}
        onChange={handleChange}
      />
    </>
  );
}

function displayReceipt(payment) {
  if (payment.legacy) {
    if (!payment.legacy.receipt) return null;
    return (
      <>
        <Tooltip title="舊系統匯入無公司資料">
          <div>
            <span style={{ color: red[400], fontSize: '95%' }}>匯</span>
            <span
              style={{
                color: '#707070',
                fontSize: '90%',
              }}>
              {payment.legacy.receipt.replace('-', '').toUpperCase()}
            </span>
          </div>
        </Tooltip>
      </>
    );
  }
  const receipt = payment.receipts?.last();
  return (
    <>
      {receipt?.invalidated ? (
        <>
          <span style={{ color: red[700], fontSize: '95%' }}>廢</span>
          <span
            style={{
              textDecoration: 'line-through #505050',
              color: '#707070',
              fontSize: '90%',
            }}>
            {payment.receipts.last()?.company}
            {payment.receipts.last()?.sn}
          </span>
        </>
      ) : (
        <>
          <span
            style={{
              color: companies[payment.receipts.last()?.company]?.color,
              marginRight: 3,
            }}>
            {payment.receipts.last()?.company}
          </span>
          {payment.receipts.last()?.sn}
        </>
      )}
    </>
  );
}

export default PaymentTable;
