const url = '/api/file';

export const queryFilesByTag = (states) => (client) => () => {
  return client.get(`${url}?tag=${states.tag}`);
};

export const createFile = (client) => (states) => {
  return client.formData().post(url, states);
};

export const updateFile = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states);
};

export const deleteFile = (client) => (states) => {
  return client.delete(`${url}/${states.id}`);
};
