import { AuthContext } from './index';
import { DateTime } from 'luxon';
import React from 'react';

function useExpiry() {
  const authContext = React.useContext(AuthContext);
  if (!authContext.auth?.expiry) return null;

  const expiry = DateTime.fromISO(authContext.auth.expiry);
  if (!expiry.isValid) return null;

  return expiry;
}

export default useExpiry;
