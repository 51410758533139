import { Link, useNavigate } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';
import { createBroker, queryBrokers } from '../../fetchers/broker.fetch';

import AddBrokerDialog from './AddBrokerDialog';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import { phoneMobileMask } from '../../validations/imask';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'name',
    headerName: '簡稱',
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/broker/${params.id}`}>{params.value}</Link>;
    },
  },
  {
    field: 'company',
    headerName: '公司',
    flex: 1,
    valueGetter: (params) => params.row.company?.name,
  },
  { field: 'phone', headerName: '聯絡電話', flex: 1 },
  { field: 'lastOrder', headerName: '最新訂單', flex: 1 },
];
const initialState = {
  name: '',
  phone: '',
  title: '',
  address: '',
};

function BrokerList(props) {
  useCrumbs([crumbs.brokerList]);
  const navigate = useNavigate();
  const [keyword, setKeyword] = React.useState('');
  const [dialog, setDialog] = React.useState({
    open: false,
    mode: '公司',
    broker: initialState,
  });
  const brokersQuery = useQuery(
    ['brokers', { keyword: keyword }],
    queryBrokers(keyword)
  );
  const brokerCreateRequest = useMutation(createBroker);

  function handleSave() {
    const payload = {
      name: dialog.broker.name,
      phone: phoneMobileMask.unmask(dialog.broker.phone),
    };
    if (dialog.mode === '公司') {
      payload.company = {
        name: dialog.broker.title,
        address: dialog.broker.address,
      };
    }
    brokerCreateRequest.mutate(payload, {
      onSuccess: (data) => navigate(`/broker/${data._id}`),
    });
  }

  if (brokersQuery.isLoading) return <Spinner />;
  return (
    <>
      <ListPage
        columns={columns}
        rows={brokersQuery.data}
        onSearch={(query) => setKeyword(query)}
        onCellDoubleClick={(params) => navigate(`/broker/${params.row._id}`)}
      />
      <AddBrokerDialog
        state={dialog}
        setState={setDialog}
        initialState={initialState}
        onSave={handleSave}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(speedDialIcons.create, '新增仲介', () =>
            setDialog({ ...dialog, open: true })
          ),
        ]}
      />
    </>
  );
}

export default BrokerList;
