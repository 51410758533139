import { DateTime } from 'luxon';
import IMask from 'imask';

const masker = ({ masked, transform, maskDefault }) =>
  (function () {
    const mask = IMask.createPipe(
      masked,
      IMask.PIPE_TYPE.UNMASKED,
      IMask.PIPE_TYPE.MASKED
    );
    const unmask = IMask.createPipe(
      masked,
      IMask.PIPE_TYPE.MASKED,
      IMask.PIPE_TYPE.UNMASKED
    );
    const onChange = (e) => {
      const unmasked = unmask(e.target.value);
      const newValue = mask(unmasked);
      e.target.value = newValue;
    };
    return {
      mask,
      onChange,
      transform: transform || unmask,
      unmask,
      maskDefault: maskDefault || mask,
    };
  })();

export const dateMask = masker({
  masked: {
    mask: Date,
    pattern: 'yyyy-MM-dd',
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 9999,
      },
    },
    format: (date) => {
      return date.toFormat('yyyy-MM-dd');
    },
    parse: (dateStr) => {
      return DateTime.fromISO(dateStr);
    },
  },
  transform: (value) => {
    if (!value) return '';
    const transformed = DateTime.fromISO(value);
    if (transformed.isValid) return transformed.toISO();
    return '';
  },
});

export const addressMask = masker({
  masked: {
    mask: 'post address',
    blocks: {
      post: {
        mask: [
          {
            overwrite: false,
            eager: false,
            mask: '',
          },
          {
            overwrite: false,
            eager: false,
            mask: '000',
          },
          {
            overwrite: false,
            eager: false,
            mask: '00000',
          },
          {
            overwrite: false,
            eager: false,
            mask: '000000',
          },
        ],
      },
      address: {
        mask: /\.*/,
      },
    },
  },
});

export const mobileMask = masker({
  masked: {
    mask: '{09}00-000-000',
  },
});

export const phoneMask = masker({
  masked: {
    mask: [
      {
        overwrite: false,
        eager: false,
        mask: '(00) 0000-0000',
        startsWith: '02',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(000) 00-0000',
        startsWith: '037',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '03',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '047',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '049',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 0000-0000',
        startsWith: '04',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '05',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '06',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '07',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(0000) 0-0000',
        startsWith: '0836',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(000) 00-0000',
        startsWith: '089',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(000) 00-0000',
        startsWith: '082',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '08',
      },
      {
        overwrite: false,
        eager: false,
        mask: '0000000000',
        startsWith: '',
      },
    ],
    dispatch: function (appended, dynamicMasked) {
      var number = dynamicMasked.value + appended;

      return dynamicMasked.compiledMasks.find(function (m) {
        return number.indexOf(m.startsWith) === 0;
      });
    },
  },
});

export const phoneMobileMask = masker({
  masked: {
    mask: [
      {
        overwrite: false,
        eager: false,
        mask: '(00) 0000-0000',
        startsWith: '02',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(000) 00-0000',
        startsWith: '037',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '03',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '047',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '049',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 0000-0000',
        startsWith: '04',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '05',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '06',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '07',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(0000) 0-0000',
        startsWith: '0836',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(000) 00-0000',
        startsWith: '089',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(000) 00-0000',
        startsWith: '082',
      },
      {
        overwrite: false,
        eager: false,
        mask: '(00) 000-0000',
        startsWith: '08',
      },
      {
        overwrite: false,
        eager: false,
        mask: '0000-000-000',
        startsWith: '09',
      },
      {
        overwrite: false,
        eager: false,
        mask: '0000000000',
        startsWith: '',
      },
    ],
    dispatch: function (appended, dynamicMasked) {
      var number = dynamicMasked.value + appended;

      return dynamicMasked.compiledMasks.find(function (m) {
        return number.indexOf(m.startsWith) === 0;
      });
    },
  },
});

export const ssnMask = masker({
  masked: {
    mask: /^&|^[A-Za-z]\d{0,9}$/,
  },
});
