import { DateTime } from 'luxon';
import sha256 from 'crypto-js/sha256';

const axios = require('axios').default;

class HttpClient {
  constructor(authHandler) {
    this.#auth = authHandler.auth;
    this.#setAuth = authHandler.setAuth;
    this.#client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: this.#auth ? `Bearer ${this.#auth.token}` : '',
      },
      timeout: authHandler.timeout,
    });
    this.#client.interceptors.response.use(
      (response) => response,
      (err) => {
        console.error(err);
        if (
          err.response.status === 401 ||
          DateTime.fromISO(this.#auth.expiry) < DateTime.now()
        ) {
          this.#setAuth('');
          return Promise.reject(err.response.data);
        }
        return Promise.reject(err);
      }
    );
  }
  #auth;
  #setAuth;
  #client;
  #handleResponse(response) {
    if (response.status !== 200) {
      return Promise.reject(response.status);
    }
    return Promise.resolve(response.data);
  }
  #handleError(error) {
    if (error.error) {
      return Promise.reject(error.error);
    }
    if (error.code === 'ERR_NETWORK') {
      return Promise.reject('伺服器沒有回應，請檢查網路連線及伺服器狀態');
    }
    console.log(error);
    if (error.response?.status === 404) {
      return Promise.reject('客戶端出現錯誤：API ENDPOINT 不存在');
    }
    if (error.response?.data?.error) {
      return Promise.reject(error.response.data.error);
    }
    return Promise.reject('發生無法預期的錯誤');
  }
  #isExpired() {
    if (this.#auth && this.#auth.expiry < Date.now()) {
      this.#setAuth('');
      return true;
    }
    return false;
  }
  async get(url) {
    if (this.#isExpired()) return;
    return this.#client
      .get(url)
      .then((response) => this.#handleResponse(response))
      .catch((error) => {
        return this.#handleError(error);
      });
  }
  async post(url, data, config) {
    if (this.#isExpired()) return;
    const sha = sha256(
      process.env.REACT_APP_JWT_SECRET + JSON.stringify(data || {})
    ).toString();
    return this.#client
      .post(url, { ...data, sha }, { ...config })
      .then((response) => this.#handleResponse(response))
      .catch((error) => {
        return this.#handleError(error);
      });
  }
  async put(url, data, config) {
    if (this.#isExpired()) return;
    const sha = sha256(
      process.env.REACT_APP_JWT_SECRET + JSON.stringify(data || {})
    ).toString();
    return this.#client
      .put(url, { ...data, sha }, { ...config })
      .then((response) => this.#handleResponse(response))
      .catch((error) => {
        return this.#handleError(error);
      });
  }
  async delete(url, data) {
    if (this.#isExpired()) return;
    if (!data) data = {};
    const sha = sha256(
      process.env.REACT_APP_JWT_SECRET + JSON.stringify(data)
    ).toString();
    data.sha = sha;
    return this.#client
      .delete(url, { data })
      .then((response) => this.#handleResponse(response))
      .catch((error) => {
        return this.#handleError(error);
      });
  }
  formData() {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: this.#auth ? `Bearer ${this.#auth.token}` : '',
      },
    });
  }
}

export default HttpClient;
