import * as yup from 'yup';

import { Button, Dialog, DialogTitle, Grid, TextField } from '@mui/material';
import {
  getErrors,
  mobile,
  phone,
  postal,
  ssn,
} from '../../validations/yupSchemas';
import { mobileMask, phoneMask, ssnMask } from '../../validations/imask';

import { Box } from '@mui/system';
import React from 'react';
import { createCustomer } from '../../fetchers/customer.fetch';
import getPostalCode from '../../utils/getPostalCode';
import registerState from '../../utils/registerState';
import { toast } from 'react-toastify';
import useMutation from '../../services/httpClient/useMutation';
import { useNavigate } from 'react-router-dom';

const initial = {
  name: '',
  address: '',
  mailingAddress: '',
  ssn: '',
  mobile: '',
  phone: '',
  email: '',
};
const schema = yup.object({
  name: yup
    .string()
    .transform((value) => value.trim().replace(' ', ''))
    .required('請輸入姓名'),
  address: postal,
  mailingAddress: postal,
  ssn: ssn,
  phone: phone,
  mobile: mobile,
  email: yup.string().email('請檢查Email'),
});

function AddCustomerDialog({ open, onClose }) {
  const [customer, setCustomer] = React.useState(initial);
  const [errors, setErrors] = React.useState({});
  const navigate = useNavigate();
  const registerInput = registerState(customer, setCustomer, errors);
  const createCustomerRequest = useMutation(createCustomer, {
    onSuccess: (data) => {
      navigate(`/customer/${data._id}`);
    },
  });

  function reset() {
    setCustomer(initial);
    setErrors({});
  }
  function handleClose() {
    reset();
    onClose();
  }
  function handleSubmit() {
    schema
      .validate(customer, {
        abortEarly: false,
      })
      .then((res) => {
        setErrors({});
        createCustomerRequest.mutate(res);
      })
      .catch((err) => {
        setErrors(getErrors(err));
        return false;
      });
  }
  function handlePostal(field) {
    return async (e) => {
      let address = e.target.value;
      if (address.length > 5 && !/\d{3}/.test(address.substring(0, 5))) {
        try {
          let postal = await getPostalCode(address);
          if (postal) {
            postal += ' ';
          }
          setCustomer((prev) => ({ ...prev, [field]: `${postal}${address}` }));
        } catch (error) {
          toast.error(`發生錯誤：${error}`, {
            autoClose: false,
          });
        }
      }
    };
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>新增客戶資料</DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="姓名"
              fullWidth
              {...registerInput('name')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <TextField
              variant="outlined"
              label="戶籍地址"
              fullWidth
              {...registerInput('address')}
              onBlur={handlePostal('address')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="身分證字號"
              fullWidth
              {...registerInput('ssn', (val) => ssnMask.mask(val))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <TextField
              variant="outlined"
              label="郵寄地址"
              fullWidth
              {...registerInput('mailingAddress')}
              onBlur={handlePostal('mailingAddress')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="手機"
              fullWidth
              {...registerInput('mobile', (val) => mobileMask.mask(val))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="電話"
              fullWidth
              {...registerInput('phone', (val) => phoneMask.mask(val))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              label="Email"
              fullWidth
              {...registerInput('email')}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              取消
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={handleSubmit}>
              儲存
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default AddCustomerDialog;
