import { Step, StepButton, StepLabel, Stepper } from '@mui/material';

import { CheckCircle } from '@mui/icons-material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

export function PurchaseStepper({
  purchase,
  activeStep,
  setDialog,
  hasAccess,
}) {
  if (purchase.status === 'invalidated') return null;
  return (
    <Stepper sx={{ mb: 2 }} activeStep={activeStep}>
      <Step>
        <StepLabel>草稿</StepLabel>
      </Step>
      {activeStep === 0 ? (
        <Step>
          <StepLabel>送簽</StepLabel>
        </Step>
      ) : (
        <Step>
          <AuditStep purchase={purchase} setDialog={setDialog} />
        </Step>
      )}
      <Step>
        <StepLabel>採購</StepLabel>
      </Step>
      <Step>
        {activeStep === 3 && !purchase.invalidated ? (
          <StepButton onClick={() => setDialog('shipment')}>到貨</StepButton>
        ) : (
          <StepLabel>到貨</StepLabel>
        )}
      </Step>
      <Step>
        <InspectionStep purchase={purchase} setDialog={setDialog} />
      </Step>
      <Step>
        {activeStep === 5 && hasAccess('F') && !purchase.invalidated ? (
          <StepButton onClick={() => setDialog('payment')}>
            {purchase.status === 'confirmed' ? '月結' : '請款'}
          </StepButton>
        ) : (
          <StepLabel>
            {purchase.status === 'confirmed' ? '月結' : '請款'}
          </StepLabel>
        )}
      </Step>
      <Step>
        <StepLabel>結案</StepLabel>
      </Step>
    </Stepper>
  );
}
function AuditStep({ purchase, setDialog }) {
  const check = Object.entries(purchase.audit.checks).find(
    (c) => c[1].checked === false
  );
  if (check) {
    return (
      <StepButton
        sx={{ '.MuiStepLabel-label': { color: 'red' } }}
        icon={<WarningIcon color="error" />}
        optional={`${check[1].name}退簽`}
        onClick={() => setDialog('audit')}>
        送簽
      </StepButton>
    );
  }
  return <StepButton onClick={() => setDialog('audit')}>送簽</StepButton>;
}
function InspectionStep({ purchase, setDialog }) {
  const result = purchase.inspection?.checked;
  if (result === false)
    return (
      <StepButton
        sx={{ '.MuiStepLabel-label': { color: 'red' } }}
        icon={<WarningIcon color="error" />}
        optional={`${purchase.inspection.name}退回`}
        onClick={() => setDialog('inspection')}>
        驗收
      </StepButton>
    );
  if (purchase.status === 'inspection' && result === true)
    return (
      <StepLabel icon={<CheckCircle sx={{ color: 'green', fontSize: 27.5 }} />}>
        驗收
      </StepLabel>
    );
  if (result === true) return <StepLabel>驗收</StepLabel>;
  return <StepButton onClick={() => setDialog('inspection')}>驗收</StepButton>;
}
