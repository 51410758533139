import { Alert, Button, Grid, TextField } from '@mui/material';

import AddReceiptDialog from '../AddReceiptDialog';
import { DateTime } from 'luxon';
import React from 'react';
import ReceiptInfo from './ReceiptInfo';
import { bindReceiptToPayment } from '../../fetchers/payment.fetch';
import { deleteReceipt } from '../../fetchers/payment.fetch';
import useMutation from '../../services/httpClient/useMutation';

function ReceiptPanel({ payment, onChange }) {
  const [mode, setMode] = React.useState('info');
  const [deleteState, setDeleteState] = React.useState({
    open: false,
    desc: '',
    check: false,
  });
  const [records, setRecords] = React.useState();
  const [error, setError] = React.useState();
  const [sn, setSn] = React.useState('');

  const deleteRequest = useMutation(deleteReceipt, {
    onSuccess: (data) => {
      onChange();
      return [false, true];
    },
    onError: (err) => setDeleteState({ ...deleteState, error: err }),
  });
  const bindReceiptRequest = useMutation(bindReceiptToPayment, {
    onSuccess: () => {
      onChange();
      setError(null);
    },
    onError: (err) => {
      setError(err);
    },
  });

  React.useEffect(() => {
    if (payment) {
      setRecords(payment.receipts);
    }
  }, [payment]);

  function handleReceiptCreated(receipt) {
    const payload = [...records, receipt];
    setRecords(payload);
    onChange(payload);
    setMode('info');
  }
  function handleReceiptCancel() {
    setMode('info');
  }
  async function handleDelete() {
    if (deleteState.desc.length === 0) {
      return setDeleteState({ ...deleteState, error: '請輸入刪除原因。' });
    }
    deleteRequest.mutate({ id: records.last()._id, desc: deleteState.desc });
  }
  function handleBindReceipt(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      sn.length === 10
    ) {
      event.preventDefault();
      bindReceiptRequest.mutate({ id: payment._id, sn });
    }
  }
  const buttons = () => (
    <>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: '10px' }}
        alignItems="center">
        <Grid item>
          <Button variant="outlined" onClick={() => setMode('paper')}>
            輸入發票
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => setMode('electronic')}>
            開立電子發票
          </Button>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            size="small"
            label="綁定發票"
            value={sn}
            onChange={(event) => {
              if (event.target.value.match(/^\w{0,2}\d{0,8}$/)) {
                setSn(event.target.value.toUpperCase());
              }
            }}
            onKeyDown={handleBindReceipt}
          />
        </Grid>
      </Grid>
      {error && (
        <Alert style={{ marginTop: 10 }} severity="error">
          {error}
        </Alert>
      )}
    </>
  );
  const receiptInfo = () => {
    const record = records.last();
    record.date = DateTime.fromISO(record.date);
    return (
      <ReceiptInfo
        receipt={record}
        onDelete={handleDelete}
        state={deleteState}
        setState={setDeleteState}
      />
    );
  };
  function content() {
    if (!records) return null;

    if (mode === 'paper' || mode === 'electronic')
      return (
        <AddReceiptDialog
          mode={mode}
          tag={payment._id}
          onCancel={handleReceiptCancel}
          onCreated={handleReceiptCreated}
          amount={payment.amount}
          company={payment.company}
        />
      );

    if (payment.deleted && records.length === 0) return <p>無發票資料</p>;

    if (!payment.deleted && records.count((x) => !x.invalidated) === 0)
      return buttons();

    return receiptInfo();
  }

  return (
    <div
      style={{
        padding: '0px 20px 20px 20px',
        // border: '1px solid rgba(0,0,0,0.3)',
      }}>
      {content()}
    </div>
  );
}

export default ReceiptPanel;
