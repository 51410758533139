import { Grid, Paper, TextField } from '@mui/material';
import { mobileMask, phoneMask, ssnMask } from '../../validations/imask';

import React from 'react';

function CustomerInfo({ customer, register, handlePostal }) {
  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Grid container spacing={2} justifyContent="start">
        <Grid item xs={12} md={4} order={{ xs: 0, sm: 0 }}>
          <TextField
            variant="outlined"
            label="姓名"
            size="small"
            fullWidth
            inputProps={{ readOnly: !!customer.name }}
            {...register('name')}
          />
        </Grid>
        <Grid item xs={12} md={8} order={{ xs: 4, sm: 1 }}>
          <TextField
            variant="outlined"
            label="戶籍地址"
            size="small"
            fullWidth
            inputProps={{ readOnly: !!customer.address }}
            {...register('address')}
            onBlur={handlePostal('address')}
          />
        </Grid>
        <Grid item xs={12} md={4} order={{ xs: 1, sm: 2 }}>
          <TextField
            variant="outlined"
            label="身分證字號"
            size="small"
            inputProps={{ readOnly: !!customer.ssn }}
            fullWidth
            {...register('ssn', (val) => ssnMask.mask(val))}
          />
        </Grid>
        <Grid item xs={12} md={8} order={{ xs: 5, sm: 3 }}>
          <TextField
            variant="outlined"
            label="郵寄地址"
            size="small"
            fullWidth
            {...register('mailingAddress')}
            onBlur={handlePostal('mailingAddress')}
          />
        </Grid>
        <Grid item xs={12} md={4} order={{ xs: 2, sm: 4 }}>
          <TextField
            variant="outlined"
            label="電話"
            mask="(00) 0000-0000"
            size="small"
            fullWidth
            {...register('phone', (val) => phoneMask.mask(val))}
          />
        </Grid>
        <Grid item xs={12} md={4} order={{ xs: 3, sm: 5 }}>
          <TextField
            label="手機"
            size="small"
            fullWidth
            {...register('mobile', (val) => mobileMask.mask(val))}
          />
        </Grid>
        <Grid item xs={12} md={4} order={{ xs: 6, sm: 6 }}>
          <TextField
            variant="outlined"
            label="Email"
            size="small"
            fullWidth
            {...register('email')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CustomerInfo;
