import * as yup from 'yup';

import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { date, getErrors } from '../../validations/yupSchemas';

import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import PrintableDialog from '../PrintableDialog';
import React from 'react';
import TempForm from '../TempForm';
import { rates } from './TempRecords';

const schema = yup.object({
  id: yup.string().nullable(),
  date: date.required('請輸入暫厝日期'),
  sn: yup.string().required('請輸入暫厝編號'),
  tempType: yup.string().required('請輸入類別'),
  paidUntil: date
    .required('請輸入已付費至日期')
    .test('paidUntil', '付費至日期必須晚於暫厝日期', function (paidUntil) {
      if (DateTime.fromISO(paidUntil) >= DateTime.fromISO(this.parent.date))
        return true;
      return false;
    }),
  moveout: date
    .nullable()
    .test('moveout', '請檢查遷出日期', function (moveout) {
      if (!moveout) return true;
      let date = DateTime.fromISO(moveout).startOf('day');
      if (DateTime.fromISO(this.parent.date).startOf('day') > date)
        return false;
      return true;
    }),
});
export function TempEdit({ deceased, record, setMode, onSave }) {
  const [payload, setPayload] = React.useState();
  const [form, setForm] = React.useState(false);

  React.useEffect(() => {
    if (!record || !record._id) {
      return setPayload({
        id: null,
        date: DateTime.now().startOf('day'),
        sn: '',
        tempType: '骨灰',
        paidUntil: null,
        moveout: null,
        errors: {},
      });
    }
    setPayload({ ...record, errors: {} });
  }, [record]);

  function handleSave() {
    schema
      .validate(payload, {
        abortEarly: false,
      })
      .then((res) => {
        setPayload({ ...payload, errors: {} });
        onSave(res);
        setMode('table');
      })
      .catch((err) => {
        setPayload({ ...payload, errors: getErrors(err) });
      });
  }

  if (!payload) return <p>Loading...</p>;

  const tempFee = () => {
    if (!payload.date) return 0;
    if (!payload.paidUntil) return 0;
    if (!payload.tempType) return 0;

    const start = DateTime.fromISO(payload.date).startOf('day');
    const end = DateTime.fromISO(payload.paidUntil)
      .startOf('day')
      .plus({ days: 1 });

    if (!start.isValid) return 0;
    if (!end.isValid) return 0;

    const diff = end.diff(start, ['years', 'months', 'days', 'hours']);

    const rate = rates[payload.tempType];
    return (
      diff.years * rate.year +
      diff.months * rate.month +
      Math.ceil(diff.days) * rate.day
    );
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            label="暫厝日期"
            value={payload.date}
            onChange={(e) => setPayload({ ...payload, date: e.startOf('day') })}
            inputFormat="yyyy-MM-dd"
            renderInput={(params) => (
              <TextField
                helperText={payload.errors.date?.message}
                fullWidth
                {...params}
                error={!!payload.errors.date}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            value={payload.sn}
            onChange={(e) => setPayload({ ...payload, sn: e.target.value })}
            label="暫厝編號"
            error={!!payload.errors.sn}
            helperText={payload.errors.sn?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl error={!!payload.errors.tempType} fullWidth>
            <InputLabel>暫厝類別</InputLabel>
            <Select
              label="暫厝類別"
              value={payload.tempType}
              onChange={(e) =>
                setPayload({ ...payload, tempType: e.target.value })
              }>
              <MenuItem value="骨灰">骨灰</MenuItem>
              <MenuItem value="香火袋">香火袋</MenuItem>
              <MenuItem value="骨罈">骨罈</MenuItem>
              <MenuItem value="靈位">靈位</MenuItem>
            </Select>
            <FormHelperText>{payload.errors.tempType?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            label="已付費至"
            value={payload.paidUntil}
            onChange={(e) =>
              setPayload({ ...payload, paidUntil: e.startOf('day') })
            }
            inputFormat="yyyy-MM-dd"
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                error={!!payload.errors.paidUntil}
                helperText={payload.errors.paidUntil?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePicker
            label="遷出日期"
            value={payload.moveout}
            onChange={(e) =>
              setPayload({ ...payload, moveout: e.startOf('day') })
            }
            inputFormat="yyyy-MM-dd"
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                error={!!payload.errors.moveout}
                helperText={payload.errors.moveout?.message}
              />
            )}
            default
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {!!tempFee() && (
                <Alert severity="info" sx={{ pr: 3 }}>
                  暫厝費：$ {tempFee().toLocaleString()}
                  {record?._id && !record.moveout && (
                    <Button
                      sx={{ ml: 1.5 }}
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={() => setForm(true)}>
                      列印切結書
                    </Button>
                  )}
                </Alert>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setMode('table')}>
                取消
              </Button>
              {(!record ||
                (record &&
                  (!record.moveout ||
                    DateTime.fromISO(record.moveout) >
                      DateTime.now().minus({ month: 1 })))) && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={handleSave}>
                  儲存
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {deceased && (
        <PrintableDialog
          open={form}
          onClose={() => setForm(false)}
          maxWidth="lg"
          fullWidth>
          <TempForm deceased={deceased} />
        </PrintableDialog>
      )}
    </Box>
  );
}
