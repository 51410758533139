import { AuthContext } from '../authentication';
import HttpClient from './index';
import React from 'react';

function useHttpClient(options) {
  const authContext = React.useContext(AuthContext);
  const [client, setClient] = React.useState(
    () =>
      new HttpClient({
        auth: authContext.auth,
        setAuth: authContext.setAuth,
        timeout: options?.timeout || 5000,
      })
  );

  return client;
}

export default useHttpClient;
