const zhTW = {
  previousMonth: '上個月',
  nextMonth: '下個月',
  openPreviousView: undefined,
  openNextView: undefined,
  cancelButtonLabel: '取消',
  clearButtonLabel: '清除',
  okButtonLabel: '確定',
  todayButtonLabel: '今天',
  start: '開始日期',
  end: '結束日期',
};

export default zhTW;
