import AddIcon from '@mui/icons-material/Add';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PaidIcon from '@mui/icons-material/Paid';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ViewListIcon from '@mui/icons-material/ViewList';

const speedDialIcons = {
  main: <SpeedDialIcon />,
  mainWithEdit: <SpeedDialIcon openIcon={<EditIcon />} />,
  mainWithSearch: <SpeedDialIcon openIcon={<SearchIcon />} />,
  edit: <EditIcon />,
  add: <AddIcon />,
  save: <SaveIcon />,
  addPayment: <PaidIcon />,
  addContact: <PersonAddIcon />,
  addDeceased: <PersonAddAltIcon />,
  close: <DoDisturbOnIcon />,
  list: <ViewListIcon />,
  addToList: <PlaylistAddIcon />,
  complete: <LibraryAddCheckIcon />,
  print: <PrintIcon />,
  copy: <ContentCopyIcon />,
  trash: <DeleteIcon />,
  cancel: <CancelIcon />,
  report: <AnalyticsIcon />,
};

export default speedDialIcons;
