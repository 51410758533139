import { DateTime } from 'luxon';
import useExpiry from './useExpiry';

function useIsAuthenticated() {
  const expiry = useExpiry();

  if (!expiry) return false;
  if (expiry < DateTime.now()) return false;
  return true;
}

export default useIsAuthenticated;
