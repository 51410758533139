import { blue, indigo } from '@mui/material/colors';

import { Box } from '@mui/material';
import React from 'react';

export function Card({ area, children }) {
  return (
    <Box
      sx={{
        '& .container': {
          display: 'inline-block',
        },
        '& .title': {
          fontSize: 14,
          height: '26.5px',
          color: area === '頂福陵園' ? blue[700] : indigo[700],
          textDecoration: 'none',
        },
        '& .content': {
          fontSize: 21,
          color: (theme) => theme.palette.gray.light,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}>
      {children}
    </Box>
  );
}
