import { toast } from 'react-toastify';
import useHttpClient from './useHttpClient';
import { useQuery as useReactQuery } from 'react-query';

function useQuery(key, callback, options, axiosConfigs) {
  const client = useHttpClient(axiosConfigs);
  return useReactQuery(key, callback(client), {
    onError: (error) =>
      toast.error(`發生錯誤：${error}`, {
        autoClose: false,
      }),
    ...options,
  });
}

export default useQuery;
