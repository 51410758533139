import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import { queryUser, updateUser } from '../../fetchers/system.fetch';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Detail from '../../components/Detail';
import React from 'react';
import Spinner from '../../components/Spinner';
import { access } from './system.const';
import crumbs from '../../services/crumbs/crumbs.const';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import useUser from '../../services/authentication/useUser';

function AccountDetail(props) {
  useCrumbs([crumbs.accountDetail]);
  const user = useUser();
  const userQuery = useQuery(['user', user._id], queryUser({ id: user._id }), {
    onSuccess: (data) => setAccount(data),
  });
  const [account, setAccount] = React.useState(userQuery.data);
  const userUpdateRequest = useMutation(updateUser, {
    onSuccess: () => {
      toast.success('成功儲存帳號資料');
      userQuery.refetch();
    },
  });
  function handleInfoChange() {
    const payload = {
      name: account.name,
      username: account.username,
    };
    if (account.password) {
      payload.password = account.password;
    }
    userUpdateRequest.mutate({ id: user._id, ...payload });
  }

  if (userQuery.isLoading || !account) return <Spinner />;
  if (userQuery.isError) return <p>{userQuery.error.toString()}</p>;

  return (
    <>
      <Paper elevation={2} sx={{ mt: 2 }}>
        <Grid container sx={{ p: '0 20px 20px 20px' }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="filled"
              size="small"
              label="帳號識別碼"
              value={account._id}
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="filled"
              size="small"
              label="姓名"
              value={account.name}
              onChange={(event) =>
                setAccount({ ...account, name: event.target.value })
              }
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">部門</InputLabel>
              <Select
                label="部門"
                value={account.access.substring(0, 1)}
                onChange={(event) =>
                  setAccount({
                    ...account,
                    access: `${event.target.value}${account.access.substring(
                      1
                    )}`,
                  })
                }
                inputProps={{ readOnly: true }}>
                {Object.keys(access).map((record) => (
                  <MenuItem key={record} value={record}>
                    {access[record].department}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">職位</InputLabel>
              <Select
                label="職位"
                value={account.access.substring(1)}
                onChange={(event) =>
                  setAccount({
                    ...account,
                    access: `${account.access.substring(0, 1)}${
                      event.target.value
                    }`,
                  })
                }
                inputProps={{ readOnly: true }}>
                {Object.keys(access[account.access.substring(0, 1)])
                  .slice(1)
                  .map((record) => (
                    <MenuItem key={record} value={record}>
                      {access[account.access.substring(0, 1)][record]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="帳號"
              value={account.username}
              onChange={(event) =>
                setAccount({ ...account, username: event.target.value })
              }
              inputProps={{ readOnly: account.deactivated }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="密碼"
              type="password"
              value={account.password}
              onChange={(event) =>
                setAccount({ ...account, password: event.target.value })
              }
              inputProps={{ readOnly: account.deactivated }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Detail
              title="LINE"
              value={
                account.deactivated ? null : account.line?.uid ? (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <CheckCircleIcon color="success" />
                    </Grid>
                    <Grid item sx={{ mb: 0.5 }}>
                      已綁定
                    </Grid>
                  </Grid>
                ) : (
                  <Link
                    href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655881257&redirect_uri=${encodeURIComponent(
                      `${window.location.origin}/line-connect?id=${account._id}`
                    )}&state=00000&scope=profile%20openid`}>
                    <Box
                      sx={{
                        display: 'flex',
                        backgroundColor: '#06C755',
                        alignItems: 'center',
                        height: '35px',
                        borderRadius: '5px',
                        padding: '0 15px 0 5px',
                        marginTop: '1px',
                      }}>
                      <div style={{ height: '100%', marginRight: 5 }}>
                        <img
                          src="https://dinfucemstorage.blob.core.windows.net/image/line_logo.png"
                          alt="line login"
                          height="100%"
                        />
                      </div>
                      <div
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                        }}>
                        綁定
                      </div>
                    </Box>
                  </Link>
                )
              }
            />
          </Grid>
          <Grid item>
            <Detail
              title="Notify"
              value={
                account.deactivated ? null : account.line?.accessToken ? (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <CheckCircleIcon color="success" />
                    </Grid>
                    <Grid item sx={{ mb: 0.5 }}>
                      已綁定
                    </Grid>
                  </Grid>
                ) : (
                  <Link
                    href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=Lj7okY1sbaqQOKQTMmxWHM&redirect_uri=${encodeURIComponent(
                      `${process.env.REACT_APP_API_URL}/notify/connect?id=${account._id}`
                    )}&state=00000&scope=notify&response_mode=form_post`}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '38px',
                        padding: '0 15px 0 5px',
                        marginTop: '1px',
                      }}>
                      <div style={{ height: '100%', marginRight: 5 }}>
                        <img
                          src="https://dinfucemstorage.blob.core.windows.net/image/line-notify-logo.png"
                          alt="line login"
                          height="100%"
                        />
                      </div>
                      <div
                        style={{
                          color: 'rgb(0,178,36)',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: 14,
                        }}>
                        綁定
                      </div>
                    </Box>
                  </Link>
                )
              }
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ mt: 2 }}>
        <div style={{ padding: 20, overflow: 'auto' }}>
          <TableContainer sx={{ height: 280 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>日期</TableCell>
                  <TableCell>登入結果</TableCell>
                  <TableCell>IP位置</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {account.logins.map((login) => (
                  <TableRow key={login.date.luxon().toMillis()}>
                    <TableCell>{login.date?.toDateTime()}</TableCell>
                    <TableCell>{login.result ? '成功' : '失敗'}</TableCell>
                    <TableCell>{login.ip}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(
            speedDialIcons.save,
            '儲存',
            handleInfoChange,
            account?.deactivated
          ),
        ]}
      />
    </>
  );
}

export default AccountDetail;
