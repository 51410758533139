import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';

import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { blue } from '@mui/material/colors';
import companies from '../../utils/companies.const';
import { queryItemsByTag } from '../../fetchers/item.fetch';
import { serialize } from '../../utils/serializeProduct';
import { toast } from 'react-toastify';
import useHttpClient from '../../services/httpClient/useHttpClient';
import useQuery from '../../services/httpClient/useQuery';
import { v4 as uuid } from 'uuid';

const initialState = {
  company: '福',
  vendor: null,
  quote: null,
  product: null,
  desc: '',
  taxIncluded: true,
  items: [
    {
      index: uuid(),
      itemType: 'm',
      name: '',
      price: 0,
      quantity: 1,
      unit: '',
      desc: '',
      stocked: true,
    },
  ],
};

function EditPurchaseDialog({ open, onClose, purchase: edit, onSave }) {
  const [purchase, setPurchase] = React.useState(initialState);
  const [query, setQuery] = React.useState('');
  const [height, setHeight] = React.useState(400);
  const itemTable = React.useRef();
  const itemsQuery = useQuery(
    ['items', 'tag', edit?.vendor._id],
    queryItemsByTag({ tag: edit?.vendor._id }),
    {
      enabled: !!edit,
      onSuccess: (data) => {
        const updated = { ...edit };
        updated.vendor.items = data;
        updated.items = updated.items.map((item) => {
          item.index = uuid();
          return item;
        });
        updated.items.push({
          index: uuid(),
          itemType: 'm',
          name: '',
          price: 0,
          quantity: 1,
          unit: '',
          desc: '',
          stocked: true,
        });
        updated.quote = updated.tags.find((t) => t.tagType === 'Quote');
        if (updated.quote) {
          updated.quote = {
            _id: updated.quote._id,
            company: updated.quote.label.substring(0, 1),
            sn: updated.quote.label.substring(1),
          };
        }
        updated.product = updated.tags.find((t) => t.tagType === 'Product');
        if (updated.product) {
          updated.product = {
            _id: updated.product._id,
            sn: updated.product.label,
          };
        }
        setPurchase(updated);
      },
    }
  );
  const client = useHttpClient();

  function handleChangeItem(index, item) {
    if (!item || !item._id) return;
    if (purchase.items.some((i) => i._id === item._id)) {
      toast.warning(
        `品項「${item.name}」已被加入採購單，請確認是否需要重複加入。`,
        {
          autoClose: 20000,
        }
      );
    }
    const updated = [...purchase.items];
    if (!index) {
      index = updated[updated.length - 1].index;
    }
    updated[purchase.items.findIndex((i) => i.index === index)] = {
      index: uuid(),
      itemType: 't',
      name: item.name,
      price: item.price || 0,
      unit: item.unit,
      quantity: 1,
      desc: item.desc,
      stocked: item.stocked,
      _id: item._id,
    };
    setPurchase((prev) => ({ ...prev, items: updated }));
    setHeight(itemTable.current?.offsetHeight || 400);
    updateLastItem();
  }
  function handleDeleteItem(index) {
    const updated = purchase.items.filter((i) => i.index !== index);
    setPurchase({ ...purchase, items: updated });
    setHeight(itemTable.current?.offsetHeight || 400);
  }
  function handleSave() {
    const payload = {
      taxIncluded: purchase.taxIncluded,
      company: purchase.company,
      vendor: {
        _id: purchase.vendor._id,
        name: purchase.vendor.name,
      },
      status: purchase.status || 'draft',
      tags: [],
      items: purchase.items.slice(0, -1),
      desc: purchase.desc,
    };
    if (purchase.product) {
      payload.tags.push({
        _id: purchase.product._id,
        label: purchase.product.sn,
        tagType: 'Product',
      });
    }
    if (purchase.quote) {
      payload.tags.push({
        _id: purchase.quote._id,
        label: `${purchase.quote.company}${purchase.quote.sn}`,
        tagType: 'Quote',
      });
    }

    if (edit) {
      payload.audit = { ...purchase.audit };
      payload.audit.checks = {};
      for (let key of Object.keys(purchase.audit.checks)) {
        payload.audit.checks[key] = {
          access: purchase.audit.checks[key].access,
        };
      }
      payload.status = 'audit';
      return client
        .put(`/api/purchase/${edit._id}`, payload)
        .then((response) => {
          if (onSave) {
            onSave(response);
          }
          onClose();
        })
        .catch((err) => {
          alert(err);
        });
    }

    client
      .post(`/api/purchase`, payload)
      .then((response) => {
        onSave(response);
        onClose();
      })
      .catch((err) => {
        alert(err);
      });
  }
  function handleClose() {
    onClose();
    if (!edit) {
      setPurchase(initialState);
    } else {
      setPurchase({
        ...edit,
        vendor: purchase.vendor,
        quote: purchase.quote,
        product: purchase.product,
      });
    }
  }
  const handleChange = (index, field, value) => {
    setPurchase((prev) => {
      const updated = purchase.items.map((item) => {
        if (item.index === index) {
          return {
            ...item,
            [field]: value,
            itemType: field === 'name' ? 'm' : item.itemType,
          };
        }
        const regex = new RegExp(value);
        if (
          field === 'name' &&
          prev.vendor?.items.some((i) => regex.test(i.name))
        ) {
          toast.warning(
            `已有名稱類似「${value}」的品項，請檢查是否需要建立新品項。`,
            {
              autoClose: 20000,
            }
          );
        }
        return item;
      });
      return { ...prev, items: updated };
    });
    updateLastItem();
  };
  const updateLastItem = () => {
    setPurchase((prev) => {
      const updated = [...prev.items];
      const last = updated.at(-1);
      const insert = {
        index: uuid(),
        itemType: 'm',
        name: '',
        price: 0,
        quantity: 1,
        desc: '',
        beforeTax: false,
        stocked: true,
      };
      if (last.name || last.price || last.desc) {
        updated.push(insert);
      } else {
        updated[updated.length - 1] = insert;
      }
      return { ...prev, items: updated };
    });
  };

  if (itemsQuery.isLoading) return <p>Loading...</p>;
  if (itemsQuery.isError) return <p>{itemsQuery.error.toString()}</p>;

  const queryRegex = new RegExp(query, 'i');

  return (
    <Dialog open={open} maxWidth="xl" fullWidth>
      <Grid container justifyContent="space-between" alignItems="center">
        <DialogTitle>{purchase._id ? '編輯' : '新增'}採購單</DialogTitle>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!purchase.taxIncluded}
                  onChange={(event) => {
                    setPurchase({
                      ...purchase,
                      taxIncluded: !event.target.checked,
                    });
                  }}
                />
              }
              label="未稅"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Box sx={{ m: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>公司</InputLabel>
              <Select
                label="公司"
                value={purchase.company}
                onChange={(event) =>
                  setPurchase({ ...purchase, company: event.target.value })
                }>
                <MenuItem value="福">頂福事業</MenuItem>
                <MenuItem value="專">管理費專戶</MenuItem>
                <MenuItem value="利">頂利事業</MenuItem>
                <MenuItem value="茂">辰茂事業</MenuItem>
                <MenuItem value="企">頂利企業社</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <VendorPanel
            vendor={purchase.vendor}
            setVendor={
              edit
                ? null
                : (vendor) => setPurchase((prev) => ({ ...prev, vendor }))
            }
            purchase={purchase}
            setPurchase={setPurchase}
          />
          <ProductPanel
            product={purchase.product}
            setProduct={(product) => setPurchase({ ...purchase, product })}
          />
          <QuotePanel
            quote={purchase.quote}
            setQuote={(quote) => setPurchase({ ...purchase, quote })}
          />
        </Grid>
      </Box>
      <Box sx={{ m: 3, mt: 1, minHeight: 400 }}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={3} lg={2}>
            <Grid
              container
              direction="column"
              sx={{
                border: '1px solid #C0C0C0',
                borderRadius: 0.5,
              }}>
              <Grid item sx={{ px: 1 }}>
                <TextField
                  size="small"
                  sx={{ mt: 1.5 }}
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder="搜尋品項"
                  inputProps={{ type: 'search' }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    minHeight: 400,
                    maxHeight: height + 10,
                    mt: 1.5,
                    overflow: 'auto',
                  }}>
                  <MenuList
                    sx={{
                      '.MuiListItemText-primary': { fontSize: '13px' },
                    }}>
                    {purchase.vendor &&
                      purchase.vendor.items
                        .filter((i) => queryRegex.test(i.name))
                        .map((item, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleChangeItem(null, item)}>
                            <ListItemText>{item.name}</ListItemText>
                          </MenuItem>
                        ))}
                  </MenuList>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Box
              sx={{
                border: '1px solid #C0C0C0',
                borderRadius: 0.5,
                height: '100%',
              }}>
              <Table
                sx={{
                  [`.${tableCellClasses.root}`]: {
                    paddingLeft: 1,
                    paddingRight: 0,
                  },
                }}
                ref={itemTable}>
                <TableHead>
                  <TableRow>
                    <TableCell width="25%">品項</TableCell>
                    <TableCell width="12%">單價</TableCell>
                    <TableCell width="12%">數量</TableCell>
                    <TableCell width="12%">單位</TableCell>
                    <TableCell>入庫</TableCell>
                    <TableCell>備註</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchase.items.map((item, pos) => (
                    <TableRow key={item.index}>
                      <TableCell>
                        <Autocomplete
                          value={item}
                          onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                              handleChange(item.index, 'name', newValue);
                            } else if (newValue && newValue.inputValue) {
                              handleChange(
                                item.index,
                                'name',
                                newValue.inputValue
                              );
                            } else {
                              handleChangeItem(item.index, newValue);
                            }
                          }}
                          freeSolo
                          clearOnBlur
                          clearOnEscape
                          blurOnSelect
                          options={purchase.vendor?.items || []}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id || Date.now()}>
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          type="number"
                          value={item.price}
                          onChange={(event) =>
                            handleChange(
                              item.index,
                              'price',
                              event.target.value
                            )
                          }
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          type="number"
                          value={item.quantity}
                          onChange={(event) =>
                            handleChange(
                              item.index,
                              'quantity',
                              event.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {item.itemType === 'm' ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            value={item.unit || ''}
                            onChange={(event) =>
                              handleChange(
                                item.index,
                                'unit',
                                event.target.value
                              )
                            }
                          />
                        ) : (
                          (function () {
                            const tracked = purchase.vendor.items.find(
                              (i) => i._id === item._id
                            );
                            return (
                              <FormControl size="small" fullWidth>
                                <Select
                                  value={item.unit}
                                  onChange={(event) =>
                                    handleChange(
                                      item.index,
                                      'unit',
                                      event.target.value
                                    )
                                  }>
                                  <MenuItem value={tracked.unit}>
                                    {tracked.unit}
                                  </MenuItem>
                                  {Object.keys(tracked.conversions).map(
                                    (u, index) => (
                                      <MenuItem key={index} value={u}>
                                        {u}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            );
                          })()
                        )}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={item.stocked}
                          onChange={(event) =>
                            handleChange(
                              item.index,
                              'stocked',
                              event.target.checked
                            )
                          }
                          disabled={item.itemType !== 'm'}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={item.desc}
                          onChange={(event) =>
                            handleChange(item.index, 'desc', event.target.value)
                          }
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        {pos !== purchase.items.length - 1 && (
                          <IconButton
                            onClick={() => handleDeleteItem(item.index)}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="備註"
              value={purchase.desc}
              onChange={(event) =>
                setPurchase({ ...purchase, desc: event.target.value })
              }
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
        <div style={{ textAlign: 'right', marginTop: 30 }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            取消
          </Button>
          {purchase.company &&
            purchase.vendor &&
            purchase.items.length > 1 &&
            purchase.items
              .slice(0, -1)
              .every((i) => i.name && i.price && i.quantity && i.unit) && (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color="primary"
                onClick={handleSave}>
                儲存
              </Button>
            )}
        </div>
      </Box>
    </Dialog>
  );
}

function VendorPanel({ vendor, setVendor, setPurchase }) {
  const [query, setQuery] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [vendors, setVendors] = React.useState();
  const client = useHttpClient();

  function handleKeyDown(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      query.length > 0
    ) {
      event.preventDefault();
      handleQuery();
      event.target.blur();
    }
  }
  function handleQuery() {
    client
      .get(`/api/vendor?query=${query}&select=_id+name+title+items&items=true`)
      .then((response) => {
        for (let vendor of response) {
          vendor.items.sort((a, b) => a.name.localeCompare(b.name));
        }
        setVendors(
          response.map((v) => ({ _id: v._id, name: v.title, items: v.items }))
        );
        setQuery('');
      })
      .catch((err) => {
        alert(err.toString());
      });
  }
  function selectVendor(vendor) {
    setOpen(false);
    if (!vendor) {
      setPurchase((prev) => ({
        ...prev,
        items: prev.items.filter((i) => i.itemType === 'm'),
      }));
    }
    setVendor(vendor);
    setQuery('');
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth>
        <DialogTitle>選擇廠商</DialogTitle>
        <Box sx={{ p: 3, pt: 0 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="請輸入廠商名稱"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          {vendors && (
            <TableContainer
              sx={{
                maxHeight: 240,
                [`.${tableRowClasses.root}:hover`]: {
                  backgroundColor: blue[50],
                },
              }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>廠商名稱</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendors.map((vendor) => (
                    <TableRow
                      key={vendor._id}
                      onDoubleClick={() => selectVendor(vendor)}>
                      <TableCell>{vendor.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div style={{ textAlign: 'right', marginTop: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}>
              取消
            </Button>
          </div>
        </Box>
      </Dialog>
      {vendor ? (
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>廠商</InputLabel>
            <OutlinedInput
              variant="outlined"
              size="small"
              label="廠商"
              inputProps={{ readOnly: true }}
              value={vendor.name}
              endAdornment={
                setVendor && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => selectVendor()}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid item>
          <Button
            variant="text"
            onClick={() => {
              setOpen(true);
            }}>
            選擇廠商
          </Button>
        </Grid>
      )}
    </>
  );
}
function ProductPanel({ product, setProduct }) {
  const [products, setProducts] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const client = useHttpClient();

  function handleKeyDown(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      query.length > 0
    ) {
      event.preventDefault();
      handleQuery();
      event.target.blur();
    }
  }
  function handleQuery() {
    client
      .get(`/api/product?sn=${encodeURIComponent(serialize(query))}`)
      .then((response) => {
        setProducts(response);
        setQuery('');
      })
      .catch((err) => {
        alert(err.toString());
      });
  }
  function selectProduct(product) {
    setOpen(false);
    setProduct({ _id: product._id, sn: product.sn });
    setQuery('');
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth>
        <DialogTitle>選擇產品</DialogTitle>
        <Box sx={{ p: 3, pt: 0 }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="請輸入產品編號"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          {products && (
            <div style={{ overflow: 'hidden' }}>
              <TableContainer
                sx={{
                  maxHeight: 240,
                  [`.${tableRowClasses.root}:hover`]: {
                    backgroundColor: blue[50],
                  },
                }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>產品編號</TableCell>
                      <TableCell>狀態</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <TableRow
                        key={product._id}
                        onDoubleClick={() => selectProduct(product)}>
                        <TableCell>{product.sn}</TableCell>
                        <TableCell>{product.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          <div style={{ textAlign: 'right', marginTop: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}>
              取消
            </Button>
          </div>
        </Box>
      </Dialog>
      {product ? (
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>產品編號</InputLabel>
            <OutlinedInput
              variant="outlined"
              size="small"
              label="產品編號"
              inputProps={{ readOnly: true }}
              value={product.sn}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setProduct()}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid item>
          <Button variant="text" onClick={() => setOpen(true)}>
            選擇產品
          </Button>
        </Grid>
      )}
    </>
  );
}
function QuotePanel({ quote, setQuote }) {
  const [query, setQuery] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [quotes, setQuotes] = React.useState();
  const client = useHttpClient();

  function handleKeyDown(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      query.length > 0
    ) {
      event.preventDefault();
      handleQuery();
      event.target.blur();
    }
  }
  function handleQuery() {
    client
      .get(`/api/quote?sn=${query}`)
      .then((response) => {
        setQuotes(response);
        setQuery('');
      })
      .catch((err) => {
        alert(err.toString());
      });
  }
  function selectQuote(quote) {
    setOpen(false);
    setQuote(quote);
    setQuery('');
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth>
        <DialogTitle>選擇報價單</DialogTitle>
        <Box sx={{ p: 3, pt: 0 }}>
          <TextField
            variant="outlined"
            size="small"
            type="number"
            placeholder="請輸入報價單編號"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          {quotes && (
            <TableContainer
              sx={{
                maxHeight: 240,
                [`.${tableRowClasses.root}:hover`]: {
                  backgroundColor: blue[50],
                },
              }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>單號</TableCell>
                    <TableCell>公司</TableCell>
                    <TableCell>金額</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotes.map((quote) => (
                    <TableRow
                      key={quote._id}
                      onDoubleClick={() => selectQuote(quote)}>
                      <TableCell>{quote.sn}</TableCell>
                      <TableCell>{companies[quote.company].short}</TableCell>
                      <TableCell>{quote.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div style={{ textAlign: 'right', marginTop: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}>
              取消
            </Button>
          </div>
        </Box>
      </Dialog>
      {quote ? (
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>報價單</InputLabel>
            <OutlinedInput
              variant="outlined"
              size="small"
              label="報價單"
              inputProps={{ readOnly: true }}
              value={`${quote.company} ${quote.sn.toString().padStart(5, '0')}`}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setQuote()}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid item>
          <Button variant="text" onClick={() => setOpen(true)}>
            選擇報價單
          </Button>
        </Grid>
      )}
    </>
  );
}

export default EditPurchaseDialog;
