import './styles.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import App from './App';
import AuthenticationProvider from './services/authentication';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import localText from './utils/dataGridLocale';
import reportWebVitals from './reportWebVitals';
import { zhTW } from '@mui/material/locale';
import zhTWDatePicker from './utils/datePickerLocale';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});
const theme = createTheme({
  palette: {
    appBackground: '#EEF0F4',
    gray: {
      main: 'rgba(0,0,0,0.75)',
      light: 'rgba(0,0,0,0.65)',
      dark: 'rgba(0,0,0,0.85)',
    },
  },
  zhTW,
  localText,
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        localeText={zhTWDatePicker}>
        <AuthenticationProvider>
          <BrowserRouter>
            <QueryClientProvider client={client}>
              <App />
              {process.env.NODE_ENV === 'development' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </QueryClientProvider>
          </BrowserRouter>
        </AuthenticationProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
