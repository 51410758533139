import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DeceasedInfoPanel, {
  deceasedInfoPanelReducer,
} from '../../components/DeceasedInfoPanel';
import { Link, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';

import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { handleParams } from '../../utils/handleParams';
import { queryDeceaseds } from '../../fetchers/deceased.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';

const columns = (setDeceased) => [
  {
    field: 'name',
    headerName: '姓名',
    flex: 1,
    renderCell: (params) => {
      return (
        <Button onClick={() => setDeceased(params.row)}>{params.value}</Button>
      );
    },
  },
  {
    field: 'area',
    headerName: '區域',
    flex: 1,
  },
  {
    field: 'product',
    headerName: '產品編號',
    flex: 1,
    renderCell: (param) => {
      return <Link to={`/product/${param.value._id}`}>{param.value.sn}</Link>;
    },
  },
  {
    field: 'birth',
    headerName: '出生日期',
    flex: 1,
    valueGetter: (params) => handleDate(params.value),
  },
  {
    field: 'death',
    headerName: '死亡日期',
    flex: 1,
    valueGetter: (params) => handleDate(params.value),
  },
  {
    field: 'temp',
    headerName: '暫厝日期',
    flex: 1,
    valueGetter: (params) => handleDate(params.value),
  },
  {
    field: 'movein',
    headerName: '遷入日期',
    flex: 1,
    valueGetter: (params) => handleDate(params.value),
  },
  {
    field: 'moveout',
    headerName: '遷出日期',
    flex: 1,
    valueGetter: (params) => handleDate(params.value),
  },
];
function handleDate(date) {
  if (date) {
    return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
  }
  return '';
}

function DeceasedList(props) {
  useCrumbs([crumbs.deceasedList]);
  const [params, setParams] = useSearchParams();
  const [query, setQuery] = React.useState(handleParams(params));
  const [keywords, setKeywords] = React.useState(query);
  const deceasedDialog = React.useReducer(deceasedInfoPanelReducer, {
    deceased: null,
    refresh: Date.now(),
  });
  const deceasedsQuery = useQuery(
    ['deceaseds', keywords],
    queryDeceaseds(keywords),
    { placeholderData: [], staleTime: 1000 * 30 }
  );
  React.useEffect(() => {
    deceasedsQuery.refetch();
  }, [deceasedDialog[0].refresh]);

  if (deceasedsQuery.isLoading) return <Spinner />;

  function handleChange(field) {
    return (event) => {
      if (field === 'range') {
        return setQuery({ ...query, [field]: event });
      }
      setQuery({ ...query, [field]: event.target.value });
    };
  }
  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function getQueryInput(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            value={query.product || ''}
            onChange={handleChange('product')}
            onKeyDown={handleKeyDown}
            variant="outlined"
            label="產品編號"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            value={query.name || ''}
            onChange={handleChange('name')}
            onKeyDown={handleKeyDown}
            variant="outlined"
            label="歿者姓名"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>日期</InputLabel>
            <Select
              label="日期"
              value={query.type || ''}
              onChange={handleChange('type')}>
              <MenuItem value="">取消</MenuItem>
              <MenuItem value="遷入">遷入日期</MenuItem>
              <MenuItem value="暫厝">暫厝日期</MenuItem>
              <MenuItem value="遷出">遷出日期</MenuItem>
              <MenuItem value="出生">出生日期</MenuItem>
              <MenuItem value="死亡">死亡日期</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }

  return (
    <>
      <ListPage
        columns={columns((deceased) =>
          deceasedDialog[1]({ type: 'open', payload: deceased })
        )}
        rows={deceasedsQuery.isSuccess ? deceasedsQuery.data : []}
        onCellDoubleClick={(params) =>
          deceasedDialog[1]({ type: 'open', payload: params.row })
        }
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={getQueryInput}
        />
      </ListPage>
      <DeceasedInfoPanel control={deceasedDialog} />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
        ]}
      />
    </>
  );
}

export default DeceasedList;
