import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';
import { createUser, queryUsers } from '../../fetchers/system.fetch';

import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import { access } from './system.const';
import crumbs from '../../services/crumbs/crumbs.const';
import departments from '../../utils/departments.const';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useAccess from '../../services/authentication/useAccess';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';

const columns = [
  {
    field: 'name',
    headerName: '姓名',
    flex: 1,
    renderCell: (params) => (
      <Link to={`/system/account/${params.row._id}`}>{params.value}</Link>
    ),
  },
  {
    field: 'department',
    headerName: '部門',
    flex: 1,
    valueGetter: (params) => departments[params.row.access.substring(0, 1)],
  },
  {
    field: 'logins',
    headerName: '上次登入時間',
    flex: 1,
    valueGetter: (params) => params.value[0]?.date?.toDateTime(),
  },
];
function generatePassword() {
  var length = 8,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

function AccountList(props) {
  useCrumbs([crumbs.accountList]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const hasAccess = useAccess();
  const [keyword, setKeyword] = React.useState('');
  const [newAccount, setNewAccount] = React.useState({
    open: searchParams.get('notify') ? true : false,
  });
  const accountsQuery = useQuery(
    ['accounts', keyword],
    queryUsers({ keyword })
  );
  const accountCreateRequest = useMutation(createUser, {
    onSuccess: (value) => {
      toast.success('成功建立帳號資料');
      setNewAccount({ open: false });
      accountsQuery.refetch();
    },
    onError: (error) => toast.error(error.message),
  });

  React.useEffect(() => {
    if (searchParams.get('notify')) {
      setNewAccount({
        open: true,
        account: {
          line: {
            accessToken: searchParams.get('notify'),
          },
        },
      });
      setSearchParams();
    }
  }, []);

  if (accountsQuery.isLoading) return <Spinner />;
  if (accountsQuery.isError) return <p>{accountsQuery.error.toString()}</p>;

  function handleCreate() {
    if (!newAccount.account?.line?.accessToken)
      return toast.error('無Line Notify綁定資料。');
    accountCreateRequest.mutate(
      {
        ...newAccount.account,
        username: newAccount.account.email.match(/(.*)@/)[1],
        password: generatePassword(),
        line: {
          date: Date.now(),
          accessToken: newAccount.account.line.accessToken,
        },
      },
      {
        onSuccess: (data) => {
          navigate(`/system/account/${data._id}`);
        },
      }
    );
  }

  return (
    <>
      <ListPage
        columns={columns}
        rows={accountsQuery.data}
        onSearch={(query) => setKeyword(query)}
        onCellDoubleClick={(params) =>
          navigate(`/system/account/${params.row._id}`)
        }
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(
            speedDialIcons.add,
            '新增帳號',
            () => {
              window.location.href = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=Lj7okY1sbaqQOKQTMmxWHM&redirect_uri=${encodeURIComponent(
                `${process.env.REACT_APP_API_URL}/notify/access-token`
              )}&state=00000&scope=notify&response_mode=form_post`;
            },
            !hasAccess('Ah') && !hasAccess('Zm')
          ),
        ]}
      />
      <Dialog
        open={newAccount.open && newAccount.account?.line?.accessToken}
        onClose={() => setNewAccount({ ...newAccount, open: false })}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>建立帳號</DialogTitle>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="姓名"
              value={newAccount.account?.name || ''}
              onChange={(e) =>
                setNewAccount({
                  ...newAccount,
                  account: { ...newAccount.account, name: e.target.value },
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Email"
              value={newAccount.account?.email || ''}
              onChange={(e) =>
                setNewAccount({
                  ...newAccount,
                  account: { ...newAccount.account, email: e.target.value },
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">部門</InputLabel>
              <Select
                label="部門"
                value={newAccount.account?.access?.substring(0, 1) || ''}
                onChange={(event) =>
                  setNewAccount({
                    ...newAccount,
                    account: {
                      ...newAccount.account,
                      access: `${
                        event.target.value
                      }${newAccount.account.access?.substring(1)}`,
                    },
                  })
                }>
                {Object.keys(access)
                  .filter((x) => x !== 'Z')
                  .map((record) => (
                    <MenuItem key={record} value={record}>
                      {access[record].department}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">職位</InputLabel>
              <Select
                label="職位"
                value={newAccount.account?.access?.substring(1) || ''}
                onChange={(event) =>
                  setNewAccount({
                    ...newAccount,
                    account: {
                      ...newAccount.account,
                      access: `${newAccount.account.access.substring(0, 1)}${
                        event.target.value
                      }`,
                    },
                  })
                }>
                {newAccount.account?.access &&
                  Object.keys(access[newAccount.account.access.substring(0, 1)])
                    .slice(1)
                    .map((record) => (
                      <MenuItem key={record} value={record}>
                        {
                          access[newAccount.account.access.substring(0, 1)][
                            record
                          ]
                        }
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          {newAccount.account?.line?.accessToken && (
            <Grid item xs={12} sm={6}>
              <p style={{ color: '#606060' }}>
                綁定Line Notify：{newAccount.account.line.accessToken}
              </p>
            </Grid>
          )}
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            {newAccount.account &&
              newAccount.account.access &&
              newAccount.account.name &&
              newAccount.account.email &&
              newAccount.account.access.length === 2 && (
                <Button
                  variant="contained"
                  size="large"
                  sx={{ mr: 2 }}
                  onClick={handleCreate}>
                  確定
                </Button>
              )}
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => setNewAccount({ open: false })}>
              取消
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default AccountList;
