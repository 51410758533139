import axios from 'axios';

export default async function getPostalCode(address) {
  return axios
    .get(`https://zip5.5432.tw/zip5json.py?adrs=${encodeURIComponent(address)}`)
    .then((response) => {
      response = response.data;
      return response.zipcode6 ? response.zipcode6 : response.zipcode5;
    });
}
