import React from 'react';
import styles from './styles.module.scss';

function Spinner(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.spinner}>
        <div className={styles.dot1}></div>
        <div className={styles.dot2}></div>
      </div>
    </div>
  );
}

export default Spinner;
