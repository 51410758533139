import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import React from 'react';

export function CollapseControl({ title, collapsed, setCollapsed }) {
  return (
    <Box
      sx={{
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 13,
        display: 'flex',
      }}>
      <span
        style={{
          display: 'inline-block',
          marginTop: 5,
        }}>
        {title}
      </span>
      <span
        style={{
          display: 'inline-block',
          flex: 1,
          borderTop: '1px solid',
          borderColor: '#BEBEBE',
          margin: 15,
        }}></span>
      <span>
        <IconButton size="small" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? (
            <ArrowDropDownIcon fontSize="small" />
          ) : (
            <ArrowDropUpIcon fontSize="small" />
          )}
        </IconButton>
      </span>
    </Box>
  );
}
