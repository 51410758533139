import AddTaggedPaymentDialog, {
  addTaggedPaymentDialogReducer,
} from '../../components/AddTaggedPaymentDialog';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import SpeedDial, { createAction } from '../../services/speedDial';
import {
  closeOrder,
  queryOrderById,
  returnItems,
  updateOrder,
} from '../../fetchers/order.fetch';
import { red, yellow } from '@mui/material/colors';

import AddContactDialog from '../../components/AddContactDialog';
import { AddItemDialog } from './AddItemDialog';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import ItemTable from './ItemTable';
import { OrderInfo } from './OrderInfo';
import PaymentTable from '../../components/PaymentTable';
import PaymentsIcon from '@mui/icons-material/Payments';
import Spinner from '../../components/Spinner';
import WarningIcon from '@mui/icons-material/Warning';
import crumbs from '../../services/crumbs/crumbs.const';
import { queryProductsBySerialNumber } from '../../fetchers/product.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useAccess from '../../services/authentication/useAccess';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import { useParams } from 'react-router-dom';
import useQuery from '../../services/httpClient/useQuery';
import useUser from '../../services/authentication/useUser';

function OrderDetail(props) {
  useCrumbs([crumbs.orderList, crumbs.orderDetail]);
  const { id } = useParams();
  const user = useUser();
  const hasAccess = useAccess();
  const orderQuery = useQuery(['order', id], queryOrderById({ id }), {
    onSuccess: (data) => {
      data.timeline = [];
      if (data.summary) {
        data.summary.interactions.forEach((interaction) => {
          data.timeline.push({
            date: DateTime.fromISO(interaction.date),
            type: interaction.interactionType,
            broker: interaction.broker,
          });
        });
        data.summary.referrals.forEach((referral) => {
          data.timeline.push({
            date: DateTime.fromISO(referral.created.date),
            type: '通報',
            broker: referral.broker,
          });
        });
        data.timeline.sort((a, b) => (a.date > b.date ? 1 : -1));
      }
      setOrder(data);
      setItems(data.items);
    },
    staleTime: 1000 * 60 * 10,
  });
  const orderUpdateRequest = useMutation(updateOrder, {
    onSuccess: () => {
      toast.success('成功儲存訂單紀錄');
      orderQuery.refetch();
    },
  });
  const closeOrderRequest = useMutation(closeOrder, {
    onSuccess: (response) => {
      toast.success(
        `成功訂單完款：開立權狀 ${response.length - 1} 張、
        更新產品資料：${response
          .slice(1)
          .map((p) => p.sn)
          .join('、')}`,
        { autoClose: 10000 }
      );
      orderQuery.refetch();
    },
  });
  const orderReturnRequest = useMutation(returnItems, {
    onSuccess: () => {
      toast.success(`成功退還產品`);
      orderQuery.refetch();
    },
  });
  const [order, setOrder] = React.useState(orderQuery.data);
  const [items, setItems] = React.useState(orderQuery.data?.items);
  const [itemDialog, setItemDialog] = React.useState(false);
  const [contactDialog, setContactDialog] = React.useState(false);
  const [paymentDialog, paymentDialogDispatch] = React.useReducer(
    addTaggedPaymentDialogReducer,
    { open: false, refresh: Date.now() }
  );
  const [commissionDialog, setCommissionDialog] = React.useState({
    open: false,
  });
  const [cancelDialog, setCancelDialog] = React.useState({
    open: false,
    cancelType: '逾期',
    date: DateTime.now(),
  });
  const [returnDialog, setReturnDialog] = React.useState({
    open: false,
    date: DateTime.now(),
    returnType: '退還',
    items: [],
  });

  if (orderQuery.isLoading || !order) return <Spinner />;

  const total =
    order?.items.reduce(
      (sum, item) =>
        sum + item.price + item.const + item.additional - item.discount,
      0
    ) - (order.legacy?.discount?.amount || 0);
  const receivable = total - (order?.received || 0);
  const discount = order.legacy
    ? order.legacy.discount?.amount || 0
    : Math.round(
        order?.items.reduce((sum, item) => sum + item.discount, 0),
        2
      );
  const showCommissionAction = () => {
    if (!hasAccess('F')) return false;
    if (!order.closed) return false;
    if (!order.canceled) return false;
    if (!order.broker) return false;
    if (order.broker.commission.commissionType === 0) return false;
    if (order.broker.commission.date) {
      const date = DateTime.fromISO(order.broker.commission.date);
      if (date < DateTime.now().minus({ day: 7 })) return false;
      return true;
    }
    return true;
  };
  function handlePaymentsLoaded(response) {
    setOrder({ ...order, received: response.total });
  }
  function handleContact(response) {
    if (order.buyers.find((x) => x._id === response._id)) {
      return '重複客戶資料';
    }
    const updated = order.buyers.map((contact) => ({
      _id: contact._id,
      name: contact.name,
      ssn: contact.ssn,
    }));
    updated.push(response);
    const types = ['A', 'B', 'C'];
    const updatedItems = items.map((item) => {
      const type = item.sn.substring(0, 1);
      if (types.includes(type)) {
        return {
          ...item,
          certificate: {
            ...item.certificate,
            sn: null,
          },
        };
      }
      return item;
    });
    orderUpdateRequest.mutate({ id, buyers: updated, items: updatedItems });
  }
  function handleDeleteBuyer(contactId) {
    const filtered = order.buyers.filter(
      (contact) => contact._id !== contactId
    );
    const updated = filtered.map((contact) => ({
      _id: contact._id,
      name: contact.name,
      ssn: contact.ssn,
    }));
    const types = ['A', 'B', 'C'];
    const updatedItems = items.map((item) => {
      const type = item.sn.substring(0, 1);
      if (types.includes(type)) {
        return {
          ...item,
          certificate: {
            ...item.certificate,
            sn: null,
          },
        };
      }
      return item;
    });
    orderUpdateRequest.mutate({ id, buyers: updated, items: updatedItems });
  }
  function handleCommission() {
    if (!commissionDialog.paymentMethod) {
      return toast.error('請輸入支付方式', { autoClose: false });
    }
    if (!commissionDialog.amount || commissionDialog.amount === 0) {
      return toast.error('請輸入金額', { autoClose: false });
    }
    orderUpdateRequest.mutate(
      {
        id,
        broker: {
          ...order.broker,
          commission: {
            ...order.broker.commission,
            ...commissionDialog,
            date: commissionDialog.date || DateTime.now(),
            by: {
              _id: user._id,
              name: user.name,
            },
          },
        },
      },
      {
        onSuccess: () => setCommissionDialog({ open: false }),
      }
    );
  }
  function handleSave() {
    const updated = {
      id,
      items: items.slice(),
      buyers: order.buyers,
      desc: order.desc,
      promotional: order.promotional,
    };
    orderUpdateRequest.mutate(updated);
  }
  function handleCancelOrder() {
    if (!!order.closed) return;
    const updated = {
      ...order,
      canceled: {
        cancelType: cancelDialog.cancelType,
        date: cancelDialog.date,
        by: {
          _id: user._id,
          name: user.name,
        },
      },
    };
    orderUpdateRequest.mutate(updated);
  }
  return (
    <Box>
      {!!order.canceled && (
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={4}>
            <Alert
              sx={{ mb: 2 }}
              severity="warning">{`訂單已於 ${order.canceled.date.toDate()} 因為 ${
              order.canceled.cancelType
            } 由 ${order.canceled.by.name} 取消訂單。`}</Alert>
          </Grid>
        </Grid>
      )}
      <OrderInfo
        order={order}
        total={total}
        receivable={receivable}
        setOrder={setOrder}
        onDeleteBuyer={handleDeleteBuyer}
        discount={discount}
      />
      <Paper elevation={2} sx={{ mt: 2 }}>
        <ItemTable
          id={id}
          items={items}
          setItems={setItems}
          orderClosed={!!order.closed || !!order.canceled}
          onSave={() => orderQuery.refetch()}
        />
        <AddItemDialog
          items={items}
          setItems={setItems}
          open={itemDialog}
          setOpen={setItemDialog}
        />
      </Paper>
      <Paper elevation={2} sx={{ mt: 2 }}>
        <PaymentTable
          tag={order._id}
          flag={paymentDialog.refresh}
          onLoaded={handlePaymentsLoaded}
        />
      </Paper>
      {(order.closed || order.canceled) && (
        <>
          <ReturnDialog
            order={order}
            state={returnDialog}
            setState={setReturnDialog}
            orderReturnRequest={orderReturnRequest}
          />
          <Dialog
            open={commissionDialog.open}
            onClose={() =>
              setCommissionDialog((prev) => ({ ...prev, open: false }))
            }
            maxWidth="xs"
            fullWidth>
            <DialogTitle>佣金</DialogTitle>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <DatePicker
                  label="日期"
                  inputFormat="yyyy-MM-dd"
                  value={commissionDialog.date || DateTime.now()}
                  onChange={(val) =>
                    setCommissionDialog({
                      ...commissionDialog,
                      date: val.toStart('day'),
                    })
                  }
                  renderInput={(params) => <TextField fullWidth {...params} />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="支付方式"
                  value={commissionDialog.paymentMethod || ''}
                  onChange={(e) =>
                    setCommissionDialog((prev) => ({
                      ...prev,
                      paymentMethod: e.target.value,
                    }))
                  }
                  fullWidth>
                  <MenuItem value="匯款">匯款</MenuItem>
                  <MenuItem value="現金">現金</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="金額"
                  type="number"
                  value={commissionDialog.amount || ''}
                  onChange={(e) =>
                    setCommissionDialog((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setCommissionDialog({ open: false })}
                  sx={{ mr: 2 }}>
                  取消
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCommission}>
                  儲存
                </Button>
              </Grid>
            </Grid>
          </Dialog>
        </>
      )}
      {!order.closed && !order.canceled && (
        <>
          <AddTaggedPaymentDialog
            state={{
              open: paymentDialog.open,
              setOpen: (open) =>
                paymentDialogDispatch({ type: open ? 'open' : 'close' }),
            }}
            contacts={order.buyers}
            tag={order._id}
            tagType="Order"
            tagLabel={order.sn}
            open={paymentDialog.open}
            onClose={() => paymentDialogDispatch({ type: 'close' })}
            onSubmit={() => {
              toast.success('成功新增付費紀錄');
              paymentDialogDispatch({ type: 'refresh' });
            }}
          />
          <AddContactDialog
            state={{ open: contactDialog, setOpen: setContactDialog }}
            onChange={handleContact}
            contactTypes={['訂購人']}
          />
          <Dialog
            open={cancelDialog.open}
            onClose={() =>
              setCancelDialog({
                open: false,
                date: DateTime.now().startOf('day'),
                cancelType: '逾期',
              })
            }>
            <DialogTitle>取消訂單</DialogTitle>
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <DatePicker
                  label="取消日期"
                  inputFormat="yyyy-MM-dd"
                  value={cancelDialog.date || ''}
                  onChange={(val) =>
                    setCancelDialog({
                      ...cancelDialog,
                      date: val.startOf('day'),
                    })
                  }
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">類別</InputLabel>
                  <Select
                    label="類別"
                    value={cancelDialog.cancelType || ''}
                    onChange={(event) =>
                      setCancelDialog({
                        ...cancelDialog,
                        cancelType: event.target.value,
                      })
                    }>
                    <MenuItem value="逾期">逾期</MenuItem>
                    {DateTime.now().startOf('day') <
                      DateTime.fromISO(order.orderDate)
                        .startOf('day')
                        .plus({ days: 5 }) && (
                      <MenuItem value="退訂">退訂</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningIcon sx={{ color: yellow[900], mr: 0.5 }} />
                    <p style={{ color: red[600] }}>
                      {cancelDialog.cancelType === '逾期'
                        ? '逾期不退訂金，須開立發票。'
                        : '退訂不需要開立發票。'}
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() =>
                    setCancelDialog({
                      open: false,
                      cancelType: '逾期',
                      date: DateTime.now(),
                    })
                  }>
                  取消
                </Button>
                {!!cancelDialog.cancelType && !!cancelDialog.date && (
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ ml: 2 }}
                    onClick={handleCancelOrder}>
                    確定
                  </Button>
                )}
              </Grid>
            </Grid>
          </Dialog>
        </>
      )}
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(
            speedDialIcons.save,
            '儲存',
            handleSave,
            !!order.closed || !!order.canceled || !hasAccess('AF')
          ),
          createAction(
            speedDialIcons.addToList,
            '加入產品',
            () => setItemDialog(true),
            !!order.closed || !!order.canceled || !hasAccess('AF')
          ),
          createAction(
            speedDialIcons.addContact,
            '加入訂購人',
            () => setContactDialog(true),
            !!order.closed || !!order.canceled || !hasAccess('AF')
          ),
          createAction(
            speedDialIcons.addPayment,
            '新增付費紀錄',
            () => paymentDialogDispatch({ type: 'open' }),
            !!order.closed || !!order.canceled || !hasAccess('AF')
          ),
          createAction(
            <PaymentsIcon />,
            '新增佣金紀錄',
            () => setCommissionDialog({ ...commissionDialog, open: true }),
            !showCommissionAction()
          ),
          createAction(
            speedDialIcons.complete,
            '完款',
            () => closeOrderRequest.mutate({ id }),
            !!order.closed ||
              !!order.canceled ||
              (total === 0 && discount === 0) ||
              (receivable && receivable !== 0) ||
              !hasAccess('AF')
          ),
          createAction(
            speedDialIcons.cancel,
            '取消訂單',
            () => setCancelDialog({ ...cancelDialog, open: true }),
            !!order.closed || !!order.canceled || !hasAccess('AF')
          ),
          createAction(
            <AssignmentReturnIcon />,
            '退還產品',
            () =>
              setReturnDialog({
                open: true,
                date: DateTime.now(),
                returnType: '退還',
                items: [],
              }),
            !order.closed ||
              !hasAccess('AF') ||
              order.items.every((item) => /退還|退費|公司購回/.test(item.desc))
          ),
        ]}
      />
    </Box>
  );
}

export default OrderDetail;

function ReturnDialog({ order, state, setState, orderReturnRequest }) {
  const productsQuery = useQuery(
    ['products', { sn: order?.items.map((item) => item.sn) }],
    queryProductsBySerialNumber({ sn: order?.items.map((item) => item.sn) }),
    {
      enabled: state.open,
      onSettled: (data) => {
        if (!data) return;
        setState((prev) => ({
          ...prev,
          items: data.filter(
            (item) =>
              (item.deceaseds.length === 0 ||
                item.deceaseds.every((deceased) => !!deceased.moveout)) &&
              !/退還|退費|公司購回/.test(
                order.items.find((i) => i._id === item._id.toString()).desc
              )
          ),
        }));
      },
    }
  );

  useEffect(() => {
    if (!productsQuery.data) return;
    setState((prev) => ({
      ...prev,
      items: productsQuery.data.filter(
        (item) =>
          (item.deceaseds.length === 0 ||
            item.deceaseds.every((deceased) => !!deceased.moveout)) &&
          !/退還|退費|公司購回/.test(
            order.items.find((i) => i._id === item._id.toString()).desc
          )
      ),
    }));
  }, [state.open]);

  if (productsQuery.isLoading || productsQuery.isError) return null;

  function handleReturn() {
    const filtered = state.items.filter((item) => item.check);
    if (filtered.length === 0) return;
    orderReturnRequest.mutate({
      id: order.id,
      date: state.date.toISODate(),
      returnType: state.returnType,
      items: filtered.map((item) => ({
        _id: item.id,
        amount: item.amount,
        desc: item.desc,
      })),
    });
    setState({
      open: false,
      date: DateTime.now(),
      returnType: '退還',
      items: [],
    });
  }

  return (
    <Dialog
      open={state.open}
      onClose={() => setState({ open: false })}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>退還產品</DialogTitle>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <DatePicker
            label="日期"
            inputFormat="yyyy-MM-dd"
            value={state.date || ''}
            onChange={(val) =>
              setState({
                ...state,
                date: val.startOf('day'),
              })
            }
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">類別</InputLabel>
            <Select
              label="類別"
              value={state.returnType || ''}
              onChange={(event) =>
                setState({
                  ...state,
                  returnType: event.target.value,
                })
              }>
              <MenuItem value="退還">退還</MenuItem>
              <MenuItem value="退費">退費</MenuItem>
              <MenuItem value="公司購回">公司購回</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {state.items && (
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>產品編號</TableCell>
                  <TableCell>金額</TableCell>
                  <TableCell>備註</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.items.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <Checkbox
                        checked={state.items[index].check || false}
                        onChange={() => {
                          const items = [...state.items];
                          items[index].check = !items[index].check;
                          setState({ ...state, items });
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.sn}</TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        value={state.items[index].amount || ''}
                        onChange={(event) => {
                          const items = [...state.items];
                          items[index].amount = event.target.value;
                          setState({ ...state, items });
                        }}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={state.items[index].desc || ''}
                        onChange={(event) => {
                          const items = [...state.items];
                          items[index].desc = event.target.value;
                          setState({ ...state, items });
                        }}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => setState({ open: false })}>
            取消
          </Button>
          {state.items?.some((i) => i.amount?.toString().length > 0) && (
            <Button
              variant="contained"
              size="large"
              sx={{ ml: 2 }}
              onClick={handleReturn}>
              確定
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
