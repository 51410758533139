import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
} from '@mui/material';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import React from 'react';
import styles from './style.module.scss';
import useSas from '../../services/authentication/useSas';

const listTitleStyles = {
  fontSize: 15.5,
  width: 100,
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 0,
  paddingBottom: 0,
  '& p': {
    marginTop: 0.6,
    marginBottom: 0.6,
  },
};

const listValueStyles = {
  fontSize: 15.5,
  width: 270,
  paddingTop: 0,
  paddingBottom: 0,
  '& p': {
    marginTop: 0.6,
    marginBottom: 0.6,
  },
};

const listValueBoldStyles = {
  fontWeight: 'bold',
  //color: 'rgba(0,0,0,0.7)',
};

const types = {
  A: {
    title: '骨灰(骸)存放單位使用權狀',
    type: '塔位',
  },
  B: {
    title: '靈位使用權狀',
    type: '靈位',
  },
  C: {
    title: '罈位使用權狀',
    type: '罈位',
  },
  E: {
    title: '墓地使用權狀',
    type: '墓地',
  },
};

function PrintableCert({ products: prints }) {
  const sas = useSas();
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    if (prints) {
      setProducts(
        prints.map((p) => {
          p.certificate.date = DateTime.fromISO(p.certificate.date);
          return p;
        })
      );
    }
  }, [prints]);

  return (
    <Box
      sx={{
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
      {products.map((product) => {
        const type = product.sn.substring(0, 1);
        if (product.area === '永生墓園') return null;
        if (type === 'D' || type === 'F') return null;
        if (
          !product?.certificate?.sn ||
          !product?.location ||
          !product?.certificate?.date ||
          product?.certificate.contacts.filter(
            (c) => c.contactType === '權益人'
          ).length === 0 ||
          (type === 'E' && !product.lot.size)
        )
          return null;

        return (
          <Box
            key={product._id}
            className={styles.form}
            sx={{
              background: `url('https://dinfucemstorage.blob.core.windows.net/file/%E6%AC%8A%E7%8B%80.jpeg?${sas}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '149mm 210mm',
            }}>
            <Box
              sx={{
                fontFamily: '標楷體,BiauKai,DFKai-SB,KaiU,標楷體-繁,serif',
                fontWeight: 'bold',
                position: 'absolute',
                textAlign: 'center',
                width: '100%',
                fontSize: 28,
                top: 150,
                color: 'rgba(0,0,0,0.8)',
              }}>
              {types[type].title}
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 130,
                padding: 11,
                textAlign: 'justify',
                lineHeight: 1.6,
              }}>
              本權狀係證明使用權人享有登載位置之
              {types[type].type}
              使用權利。其使用權應依照
              {!product.certificate.untransferrable && '雙方買賣契約書及'}
              林口頂福陵園墓地暨納骨塔使用管理辦法為之。
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: `${
                  185 +
                  (product.certificate.promotional ||
                  product.certificate.untransferrable
                    ? 60
                    : 0)
                }px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: `${
                  300 -
                  (product.certificate.promotional ||
                  product.certificate.untransferrable
                    ? 60
                    : 0)
                }px`,
                width: '100%',
              }}>
              <Box
                sx={{
                  width: '370px',
                }}>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: 'none',
                    },
                  }}>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={listTitleStyles}>
                        <p>權</p>
                        <p>狀</p>
                        <p>編</p>
                        <p>號</p>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...listValueStyles,
                          ...listValueBoldStyles,
                        }}>
                        {product.certificate.sn}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={listTitleStyles}>
                        <p>位</p>
                        <p>置</p>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...listValueStyles,
                          ...listValueBoldStyles,
                        }}>
                        {product.location
                          .replace(/([\d+,A-Z]+)/g, ' $1 ')
                          .trim()}
                      </TableCell>
                    </TableRow>
                    {type === 'E' && product.lot.size && (
                      <TableRow>
                        <TableCell sx={listTitleStyles}>
                          <p>坪</p>
                          <p>數</p>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...listValueStyles,
                            ...listValueBoldStyles,
                          }}>
                          {product.lot.size} 坪
                        </TableCell>
                      </TableRow>
                    )}
                    {product.contacts
                      .filter((c) => c.contactType === '權益人')
                      .map((contact, index) => (
                        <TableRow>
                          <TableCell
                            sx={{
                              ...listTitleStyles,
                              '& p': {
                                visibility: index === 0 ? 'visible' : 'hidden',
                                marginTop: 0.6,
                                marginBottom: 0.6,
                              },
                            }}>
                            <p>使</p>
                            <p>用</p>
                            <p>權</p>
                            <p>人</p>
                          </TableCell>
                          <TableCell
                            sx={{
                              ...listValueStyles,
                              fontWeight: 'bold',
                            }}>
                            {`${contact.name} `}
                            <small
                              style={{
                                ...listValueBoldStyles,
                              }}>
                              {contact.ssn}
                            </small>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 190,
                fontSize: 12,
                mx: 11,
                textAlign: 'justify',
                display: product.certificate.promotional ? 'block' : 'none',
                color: 'rgba(0,0,0,0.6)',
                fontStyle: 'italic',
              }}>
              *
              本權狀所載產品使用權係以優惠價購得，僅得轉讓予直系血親或兄弟姊妹，若轉讓予他人需額外支付頂福公司原定價之二成費用。
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 190,
                fontSize: 12,
                mx: 11,
                textAlign: 'justify',
                display: product.certificate.untransferrable ? 'block' : 'none',
                color: 'rgba(0,0,0,0.6)',
                fontStyle: 'italic',
              }}>
              * 本權狀所載產品使用權不得轉讓，若遷出需將本權狀歸還頂福公司。
            </Box>
            <Box
              sx={{
                position: 'absolute',
                fontSize: 25,
                bottom: 140,
                width: '100%',
                textAlign: 'center',
                fontFamily: '標楷體,BiauKai,DFKai-SB,KaiU,標楷體-繁,serif',
                fontWeight: 'bold',
                color: 'rgba(0,0,0,0.8)',
              }}>
              頂福事業股份有限公司
              <span style={{ fontSize: 20, marginLeft: 10 }}>
                負責人：劉璟儀
              </span>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 80,
                width: '100%',
                textAlign: 'center',
                fontSize: 22,
                fontWeight: 500,
                letterSpacing: 2.3,
                color: 'rgba(0,0,0,0.8)',
              }}>
              中華民國
              <span style={{ marginLeft: 10, marginRight: 10 }}>
                {product.certificate.date.year - 1911}
              </span>
              年
              <span style={{ marginLeft: 10, marginRight: 10 }}>
                {product.certificate.date.month}
              </span>
              月
              <span style={{ marginLeft: 10, marginRight: 10 }}>
                {product.certificate.date.day}
              </span>
              日
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default PrintableCert;
