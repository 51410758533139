import { Grid, TextField } from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';

import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { handleParams } from '../../utils/handleParams';
import { phoneMobileMask } from '../../validations/imask';
import { queryReferrals } from '../../fetchers/broker.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';
import { useSearchParams } from 'react-router-dom';

const columns = [
  {
    field: 'date',
    headerName: '日期',
    flex: 1.5,
    valueGetter: (params) => params.value?.toDateTime(),
  },
  {
    field: 'broker',
    headerName: '仲介',
    flex: 1,
    valueGetter: (params) => params.value.name,
  },
  {
    field: 'name',
    headerName: '姓名',
    flex: 1,
  },
  {
    field: 'phone',
    headerName: '電話',
    flex: 1,
    valueGetter: (params) =>
      phoneMobileMask.mask((params.value || '').replace(/-/g, '')),
  },
  {
    field: 'deceased',
    headerName: '歿者',
    flex: 1,
  },
  {
    field: 'ssn',
    headerName: '身分證字號',
    flex: 1,
  },
  {
    field: 'by',
    headerName: '業務',
    flex: 1,
    valueGetter: (params) => params.row.created.by.name,
  },
  {
    field: 'desc',
    headerName: '備註',
    flex: 1,
  },
];

function ReferralList(props) {
  useCrumbs([crumbs.brokerList, crumbs.referral]);
  const [params, setParams] = useSearchParams();
  const [query, setQuery] = React.useState(handleParams(params));
  const [keywords, setKeywords] = React.useState(() => {
    if (params.getAll('range').length !== 0) {
      return query;
    }
    return {};
  });
  const referralsQuery = useQuery(
    ['referrals', { keywords }],
    queryReferrals(keywords),
    {
      staleTime: 0,
    }
  );

  if (referralsQuery.isLoading) return <p>Loading...</p>;

  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }
  function queryInput(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            label="關鍵字"
            placeholder="查詢..."
            value={query.keyword || ''}
            onChange={(event) =>
              setQuery({ ...query, keyword: event.target.value })
            }
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <ListPage
        columns={columns}
        rows={referralsQuery.data}
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={(event) => setQuery({ ...query, range: event })}
          renderInput={queryInput}
        />
      </ListPage>
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
        ]}
      />
    </>
  );
}

export default ReferralList;
