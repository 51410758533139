import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';

import { Box } from '@mui/system';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { handleParams } from '../../utils/handleParams';
import loadScript from '../../utils/loadScript';
import { queryEventOrders } from '../../fetchers/event.fetch';
import { saveAs } from 'file-saver';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';
import { useSearchParams } from 'react-router-dom';

const statusColors = {
  未取號: 'rgb(233, 101, 143)',
  待付費: 'rgb(108, 164, 213)',
  發票開立失敗: 'rgb(235, 185, 62)',
  逾期: 'rgb(113, 12, 16)',
  完款: 'rgb(142, 178, 108)',
};
const columns = [
  {
    field: '_id',
    headerName: '報名編號',
    flex: 1,
  },
  {
    field: 'date',
    headerName: '日期',
    flex: 1,
    valueGetter: (params) => params.value?.toDate(),
  },
  {
    field: 'amount',
    headerName: '金額',
    flex: 1,
    valueGetter: (params) => params.value?.toLocaleString(),
  },
  {
    field: 'receipts',
    headerName: '發票號碼',
    valueGetter: (params) => params.value?.at(-1)?.sn,
    flex: 1,
  },
  {
    field: 'name',
    headerName: '客戶姓名',
    flex: 1,
  },
  {
    field: 'status',
    headerName: '狀態',
    renderCell: (params) => {
      return (
        <span
          style={{
            color: statusColors[params.value],
            fontWeight: 'bold',
          }}>
          {params.value}
        </span>
      );
    },
    flex: 1,
  },
  {
    field: 'imported',
    headerName: '匯入',
    flex: 0.5,
    type: 'boolean',
  },
];

function EventOrderList(props) {
  useCrumbs([crumbs.eventOrderList]);
  const [params, setParams] = useSearchParams();
  const [query, setQuery] = React.useState(handleParams(params));
  const [keywords, setKeywords] = React.useState(query);
  const ordersQuery = useQuery(
    ['event', 'orders', keywords],
    queryEventOrders(keywords),
    {
      placeholderData: [],
      staleTime: 1000 * 30,
    },
    { timeout: 6000 }
  );

  React.useEffect(() => {
    if (!window.ExcelJS) {
      loadScript(
        'https://cdnjs.cloudflare.com/ajax/libs/exceljs/4.3.0/exceljs.min.js'
      );
    }
  }, []);

  if (ordersQuery.isLoading) return <Spinner />;
  if (ordersQuery.isError) return <p>{ordersQuery.error.toString()}</p>;

  function handleChange(field) {
    return (event) => {
      setQuery({
        ...query,
        [field]: field === 'range' ? event : event.target.value,
      });
    };
  }
  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }
  function getQueryInputs(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="報名編號"
            value={query.sn || ''}
            onChange={handleChange('sn')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="姓名"
            value={query.name || ''}
            onChange={handleChange('name')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="發票號碼"
            value={query.receipt || ''}
            onChange={handleChange('receipt')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>狀態</InputLabel>
            <Select
              label="狀態"
              value={query.status || ''}
              onChange={handleChange('status')}>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="完款">完款</MenuItem>
              <MenuItem value="待付費">待付費</MenuItem>
              <MenuItem value="逾期">逾期</MenuItem>
              <MenuItem value="發票開立失敗">發票開立失敗</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <ListPage
        striped={false}
        columns={columns}
        rows={ordersQuery.data}
        getRowId={(params) => params._id}
        getRowClassName={() => 'detail-header'}
        getDetailPanelContent={(params) => getOrderDetail(params.row)}
        getDetailPanelHeight={(params) =>
          204 + params.row.items.length * 73.0312
        }
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={getQueryInputs}
        />
      </ListPage>
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
          createAction(speedDialIcons.report, '匯出報表', () =>
            generateReport(ordersQuery.data, params)
          ),
        ]}
      />
    </>
  );
}

export default EventOrderList;

function getShuttleInfo(detail) {
  let result = [];
  switch (detail.date) {
    case 0:
      result.push('第一天');
      break;
    case 1:
      result.push('第二天');
      break;
    default:
      break;
  }
  result.push(detail.station);
  result.push(detail.time);
  result.push(detail.party + '人');
  return result.join(' ');
}
function getOrderDetail(order) {
  return (
    <Box>
      <Grid
        container
        sx={{
          fontFamily: 'sans-serif',
          '.MuiGrid-item': { p: 2, fontSize: 15 },
        }}>
        <Grid item xs={12} sm={4}>
          <div>報名人：{order.name}</div>
          <div>電話：{order.mobile}</div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            付費方式：
            {!order.payment.paymentNo
              ? '未選擇繳款方式'
              : order.payment.paymentType === '匯款'
              ? '銀行轉帳'
              : '超商代碼'}
          </div>
          {!order.payment.paymentNo ? null : order.payment.bankCode ? (
            <>
              <div>銀行代碼：{order.payment.bankCode}</div>
              <div>轉帳帳號：{order.payment.paymentNo}</div>
              {!order.payment.paid ? (
                <div>截止時間：{order.payment.expiry?.toDateTime()}</div>
              ) : (
                <div>完款時間：{order.payment.paid?.toDateTime()}</div>
              )}
            </>
          ) : (
            <>
              <div>代碼：{order.payment.paymentNo}</div>
              {!order.payment.paid ? (
                <div>截止時間：{order.payment.expiry?.toDateTime()}</div>
              ) : (
                <div>完款時間：{order.payment.paid?.toDateTime()}</div>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {order.receipts?.length > 0 ? (
            <>
              <div>
                電子發票：
                {order.receipts.length > 0
                  ? order.receipts.at(-1).sn
                  : '尚未開立發票'}
              </div>
              <div>
                發票日期：
                {order.receipts.at(-1).date?.toDateTime()}
              </div>
              <div>隨機碼：{order.receipts.at(-1).random}</div>
              <div>發票金額：{order.amount.toLocaleString()}</div>
            </>
          ) : null}
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            {!order.items ? (
              <p>無產品內容</p>
            ) : (
              order.items.map((item, index) => (
                <TableRow
                  key={`row-${index}`}
                  sx={{ '.MuiTableCell-body': { fontSize: 16 } }}>
                  <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    {item.event}
                  </TableCell>
                  <TableCell>
                    <Box>{item.name}</Box>
                    <Box
                      sx={{
                        color: 'rgb(90, 94, 97)',
                        fontSize: 13,
                        display: { xs: 'block', md: 'none' },
                      }}>
                      {item.event}
                    </Box>
                  </TableCell>
                  <TableCell>${item.price.toLocaleString()}</TableCell>
                  <TableCell>
                    {item.name !== '接駁車' ? (
                      <>
                        <Box>
                          <span
                            style={{
                              color: 'rgb(113, 12, 16)',
                              marginRight: 10,
                            }}>
                            逝
                          </span>
                          {item.deceaseds.join(', ')}
                        </Box>
                        <Box>
                          <span
                            style={{
                              color: 'rgb(232, 122, 41)',
                              marginRight: 10,
                            }}>
                            陽
                          </span>
                          {item.family.join(', ')}
                        </Box>
                      </>
                    ) : (
                      <Box>{getShuttleInfo(item.shuttle)}</Box>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function generateReport(orders, params) {
  const wb = new window.ExcelJS.Workbook();
  const ws = wb.addWorksheet();

  ws.getRow('1').alignment = {
    horizontal: 'center',
    vertical: 'middle',
  };
  ws.getRow('1').font = {
    bold: true,
    size: 16,
  };

  const titles = [
    '訂單編號',
    '訂購人',
    '電話',
    '付款方式',
    '完款日期',
    '發票號碼',
    '發票日期',
    '金額',
    '如意',
    '吉祥',
    '思親1',
    '思親2',
    '甲種',
    '特種',
    '專區',
    '供燈',
    '接駁車',
    '光明燈',
    '年度-甲種',
    '年度-特種',
    '年度-如意',
    '年度-吉祥',
    '年度-思親',
    '吉祥金紙',
    '思親金紙',
    '黃金',
    '富貴',
  ];
  ws.getRow(1).values = titles;

  for (let i = 0; i < orders.length; i++) {
    const row = ws.getRow(2 + i);
    const order = orders[i];
    const values = [];

    row.font = {
      size: 14,
    };

    values[0] = order._id;
    values[1] = order.name;
    values[2] = order.mobile;
    values[3] = order.payment.paymentType;
    values[4] = order.payment.paid
      ? DateTime.fromISO(order.payment.paid).toFormat('yyyy-MM-dd')
      : '';
    values[5] = order.receipts?.at(-1)?.sn || '';
    values[6] = order.receipts?.at(-1)?.date
      ? DateTime.fromISO(order.receipts.at(-1)?.date).toFormat('yyyy-MM-dd')
      : '';
    values[7] = order.amount;
    values[8] = order.items.filter((item) => item.name === '如意套組').length;
    values[9] = order.items.filter((item) => item.name === '吉祥套組').length;
    values[10] = order.items.filter(
      (item) => item.name === '思親套組' || item.name === '思親套組(1)'
    ).length;
    values[11] = order.items.filter(
      (item) => item.name === '思親套組(2)'
    ).length;
    values[12] = order.items.filter((item) => item.name === '甲種牌位').length;
    values[13] = order.items.filter((item) => item.name === '特種牌位').length;
    values[14] = order.items.filter((item) => item.name === '專區牌位').length;
    values[15] = order.items.filter((item) => item.name === '供燈').length;
    values[17] = order.items.filter((item) => item.name === '光明燈').length;
    values[16] = order.items.filter((item) => item.name === '接駁車').length;
    values[18] = order.items.filter(
      (item) => item.name === '年度優惠-甲種'
    ).length;
    values[19] = order.items.filter(
      (item) => item.name === '年度優惠-特種'
    ).length;
    values[20] = order.items.filter(
      (item) => item.name === '年度優惠-如意'
    ).length;
    values[21] = order.items.filter(
      (item) => item.name === '年度優惠-吉祥'
    ).length;
    values[22] = order.items.filter(
      (item) => item.name === '年度優惠-思親'
    ).length;
    values[23] = order.items.filter((item) => item.name === '吉祥金紙').length;
    values[24] = order.items.filter((item) => item.name === '思親金紙').length;
    values[25] = order.items.filter((item) => item.name === '黃金套組').length;
    values[26] = order.items.filter((item) => item.name === '富貴套組').length;
    row.values = values;
  }

  wb.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    const range = params
      .getAll('range')
      ?.map((d) => DateTime.fromMillis(Number(d))) || [
      DateTime.now().startOf('month'),
      DateTime.now().endOf('month'),
    ];
    saveAs(
      blob,
      `法會網路報名清單(${range[0].toFormat('yyyyMMdd')}-${range[1].toFormat(
        'yyyyMMdd'
      )}${
        params.get('status') ? params.get('status') : ''
      })<${DateTime.now().toFormat('yyyyMMdd')}匯出>.xlsx`
    );
  });
}
