/* eslint-disable */
import { DateTime } from 'luxon';

// Strings
String.prototype.luxon = function () {
  const date = DateTime.fromISO(this);
  if (date.isValid) {
    return date;
  }
  return null;
};
String.prototype.title = function () {
  const title = companies[this];
  if (!title) return this;
  return title;
};
String.prototype.thousand = function () {
  return this.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
String.prototype.toDate = function () {
  const obj = this.luxon();
  if (obj && obj.isValid) {
    return obj.toFormat('yyyy-MM-dd');
  }
  return '';
};
String.prototype.toTime = function () {
  const obj = this.luxon();
  if (obj && obj.isValid) {
    return obj.toFormat('HH:mm');
  }
  return '';
};
String.prototype.toDateTime = function () {
  const obj = this.luxon();
  if (obj && obj.isValid) {
    return obj.toFormat('yyyy-MM-dd HH:mm');
  }
  return '';
};
String.prototype.toPhone = function () {
  const sanitized = this.replace('-', '');
  if (sanitized.substring(0, 2) === '09' && sanitized.length === 10) {
    return `${sanitized.substring(0, 4)}-${sanitized.substring(
      4,
      7
    )}-${sanitized.substring(7, 10)}`;
  }
  return this;
};

// Arrays
Array.prototype.last = function () {
  if (this.length === 0) return null;
  return this[this.length - 1];
};
Array.prototype.count = function (condition) {
  if (this.length === 0) return 0;
  return this.filter((x) => condition(x)).length;
};
Array.prototype.any = function () {
  if (this.length > 0) return true;
  return false;
};

// DateTime
DateTime.prototype.toDate = function () {
  return this.toFormat('yyyy-MM-dd');
};
DateTime.prototype.toDateTime = function () {
  return this.toFormat('yyyy-MM-dd HH:mm');
};
