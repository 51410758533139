import { Table, buttonClasses, tableCellClasses } from '@mui/material';

import React from 'react';

export function ContactTable({ children }) {
  return (
    <Table
      sx={{
        [`& .${tableCellClasses.body}`]: {
          py: 0,
          fontSize: 16,
          [`& .${buttonClasses.root}`]: {
            fontSize: 16,
          },
        },
        [`& .${tableCellClasses.head}`]: {
          py: 0.5,
        },
      }}>
      {children}
    </Table>
  );
}
