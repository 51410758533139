import {
  Alert,
  Button,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { Box } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import useHttpClient from '../services/httpClient/useHttpClient';

function CustomerSearchDialog({ state, select, onSelect }) {
  const client = useHttpClient();
  const [customers, setCustomers] = React.useState();
  const [query, setQuery] = React.useState('');
  const [error, setError] = React.useState();

  function handleKeyDown(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      query.length > 0
    ) {
      event.preventDefault();
      handleQuery();
      setQuery('');
      event.target.blur();
    }
  }
  function handleQuery() {
    client
      .get(`/api/customer?keyword=${query}${select ? `&select=${select}` : ''}`)
      .then((response) => {
        setCustomers(response);
      })
      .catch((err) => {
        setError(err);
      });
  }
  function handleSelect(customer) {
    onSelect(customer);
    setQuery('');
    setCustomers(null);
    setError(null);
    if (state.setOpen) {
      state.setOpen(false, 'complete');
    }
  }

  if (!state) return null;

  return (
    <Dialog
      open={state.open}
      maxWidth="sm"
      onClose={() => {
        if (state.setOpen) {
          state.setOpen(false, 'cancel');
        }
        setCustomers(null);
      }}
      fullWidth>
      <DialogTitle>選擇客戶</DialogTitle>
      <Box sx={{ padding: '0 20px 20px 20px', minHeight: 150 }}>
        <TextField
          variant="outlined"
          placeholder="查詢客戶..."
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          onKeyDown={handleKeyDown}
          size="small"
          autoFocus
        />
        {error && (
          <Alert sx={{ mt: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <div
          style={{
            marginTop: 20,
            border: '1px solid rgba(0,0,0,0.1)',
            borderRadius: 4,
            minHeight: 100,
          }}>
          {customers && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>姓名</TableCell>
                  <TableCell>身分證字號</TableCell>
                  <TableCell>行動電話</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                      查無客戶資料
                    </TableCell>
                  </TableRow>
                ) : (
                  customers.map((customer) => (
                    <TableRow key={customer._id}>
                      <TableCell>{customer.name}</TableCell>
                      <TableCell>{customer.ssn}</TableCell>
                      <TableCell>{customer.mobile}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleSelect(customer)}>
                          選擇
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
        </div>
      </Box>
    </Dialog>
  );
}

export default CustomerSearchDialog;
