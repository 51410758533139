import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';

export function EditConversions({ item, onClose, onSave }) {
  const [conversions, setConversions] = React.useState();

  React.useEffect(() => {
    const updated = Object.keys(item.conversions).map((unit, index) => ({
      id: index,
      unit,
      ratio: item.conversions[unit].ratio,
      baseUnit: item.conversions[unit].unit,
    }));
    updated.push({ id: updated.length + 1, unit: '', ratio: '', baseUnit: '' });
    setConversions(updated);
  }, [item]);

  function isValid() {
    const sliced = conversions.slice(0, conversions.length - 1);
    if (sliced.some((c) => !c.unit || !c.ratio || !c.baseUnit)) return false;
    for (let conv of sliced) {
      let current = conv.baseUnit;
      while (current !== item.unit) {
        const next = conversions.find((c) => c.unit === current);
        if (!next) {
          return false;
        }
        current = next.baseUnit;
      }
    }
    return true;
  }
  function handleChange(event, id, field) {
    const updated = conversions.map((conv) => {
      if (conv.id === id)
        return {
          ...conv,
          [field]: event.target.value,
        };
      return conv;
    });
    const last = updated[updated.length - 1];
    if (last.unit) {
      updated.push({
        id: last.id + 1,
        unit: '',
        ratio: '',
        baseUnit: '',
      });
    }
    setConversions(updated);
  }
  function handleDelete(id) {
    const updated = conversions.filter((c) => c.id !== id);
    setConversions(updated);
  }
  function handleSave() {
    const sliced = conversions.slice(0, conversions.length - 1);
    const payload = {};
    for (let conv of sliced) {
      payload[conv.unit] = {
        ratio: conv.ratio,
        unit: conv.baseUnit,
      };
    }
    onSave({ conversions: payload });
  }

  if (!conversions) return <p>Loading...</p>;

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm">
      <DialogTitle>編輯單位換算</DialogTitle>
      <Box sx={{ m: 3, mt: 0 }}>
        <div>基本單位： {item.unit}</div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>單位</TableCell>
              <TableCell>換算</TableCell>
              <TableCell width="20%"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversions.map((conversion, index) => (
              <TableRow key={conversion.id}>
                <TableCell>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={conversion.unit}
                    onChange={(event) =>
                      handleChange(event, conversion.id, 'unit')
                    }
                  />
                </TableCell>
                <TableCell>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    wrap="nowrap">
                    <Grid item flex={1}>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        value={conversion.ratio}
                        onChange={(event) =>
                          handleChange(event, conversion.id, 'ratio')
                        }
                      />
                    </Grid>
                    <Grid item flex={0.5}>
                      <TextField
                        sx={{ ml: 2 }}
                        variant="outlined"
                        size="small"
                        value={conversion.baseUnit}
                        onChange={(event) =>
                          handleChange(event, conversion.id, 'baseUnit')
                        }
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="right">
                  {index !== conversions.length - 1 && (
                    <IconButton onClick={() => handleDelete(conversion.id)}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={onClose}>
              取消
            </Button>
          </Grid>
          {isValid() && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSave}>
                儲存
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Dialog>
  );
}
