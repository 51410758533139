const url = '/api/customer';

export const queryCustomerById = (states) => (client) => () => {
  return client.get(`${url}/${states.id}`);
};

export const queryCustomerByKeyword = (states) => (client) => () => {
  if (states.keyword) {
    return client.get(`${url}?keyword=${states.keyword}`);
  }
  return client.get(url);
};

export const createCustomer = (client) => (states) => {
  return client.post(url, states);
};

export const updateCustomer = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states);
};
