import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import React from 'react';

const interactionTypes = ['電話', '外訪'];

function AddEventDialog({ state, setState, onCreate, status }) {
  function handleClose() {
    setState({
      open: false,
      interactionType: '電話',
      desc: '',
    });
  }
  return (
    <Dialog open={state.open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>新增追蹤紀錄</DialogTitle>
      <div style={{ padding: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel>類別</InputLabel>
              <Select
                label="類別"
                value={state.interactionType}
                onChange={(event) =>
                  setState({ ...state, interactionType: event.target.value })
                }>
                {Object.values(interactionTypes).map((event, index) => (
                  <MenuItem key={`e-${index}`} value={event}>
                    {event}
                  </MenuItem>
                ))}
                {status !== '成交' && <MenuItem value="結案">結案</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="內容"
              value={state.desc}
              onChange={(event) =>
                setState({ ...state, desc: event.target.value })
              }
              minRows={5}
              multiline
              fullWidth
            />
          </Grid>
          {state.error && (
            <Grid item xs={12}>
              <Alert severity="error">{state.error}</Alert>
            </Grid>
          )}
        </Grid>
        <div style={{ textAlign: 'right', marginTop: 30 }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            取消
          </Button>
          {state.desc.length !== 0 && (
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              onClick={onCreate}>
              新增
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default AddEventDialog;
