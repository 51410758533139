import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../../services/speedDial';
import { blue, green, indigo, red } from '@mui/material/colors';
import { labels, tagTypes } from '../../utils/tagTypes.const';
import { statuses, titles } from './purchase.const';

import { CheckCircle } from '@mui/icons-material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import EditPurchaseDialog from './EditPurchaseDialog';
import { ListPage } from '../../components/ListPage';
import PurchaseDetailPanel from './PurchasePreview';
import React from 'react';
import Spinner from '../../components/Spinner';
import companies from '../../utils/companies.const';
import crumbs from '../../services/crumbs/crumbs.const';
import getSearchParams from '../../utils/getSearchParams';
import { handleParams } from '../../utils/handleParams';
import { queryPurchases } from '../../fetchers/purchase.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';

const titleColors = {
  總經理: green[500],
  覆核: blue[500],
  部門: indigo[500],
};
const columns = [
  {
    field: 'sn',
    headerName: '編號',
    flex: 0.5,
    valueGetter: (params) => `${params.row.company}${params.row.sn || ''}`,
    renderCell: (params) => {
      if (!params.row.sn)
        return (
          <Link
            to={`/purchase/${params.row._id}`}
            style={{ color: companies[params.row.company].color }}>
            {params.row.company}
          </Link>
        );
      return (
        <Link
          to={`/purchase/${params.row._id}`}
          style={{ color: companies[params.row.company].color }}>
          {params.row.company} {params.row.sn}
        </Link>
      );
    },
  },
  {
    field: 'vendor',
    headerName: '廠商',
    flex: 1.5,
    renderCell: (params) => (
      <Link to={`/vendor/${params.value._id}`}>{params.value.name}</Link>
    ),
  },
  {
    field: 'created',
    headerName: '日期',
    flex: 1,
    valueGetter: (params) => params.value.date?.toDate(),
  },
  {
    field: 'amount',
    headerName: '金額',
    flex: 0.6,
    type: 'number',
    valueGetter: (params) => Math.round(params.value).toLocaleString(),
  },
  {
    field: 'tags',
    headerName: '標籤',
    renderCell: (params) =>
      params.value.map((tag, index) => {
        return (
          <Link
            key={`${params.row._id}-${index}`}
            style={{ marginRight: 10 }}
            to={`/${tag.tagType}/${tag._id}`}>
            {tag.tagType === 'Product'
              ? labels[tag.label.substring(0, 1)]
              : tagTypes[tag.tagType]}
            {tag.label}
          </Link>
        );
      }),
    flex: 1.5,
  },
  {
    field: 'createdBy',
    headerName: '承辦',
    flex: 0.8,
    valueGetter: (params) => params.row.created.by.name,
  },
  {
    field: 'status',
    headerName: '狀態',
    flex: 1,
    renderCell: (params) => {
      const checks = Object.entries(params.row.audit.checks);
      let title;
      if (params.value === 'audit') {
        title =
          titles[
            checks.find(
              (c) => c[1].checked === false || c[1].checked === undefined
            )?.[0]
          ];
      }
      const color = titleColors[title];
      return (
        <span
          style={{
            color: statuses[params.value]?.color,
            display: 'flex',
            alignItems: 'center',
          }}>
          {params.value === 'inspection' && params.row.inspection.checked && (
            <CheckCircle />
          )}
          {statuses[params.value]?.label}
          {title &&
            (checks.some((c) => c[1].checked === false) ? (
              <span style={{ color: red[500] }}>{` (${title}退簽)`}</span>
            ) : (
              <span style={{ color }}>{` ⇀ ${title}`}</span>
            ))}
        </span>
      );
    },
  },
];
function PurchaseList(props) {
  useCrumbs([crumbs.purchaseList]);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [addPurchaseDialog, setAddPurchaseDialog] = React.useState(false);
  const [keywords, setKeywords] = React.useState(handleParams(params));
  const [query, setQuery] = React.useState(keywords);
  const purchasesQuery = useQuery(
    ['purchases', keywords],
    queryPurchases(keywords),
    {
      placeholder: [],
      staleTime: 30000,
    }
  );

  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }
  function getQueryInputs(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="採購單號"
            value={query.sn || ''}
            type="number"
            onChange={handleChange('sn')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="品項"
            value={query.item || ''}
            onChange={handleChange('item')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="標籤"
            value={query.tag || ''}
            onChange={handleChange('tag')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>公司</InputLabel>
            <Select
              label="公司"
              value={query.company || ''}
              onChange={(event) =>
                setQuery({ ...query, company: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="福">頂福事業</MenuItem>
              <MenuItem value="利">頂利事業</MenuItem>
              <MenuItem value="茂">辰茂事業</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>狀態</InputLabel>
            <Select
              label="狀態"
              value={query.status || ''}
              onChange={(event) =>
                setQuery({ ...query, status: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="draft">草稿</MenuItem>
              <MenuItem value="audit">送簽</MenuItem>
              <MenuItem value="purchase">採購</MenuItem>
              <MenuItem value="shipment">到貨</MenuItem>
              <MenuItem value="inspection">驗收</MenuItem>
              <MenuItem value="payment">請款</MenuItem>
              <MenuItem value="closed">結案</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="廠商"
            value={query.vendor || ''}
            onChange={handleChange('vendor')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>地點/部門</InputLabel>
            <Select
              label="地點/部門"
              value={query.location || ''}
              onChange={(event) =>
                setQuery({ ...query, location: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="北">台北</MenuItem>
              <MenuItem value="林">林口</MenuItem>
              <MenuItem value="行">行政部</MenuItem>
              <MenuItem value="管">管理部</MenuItem>
              <MenuItem value="業">業務部</MenuItem>
              <MenuItem value="工">工務部</MenuItem>
              <MenuItem value="財">財務室</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            label="承辦人"
            value={query.purchaser || ''}
            onChange={handleChange('purchaser')}
            onKeyDown={handleKeyDown}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }
  function handleChange(field) {
    return (event) => {
      setQuery({
        ...query,
        [field]: field === 'range' ? event : event.target.value,
      });
    };
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }
  function handleSave(response) {
    navigate(`/purchase/${response._id}`);
  }

  if (purchasesQuery.isLoading) return <Spinner />;
  if (purchasesQuery.isError) return <p>{purchasesQuery.error.toString()}</p>;

  return (
    <>
      <ListPage
        columns={columns}
        rows={purchasesQuery.data}
        striped={false}
        onCellDoubleClick={(params) => navigate(`/purchase/${params.row._id}`)}
        getDetailPanelContent={(params) => (
          <PurchaseDetailPanel purchase={params.row} />
        )}
        getDetailPanelHeight={() => 200}
        getRowClassName={() => 'detail-header'}
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={getQueryInputs}
        />
      </ListPage>
      <EditPurchaseDialog
        open={addPurchaseDialog}
        onSave={handleSave}
        onClose={() => setAddPurchaseDialog(false)}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
          createAction(speedDialIcons.add, '新增採購單', () =>
            setAddPurchaseDialog(true)
          ),
        ]}
      />
    </>
  );
}

export default PurchaseList;
