import { Button, Dialog, DialogTitle, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import useUser from '../../../services/authentication/useUser';

export function EditQuote({ item, onClose, onSave }) {
  const [payload, setPayload] = React.useState();
  const user = useUser();

  React.useEffect(() => {
    let update;
    if (!item.quote || !item.quote.label) {
      update = {
        name: '',
        label: '',
        price: 0,
        desc: '',
        error: {},
      };
    } else {
      update = item.quote;
      update.error = {};
    }
    setPayload(update);
  }, [item]);

  if (!payload) return <p>Loading...</p>;

  function isValid() {
    const ary = [
      payload.name.trim().length > 0,
      payload.price > 0,
      payload.label.trim().length > 0,
    ];
    return ary.every((c) => c === true);
  }

  async function handleSave() {
    const updated = {
      quote: {
        ...payload,
        created: {
          by: {
            _id: user._id,
            name: user.name,
          },
          date: Date.now(),
        },
      },
      quotes: [...item.quotes],
    };
    if (item.quote) {
      updated.quotes.push(item.quote);
    }
    const result = await onSave(updated);
    if (result) {
      return setPayload({
        ...payload,
        error: {
          api: { message: result },
        },
      });
    }
  }
  async function handleDelete() {
    const updated = {};
    updated.created = {
      by: {
        _id: user._id,
        name: user.name,
      },
      date: Date.now(),
    };
    const result = await onSave({
      quote: updated,
      quotes: [...item.quotes, item.quote],
    });
    if (result) {
      updated.error.api = { message: result };
      return setPayload(updated);
    }
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm">
      <DialogTitle>編輯基本資料</DialogTitle>
      <Box sx={{ m: 3, mt: 1 }}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="標籤"
              fullWidth
              value={payload.label}
              onChange={(event) =>
                setPayload({
                  ...payload,
                  label: event.target.value,
                })
              }
              onBlur={(event) =>
                setPayload({
                  ...payload,
                  label: event.target.value.replace(/\s/g, '/'),
                })
              }
              error={!!payload.error.label}
              helperText={payload.error.label?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="品名"
              fullWidth
              value={payload.name}
              onChange={(event) =>
                setPayload({ ...payload, name: event.target.value })
              }
              error={!!payload.error.name}
              helperText={payload.error.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="單價"
              fullWidth
              type="number"
              value={payload.price}
              onChange={(event) =>
                setPayload({ ...payload, price: event.target.value })
              }
              error={!!payload.error.unit}
              helperText={payload.error.unit?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              label="備註"
              fullWidth
              value={payload.desc}
              onChange={(event) =>
                setPayload({ ...payload, desc: event.target.value })
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ mt: 1 }}>
          <Grid item>
            {item.quote?.label && (
              <Button variant="contained" color="error" onClick={handleDelete}>
                刪除
              </Button>
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={onClose}>
                  取消
                </Button>
              </Grid>
              {isValid() && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}>
                    儲存
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
