export const tagTypes = {
  Order: '訂',
  Product: '產',
  Quote: '報',
};
export const labels = {
  A: '塔',
  B: '靈',
  C: '罈',
  D: '燈',
  E: '墓',
  F: '佛',
};
