import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItems from './sidebar.const';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import useAccess from '../services/authentication/useAccess';

function Sidebar({ width = 240, open, toggleSidebar }) {
  const [openStates, setOpenStates] = React.useState({});
  const hasAccess = useAccess();

  function handleOpen(key) {
    setOpenStates({ ...openStates, [key]: !openStates[key] });
  }

  return (
    <Drawer
      sx={{
        width: width,
        [`& .MuiDrawer-paper`]: {
          width,
          height: '97.5vh',
          boxSizing: 'border-box',
          margin: 1.5,
          borderRadius: (theme) => theme.shape.borderRadius / 2,
        },
      }}
      anchor="left"
      open={open}
      onClose={() => toggleSidebar()}>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List component="nav">
          {MenuItems.map((item) => {
            if (!item.items) {
              if (hasAccess(item.hide)) return null;
              if (!item.show || hasAccess(item.show))
                return (
                  <ListItemButton
                    key={item.key}
                    component={Link}
                    to={item.link}
                    onClick={toggleSidebar}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{item.text}</ListItemText>
                  </ListItemButton>
                );
              return null;
            } else {
              if (hasAccess(item.hide)) return null;
              if (!item.show || hasAccess(item.show))
                return (
                  <div key={item.key}>
                    <ListItemButton onClick={() => handleOpen(item.key)}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{item.text}</ListItemText>
                      {item.items &&
                        (openStates[item.key] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        ))}
                    </ListItemButton>
                    {item.items && (
                      <Collapse in={openStates[item.key]} component="li">
                        <List>
                          {item.items.map((subitem) => {
                            if (hasAccess(item.hide)) return null;
                            if (!item.show || hasAccess(item.show))
                              return (
                                <ListItemButton
                                  key={subitem.key}
                                  component={Link}
                                  to={subitem.link}
                                  onClick={toggleSidebar}
                                  sx={{ pl: 4 }}>
                                  <ListItemText>{subitem.text}</ListItemText>
                                  <ListItemIcon>{subitem.icon}</ListItemIcon>
                                </ListItemButton>
                              );
                            return null;
                          })}
                        </List>
                      </Collapse>
                    )}
                  </div>
                );
            }
          })}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
