const url = '/api/item';

export const queryItemsWithQuote = (states) => (clients) => () => {
  return clients.get(`${url}?quote=true`);
};

export const queryItemsByTag = (states) => (clients) => () => {
  return clients.get(`${url}?tag=${states.tag}`);
};

export const updateItem = (clients) => (states) => {
  return clients.put(`${url}/${states.id}`, states);
};

export const createItem = (clients) => (states) => {
  return clients.post(`${url}`, states);
};
