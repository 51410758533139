import { Chip, Grid, Tooltip } from '@mui/material';

import { DateTime } from 'luxon';
import Detail from '../../components/Detail';
import { Link } from 'react-router-dom';
import React from 'react';
import companies from '../../utils/companies.const';

function QuoteInfo({ quote }) {
  if (!quote) return null;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item>
            <span
              style={{
                fontSize: 20,
                color: companies[quote.company].color,
              }}>
              {companies[quote.company].short}
            </span>
          </Grid>
          <Grid item>
            {quote.invalidated ? (
              <Tooltip
                title={
                  quote.invalidated
                    ? `於 ${quote.invalidated.date?.toDateTime()} 由 ${
                        quote.invalidated.by.name
                      } 作廢：${quote.invalidated.desc}。`
                    : null
                }
                placement="top">
                <Chip
                  label={quote.status}
                  color="error"
                  size="small"
                  sx={{ ml: 1 }}
                />
              </Tooltip>
            ) : (
              <Chip
                label={quote.status}
                color={quote.status === '完款' ? 'success' : 'secondary'}
                size="small"
                sx={{ ml: 1 }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail title="編號" value={quote.sn} marginless />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="客戶姓名"
          value={
            <Link to={`/customer/${quote.customer._id}`}>
              {quote.customer.name}
            </Link>
          }
          marginless
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="產品"
          value={
            <Link to={`/product/${quote.product._id}`}>{quote.product.sn}</Link>
          }
          marginless
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="報價效期"
          value={DateTime.fromISO(quote.created.date)
            .plus({ weeks: 2 })
            .toDate()}
          marginless
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="聯絡電話"
          value={quote.customer.phone}
          marginless
          showTitle
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail title="客服人員" value={quote.created.by.name} marginless />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail title="折扣" value={quote.discount?.amount} marginless />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="議價主管"
          value={
            quote.discount
              ? `${quote.discount.by.name} [${quote.discount.date?.toDate()}]`
              : null
          }
          marginless
        />
      </Grid>
    </Grid>
  );
}

export default QuoteInfo;
