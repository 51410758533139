import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import SpeedDial, { createAction } from '../services/speedDial';
import { labels, tagTypes } from '../utils/tagTypes.const';

import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { ListPage } from '../components/ListPage';
import PaymentInfoDialog from '../components/PaymentTable/PaymentInfoDialog';
import React from 'react';
import Spinner from '../components/Spinner';
import companies from '../utils/companies.const';
import crumbs from '../services/crumbs/crumbs.const';
import getSearchParams from '../utils/getSearchParams';
import { handleParams } from '../utils/handleParams';
import { queryPayments } from '../fetchers/payment.fetch';
import speedDialIcons from '../services/speedDial/speedDialIcons';
import useCrumbs from '../services/crumbs/useCrumbs';
import useQuery from '../services/httpClient/useQuery';

const columns = [
  {
    field: 'date',
    headerName: '日期',
    valueGetter: (params) => params.value?.toDate(),
    sortComparator: (v1, v2) => {
      return Date.parse(v1) - Date.parse(v2);
    },
    flex: 0.8,
  },
  {
    field: 'company',
    headerName: '公司',
    flex: 0.5,
    renderCell: (params) => (
      <span
        style={{
          color: params.row.invalidated
            ? '#707070'
            : companies[params.row.company]?.color ?? '#707070',
          marginRight: 5,
        }}>
        {params.row.company}
      </span>
    ),
  },
  {
    field: 'amount',
    headerName: '金額',
    valueGetter: (params) => params.value,
    flex: 0.6,
    type: 'number',
  },
  {
    field: 'sn',
    headerName: '發票號碼',
    flex: 1,
    valueGetter: (params) => {
      if (params.row.legacy) return params.row.legacy.receipt;
      return params.row.receipts.last()?.sn;
    },
  },
  {
    field: 'tag',
    headerName: '標籤',
    renderCell: (params) => {
      if (params.row.tag) {
        return (
          <Link to={`/${params.row.tag.tagType}/${params.row.tag._id}`}>
            {params.row.tag.tagType === 'Product'
              ? labels[params.row.tag.label.substring(0, 1)]
              : tagTypes[params.row.tag.tagType]}
            {params.row.tag.label}
          </Link>
        );
      }
    },
    flex: 1,
  },
  {
    field: 'desc',
    headerName: '內容',
    flex: 1.5,
  },
];

function PaymentList(props) {
  useCrumbs([crumbs.paymentList]);
  const [params, setParams] = useSearchParams();
  const [payment, setPayment] = React.useState();
  const [keywords, setKeywords] = React.useState(handleParams(params));
  const [query, setQuery] = React.useState(
    keywords || {
      company: '',
      customer: '',
      desc: '',
      amount: 0,
      range: [DateTime.now().startOf('month'), DateTime.now().endOf('month')],
      location: '',
    }
  );
  const paymentsQuery = useQuery(
    ['payments', keywords],
    queryPayments(keywords),
    {
      placeholder: [],
      staleTime: 30000,
    }
  );

  if (paymentsQuery.isLoading) return <Spinner />;
  if (paymentsQuery.isError) return <p>{paymentsQuery.error.toString()}</p>;

  function handleChange(field) {
    return (event) => {
      setQuery({
        ...query,
        [field]: field === 'range' ? event : event.target.value,
      });
    };
  }
  function getQueryInputs(startProps, endProps) {
    return (
      <Grid container spacing={2} sx={{ p: '20px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...startProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField variant="outlined" size="small" fullWidth {...endProps} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>公司</InputLabel>
            <Select
              label="公司"
              value={query.company || ''}
              onChange={(event) =>
                setQuery({ ...query, company: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="福">頂福事業</MenuItem>
              <MenuItem value="利">頂利事業</MenuItem>
              <MenuItem value="茂">辰茂事業</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            label="客戶資料"
            value={query.customer || ''}
            onChange={(event) =>
              setQuery({ ...query, customer: event.target.value })
            }
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            label="內容"
            value={query.item || ''}
            onChange={(event) =>
              setQuery({ ...query, item: event.target.value })
            }
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            size="small"
            label="金額"
            type="number"
            value={query.amount || ''}
            onChange={(event) =>
              setQuery({ ...query, amount: event.target.value })
            }
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>地點/部門</InputLabel>
            <Select
              label="地點/部門"
              value={query.location || ''}
              onChange={(event) =>
                setQuery({ ...query, location: event.target.value })
              }>
              <MenuItem value="">全部</MenuItem>
              <MenuItem value="北">台北</MenuItem>
              <MenuItem value="林">林口</MenuItem>
              <MenuItem value="行">行政部</MenuItem>
              <MenuItem value="管">管理部</MenuItem>
              <MenuItem value="業">業務部</MenuItem>
              <MenuItem value="工">工務部</MenuItem>
              <MenuItem value="財">財務室</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  function handleSearch() {
    const updated = { ...query };
    if (!updated.range) {
      updated.range = [
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ];
      setQuery(updated);
    }
    setKeywords(updated);
    setParams({
      ...updated,
      range: [updated.range[0].toMillis(), updated.range[1].toMillis()],
    });
  }
  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && event.target.value.length > 0) {
      event.preventDefault();
      handleSearch();
    }
  }

  return (
    <div>
      <ListPage
        columns={columns}
        rows={paymentsQuery.data}
        onCellDoubleClick={(params) => {
          if (params.row.legacy) return;
          setPayment(params.row);
        }}
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }>
        <DateRangePicker
          inputFormat="yyyy-MM-dd"
          value={
            query.range || [
              DateTime.now().startOf('month'),
              DateTime.now().endOf('month'),
            ]
          }
          onChange={handleChange('range')}
          renderInput={getQueryInputs}
        />
      </ListPage>
      <PaymentInfoDialog
        payment={payment}
        setPayment={setPayment}
        onChange={handleChange}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithSearch, null, handleSearch),
        ]}
      />
    </div>
  );
}

export default PaymentList;
