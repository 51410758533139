import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableRowClasses,
} from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import {
  queryProductsByCustomerId,
  queryProductsBySerialNumber,
} from '../../fetchers/product.fetch';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { DateTime } from 'luxon';
import React from 'react';
import { blue } from '@mui/material/colors';
import { createQuote } from '../../fetchers/quote.fetch';
import { queryCustomerByKeyword } from '../../fetchers/customer.fetch';
import { queryItemsWithQuote } from '../../fetchers/item.fetch';
import { serialize } from '../../utils/serializeProduct';
import { toast } from 'react-toastify';
import useMutation from '../../services/httpClient/useMutation';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../services/httpClient/useQuery';
import { useQueryClient } from 'react-query';
import { v4 as uuid } from 'uuid';

function Item(label, name, price, desc) {
  return {
    quote: {
      label,
      name,
      price,
      desc,
    },
  };
}
function join(target, dict, qi, indexes) {
  let ary = qi.label;
  if (!Array.isArray(ary)) {
    ary = qi.label.split('/');
  }
  if (ary.length > 1) {
    let append = target.children.find((c) => c.name === ary[0]);
    if (!append) {
      append = {
        id: `p${indexes.parent++}`,
        name: ary[0],
        children: [],
      };
      target.children.push(append);
    }
    join(append, dict, { ...qi, label: ary.slice(1) }, indexes);
  } else {
    const n = {
      id: `n${indexes.node++}`,
      name: ary[0],
    };
    target.children.push(n);
    dict[n.id] = qi;
  }
}
function renderTree(nodes) {
  if (!Array.isArray(nodes))
    return (
      <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.children) && nodes.children.length > 0
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  else
    return nodes.map((node) => (
      <TreeItem key={node.id} nodeId={node.id} label={node.name}>
        {Array.isArray(node.children) && node.children.length > 0
          ? node.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    ));
}
const initialState = (product, customer) => ({
  product: null,
  company: '福',
  customer: customer,
  desc: '',
  items: [
    {
      index: uuid(),
      itemType: 'm',
      name: '',
      price: '',
      quantity: 1,
      desc: '',
    },
  ],
});

function AddQuoteDialog({
  open,
  customer,
  product,
  copyQuote,
  redirectOnSubmitted = false,
  onSubmitted,
  onClose,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [quote, setQuote] = React.useState(initialState(product, customer));
  const itemsQuery = useQuery(['items', 'quote'], queryItemsWithQuote(), {
    onSuccess: (data) => {
      if (Array.isArray(data)) {
        data = data.concat([
          Item('開穴費/制式壁掛式開穴費', '制式壁掛式開穴費', 6000),
          Item('開穴費/骨罈式開穴費', '骨罈式開穴費', 8000),
          Item('開穴費/單穴開穴費', '單穴開穴費', 18000),
          Item('開穴費/雙穴開穴費', '雙穴開穴費', 25000),
          Item('開穴費/雙穴開單邊開穴費', '雙穴開單邊開穴費', 20000),
          Item(
            '開穴費/非制式壁掛式(家族墓內)開穴費',
            '非制式壁掛式(家族墓內)開穴費',
            3000
          ),
          Item('其他/帳篷3M*3M', '帳篷3M*3M', 1000),
          Item('其他/帳篷3M*4.5M', '帳篷3M*4.5M', 1500),
          Item('其他/雄黃', '雄黃', 1300),
          Item('其他/木炭', '木炭', 1200),
          Item('其他/棺木處理費', '棺木處理費', 12000),
          Item('其他/撿骨師(園區撿骨)', '撿骨師(園區撿骨)', 12000),
          Item('其他/園區撿骨無化', '園區撿骨無化', 8000),
          Item('其他/撿骨日(擇日)', '撿骨日(擇日)', 1500),
          Item('其他/土葬(擇日)', '土葬(擇日)', 2000),
          Item('其他/進金日(擇日)', '進金日(擇日)', 1500),
          Item('其他/單穴棺木覆土清理', '單穴棺木覆土清理', 12000),
          Item('其他/二次火化(私人)', '二次火化(私人)', 2500),
          Item('其他/定分金(小甕)', '定分金(小甕)', 2600),
          Item('其他/定分金(大甕)', '定分金(大甕)', 2800),
          Item('其他/換甕工資(小甕、大甕)', '換甕工資(小甕、大甕)', 2000),
          Item('其他/大甕(撿骨)', '大甕(撿骨)', 0),
          Item('其他/小甕(骨灰)', '小甕(骨灰)', 0),
          Item('施工工法/單穴-基礎加強工法', '單穴-基礎加強工法', 40000),
          Item('施工工法/雙穴-基礎加強工法', '雙穴-基礎加強工法', 50000),
          Item('施工工法/穴內-細砂碎石工法', '穴內-細砂碎石工法', 800),
          Item('施工工法/穴內-5CM碎石', '穴內-5CM碎石', 5000),
          Item('施工工法/單穴-矽利康施作', '單穴-矽利康施作', 3500),
          Item('施工工法/雙穴-矽利康施作', '雙穴-矽利康施作', 5500),
          Item('施工工法/穴內-碎石(1分中白石)', '穴內-碎石(1分中白石)', 200),
          Item('施工工法/隔間(A)-兩階三層', '隔間(A)-兩階三層', 24000),
          Item('施工工法/隔間(B)-三階四層', '隔間(B)-三階四層', 26000),
        ]);
        const list = processList(data);
        queryClient.setQueryData(['items', 'quote'], list);
        setState(list);
      }
    },
    enabled: open,
  });
  const [state, setState] = React.useState({
    list: [],
    dict: {},
    ...itemsQuery.data,
    products: [],
    selected: null,
  });
  const quoteCreateRequest = useMutation(createQuote, {
    onSuccess: (data) => {
      toast.success('成功新增報價單');
      if (onSubmitted) {
        onSubmitted(data);
      }
      onClose();
      if (redirectOnSubmitted) {
        navigate(`/quote/${data._id}`);
      }
    },
  });

  React.useEffect(() => {
    handleCopyQuote();
  }, [copyQuote]);

  const handleChange = React.useCallback(
    (index, field, event) => {
      const updated = quote.items.map((item) => {
        if (item.index === index) {
          return { ...item, [field]: event.target.value };
        }
        return item;
      });
      const last = updated.at(-1);
      if (last.name || last.price || last.desc) {
        updated.push({
          index: DateTime.now(),
          itemType: 'm',
          name: '',
          price: '',
          quantity: 1,
          desc: '',
        });
      }
      setQuote({ ...quote, items: updated });
    },
    [quote]
  );
  function processList(data) {
    if (!Array.isArray(data)) return data;
    data = data.map((r) => ({ ...r.quote, _id: r._id }));
    data = [...data];
    const target = { id: 'root', name: 'root', children: [] };
    const dict = {};
    const indexes = {
      parent: 1,
      node: 1,
    };
    for (let qi of data) {
      try {
        join(target, dict, qi, indexes);
      } catch (error) {
        console.log('qi', qi, error);
      }
    }
    return { list: target.children, dict };
  }
  function handleCopyQuote() {
    if (copyQuote) {
      const q = {};
      q.product = copyQuote.product;
      q.company = copyQuote.company;
      q.customer = copyQuote.customer;
      q.desc = copyQuote.desc;
      q.items = copyQuote.items.map((item) => ({ ...item, index: uuid() }));
      q.items.push({
        index: uuid(),
        itemType: 'm',
        name: '',
        price: '',
        quantity: 1,
        desc: '',
      });
      setQuote(q);
    }
  }
  function handleSelect(event, nodeIds) {
    setState({ ...state, selected: nodeIds });
  }
  function handleAddItem() {
    if (state.selected && state.selected.substring(0, 1) === 'n') {
      const item = { ...state.dict[state.selected] };
      item.index = uuid();
      item.itemType = item._id ? 't' : 'u';
      item.quantity = 1;
      const updated = [...quote.items.slice(0, -1), item, quote.items.at(-1)];
      setQuote({ ...quote, items: updated });
    }
  }
  function handleDeleteItem(index) {
    const updated = quote.items.filter((i) => i.index !== index);
    setQuote({ ...quote, items: updated });
  }
  function handleSave() {
    const payload = { ...quote, items: quote.items.slice(0, -1) };
    quoteCreateRequest.mutate(payload);
  }
  function handleClose() {
    if (copyQuote) {
      handleCopyQuote();
    } else {
      setQuote(initialState);
    }
    onClose();
  }

  if (itemsQuery.isLoading) return <p>Loading...</p>;
  if (itemsQuery.isError) return <p>itemsQuery.error.toString()</p>;

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>新增報價單</DialogTitle>
      <Box sx={{ m: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>公司</InputLabel>
              <Select
                label="公司"
                value={quote.company}
                onChange={(event) =>
                  setQuote({ ...quote, company: event.target.value })
                }>
                <MenuItem value="福">頂福事業</MenuItem>
                <MenuItem value="利">頂利事業</MenuItem>
                <MenuItem value="茂">辰茂事業</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <CustomerPanel
            customer={quote.customer}
            setCustomer={(customer) => setQuote({ ...quote, customer })}
          />
          <ProductPanel
            customer={quote.customer}
            product={quote.product}
            setProduct={(product) => setQuote({ ...quote, product })}
          />
        </Grid>
      </Box>
      <Box sx={{ m: 3, mt: 1, minHeight: 350 }}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={2.5}>
            <Box
              sx={{
                minHeight: 350,
                height: '100%',
                border: '1px solid #C0C0C0',
                borderRadius: 0.5,
              }}>
              <TreeView
                onNodeSelect={handleSelect}
                onDoubleClick={handleAddItem}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultEndIcon={<AddCircleOutlineIcon />}>
                {renderTree(state.list)}
              </TreeView>
            </Box>
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Box
              sx={{
                border: '1px solid #C0C0C0',
                height: '100%',
                borderRadius: 0.5,
              }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="32%">品項</TableCell>
                    <TableCell width="15%">單價</TableCell>
                    <TableCell width="13%">數量</TableCell>
                    <TableCell>備註</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quote.items.map((item, pos) => (
                    <TableRow key={item.index}>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={item.name}
                          onChange={(event) =>
                            handleChange(item.index, 'name', event)
                          }
                          InputProps={{ readOnly: item.itemType !== 'm' }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          type="number"
                          value={item.price}
                          onChange={(event) =>
                            handleChange(item.index, 'price', event)
                          }
                          InputProps={{ readOnly: item.itemType !== 'm' }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          type="number"
                          value={item.quantity}
                          onChange={(event) =>
                            handleChange(item.index, 'quantity', event)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={item.desc}
                          onChange={(event) =>
                            handleChange(item.index, 'desc', event)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {pos !== quote.items.length - 1 && (
                          <IconButton
                            onClick={() => handleDeleteItem(item.index)}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="備註"
              value={quote.desc}
              onChange={(event) =>
                setQuote({ ...quote, desc: event.target.value })
              }
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
        <div style={{ textAlign: 'right', marginTop: 30 }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            取消
          </Button>
          {quote.customer &&
            quote.product &&
            quote.items.length > 1 &&
            quote.items
              .slice(0, -1)
              .every((i) => i.name && i.price && i.quantity) && (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color="primary"
                onClick={handleSave}>
                儲存
              </Button>
            )}
        </div>
      </Box>
    </Dialog>
  );
}

function ProductPanel({ product, customer, setProduct }) {
  const [products, setProducts] = React.useState();
  const [query, setQuery] = React.useState();
  const [keyword, setKeyword] = React.useState();
  useQuery(
    ['products', { sn: keyword }],
    queryProductsBySerialNumber({ sn: serialize(keyword) }),
    {
      onSuccess: (data) => {
        setProducts(data);
        setQuery('');
      },
      enabled: !!keyword,
    }
  );
  useQuery(
    ['products', 'customer', customer?._id],
    queryProductsByCustomerId({ id: customer?._id }),
    {
      onSuccess: (data) => setProducts(data),
      enabled: !!customer,
    }
  );

  function handleKeyDown(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      query.length > 0
    ) {
      event.preventDefault();
      setKeyword(query);
      event.target.blur();
    }
  }
  function selectProduct(product) {
    setProduct({ _id: product._id, sn: product.sn });
    setQuery();
  }

  return (
    <>
      <Dialog
        open={!!query || query === ''}
        onClose={() => setQuery()}
        maxWidth="xs"
        fullWidth>
        <DialogTitle>選擇產品</DialogTitle>
        <Box sx={{ p: 3, pt: 0 }}>
          <TextField
            variant="outlined"
            size="small"
            value={query || ''}
            placeholder="輸入產品編號"
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          {products && (
            <div style={{ overflow: 'hidden' }}>
              <TableContainer
                sx={{
                  maxHeight: 240,
                  [`.${tableRowClasses.root}:hover`]: {
                    backgroundColor: blue[50],
                  },
                }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>產品編號</TableCell>
                      <TableCell>狀態</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <TableRow
                        key={product._id}
                        onDoubleClick={() => selectProduct(product)}>
                        <TableCell>{product.sn}</TableCell>
                        <TableCell>{product.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          <div style={{ textAlign: 'right', marginTop: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setQuery()}>
              取消
            </Button>
          </div>
        </Box>
      </Dialog>
      {product ? (
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>產品編號</InputLabel>
            <OutlinedInput
              variant="outlined"
              size="small"
              label="產品編號"
              inputProps={{ readOnly: true }}
              value={product.sn}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setQuery('')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid item>
          <Button variant="text" onClick={() => setQuery('')}>
            選擇產品
          </Button>
        </Grid>
      )}
    </>
  );
}

function CustomerPanel({ customer, setCustomer }) {
  const [query, setQuery] = React.useState();
  const [keyword, setKeyword] = React.useState();
  const customersQuery = useQuery(
    ['customer', { keyword }],
    queryCustomerByKeyword({ keyword }),
    {
      enabled: !!keyword,
    }
  );

  function handleKeyDown(event) {
    if (
      (event.code === 'Enter' || event.code === 'NumpadEnter') &&
      query.length > 0
    ) {
      event.preventDefault();
      setKeyword(query);
      event.target.blur();
    }
  }
  function selectCustomer(customer) {
    setCustomer({
      _id: customer._id,
      name: customer.name,
      phone: customer.mobile || customer.phone || '',
    });
    setQuery();
  }

  return (
    <>
      <Dialog
        open={!!query || query === ''}
        onClose={() => setQuery()}
        maxWidth="xs"
        fullWidth>
        <DialogTitle>選擇聯絡人</DialogTitle>
        <Box sx={{ p: 3, pt: 0 }}>
          <TextField
            variant="outlined"
            size="small"
            value={query || ''}
            placeholder="輸入姓名或電話"
            onChange={(event) => setQuery(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          {customersQuery.data && (
            <TableContainer
              sx={{
                maxHeight: 240,
                [`.${tableRowClasses.root}:hover`]: {
                  backgroundColor: blue[50],
                },
              }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>姓名</TableCell>
                    <TableCell>身分證字號</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customersQuery.data.map((customer) => (
                    <TableRow
                      key={customer._id}
                      onDoubleClick={() => selectCustomer(customer)}>
                      <TableCell>{customer.name}</TableCell>
                      <TableCell>{customer.ssn}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div style={{ textAlign: 'right', marginTop: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setQuery()}>
              取消
            </Button>
          </div>
        </Box>
      </Dialog>
      {customer ? (
        <>
          <Grid item xs={12} sm={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>聯絡人</InputLabel>
              <OutlinedInput
                variant="outlined"
                size="small"
                label="聯絡人"
                inputProps={{ readOnly: true }}
                value={customer.name}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setQuery('')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              label="聯絡電話"
              value={customer.phone}
              onChange={(event) =>
                setCustomer({ ...customer, phone: event.target.value })
              }
              fullWidth
            />
          </Grid>
        </>
      ) : (
        <Grid item>
          <Button variant="text" onClick={() => setQuery('')}>
            選擇聯絡人
          </Button>
        </Grid>
      )}
    </>
  );
}

export default AddQuoteDialog;
