import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  buttonClasses,
  tableCellClasses,
} from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddContactDialog from '../../components/AddContactDialog';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/system';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { Link } from 'react-router-dom';
import PrintableCert from '../../components/PrintableCert';
import PrintableDialog from '../../components/PrintableDialog';
import React from 'react';
import { green } from '@mui/material/colors';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import useHttpClient from '../../services/httpClient/useHttpClient';

const columns = [
  {
    field: 'sn',
    headerName: '產品編號',
    flex: 1,
  },
  {
    field: 'archiveType',
    headerName: '類別',
    editable: true,
    type: 'singleSelect',
    valueOptions: ['換發', '補發', '其他', '不變'],
    flex: 1,
  },
  {
    field: 'cert',
    headerName: '權狀編號',
    editable: true,
    flex: 1,
  },
  {
    field: 'desc',
    headerName: '備註',
    editable: true,
    flex: 2,
  },
];
const initialState = {
  date: DateTime.now().setZone('Asia/Taipei'),
  transferors: [],
  transferees: [],
  items: [],
  applicationType: '轉讓',
  desc: '',
};

function Transfer(props) {
  const [state, setState] = React.useState(initialState);
  const [open, setOpen] = React.useState(false);
  const [result, setResult] = React.useState({
    certs: null,
    print: false,
  });
  const client = useHttpClient();

  function handleEditRowsModelChange(model) {
    for (let sn of Object.keys(model)) {
      const updated = state.items.map((item) => {
        if (item.sn === sn) {
          return {
            ...item,
            archiveType: model[sn].archiveType.value,
            cert: model[sn].cert.value,
            desc: model[sn].desc.value,
          };
        }
        return item;
      });
      setState((s) => ({ ...s, items: updated }));
    }
  }
  function queryProducts(list) {
    if (list.length > 0) {
      return client
        .get(`/api/product?ids=${list.map((t) => t._id).join('+')}`)
        .then((response) => {
          const update = response.map((p) => ({
            _id: p._id,
            sn: p.sn,
            archiveType: '',
            cert: '',
            desc: '',
          }));
          setState((s) => ({ ...s, items: update }));
        })
        .catch((err) => console.log(err));
    }
    setState((s) => ({ ...s, items: [] }));
  }
  function handleContact(response) {
    let source = 'transferors';
    if (response.contactType === '新權益人') {
      source = 'transferees';
    }
    if (state[source].find((contact) => contact._id === response._id)) {
      return '重複客戶資料';
    }
    const updated = [...state[source], response];
    setState({ ...state, [source]: updated });
    if (source === 'transferors') queryProducts(updated);
  }
  function handleSubmit() {
    const payload = { ...state };
    payload.items = payload.items.filter((i) => i.archiveType !== '');
    client
      .post('/api/transfer', payload)
      .then((response) => {
        displayResult(response);
        setState(initialState);
      })
      .catch((error) => console.log(error));
  }
  function displayResult(response) {
    setResult({ ...result, certs: response });
  }
  function handleDeleteContact(t, id) {
    const list = t === 'o' ? 'transferors' : 'transferees';
    const update = state[list].filter((c) => c._id !== id);
    setState({
      ...state,
      [list]: update,
    });
    if (list === 'transferors') queryProducts(update);
  }
  function handleChange(t, id) {
    const from = t === 'o' ? 'transferors' : 'transferees';
    const to = t !== 'o' ? 'transferors' : 'transferees';
    const contact = state[from].find((c) => c._id === id);
    contact.contactType = t === 'o' ? '新權益人' : '原權益人';

    const updated = {
      ...state,
      [from]: state[from].filter((v) => v._id !== id),
      [to]: state[to].concat(contact),
    };
    setState(updated);
    queryProducts(updated['transferors']);
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          [`.${buttonClasses.root}`]: {
            p: 0,
            fontSize: '14px',
          },
        }}>
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <DatePicker
                  label="日期"
                  inputFormat="yyyy-MM-dd"
                  value={state.date}
                  onChange={(val) =>
                    setState({ ...state, date: val.startOf('day') })
                  }
                  renderInput={(params) => (
                    <TextField size="small" fullWidth {...params} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel>類別</InputLabel>
                  <Select
                    label="類別"
                    value={state.applicationType}
                    onChange={(event) =>
                      setState({
                        ...state,
                        applicationType: event.target.value,
                      })
                    }>
                    <MenuItem value="轉讓">轉讓</MenuItem>
                    <MenuItem value="繼承">繼承</MenuItem>
                    <MenuItem value="更名">更名</MenuItem>
                    <MenuItem value="不變">不變更</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  label="備註"
                  onChange={(event) =>
                    setState({ ...state, desc: event.target.value })
                  }
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={1}
            sx={{
              height: 300,
              [`.${tableCellClasses.root}`]: { fontSize: 15 },
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>原權益人</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>姓名</TableCell>
                  <TableCell>身分證字號</TableCell>
                  <TableCell>手機</TableCell>
                  <TableCell width="25%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.transferors.map((contact) => (
                  <TableRow key={contact._id}>
                    <TableCell>
                      <Button
                        variant="text"
                        size="small"
                        component={Link}
                        to={`/customer/${contact._id}`}>
                        {contact.name}
                      </Button>
                    </TableCell>
                    <TableCell>{contact.ssn}</TableCell>
                    <TableCell>{contact.mobile.toPhone()}</TableCell>
                    <TableCell>
                      <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                          <IconButton
                            onClick={() =>
                              handleDeleteContact('o', contact._id)
                            }>
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => handleChange('o', contact._id)}>
                            <ChevronRightIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={1} sx={{ height: 300 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>新權益人</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="5%"></TableCell>
                  <TableCell>姓名</TableCell>
                  <TableCell>身分證字號</TableCell>
                  <TableCell>手機</TableCell>
                  <TableCell width="5%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.transferees.map((contact) => (
                  <TableRow key={contact._id}>
                    <TableCell>
                      <IconButton
                        onClick={() => handleChange('n', contact._id)}>
                        <ChevronLeftIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="text"
                        component={Link}
                        to={`/customer/${contact._id}`}>
                        {contact.name}
                      </Button>
                    </TableCell>
                    <TableCell>{contact.ssn}</TableCell>
                    <TableCell>{contact.mobile.toPhone()}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleDeleteContact('n', contact._id)}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={1}>
            <DataGrid
              sx={{ height: '300px' }}
              columns={columns}
              rows={state.items}
              editMode="row"
              onEditRowsModelChange={handleEditRowsModelChange}
              getRowId={(v) => v.sn}
              hideFooter
            />
          </Paper>
        </Grid>
      </Grid>
      <AddContactDialog
        state={{ open, setOpen }}
        contactTypes={['原權益人', '新權益人']}
        onChange={handleContact}
      />
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(<AccountBoxIcon />, '加入客戶資料', () => setOpen(true)),
          createAction(
            <LibraryAddCheckIcon />,
            '確定異動',
            handleSubmit,
            state.transferors.length === 0 ||
              (state.applicationType !== '不變' &&
                state.transferees.length === 0) ||
              state.items
                .filter((i) => i.archiveType !== '')
                .every((item) => {
                  if (!item.archiveType) return true;
                  if (
                    item.archiveType !== '不變' &&
                    ['D', 'E', 'F'].includes(item.sn.substring(0, 1)) &&
                    !item.cert
                  )
                    return true;
                  return false;
                })
          ),
        ]}
      />
      {result?.certs && (
        <Dialog open={!!result.certs} maxWidth="md" fullWidth>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <DialogTitle>異動結果</DialogTitle>
            </Grid>
            <Grid item>
              <Button
                sx={{ mr: 2 }}
                variant="outlined"
                color="secondary"
                onClick={() => setResult({ ...result, print: true })}>
                列印
              </Button>
            </Grid>
          </Grid>
          <Box
            sx={{
              p: '0 20px 20px',
              '.result': {
                mb: 2,
                '.title': {
                  backgroundColor: green[100],
                  p: 1,
                  mb: 2,
                  display: 'flex',
                  div: {
                    marginRight: '30px',
                    fontWeight: 'bold',
                    color: '#3E3E3E',
                    sup: {
                      marginRight: '10px',
                    },
                  },
                },
                '.cert': {
                  p: '0 20px',
                  border: '1px solid #E0E0E0',
                  borderRadius: 1,
                  minHeight: 200,
                  span: {},
                },
                '.arrow': {
                  display: { xs: 'none', sm: 'flex' },
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            }}>
            {result.certs.map((cert) => (
              <div className="result">
                <div className="title">
                  <div>
                    <sup>產品編號</sup>
                    {cert[1].sn}
                  </div>
                  <div>
                    <sup>類別</sup>
                    {cert[0].archived.applicationType}
                    {cert[0].archived.archiveType}
                  </div>
                  {cert[0].archived.desc && (
                    <div>
                      <sup>備註</sup>
                      {cert[0].archived.desc}
                    </div>
                  )}
                </div>
                <Grid container spacing={2}>
                  <Grid item={12} xs={12} sm={5.8}>
                    <div className="cert">
                      <p style={{ borderBottom: '1px solid #E0E0E0' }}>
                        原權狀
                      </p>
                      <p>
                        <span>權狀編號：</span>
                        {cert[0].sn}
                      </p>
                      <p>
                        <span>發狀日期：</span>
                        {cert[0].date?.toDate()}
                      </p>
                      <p>
                        <span>權益人：</span>
                        {cert[0].contacts.map((c) => c.name).join('、')}
                      </p>
                    </div>
                  </Grid>
                  <Grid item sm={0.4} className="arrow">
                    <ArrowForwardIcon />
                  </Grid>
                  <Grid item={12} xs={12} sm={5.8}>
                    <div className="cert">
                      <p style={{ borderBottom: '1px solid #E0E0E0' }}>
                        新權狀
                      </p>
                      <p>
                        <span>權狀編號：</span>
                        {cert[1].certificate.sn}
                      </p>
                      <p>
                        <span>發狀日期：</span>
                        {cert[1].certificate.date?.toDate()}
                      </p>
                      <p>
                        <span>權益人：</span>
                        {cert[1].certificate.contacts
                          .filter((c) => c.contactType === '權益人')
                          .map((c) => c.name)
                          .join('、')}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ))}
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              color="primary"
              onClick={() => setResult(null)}>
              關閉
            </Button>
          </Box>
          <PrintableDialog
            open={result.print}
            onClose={() => setResult({ ...result, print: false })}
            maxWidth="sm">
            <PrintableCert
              products={result.certs.map((c) => {
                c[1].contacts = c[1].certificate.contacts;
                return c[1];
              })}
            />
          </PrintableDialog>
        </Dialog>
      )}
    </>
  );
}

export default Transfer;
