import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { phoneMobileMask, ssnMask } from '../../validations/imask';

import { Box } from '@mui/system';
import Panel from '../../components/Panel';
import React from 'react';
import Tabs from '../../components/Tabs';
import { red } from '@mui/material/colors';

function AddReferralDialog({ state, setState, contacts = [], onOperation }) {
  const [selected, setSelected] = React.useState('單筆輸入');

  function handleChange(field) {
    return (event) => {
      setState({ ...state, [field]: event.target.value });
    };
  }
  function handleMultipleChange(event) {
    let error;
    const lines = event.target.value.split('\n');
    for (let i = 0; i < lines.length; i++) {
      const record = lines[i].split(',');
      if (record.length !== 5) {
        error = i + 1;
        break;
      }
    }
    setState({ ...state, multiple: event.target.value, error });
  }

  if (!state) return null;

  return (
    <Dialog open={state.open} maxWidth="sm" fullWidth>
      <DialogTitle>新增通報紀錄</DialogTitle>
      <Tabs
        onChange={(e) => setSelected(e)}
        elevation={0}
        tabs={[
          {
            title: '單筆輸入',
            content: (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl size="small" fullWidth>
                      <InputLabel>通報人</InputLabel>
                      <Select
                        label="通報人"
                        value={state.contact}
                        onChange={handleChange('contact')}
                        inputProps={{ readOnly: contacts.length === 0 }}>
                        {contacts.map((contact) => (
                          <MenuItem key={contact._id} value={contact}>
                            {contact.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="姓名"
                      value={state.name}
                      onChange={handleChange('name')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="電話"
                      value={state.phone}
                      onChange={(event) => {
                        phoneMobileMask.onChange(event);
                        handleChange('phone')(event);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="歿者"
                      value={state.deceased}
                      onChange={handleChange('deceased')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="身分証字號"
                      value={state.ssn}
                      onChange={(e) => {
                        ssnMask.onChange(e);
                        handleChange('ssn')(e);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="備註"
                      value={state.desc}
                      onChange={handleChange('desc')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            ),
          },
          {
            title: '多筆輸入',
            content: (
              <Box>
                {state.error && (
                  <Alert severity="error" style={{ marginBottom: 20 }}>
                    請檢查第 {state.error} 行資料
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl size="small" fullWidth>
                      <InputLabel>通報人</InputLabel>
                      <Select
                        label="通報人"
                        value={state.contact}
                        onChange={handleChange('contact')}
                        inputProps={{ readOnly: contacts.length === 0 }}>
                        {contacts.map((contact) => (
                          <MenuItem key={contact._id} value={contact}>
                            {contact.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Panel title="內容" error={state.error}>
                      <Grid container>
                        <Grid
                          item
                          sx={{
                            paddingRight: 1,
                            borderRight: '0.7px solid rgba(0,0,0,0.3)',
                            color: 'rgba(0,0,0,0.5)',
                            textAlign: 'right',
                            lineHeight: '23px',
                            marginRight: '10px',
                            '& div[error]': {
                              color: red[500],
                              fontWeight: 'bold',
                            },
                          }}>
                          {state.multiple.split('\n').map((line, index) => (
                            <div
                              key={index}
                              {...{
                                error:
                                  state.error === index + 1
                                    ? state.error
                                    : null,
                              }}>
                              {index + 1}
                            </div>
                          ))}
                        </Grid>
                        <Grid item flexGrow={1}>
                          <InputBase
                            variant="outlined"
                            size="small"
                            placeholder="姓名,電話,歿者,身分證字號,備註"
                            value={state.multiple}
                            onChange={handleMultipleChange}
                            minRows={8}
                            multiline
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Panel>
                  </Grid>
                </Grid>
              </Box>
            ),
          },
        ]}
      />
      <Box sx={{ textAlign: 'right', px: 3, pb: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: state.error ? 0 : 2 }}
          onClick={() => onOperation('cancel')}>
          取消
        </Button>
        {!state.error && (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              onOperation(selected === '單筆輸入' ? 'single' : 'multiple')
            }>
            新增
          </Button>
        )}
      </Box>
    </Dialog>
  );
}

export default AddReferralDialog;
