import { Button, Dialog, DialogTitle, Grid, TextField } from '@mui/material';

import { Box } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, input: '' };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }
  handleReport = () => {
    this.props.client
      .post('/api/notify', {
        message: `ERROR REPORT\n${this.props.user.name} - ${this.state.input}\n${this.state.error.name}\n${this.state.error.message}\n${this.state.error.stack}`,
      })
      .then(() => {
        this.setState({ error: null, input: '' });
        window.location.reload();
      });
  };

  render() {
    if (this.state.error) {
      return (
        <Dialog open={true} maxWidth="sm" fullWidth>
          <DialogTitle
            sx={{ display: 'flex', alignItems: 'center', color: '#4E4E4E' }}>
            <ErrorIcon sx={{ mr: 0.5, color: 'red' }} />
            網頁發生錯誤
          </DialogTitle>
          <Box sx={{ p: 3, pt: 0 }}>
            <p style={{ color: '#4E4E4E' }}>
              新網頁上線還有一些問題，麻煩花一點時間描述錯誤發生時的情況，這些資料會被即時回報並用來幫助解決錯誤。回報後頁面會自動重新整理，如果錯誤持續發生，請直接關掉頁面重開：
            </p>
            <TextField
              minRows={5}
              placeholder="回報問題..."
              value={this.state.input}
              onChange={(e) =>
                this.setState({ ...this.state, input: e.target.value })
              }
              multiline
              fullWidth
            />
            <Grid
              container
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 3 }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => (window.location.href = '/')}>
                  取消
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleReport}>
                  回報
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      );
    }

    return this.props.children;
  }
}
