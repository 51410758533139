import { Grid } from '@mui/material';
import React from 'react';

function Section({ children, title }) {
  if (children.every((child) => !child.props.value)) return null;
  return (
    <div style={{ marginTop: 20 }}>
      <div
        style={{
          marginBottom: 25,
          color: 'rgba(0,0,0,0.6)',
          fontSize: 13,
          borderTop: '1px solid rgba(0,0,0,0.3)',
          position: 'relative',
        }}>
        <span
          style={{
            position: 'absolute',
            top: -10,
            paddingRight: 10,
            backgroundColor: 'white',
          }}>
          {title}
        </span>
      </div>
      <Grid container>
        {React.Children.map(children, (child, index) => {
          if (!child.props.value) return null;
          return (
            <Grid key={index} item xs={12} sm={child.props.sm ?? 6}>
              {child}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Section;
