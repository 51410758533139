export const access = {
  A: {
    department: '行政部',
    m: '主管',
    h: '人事',
    r: '總機',
    p: '塔位',
    c: '墓地',
  },
  F: { department: '財務室', m: '主管', a: '會計', t: '出納', s: '助理' },
  E: {
    department: '管理部',
    m: '主管',
    c: '客服',
    r: '總機',
    p: '寶塔',
    d: '司機',
  },
  C: { department: '工務部', m: '主管', p: '採購', s: '倉管', w: '工務' },
  S: { department: '業務部', m: '主管', p: '業務' },
  Z: { department: '系統', m: '管理員' },
};
