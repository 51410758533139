const localText = {
  noRowsLabel: '沒有資料',
  noResultsOverlayLabel: '未找到符合資料',
  errorOverlayDefaultLabel: '發生錯誤',
  columnMenuShowColumns: '顯示欄位',
  columnMenuFilter: '篩選',
  columnMenuHideColumn: '隱藏欄位',
  columnMenuUnsort: '取消篩選',
  columnMenuSortAsc: '升序',
  columnMenuSortDesc: '降序',
  columnsPanelHideAllButton: '隱藏全部',
  columnsPanelShowAllButton: '顯示全部',
  columnsPanelTextFieldLabel: '查詢欄位...',
  filterOperatorContains: '包含',
  filterOperatorEquals: '等於',
  filterOperatorStartsWith: '開頭為',
  filterOperatorEndsWith: '結尾為',
  filterOperatorIs: '是',
  filterOperatorNot: '不是',
  filterOperatorAfter: '之後',
  filterOperatorOnOrAfter: '等於或之後',
  filterOperatorBefore: '之前',
  filterOperatorOnOrBefore: '等於或之前',
  filterOperatorIsEmpty: '空值',
  filterOperatorIsNotEmpty: '有值',
  filterOperatorIsAnyOf: '在清單內',
  footerTotalRows: (count) => `共 ${count} 筆資料`,
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,
  footerRowSelected: (count) => `共選取 ${count.toLocaleString()} 筆資料`,
};

export default localText;
