import {
  Grid,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Box } from '@mui/system';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Detail from '../Detail';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import React from 'react';
import companies from '../../utils/companies.const';
import styles from './style.module.scss';

const itemTypes = {
  t: '品項',
  u: '預設',
  m: '自行輸入',
};
const titles = {
  dep: '部門',
  admin: '稽核',
  gm: '總經理',
};

function PurchaseForm({ purchase }) {
  return (
    <div className={styles.form}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ fontSize: 18 }}>
        <Grid item>
          <span style={{ fontSize: 23, fontWeight: 'bold', marginRight: 10 }}>
            {companies[purchase.company].short}
          </span>
          採購單
        </Grid>
        <Grid item>{purchase.created.date.toDate()}</Grid>
      </Grid>
      <hr />
      <Grid container sx={{ mt: 1, mb: 1.5 }} spacing={3}>
        <Grid item xs={4}>
          <Detail width="30%" fs="14px" title="採購單號" value={purchase.sn} />

          <Detail
            width="30%"
            fs="14px"
            title="到貨日期"
            value={purchase.receipt?.date.toDate()}
          />
        </Grid>
        <Grid item xs={4}>
          <Detail
            width="30%"
            fs="14px"
            title="廠商"
            value={purchase.vendor.name}
          />
          <Detail
            width="30%"
            fs="14px"
            title="收據"
            value={`${purchase.receipt?.receiptType}${
              purchase.receipt?.receiptType === '發票'
                ? ` ${purchase.payment?.sn?.join(', ') || ''}`
                : ''
            }`}
          />
        </Grid>
        <Grid item xs={4}>
          <Detail
            width="30%"
            fs="14px"
            title="承辦人"
            value={purchase.created.by.name}
          />
          <Detail
            width="30%"
            fs="14px"
            title="付款方式"
            value={
              purchase.payment
                ? `${
                    purchase.payment.paymentType
                  } ${purchase.payment.date?.toDate()}`
                : null
            }
          />
        </Grid>
      </Grid>
      <Table sx={{ mb: 1.5 }}>
        <TableHead>
          <TableRow>
            <TableCell>品名</TableCell>
            <TableCell>類別</TableCell>
            <TableCell>單價</TableCell>
            <TableCell>數量</TableCell>
            <TableCell>單位</TableCell>
            <TableCell>金額</TableCell>
            <TableCell>備註</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchase.items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{itemTypes[item.itemType]}</TableCell>
              <TableCell>{item.price.toLocaleString()}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.unit}</TableCell>
              <TableCell>
                {(
                  Math.round(item.price * item.quantity * 100) / 100
                ).toLocaleString()}
              </TableCell>
              <TableCell>{item.desc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ mt: 5, mb: 5, fontSize: '14px' }}>
        {purchase.desc ? (
          <>
            <span style={{ fontWeight: 'bold', marginRight: 20 }}>備註</span>
            {purchase.desc}
          </>
        ) : null}
      </Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={styles.total}
        sx={{
          backgroundColor: '#E9E9E9',
          fontSize: 20,
          padding: '20px 15px',
          borderRadius: 0.5,
        }}>
        <Box>
          {!purchase.taxIncluded && (
            <Item
              sup="營業稅"
              value={(
                Math.round((purchase.amount / 1.05) * 0.05 * 100) / 100
              ).toLocaleString()}
              marginLeft={20}
              bold
              lg
            />
          )}
        </Box>
        <Item
          sup="總金額"
          value={purchase.amount.toLocaleString()}
          marginLeft={20}
          bold
          lg
        />
      </Grid>
      <div
        className={styles.stepper}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}>
        <Stepper
          sx={{ m: 3 }}
          nonLinear
          activeStep={Object.entries(purchase.audit.checks).findIndex(
            (c) => c[1].checked === false || c[1].checked === undefined
          )}>
          {Object.keys(purchase.audit.checks).map((key) => {
            const check = purchase.audit.checks[key];
            function Icon({ active }) {
              let icon = (
                <CheckBoxOutlineBlankIcon
                  color={active ? 'primary' : 'disabled'}
                />
              );
              if (check.checked === true) {
                icon = <CheckBoxIcon color="success" />;
              }
              if (check.checked === false) {
                icon = <DisabledByDefaultIcon color="error" />;
              }
              return icon;
            }
            return (
              <Step key={key} completed={check.checked}>
                <StepLabel
                  StepIconComponent={Icon}
                  optional={
                    (check.checked || check.checked === false) && (
                      <Typography variant="caption">
                        {check.date?.toDateTime()}
                      </Typography>
                    )
                  }>
                  {titles[key] || key}
                  {(check.checked || check.checked === false) &&
                    `: ${check.name}`}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  );
}

function Item({ sup, value, marginLeft, bold, lg }) {
  return (
    <Box
      sx={{
        display: 'inline-block',
        sup: {
          display: 'block',
          color: '#505050',
          fontSize: '12px',
        },
      }}>
      <sup>{sup}</sup>
      <span
        style={{
          marginLeft: marginLeft ?? 15,
          fontWeight: bold && 'bold',
          fontSize: lg && '23px',
        }}>
        ${value.toLocaleString()}
      </span>
    </Box>
  );
}

export default PurchaseForm;
