import {
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';

import React from 'react';

function Sidebar({ state, setState, open, setOpen }) {
  return (
    <Drawer
      anchor="right"
      sx={{
        width: 240,
        [`& .MuiDrawer-paper`]: {
          p: 2,
          width: 240,
          boxSizing: 'border-box',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <Typography paragraph>門禁管制</Typography>
      <FormGroup style={{ marginBottom: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              label="忠區"
              checked={state.areaAccess.zhong}
              onChange={(event) =>
                setState({
                  ...state,
                  areaAccess: {
                    ...state.areaAccess,
                    zhong: event.target.checked,
                  },
                })
              }
            />
          }
          label="忠區"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.areaAccess.ren}
              onChange={(event) =>
                setState({
                  ...state,
                  areaAccess: {
                    ...state.areaAccess,
                    ren: event.target.checked,
                  },
                })
              }
            />
          }
          label="仁區"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.areaAccess.ai}
              onChange={(event) =>
                setState({
                  ...state,
                  areaAccess: {
                    ...state.areaAccess,
                    ai: event.target.checked,
                  },
                })
              }
            />
          }
          label="愛區"
        />
      </FormGroup>
      <Typography paragraph>法會通知</Typography>
      <FormGroup style={{ marginBottom: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              label="郵寄"
              name="notification.mailing"
              checked={state.notification.mailing}
              onChange={(event) =>
                setState({
                  ...state,
                  notification: {
                    ...state.notification,
                    mailing: event.target.checked,
                  },
                })
              }
            />
          }
          label="郵寄"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="notification.email"
              checked={state.notification.email}
              onChange={(event) =>
                setState({
                  ...state,
                  notification: {
                    ...state.notification,
                    email: event.target.checked,
                  },
                })
              }
            />
          }
          label="Email"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="notification.line"
              checked={state.notification.line}
              onChange={(event) =>
                setState({
                  ...state,
                  notification: {
                    ...state.notification,
                    line: event.target.checked,
                  },
                })
              }
            />
          }
          label="Line"
        />
      </FormGroup>
      <TextField
        variant="standard"
        label="LINE"
        value={state.line}
        onChange={(event) => setState({ ...state, line: event.target.value })}
      />
    </Drawer>
  );
}

export default Sidebar;
