import * as React from 'react';

import Box from '@mui/material/Box';
import MuiSpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import speedDialIcons from './speedDialIcons';

export function createAction(icon, name, onClick, hidden) {
  return {
    icon,
    name,
    onClick,
    hidden,
  };
}

function SpeedDial({
  actions = [{ icon: speedDialIcons.main }],
  position = 'fixed',
}) {
  return (
    <Box
      sx={{
        height: 320,
        transform: 'translateZ(0px)',
        flexGrow: 1,
        position,
        bottom: 0,
        right: 0,
        zIndex: 3,
      }}>
      <MuiSpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={actions[0].icon}
        {...(actions[0].onClick
          ? { onClick: (e) => actions[0].onClick(e) }
          : null)}>
        {actions
          .slice(1)
          .filter((action) => !action.hidden)
          .map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick(e);
              }}
            />
          ))}
      </MuiSpeedDial>
    </Box>
  );
}

export default SpeedDial;
