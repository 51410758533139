import { useNavigate, useSearchParams } from 'react-router-dom';

import React from 'react';
import useHttpClient from '../../services/httpClient/useHttpClient';

function LineConnect(props) {
  const [searchParams] = useSearchParams();
  const [error, setError] = React.useState();
  const navigate = useNavigate();
  const client = useHttpClient();

  React.useEffect(() => {
    const id = searchParams.get('id');
    const redirect = searchParams.get('red');
    console.log(redirect);
    client.get(`/api/user/${id}`).then((user) => {
      client
        .put('/auth/line', {
          code: searchParams.get('code'),
          id: id,
        })
        .then((response) => {
          client
            .put(`/api/user/${id}`, {
              line: {
                ...user.line,
                uid: response.uid,
                date: Date.now(),
              },
            })
            .then(() => navigate(redirect))
            .catch((err) => navigate('/'));
        })
        .catch((error) => {
          setError(error);
        });
    }, []);
  });

  if (error) return <div>{error}</div>;
  return <div>正在綁定LINE，請勿離開或重新整理頁面。</div>;
}

export default LineConnect;
