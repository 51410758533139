import { Box, Grid } from '@mui/material';

import React from 'react';
import { blue } from '@mui/material/colors';

function Detail({
  title,
  value,
  width = 120,
  marginless = false,
  fs = '15.5px',
  showTitle,
}) {
  if (!value && !showTitle) return null;
  return (
    <Box
      sx={{
        mb: marginless ? 0 : '15px',
        fontSize: fs,
        a: {
          color: blue[700],
          textDecoration: 'none',
          '&:hover': {
            color: blue[800],
            textDecoration: 'underline',
          },
        },
      }}>
      <Grid container alignItems="center">
        <Grid item width={width} sx={{ fontWeight: 600 }}>
          {title}
        </Grid>
        <Grid item>{value}</Grid>
      </Grid>
    </Box>
  );
}

export default Detail;
