import { CrumbsContext } from '../../layouts/DefaultLayout';
import React from 'react';

function useCrumbs(currentCrumbs) {
  const { crumbs, setCrumbs } = React.useContext(CrumbsContext);

  React.useEffect(() => {
    if (currentCrumbs) setCrumbs(currentCrumbs);
  }, []);

  return { crumbs, setCrumbs };
}

export default useCrumbs;
