import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from '@mui/material';

import ArchiveIcon from '@mui/icons-material/Archive';
import { Box } from '@mui/system';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import React from 'react';
import { blue } from '@mui/material/colors';
import { titles } from './purchase.const';

export default function PurchasePreview({ purchase }) {
  if (!purchase) return null;
  return (
    <Box sx={{ height: 200, overflow: 'auto', pl: 2 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={purchase.status === 'draft' ? 12 : 8}
          order={{ xs: 1, md: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>品名</TableCell>
                <TableCell hide-sm="true">單價</TableCell>
                <TableCell hide-md="true">數量</TableCell>
                <TableCell hide-md="true">金額</TableCell>
                <TableCell>備註</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                [`& .${tableCellClasses.body}, & .${tableCellClasses.head}`]: {
                  fontSize: '16px',
                },
                '& a': {
                  color: blue[700],
                  textDecoration: 'none',
                },
                '& a:hover': {
                  textDecoration: 'underline',
                },
              }}>
              {purchase.items.map((item, index) => (
                <TableRow key={`${index}}`}>
                  <TableCell sx={{ display: 'flex' }}>
                    {item.name}
                    {item.stocked && (
                      <Tooltip title="入庫品項" placement="top">
                        <ArchiveIcon
                          sx={{ color: '#909090', fontSize: '18px', ml: 0.5 }}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell hide-sm="true">
                    {item.price.toLocaleString()}
                  </TableCell>
                  <TableCell hide-md="true">{item.quantity}</TableCell>
                  <TableCell hide-md="true">
                    {(item.price * item.quantity).toLocaleString()}
                  </TableCell>
                  <TableCell>{item.desc}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        {purchase.status !== 'draft' && (
          <Grid item xs={12} md={4} order={{ xs: 0, md: 1 }}>
            <AuditInfo audit={purchase.audit} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
function AuditInfo({ audit }) {
  const last = audit.remarks && audit.remarks[audit.remarks.length - 1];
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        {Object.keys(audit.checks).map((key) => (
          <Grid item key={key}>
            <Grid container spacing={0.5}>
              <Grid item>{titles[key] ?? key}</Grid>
              <Grid item>{CheckedIcon(audit.checks[key]?.checked)}</Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {last && (
        <p>
          最新註記(第 {audit.remarks.length} 筆)：
          <br />
          {last.date?.toDate()} {last.name} {last.event} {last.remark}
        </p>
      )}
    </Box>
  );
}
function CheckedIcon(check) {
  if (check === true) return <CheckBoxIcon color="success" />;
  if (check === false) return <DisabledByDefaultIcon color="error" />;
  return <CheckBoxOutlineBlankIcon />;
}
