import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Popover,
  TextField,
  Tooltip,
  buttonClasses,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { DataGridPro } from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import React from 'react';
import { queryProductsBySerialNumber } from '../../fetchers/product.fetch';
import { switchItems } from '../../fetchers/order.fetch';
import { toast } from 'react-toastify';
import useAccess from '../../services/authentication/useAccess';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';

const columns = (orderClosed, navigate, onPopover) => [
  {
    field: 'sn',
    headerName: '產品編號',
    flex: 1,
    renderCell: (params) => (
      <Button
        variant="text"
        size="small"
        data-id={params.row._id}
        onClick={
          orderClosed
            ? ['A', 'B', 'C', 'D'].includes(params.row.sn.substring(0, 1)) &&
              !/退還|退費|公司購回/.test(params.row.desc)
              ? onPopover
              : () => navigate(`/product/${params.row._id}`)
            : onPopover
        }>
        {params.value}
      </Button>
    ),
  },
  {
    field: 'price',
    headerName: '定價',
    type: 'number',
    flex: 1,
    editable: !orderClosed,
  },
  {
    field: 'const',
    headerName: '工程款',
    type: 'number',
    flex: 1,
    editable: !orderClosed,
  },
  {
    field: 'additional',
    headerName: '追加款',
    type: 'number',
    flex: 1,
    editable: !orderClosed,
  },
  {
    field: 'strata',
    headerName: '管理費',
    type: 'number',
    flex: 1,
    editable: !orderClosed,
  },
  {
    field: 'discount',
    headerName: '折扣',
    type: 'number',
    flex: 1,
    editable: !orderClosed,
  },
  {
    field: 'certificate',
    headerName: '權狀編號',
    valueGetter: (params) => params.row.certificate.sn,
    valueSetter: (params) => ({
      ...params.row,
      certificate: { ...params.row.certificate, sn: params.value },
    }),
    flex: 1,
    editable: !orderClosed,
  },
  {
    field: 'certificateDate',
    headerName: '發證日期',
    valueGetter: (params) => {
      if (
        params.row.certificate.date === null ||
        params.row.certificate.date === ''
      ) {
        return '';
      }
      return DateTime.fromISO(params.row.certificate.date).toFormat(
        'yyyy-MM-dd'
      );
    },
    valueSetter: (params) => ({
      ...params.row,
      certificate: {
        ...params.row.certificate,
        date: params.value,
      },
    }),
    preProcessEditCellProps: (params) => {
      const date = DateTime.fromISO(params.props.value);
      return { ...params.props, error: !date.isValid };
    },
    flex: 1,
    editable: !orderClosed,
  },
  {
    field: 'desc',
    headerName: '備註',
    flex: 1,
    editable: !orderClosed,
    renderCell: !orderClosed
      ? undefined
      : (params) => getDescComponent(params.value),
  },
];

function getDescComponent(desc) {
  const result = desc?.matchAll(/{.+?}/g) || [];
  desc = desc?.replace(/{.+?}/g, ' ') || '';

  const tooltips = [];
  for (let tip of result) {
    tooltips.push(JSON.parse(tip[0]));
  }

  return (
    <>
      <p>{desc}</p>
      {tooltips.map((tip) => (
        <Tooltip placement={tip.placement || 'top'} title={tip.content || ''}>
          <p
            style={{
              cursor: 'pointer',
              marginLeft: 5,
              color: tip.color || 'blue',
              textDecoration: 'underline',
            }}>
            {tip.type || 'tag'}
          </p>
        </Tooltip>
      ))}
    </>
  );
}

function ItemTable({ id, items, setItems, orderClosed, onSave }) {
  const navigate = useNavigate();
  const hasAccess = useAccess();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [switchDialog, setSwitchDialog] = React.useState({
    open: false,
  });
  const open = Boolean(anchorEl);
  const productQuery = useQuery(
    ['product', 'sn', switchDialog.query],
    queryProductsBySerialNumber({
      sn: switchDialog.query,
    }),
    {
      enabled:
        switchDialog.query &&
        switchDialog.query.length > 0 &&
        switchDialog.query !== switchDialog.origin,
      staleTime: 0,
      onSuccess: (data) => {
        if (data.length !== 1)
          return setSwitchDialog({
            ...switchDialog,
            errors: {
              sn: {
                message:
                  data.length === 0
                    ? '查無產品，請檢查產品編號'
                    : '請檢查產品編號',
              },
            },
          });
        const product = data[0];
        if (product.status !== '空位')
          return setSwitchDialog({
            ...switchDialog,
            errors: {
              sn: {
                message: '產品不是空位',
              },
            },
          });
        setSwitchDialog({ ...switchDialog, product, errors: null });
      },
      onError: (error) => toast.error(error),
    }
  );
  const switchItemsRequest = useMutation(switchItems, {
    onSuccess: () => {
      toast.success('成功換位');
      setSwitchDialog({ open: false });
      onSave();
    },
    onError: (error) => toast.error(error.message),
  });

  function getGridOptions() {
    if (orderClosed) {
      return {};
    }
    return {
      editMode: 'row',
      onEditRowsModelChange: handleEditRowsModelChange,
    };
  }
  function handleEditRowsModelChange(model) {
    for (let sn of Object.keys(model)) {
      const updated = items.map((item) => {
        if (sn === item.sn) {
          return {
            ...item,
            additional: model[sn].additional.value,
            const: model[sn].const.value,
            desc: model[sn].desc.value,
            discount:
              model[sn].discount.value === 0
                ? 0
                : model[sn].discount.value < 1
                ? Math.round(
                    (1 - model[sn].discount.value) * model[sn].price.value
                  )
                : model[sn].discount.value,
            price: model[sn].price.value,
            strata: model[sn].strata.value,
            certificate: {
              ...item.certificate,
              sn: ['A', 'B', 'C'].includes(sn.substring(0, 1))
                ? null
                : model[sn].certificate?.value,
              date: !model[sn].error
                ? model[sn].certificateDate.value
                : item.certificate?.date,
            },
          };
        }
        return item;
      });
      setItems(updated);
    }
  }
  function handleDeleteItem(sn) {
    const updated = items.filter((item) => item.sn !== sn);
    setAnchorEl(null);
    setItems(updated);
  }
  function handleSwitchItemDialog(sn) {
    setAnchorEl(null);
    setSwitchDialog({
      open: true,
      origin: sn,
    });
  }
  function handleSwitchItem() {
    switchItemsRequest.mutate({
      id,
      origin: items.find((i) => i.sn === switchDialog.origin)._id,
      target: switchDialog.product,
      certificate: switchDialog.certificate,
    });
  }
  function handlePopover(event) {
    if (anchorEl) {
      return setAnchorEl(null);
    }
    setAnchorEl(event.currentTarget);
  }

  if (!items || !items) return <p>Loading...</p>;

  return (
    <>
      <Box
        sx={{
          p: 0,
          height: '400px',
          '& .MuiDataGrid-cell--editing': {
            bgcolor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
          '& .Mui-error': {
            bgcolor: (theme) =>
              `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
            color: (theme) =>
              theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
          },
        }}>
        <DataGridPro
          sx={{
            fontSize: 16,
            border: 'none',
            [`& .${buttonClasses.root}`]: { fontSize: 16 },
          }}
          getRowId={(row) => row.sn}
          columns={columns(orderClosed, navigate, handlePopover)}
          isCellEditable={(params) =>
            !orderClosed &&
            (params.field !== 'certificate' ||
              ['D', 'E', 'F'].includes(params.row.sn.substring(0, 1)))
          }
          rows={items}
          {...getGridOptions()}
          hideFooter
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Box sx={{ p: 0.8 }}>
            <Button
              variant="text"
              size="small"
              component={Link}
              to={`/product/${anchorEl?.getAttribute('data-id')}`}>
              產品頁面
            </Button>
            {orderClosed && hasAccess('ZAmpc') && (
              <Button
                variant="text"
                size="small"
                color="error"
                onClick={() => handleSwitchItemDialog(anchorEl.innerText)}>
                換位
              </Button>
            )}
            {!orderClosed && (
              <Button
                variant="text"
                size="small"
                color="error"
                onClick={() => handleDeleteItem(anchorEl.innerText)}>
                刪除
              </Button>
            )}
          </Box>
        </Popover>
      </Box>
      <Dialog
        open={hasAccess('ZAmpc') && switchDialog.open}
        onClose={() => setSwitchDialog({ open: false })}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>訂單換位</DialogTitle>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="原產品編號"
              value={switchDialog.origin || ''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="新產品編號"
              value={switchDialog.sn || ''}
              error={!!switchDialog.errors?.sn}
              helperText={switchDialog.errors?.sn?.message}
              onChange={(e) =>
                setSwitchDialog({
                  ...switchDialog,
                  sn: e.target.value,
                })
              }
              onBlur={() => {
                const duplicate = items.find((i) => i.sn === switchDialog.sn);
                if (duplicate)
                  return setSwitchDialog({
                    ...switchDialog,
                    errors: {
                      sn: {
                        message: '新產品編號和訂單產品編號相同',
                      },
                    },
                  });
                setSwitchDialog((o) => ({
                  ...o,
                  query: o.sn,
                }));
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="權狀編號"
              value={switchDialog.certificate?.sn || ''}
              onChange={(e) =>
                setSwitchDialog({
                  ...switchDialog,
                  certificate: {
                    ...switchDialog.certificate,
                    sn: e.target.value,
                  },
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="發證日期"
              inputFormat="yyyy-MM-dd"
              value={switchDialog.certificate?.date || ''}
              onChange={(val) =>
                setSwitchDialog({
                  ...switchDialog,
                  certificate: {
                    ...switchDialog.certificate,
                    date: val.startOf('day'),
                  },
                })
              }
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  error={switchDialog.errors?.date}
                  helperText={switchDialog.errors?.date?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => setSwitchDialog({ open: false })}>
              取消
            </Button>
            {!!switchDialog.product &&
              !!switchDialog.certificate?.sn &&
              !!switchDialog.certificate?.date &&
              !switchDialog.errors && (
                <Button
                  variant="contained"
                  size="large"
                  sx={{ ml: 2 }}
                  onClick={handleSwitchItem}>
                  確定
                </Button>
              )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default ItemTable;
