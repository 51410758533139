import { Box, Button } from '@mui/material';
import DeceasedInfoPanel, {
  deceasedInfoPanelReducer,
} from '../../components/DeceasedInfoPanel';

import { DateTime } from 'luxon';
import ErrorIcon from '@mui/icons-material/Error';
import { Link, useSearchParams } from 'react-router-dom';
import { ListPage } from '../../components/ListPage';
import React from 'react';
import Spinner from '../../components/Spinner';
import crumbs from '../../services/crumbs/crumbs.const';
import { queryTemps } from '../../fetchers/deceased.fetch';
import { red } from '@mui/material/colors';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useQuery from '../../services/httpClient/useQuery';
import { useQueryClient } from 'react-query';
import getSearchParams from '../../utils/getSearchParams';

const columns = (setDeceased) => [
  {
    field: 'temp',
    headerName: '暫厝編號',
    flex: 1,
    valueGetter: (params) => params.row.temp.sn,
  },
  {
    field: 'status',
    headerName: '狀態',
    flex: 1,
    renderCell: (params) =>
      params.row.temp.status === '逾期' ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ErrorIcon sx={{ mr: 0.5, mb: 0.2, fontSize: 20 }} />
          {params.row.temp.status}
        </Box>
      ) : (
        params.row.temp.status
      ),
  },
  {
    field: 'name',
    headerName: '歿者姓名',
    flex: 1,
    renderCell: (param) => {
      return (
        <Button onClick={() => setDeceased(param.row)}>{param.row.name}</Button>
      );
    },
  },
  {
    field: 'sn',
    headerName: '產品編號',
    flex: 1,
    renderCell: (param) => {
      return (
        <Link to={`/product/${param.row.product._id}`}>
          {param.row.product.sn}
        </Link>
      );
    },
  },
  {
    field: 'date',
    headerName: '暫厝日期',
    flex: 1,
    valueGetter: (params) => handleDate(params.row.temp.date),
  },
  {
    field: 'paidUntil',
    headerName: '付費至',
    flex: 1,
    valueGetter: (params) => handleDate(params.row.temp.paidUntil),
  },
  {
    field: 'moveout',
    headerName: '遷離暫厝',
    flex: 1,
    valueGetter: (params) => handleDate(params.row.temp.moveout),
  },
];
function handleDate(date) {
  if (date) {
    return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
  }
  return '';
}

function TempList(props) {
  useCrumbs([crumbs.tempList]);
  const [params, setParams] = useSearchParams();
  const queryClient = useQueryClient();
  const deceasedDialog = React.useReducer(deceasedInfoPanelReducer, {
    deceased: null,
    refresh: Date.now(),
  });
  const deceasedsQuery = useQuery(['temps'], queryTemps, {
    onSuccess: (data) => {
      const processed = data.map((deceased) => {
        deceased.temp = deceased.temps[deceased.temps.length - 1];
        deceased.temp.date = DateTime.fromISO(deceased.temp.date);
        deceased.temp.paidUntil = DateTime.fromISO(deceased.temp.paidUntil);
        if (deceased.temp.moveout)
          deceased.temp.moveout = DateTime.fromISO(deceased.temp.moveout);
        deceased.temp.status = deceased.temp.moveout
          ? '遷離'
          : deceased.temp.paidUntil < DateTime.now()
          ? '逾期'
          : '正常';
        return deceased;
      });
      queryClient.setQueryData(['temps'], processed);
    },
    staleTime: 1000 * 30,
  });

  if (deceasedsQuery.isLoading) return <Spinner />;
  return (
    <>
      <ListPage
        sx={{
          '& .expired': {
            bgcolor: red[50],
            color: red[500],
          },
        }}
        striped={false}
        selection={false}
        columns={columns((payload) =>
          deceasedDialog[1]({ type: 'open', payload })
        )}
        rows={deceasedsQuery.data}
        getRowClassName={(params) =>
          params.row.temp.status === '逾期' ? 'expired' : ''
        }
        page={Number(params.get('page'))}
        onPageChange={(page) => setParams({ ...getSearchParams(params), page })}
        pageSize={Number(params.get('page-size'))}
        onPageSizeChange={(size) =>
          setParams({ ...getSearchParams(params), 'page-size': size })
        }
      />
      <DeceasedInfoPanel
        control={deceasedDialog}
        onSave={() => deceasedsQuery.refetch()}
      />
    </>
  );
}

export default TempList;
