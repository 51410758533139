import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from '@mui/material';
import { createItem, queryItemsByTag } from '../../../fetchers/item.fetch';

import ArchiveIcon from '@mui/icons-material/Archive';
import { Box } from '@mui/system';
import { ItemDialog } from './ItemDialog';
import React from 'react';
import { blue } from '@mui/material/colors';
import { toast } from 'react-toastify';
import useMutation from '../../../services/httpClient/useMutation';
import useQuery from '../../../services/httpClient/useQuery';

function ItemTable({ state, setState, tag }) {
  const itemsQuery = useQuery(['items', 'tag', tag], queryItemsByTag({ tag }), {
    onSuccess: (data) => setItems(data),
  });
  const [items, setItems] = React.useState(itemsQuery.data);
  if (itemsQuery.isLoading) return <p>Loading...</p>;
  if (itemsQuery.isError) return <p>{itemsQuery.error.toString()}</p>;

  function handleSave(response) {
    itemsQuery.refetch();
    setState({ ...state, item: response, mode: '' });
  }

  return (
    <>
      <TableContainer sx={{ height: 280 }}>
        <Table
          stickyHeader
          sx={{
            [`.${tableCellClasses.head}`]: {
              fontSize: 15,
              fontWeight: 'bold',
              borderBottom: '2px solid #C0C0C0',
            },
            [`.${tableCellClasses.body}`]: {
              fontSize: 15,
            },
          }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>品項</TableCell>
              <TableCell>規格</TableCell>
              <TableCell>單價</TableCell>
              <TableCell>庫存</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              span: {
                color: blue[700],
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
              },
            }}>
            {items.map((item, index) => (
              <TableRow
                key={item._id}
                onDoubleClick={() =>
                  setState({ ...state, mode: 'view', item })
                }>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <span
                    onClick={() =>
                      setState({ ...state, mode: 'view', item: item })
                    }>
                    <Grid container alignItems="center">
                      <Grid item>{item.name}</Grid>
                      <Grid item>
                        {item.stocked && (
                          <ArchiveIcon sx={{ pt: 0.3 }} color="disabled" />
                        )}
                      </Grid>
                    </Grid>
                  </span>
                </TableCell>
                <TableCell>{item.spec || '-'}</TableCell>
                <TableCell>
                  {item.prices.length > 0
                    ? item.prices[item.prices.length - 1].price.toLocaleString()
                    : '-'}
                </TableCell>
                <TableCell>{item.stock}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {state.mode === 'insert' && (
        <AddItemDialog
          tag={tag}
          onAdd={handleSave}
          onCancel={() => setState({ ...state, mode: '' })}
        />
      )}
      {state.mode === 'view' && (
        <ItemDialog
          item={state.item}
          onSave={handleSave}
          onClose={() => setState({ ...state, mode: '' })}
        />
      )}
    </>
  );
}

function AddItemDialog({ tag, onAdd, onCancel }) {
  const [insert, setInsert] = React.useState({
    name: '',
    spec: '',
    unit: '',
    desc: '',
    vendor: tag,
  });
  const [error, setError] = React.useState({});
  const itemCreateRequest = useMutation(createItem, {
    onSuccess: () => {
      toast.success('成功新增品項');
      onAdd();
    },
  });

  function handleClose() {
    onCancel();
  }
  function handleSave() {
    const err = {};
    if (insert.name.trim().length === 0) err.name = { message: '請輸入品名' };
    if (insert.unit.trim().length === 0) err.unit = { message: '請輸入單位' };
    if (Object.keys(err).length > 0) return setError(err);
    itemCreateRequest.mutate(insert);
  }

  return (
    <>
      <Dialog open={true} onClose={onCancel} maxWidth="sm" fullWidth>
        <DialogTitle>新增品項</DialogTitle>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                label="品名"
                value={insert.name}
                onChange={(event) =>
                  setInsert({ ...insert, name: event.target.value })
                }
                error={!!error.name}
                helperText={error.name?.message}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                label="單位"
                value={insert.unit}
                onChange={(event) =>
                  setInsert({ ...insert, unit: event.target.value })
                }
                error={!!error.unit}
                helperText={error.unit?.message}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                label="規格"
                value={insert.spec}
                onChange={(event) =>
                  setInsert({ ...insert, spec: event.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                label="備註"
                value={insert.desc}
                onChange={(event) =>
                  setInsert({ ...insert, desc: event.target.value })
                }
                fullWidth
              />
            </Grid>
          </Grid>
          {error.api && (
            <Alert sx={{ mt: 2 }} severity="error">
              {error.api.message}
            </Alert>
          )}
          <div style={{ textAlign: 'right', marginTop: 20 }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mr: 2 }}
              onClick={handleClose}>
              取消
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              新增
            </Button>
          </div>
        </Box>
      </Dialog>
    </>
  );
}

export function EditAudit() {}

export default ItemTable;
