const url = '/api/user';

export const queryUsers = (states) => (client) => () => {
  return client.get(`${url}?keyword=${states.keyword}`);
};

export const queryUserInteractions = (client) => () => {
  return client.get(`${url}?interactions=true`);
};

export const queryUser = (states) => (client) => () => {
  return client.get(`${url}/${states.id}`);
};

export const updateUser = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states);
};

export const createUser = (client) => (states) => {
  return client.post(`${url}`, states);
};
