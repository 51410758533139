import { addressMask, mobileMask, phoneMask, phoneMobileMask } from './imask';
import { mixed, number, string } from 'yup';

import { DateTime } from 'luxon';

export function getErrors(errors) {
  if (!errors.inner) return {};
  const result = {};
  for (let error of errors.inner) {
    result[error.path] = { message: error.errors[0] };
  }
  return result;
}

export const ssn = string()
  .nullable()
  .test('ssn', '請檢查身分證', (val) => !val || verifySsn(val));
export const postal = string()
  .transform(addressMask.transform)
  .matches(/^(\d{3}|\d{5}|\d{6}).*/, {
    message: '請輸入郵遞區號',
    excludeEmptyString: true,
  });
export const phone = string()
  .transform(phoneMask.transform)
  .matches(
    /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/,
    { message: '請檢查電話號碼', excludeEmptyString: true }
  );
export const mobile = string()
  .transform(mobileMask.transform)
  .matches(/^09\d{8}$/, {
    message: '請檢查手機號碼',
    excludeEmptyString: true,
  });
export const phoneMobile = string()
  .transform(phoneMobileMask.transform)
  .matches(
    /^(09\d{8})|(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})$/,
    { message: '請檢查電話號碼', excludeEmptyString: true }
  );
export const date = mixed().test('date', '請檢查日期', (value) => {
  if (value === null) return true;
  return value instanceof DateTime
    ? value.isValid
    : DateTime.fromISO(value).isValid;
});
export const paymentType = string()
  .oneOf(
    ['現金', '匯款', '信用卡', '票據', '超商代碼', '虛擬帳號', '劃撥'],
    '無效付費方式'
  )
  .required('請輸入付費方式');
export const amount = number()
  .transform((value) => {
    if (!value) return 0;
    return value;
  })
  .positive('金額必須為正數')
  .integer('金額不可以有小數')
  .moreThan(0, '請輸入金額');
export const interactionType = string()
  .oneOf(['電話', '外訪', '仲介', '結案', '帶看', '簽約'], '無效類別')
  .required('請輸入類別');
export const source = string()
  .oneOf(['親友介紹', '舊客戶', '網路', '禮儀公司', '自來', '其他'], '無效來源')
  .required('請輸入來源');

function verifySsn(id) {
  id = id.trim();

  if (id.length === 8) {
    if (!/^\d{8}$/.test(id)) return false;
    return verifyTax(id);
  }

  const verification = id.match('^[A-Z][12]\\d{8}$');
  if (!verification) return false;

  let conver = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
  let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

  id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

  let checkSum = 0;
  for (let i = 0; i < id.length; i++) {
    const c = parseInt(id[i]);
    const w = weights[i];
    checkSum += c * w;
  }

  return checkSum % 10 === 0;
}
function verifyTax(tax) {
  const cx = [1, 2, 1, 2, 1, 2, 4, 1];
  const cnum = tax.split('');
  let sum = 0;
  function cc(num) {
    let total = num;
    if (total > 9) {
      let s = total.toString();
      const n1 = s.substring(0, 1) * 1;
      const n2 = s.substring(1, 2) * 1;
      total = n1 + n2;
    }
    return total;
  }
  cnum.forEach((item, index) => {
    if (tax.charCodeAt() < 48 || tax.charCodeAt() > 57) {
      return false;
    }
    sum += cc(item * cx[index]);
  });
  if (sum % 10 === 0) {
    return true;
  } else if (cnum[6] === '7' && (sum + 1) % 10 === 0) {
    return true;
  }
  return false;
}
