import { Box, paperClasses, typographyClasses } from '@mui/material';

import React from 'react';

export function Container({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.appBackground,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: [0, 2],
        [`& > .${paperClasses.root}`]: {
          borderRadius: (theme) => theme.shape.borderRadius / 2,
          p: 6,
          width: '100%',
          height: {
            xs: '100%',
            sm: 550,
          },
          maxWidth: {
            sm: 450,
          },
        },
        [`.${typographyClasses.h4}`]: {
          color: (theme) => theme.palette.primary.main,
          mt: 2,
          mb: 7,
          fontWeight: 'bold',
        },
      }}>
      {children}
    </Box>
  );
}
