import { Dialog, DialogTitle, Grid } from '@mui/material';
import { blue, orange } from '@mui/material/colors';
import { Box } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { updateItem } from '../../../fetchers/item.fetch';
import useMutation from '../../../services/httpClient/useMutation';
import { toast } from 'react-toastify';
import Panel from '../../../components/Panel';
import Detail from '../../../components/Detail';
import { EditQuote } from './EditQuote';
import { EditConversions } from './EditConversions';
import { EditInfo } from './EditInfo';
import { EditAudit } from './index';

export function ItemDialog({ item, onClose, onSave }) {
  const [mode, setMode] = React.useState('view');
  const itemUpdateRequest = useMutation(updateItem, {
    onSuccess: (data) => {
      toast.success('成功儲存品項');
      setMode('view');
      onSave(data);
    },
  });

  const handleClose = React.useCallback(() => {
    setMode('view');
  }, []);

  async function handleSave(payload) {
    return itemUpdateRequest
      .mutateAsync({ id: item._id, ...payload })
      .then((res) => true)
      .catch((err) => err);
  }

  if (mode === 'editInfo')
    return <EditInfo item={item} onClose={handleClose} onSave={handleSave} />;
  if (mode === 'editConversions')
    return (
      <EditConversions item={item} onClose={handleClose} onSave={handleSave} />
    );
  if (mode === 'editAudit') return <EditAudit />;
  if (mode === 'editQuote')
    return <EditQuote item={item} onClose={handleClose} onSave={handleSave} />;

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>品項資料</DialogTitle>
      <Box sx={{ m: 3, mt: 1 }}>
        <Panel
          style={{ marginBottom: 20 }}
          icon={<EditIcon />}
          onIconClick={() => setMode('editInfo')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Detail title="品名" value={item.name} />
              <Detail title="單位" value={item.unit} />
              <Detail title="規格" value={item.spec} />
              <Detail title="備註" value={item.desc} />
              <Detail title="安全庫存" value={item.min} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Detail
                title="庫存"
                value={
                  item.prices.length === 0
                    ? '-'
                    : item.prices[item.prices.length - 1].price
                }
              />
              <Detail title="最新單價" value={item.price ?? '-'} />
              <Detail
                title="是否入庫"
                value={
                  item.stocked ? (
                    <CheckIcon color="success" sx={{ fontSize: '17px' }} />
                  ) : (
                    <CloseIcon color="error" sx={{ fontSize: '17px' }} />
                  )
                }
              />
            </Grid>
          </Grid>
        </Panel>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Panel
              title="單位換算"
              icon={<EditIcon />}
              onIconClick={() => setMode('editConversions')}>
              {Object.keys(item.conversions).map((unit) => {
                const ratio = item.conversions[unit].ratio;
                const baseUnit = item.conversions[unit].unit;
                return (
                  <div>
                    <span style={{ color: blue[500] }}>1 {unit}</span>
                    <span style={{ margin: '0 10px' }}>→</span>
                    <span style={{ color: orange[700] }}>
                      {ratio} {baseUnit}
                    </span>
                  </div>
                );
              })}
            </Panel>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
              <Panel
                title="審核條件"
                icon={<EditIcon />}
                onIconClick={() => alert('Edit Clicked')}></Panel>
            </Grid> */}
          <Grid item xs={12} sm={6}>
            <Panel
              title="報價單"
              icon={<EditIcon />}
              onIconClick={() => setMode('editQuote')}>
              {item.quote && item.quote.label && (
                <>
                  <Detail title="品項" value={item.quote.name} />
                  <Detail title="標籤" value={item.quote.label} />
                  <Detail
                    title="價格"
                    value={item.quote.price.toLocaleString()}
                  />
                  <Detail title="備註" value={item.quote.desc} />
                </>
              )}
            </Panel>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
