import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { getErrors, postal, ssn } from '../../validations/yupSchemas';
import { object, string } from 'yup';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';
import getPostalCode from '../../utils/getPostalCode';
import { ssnMask } from '../../validations/imask';
import { toast } from 'react-toastify';

export function IdentificationUploadDialog({ state, setState, onSave }) {
  const [errors, setErrors] = React.useState({});
  React.useEffect(() => {
    if (!state.file) return;
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      function () {
        setState({ ...state, preview: reader.result });
      },
      false
    );
    reader.readAsDataURL(state.file);
  }, [state.file]);

  function handleSave() {
    if (!state.file) {
      return setErrors({ file: { message: '請上傳身分證' } });
    }
    schema
      .validate(state, {
        abortEarly: false,
      })
      .then(() => {
        setErrors({});
        onSave();
      })
      .catch((err) => {
        setErrors(getErrors(err));
        return false;
      });
  }
  function renderImage() {
    if (!state.preview) return null;
    return (
      <Grid item xs={12}>
        <img src={state.preview} width="100%" alt="uploaded" />
      </Grid>
    );
  }
  return (
    <Dialog open={state.open} onClose={() => setState({ open: false })}>
      <DialogTitle>更新身分證</DialogTitle>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="姓名"
            value={state.name || ''}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            error={!!errors.name}
            helperText={errors.name?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="身分證字號"
            value={state.ssn || ''}
            onChange={(e) => {
              ssnMask.onChange(e);
              setState({ ...state, ssn: e.target.value.toUpperCase() });
            }}
            error={!!errors.ssn}
            helperText={errors.ssn?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="戶籍地址"
            value={state.address || ''}
            onChange={(e) => setState({ ...state, address: e.target.value })}
            error={!!errors.address}
            helperText={errors.address?.message}
            onBlur={async (e) => {
              let address = e.target.value;
              if (
                address.length > 5 &&
                !/\d{3}/.test(address.substring(0, 5))
              ) {
                try {
                  let postal = await getPostalCode(address);
                  if (postal) {
                    setState((prev) => ({
                      ...prev,
                      address: `${postal} ${address}`,
                    }));
                  }
                } catch (error) {
                  toast.error(`查詢郵遞區號時發生錯誤：${error}`, {
                    autoClose: false,
                  });
                }
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color={!!state.file ? 'success' : 'primary'}
            onClick={() => document.getElementById('file-input').click()}>
            {!!state.file ? <CheckCircleIcon color="success" /> : null}
            上傳身分證
          </Button>
          <input
            id="file-input"
            accept="image/*,application/pdf"
            type="file"
            onChange={(event) =>
              setState({ ...state, file: event.target.files[0] })
            }
            hidden
          />
        </Grid>
        {renderImage()}
        <Grid item xs={12}>
          {errors.file && <Alert severity="error">{errors.file.message}</Alert>}
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setState({ open: false })}
            sx={{ mr: 2 }}>
            取消
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            儲存
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
const schema = object({
  name: string()
    .transform((value) => value.trim().replace(' ', ''))
    .required('請輸入姓名'),
  address: postal.required('請輸入戶籍地址'),
  ssn: ssn.required('請輸入身分證字號'),
});
