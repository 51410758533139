import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';
import { StyledTableCell } from './TempRecords';
import PrintableDialog from '../PrintableDialog';
import TempForm from '../TempForm';

export function TempTable({ deceased, setMode, setRecord }) {
  const [form, setForm] = React.useState(false);
  const records = deceased?.temps || [];

  return (
    <TableContainer
      sx={{
        '& .MuiTableContainer-root::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>暫厝日期</StyledTableCell>
            <StyledTableCell>暫厝編號</StyledTableCell>
            <StyledTableCell>狀態</StyledTableCell>
            <StyledTableCell>類別</StyledTableCell>
            <StyledTableCell hidden="md">暫厝費至</StyledTableCell>
            <StyledTableCell hidden="md">遷離暫厝</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records
            .slice()
            .reverse()
            .map((record, index) => (
              <TableRow
                key={record._id}
                onDoubleClick={() => {
                  setRecord(record);
                  setMode('edit');
                }}>
                <StyledTableCell>
                  {
                    <Button
                      onClick={() => {
                        setRecord(record);
                        setMode('edit');
                      }}>
                      {DateTime.fromISO(record.date).toFormat('yyyy-MM-dd')}
                    </Button>
                  }
                </StyledTableCell>
                <StyledTableCell>{record.sn}</StyledTableCell>
                <StyledTableCell
                  status={
                    record.moveout
                      ? '已遷離'
                      : DateTime.fromISO(record.paidUntil) < DateTime.now()
                      ? '逾期'
                      : '正常'
                  }>
                  {record.moveout ? (
                    '已遷離'
                  ) : DateTime.fromISO(record.paidUntil) < DateTime.now() ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ErrorIcon color="error" sx={{ mb: 0.4 }} />
                      逾期
                    </Box>
                  ) : (
                    '正常'
                  )}
                </StyledTableCell>
                <StyledTableCell>{record.tempType}</StyledTableCell>
                <StyledTableCell hidden="md">
                  {record.paidUntil &&
                    DateTime.fromISO(record.paidUntil).toFormat('yyyy-MM-dd')}
                </StyledTableCell>
                <StyledTableCell hidden="md">
                  {record.moveout ? (
                    DateTime.fromISO(record.moveout).toFormat('yyyy-MM-dd')
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="info"
                      onClick={() => setForm(true)}>
                      列印切結書
                    </Button>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={6}>
              {
                <Button
                  onClick={() => {
                    setMode('edit');
                    setRecord();
                  }}>
                  <AddIcon sx={{ pb: 0.4 }} /> 新增紀錄
                </Button>
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {deceased && (
        <PrintableDialog
          open={form}
          onClose={() => setForm(false)}
          maxWidth="lg"
          fullWidth>
          <TempForm deceased={deceased} />
        </PrintableDialog>
      )}
    </TableContainer>
  );
}
