import { DateTime } from 'luxon';
import { InputBase } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';
import { mobileMask } from '../../validations/imask';
import { queryProductById } from '../../fetchers/product.fetch';
import styles from './style.module.scss';
import useQuery from '../../services/httpClient/useQuery';
import useUser from '../../services/authentication/useUser';

const initialState = {
  in: [
    {
      text: '檢附使用權狀',
      temp: false,
      move: false,
      memo: '',
    },
    {
      text: '檢附火化/埋葬許可/切結書',
      temp: false,
      move: false,
      memo: '',
    },
    {
      text: '檢附死亡證明/除戶謄本',
      temp: false,
      move: false,
      memo: '',
    },
    {
      text: '檢附權益人身分證影本',
      temp: false,
      move: false,
      memo: '',
    },
    {
      text: '收取超時費/暫厝費/誦經費/其他費用',
      temp: false,
      move: false,
      memo: '',
    },
  ],
  out: [
    {
      text: '檢附使用權狀',
      move: false,
      memo: '',
    },
    {
      text: '檢附切結書',
      move: false,
      memo: '',
    },
    {
      text: '檢附權益人身分證影本',
      move: false,
      memo: '',
    },
    {
      text: '收取超時費/誦經費/其他費用',
      move: false,
      memo: '',
    },
  ],
};

function dataURItoBlob(dataURI, name) {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([ab], `${name}.png`, { type: 'image/png' });
}

function EEForm({ deceased, mode }) {
  const user = useUser();
  const [checklist, setChecklist] = React.useState(initialState[mode]);
  const form = React.useRef(null);
  const productQuery = useQuery(
    ['product', deceased.product._id],
    queryProductById({
      id: deceased.product._id,
      deceaseds: true,
      contacts: true,
    })
  );

  function handleCheck(text, field) {
    return () => {
      const updated = checklist.map((item) => {
        if (item.text === text) {
          return { ...item, [field]: !item[field] };
        }
        return item;
      });
      setChecklist(updated);
    };
  }
  function handleMemo(text) {
    return (event) => {
      const updated = checklist.map((item) => {
        if (item.text === text) {
          return { ...item, memo: event.target.value };
        }
        return item;
      });
      setChecklist(updated);
    };
  }

  if (productQuery.isLoading) return <p>Loading...</p>;
  if (productQuery.isError) return <p>{productQuery.error.toString()}</p>;
  const product = productQuery.data;
  const temp = deceased.temps.filter((t) => !t.moveout).last();

  return (
    <div ref={form} className={styles.form}>
      <div className={styles.header}>
        <div className={styles.title}>
          頂福陵園
          <span>{mode === 'in' ? '使用許可憑證' : '遷出許可憑證'}</span>
        </div>
        <div className={styles.date}>
          {DateTime.now().setZone('Asia/Taipei').toFormat('yyyy-MM-dd')}
        </div>
      </div>
      <hr />
      <div className={styles.info}>
        <div>
          <Detail title="產品編號" value={product.sn} />
          <Detail
            title="位置"
            value={product.area === '頂福陵園' ? product.location : null}
          />
          <Detail title="管理費" value={product.strata.status} />
        </div>
        <div>
          <div>
            <Detail title="使用類別" value={deceased.deceasedType} />
            {deceased.movein && (
              <Detail title="入園日期" value={deceased.movein?.toDate()} />
            )}
            {deceased.moveout && (
              <Detail title="遷出日期" value={deceased.moveout?.toDate()} />
            )}
          </div>
          <div>
            <Detail title="暫厝日期" value={temp?.date.toDate()} />
            <Detail title="暫厝編號" value={temp?.sn} />
            <Detail title="預繳至" value={temp?.paidUntil.toDate()} />
          </div>
        </div>
      </div>
      <div className={styles.tables}>
        <div>
          <table>
            <thead>
              <tr>
                <th colSpan={3}>權益人資料</th>
              </tr>
              <tr>
                <th>權益人</th>
                <th>身分證字號</th>
                <th>聯絡電話</th>
              </tr>
            </thead>
            <tbody>
              {product.contacts
                .filter((c) => c.contactType === '權益人')
                .map((contact, index) => (
                  <tr
                    key={contact._id}
                    style={{ display: index > 2 ? 'none' : null }}>
                    <td>{contact.name}</td>
                    <td>{contact.ssn}</td>
                    <td className={styles.phone}>
                      {mobileMask.mask(contact.mobile || '')}
                    </td>
                  </tr>
                ))}
              {product.contacts.filter((c) => c.contactType === '權益人')
                .length > 3 && (
                <tr>
                  <td className={styles.others} colSpan={3}>
                    {product.contacts
                      .filter((c) => c.contactType === '權益人')
                      .slice(3)
                      .map((c) => `${c.name}${c.ssn ? `(${c.ssn})` : ''}`)
                      .join('、')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th colSpan={5}>歿者資料</th>
              </tr>
              <tr>
                <th>殁者姓名</th>
                <th>性別</th>
                <th>身分證字號</th>
                <th>出生日期</th>
                <th>往生日期</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{deceased.name}</td>
                <td>{deceased.sex === 'm' ? '男' : '女'}</td>
                <td>{deceased.ssn}</td>
                <td>{deceased.birth?.toDate()}</td>
                <td>{deceased.death?.toDate()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {temp && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            fontSize: 14,
          }}>
          <div style={{ width: '70%', textAlign: 'justify' }}>
            <div>暫厝切結書</div>
            立書人
            {product.contacts
              .filter((c) => c.contactType === '權益人')
              .map((c) => c.name)
              .join('、')}
            於{DateTime.fromISO(temp.date).toFormat('yyyy年MM月dd日')}將先人
            {deceased.name}之{temp.tempType}安置於暫厝間(編號：
            {temp.sn})，保證於
            {DateTime.fromISO(temp.paidUntil).toFormat('yyyy年MM月dd日')}
            前將{temp.tempType}
            遷離暫厝間。於暫厝期間內立書人同意遵守頂福陵園管理辦法之相關規定，並依照頂福陵園暫厝收費標準付費，如有逾期未辦理延期之情事，立書人同意按月支付相關費用(不足一個月以一個月計算)且同意由頂福事業股份有限公司(下稱頂福公司)全權處理骨灰，絕無異議。立書人保證擺放於暫厝間之物品絕非法律規定之危險物品、違禁品、或貴重物品，頂福公司不負保管責任。
          </div>
          <div
            style={{
              position: 'relative',
              width: '30%',
              margin: '0 0 0 10px',
            }}>
            <div
              style={{
                position: 'absolute',
                border: '1px solid black',
                width: '100%',
                height: 80,
                bottom: 0,
                fontSize: 12,
              }}>
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  top: -10.5,
                  left: 3.5,
                  fontSize: 14,
                }}>
                立書人簽章
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: -1,
                  right: 110,
                }}>
                年
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: -1,
                  right: 60,
                }}>
                月
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: -1,
                  right: 10,
                }}>
                日
              </div>
            </div>
          </div>
        </div>
      )}
      <table className={styles.checklist}>
        <thead>
          <tr>
            <th>手續</th>
            {mode === 'in' && <th>暫厝</th>}
            <th>{mode === 'in' ? '遷入' : '遷出'}</th>
            <th>備註</th>
          </tr>
        </thead>
        <tbody>
          {checklist.map((item) => (
            <tr key={item.text}>
              <td>{item.text}</td>
              {mode === 'in' && (
                <td onClick={handleCheck(item.text, 'temp')}>
                  {item.temp ? 'V' : ''}
                </td>
              )}
              <td onClick={handleCheck(item.text, 'move')}>
                {item.move ? 'V' : ''}
              </td>
              <td>
                {item.text === '檢附使用權狀' && (
                  <span>
                    權狀編號： {product.certificate?.sn ?? '無權狀資料'}
                  </span>
                )}
                <InputBase
                  multiline
                  value={item.memo}
                  onChange={handleMemo(item.text)}
                  margin="none"
                  sx={{ padding: 0, fontSize: 14 }}
                  fullWidth
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.footer}>
        <div
          className={styles.stamps}
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            核發: {user.name}
            <div>{DateTime.now().toFormat('yyyy-MM-dd')}</div>
          </div>
          <div>暫厝</div>
          <div>遷出暫厝</div>
          <div>入園</div>
        </div>
        <div className={styles.detailsBlock}>
          <div>頂福陵園</div>
          <div className={styles.details}>
            <div className={styles.detail}>
              <div>
                <LocationOnIcon sx={{ fontSize: '10px' }} />
                <span>244 新北市林口區大水湳路89號</span>
              </div>
            </div>
            <div className={styles.detail}>
              <div>
                <LocalPhoneIcon sx={{ fontSize: '10px' }} />
                <span>(02) 2609-2988</span>
              </div>
            </div>
            <div className={styles.detail}>
              <div>
                <LanguageIcon sx={{ fontSize: '10px' }} />
                <span>www.dinfu.com.tw</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EEForm;

function Detail({ title, value }) {
  if (!value) return null;
  return (
    <div className={styles.detail}>
      <div>{title}</div>
      <div>{value}</div>
    </div>
  );
}
