import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Link } from 'react-router-dom';
import React from 'react';
import { blue } from '@mui/material/colors';
import { createVisit } from '../fetchers/visit.fetch';
import { queryProductsByCustomerId } from '../fetchers/product.fetch';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material';
import { toast } from 'react-toastify';
import useMutation from '../services/httpClient/useMutation';
import useQuery from '../services/httpClient/useQuery';

const StyledTableCell = styled(TableCell)(({ theme, highlight, hidden }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    borderBottom: '2px solid #C0C0C0',
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: highlight ? blue[200] : 'inherit',
    fontSize: 15,
  },
  [theme.breakpoints.down(hidden ?? 'xs')]: {
    display: 'none',
  },
  [`&.${tableCellClasses.body} a`]: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function ProductTable({ customerId, name, onVisit }) {
  const productsQuery = useQuery(
    ['products', 'customer', customerId],
    queryProductsByCustomerId({ id: customerId })
  );
  const visitRequest = useMutation(createVisit, {
    onSuccess: () => toast.success('成功建立開塔紀錄'),
  });

  function handleVisit(product) {
    const payload = {
      customer: {
        _id: customerId,
        name,
      },
      visitType: 'p',
      products: [product],
    };
    visitRequest.mutate(payload);
  }

  if (productsQuery.isLoading) return <p>Loading...</p>;
  return (
    <>
      <TableContainer
        sx={{
          height: 280,
          '& .MuiTableContainer-root::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>產品編號</StyledTableCell>
              <StyledTableCell hidden="md">區域</StyledTableCell>
              <StyledTableCell hidden="md">類別</StyledTableCell>
              <StyledTableCell hidden="md">管理費</StyledTableCell>
              <StyledTableCell hidden="sm">歿者</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsQuery.data.map((product, index) => {
              return (
                <TableRow key={product._id}>
                  <StyledTableCell highlight={product.highlight ? 1 : 0}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell highlight={product.highlight ? 1 : 0}>
                    <Link to={`/product/${product._id}`}>{product.sn}</Link>
                  </StyledTableCell>
                  <StyledTableCell
                    highlight={product.highlight ? 1 : 0}
                    hidden="md">
                    {product.area}
                  </StyledTableCell>
                  <StyledTableCell
                    highlight={product.highlight ? 1 : 0}
                    hidden="md">
                    {product.contactType}
                  </StyledTableCell>
                  <StyledTableCell
                    highlight={product.highlight ? 1 : 0}
                    hidden="md">
                    {product.strata.status}
                  </StyledTableCell>
                  <StyledTableCell
                    highlight={product.highlight ? 1 : 0}
                    hidden="sm">
                    {product.deceaseds.map((d) => {
                      if (d.moveout)
                        return (
                          <span
                            style={{
                              color: 'gray',
                              fontStyle: 'italic',
                              textDecoration: 'line-through',
                              marginRight: 6,
                            }}>
                            {d.name}
                          </span>
                        );
                      else
                        return <span style={{ marginRight: 6 }}>{d.name}</span>;
                    })}
                  </StyledTableCell>
                  <StyledTableCell
                    highlight={product.highlight ? 1 : 0}
                    style={{ textAlign: 'right' }}>
                    {product.productType === '塔位' &&
                      (product.contactType === '權益人' ||
                        product.contactType === '被授權人') && (
                        <Button
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            handleVisit({ _id: product._id, sn: product.sn })
                          }
                          disabled={visitRequest.isLoading}>
                          開塔
                        </Button>
                      )}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: 'right', marginTop: 15 }}>
        <Typography variant="caption">{`共 ${productsQuery.data.length} 筆資料`}</Typography>
      </div>
    </>
  );
}

export default ProductTable;
