import { IconButton, iconButtonClasses } from '@mui/material';

import { Box } from '@mui/system';
import React from 'react';
import { red } from '@mui/material/colors';

function Panel({
  children,
  title,
  onClick,
  padding,
  minHeight,
  error,
  style,
  icon,
  onIconClick,
  sx,
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        border: error ? `1px solid ${red[500]}` : '1px solid #BEBEBE',
        borderRadius: '4px',
        padding: padding ?? '20px',
        minHeight: minHeight ?? '186px',
        position: 'relative',
        height: '100%',
        [`&>.${iconButtonClasses.root}`]: {
          display: 'none',
        },
        [`&:hover>.${iconButtonClasses.root}`]: {
          display: 'block',
        },
        ...sx,
      }}
      style={{
        ...style,
      }}>
      {icon && (
        <IconButton
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={onIconClick}>
          {icon}
        </IconButton>
      )}
      <span
        style={{
          position: 'absolute',
          fontSize: 13,
          top: -9,
          left: 8,
          backgroundColor: 'white',
          padding: '0px 4.5px',
          color: error ? red[500] : 'rgba(0, 0, 0, 0.6)',
        }}>
        {title}
      </span>
      {children}
    </Box>
  );
}

export default Panel;
