import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { getErrors, phoneMobile } from '../../validations/yupSchemas';
import { object, string } from 'yup';
import { sendSms, updateRegistration } from '../../fetchers/event.fetch';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DateTime } from 'luxon';
import LightGallery from 'lightgallery/react';
import React from 'react';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Tabs from '../../components/Tabs';
import { blue } from '@mui/material/colors';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import useMutation from '../../services/httpClient/useMutation';
import useSas from '../../services/authentication/useSas';
import useUser from '../../services/authentication/useUser';

function updateArray(array, index, value) {
  const newArray = [...array];
  newArray[index] = value.trim();
  return newArray;
}
function compareArrays(original, updated) {
  if (original.length !== updated.length) {
    return true;
  }
  for (let i = 0; i < original.length; i++) {
    if (original[i] !== updated[i]) return true;
  }
  return false;
}
const schema = object({
  sn: string().required('請輸入回條序號'),
  name: string().required('請輸入姓名'),
  phone: phoneMobile.required('請輸入電話'),
  event: string().length(5).required('請選擇法會'),
  itemName: string().required('請選擇品項'),
  registry: string().required('請選擇報名方式'),
});
const Transition = function Transition(props) {
  return <Slide direction="left" {...props} />;
};

function EditRegistrationDialog({
  events,
  items,
  registration: original,
  setRegistration: setOriginal,
  onSave,
  refetch,
  backButton,
  BackdropProps,
  TransitionComponent,
  onLoadItem,
}) {
  const [registration, setRegistration] = React.useState();
  const [jump, setJump] = React.useState();
  const [warningDialog, setWarningDialog] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [sms, setSMS] = React.useState(false);
  const user = useUser();
  const sas = useSas();
  const registrationUpdateRequest = useMutation(updateRegistration, {
    onSuccess: () => {
      setWarningDialog(false);
      toast.success('成功儲存報名紀錄');
      refetch();
      if (onSave)
        onSave({
          ...registration,
          deceaseds: registration.deceaseds.filter((d) => d),
          family: registration.family.filter((f) => f),
        });
    },
  });

  React.useEffect(() => {
    if (original) {
      setRegistration(original);
    }
  }, [original]);

  function isArchived() {
    const event = {
      year: Number(registration.event.substring(0, 3)),
      name: registration.event.substring(3),
    };
    const year = DateTime.now().year - 1911;
    const month = DateTime.now().month;
    if (event.year > year) return false;
    if (event.year < year) return true;
    if (event.name === '新春' && month <= 3) return false;
    if (event.name === '清明' && month <= 6) return false;
    if (event.name === '中元' && month <= 10) return false;
    return true;
  }
  function handleClose() {
    setRegistration();
    setOriginal();
  }
  function handleSave({ soleUpdate }) {
    try {
      const regMessages = [];
      const itemMessages = [];
      const tabletMessages = [];
      schema
        .validate(registration, { abortEarly: false })
        .then(() => {
          // prepare data for saving
          const state = { id: original.regId, _id: original._id };

          // check text fields
          if (registration.sn !== original.sn) {
            state.sn = registration.sn;
            regMessages.push(`回條序號:${original.sn}→${registration.sn}`);
          }
          if (registration.name !== original.name) {
            state.name = registration.name;
            regMessages.push(`姓名:${original.name}→${registration.name}`);
          }
          if (registration.phone !== original.phone) {
            state.phone = registration.phone;
            regMessages.push(`電話:${original.phone}→${registration.phone}`);
          }
          if (registration.event !== original.event) {
            state.event = registration.event;
            itemMessages.push(`法會:${original.event}→${registration.event}`);
          }
          if (registration.itemName !== original.itemName) {
            state.itemName = registration.itemName;
            itemMessages.push(
              `品項:${original.itemName}→${registration.itemName}`
            );
          }
          if (registration.registry !== original.registry) {
            state.registry = registration.registry;
            regMessages.push(
              `報名方式:${original.registry}→${registration.registry}`
            );
          }
          if (registration.location !== original.location) {
            state.location = registration.location || null;
            itemMessages.push(
              `位置:${original.location}→${registration.location || 'null'}`
            );
          }
          if (registration.desc !== original.desc) {
            state.desc = registration.desc || null;
            itemMessages.push(`備註:${original.desc}→${registration.desc}`);
          }

          // check array fields
          const deceaseds = registration.deceaseds.filter((d) => d);
          if (compareArrays(original.deceaseds, deceaseds)) {
            state.deceaseds = deceaseds;
            tabletMessages.push(
              `歿者:[${original.deceaseds.join(',')}]→[${deceaseds.join(',')}]`
            );
          }
          const family = registration.family.filter((f) => f);
          if (compareArrays(original.family, family)) {
            state.family = family;
            tabletMessages.push(
              `陽上:[${original.family.join(',')}]→[${family.join(',')}]`
            );
          }

          // process messages
          if (regMessages.length > 0) {
            state.regHistory = `[${DateTime.now().toFormat(
              'yyyy-MM-dd HH:mm'
            )} ${user.name}] ${regMessages.join(',')}`;
          }
          if (itemMessages.length > 0) {
            state.itemHistory = `[${DateTime.now().toFormat(
              'yyyy-MM-dd HH:mm'
            )} ${user.name}] ${itemMessages.join(',')}`;
          }
          if (tabletMessages.length > 0) {
            state.tabletHistory = `[${DateTime.now().toFormat(
              'yyyy-MM-dd HH:mm'
            )} ${user.name}] ${
              soleUpdate ? '單獨修改-' : ''
            }${tabletMessages.join(',')}`;
            if (soleUpdate) state.soleUpdate = true;
          }
          if (state.itemHistory || state.regHistory || state.tabletHistory) {
            return registrationUpdateRequest.mutate(state);
          }
        })
        .catch((err) => {
          console.error('error');
          setErrors(getErrors(err));
        });
    } catch (error) {
      console.error('error', error);
    }
  }
  function handleJump(record) {
    setJump({
      ...record,
      records: registration.records.filter((r) => r._id !== registration._id),
    });
  }
  function handleJumpSave(record) {
    const records = registration.records.map((r) => {
      if (r._id === record._id) return record;
      return r;
    });
    setRegistration({ ...registration, records });
  }

  if (!registration) return null;
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="lg"
      BackdropProps={BackdropProps}
      TransitionComponent={TransitionComponent}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        {backButton && (
          <IconButton onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
        )}
        編輯
      </DialogTitle>
      <Box sx={{ pt: 2, pb: 4, display: 'flex' }}>
        <IconButton
          sx={{ borderRadius: 2, mr: 2 }}
          onClick={() => onLoadItem({ action: 'prev' })}>
          <ChevronLeftIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="回條序號"
              size="small"
              value={registration.sn}
              onChange={(event) =>
                setRegistration({ ...registration, sn: event.target.value })
              }
              error={errors.sn}
              helperText={errors.sn?.message}
              inputProps={{
                readOnly: !!onSave || isArchived(),
              }}
              InputProps={{
                endAdornment: registration?.photo && (
                  <InputAdornment position="end">
                    <IconButton>
                      <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
                        <a
                          style={{ color: 'black' }}
                          href={`https://dinfucemstorage.blob.core.windows.net/file/${registration.photo}.jpg?${sas}`}>
                          <ReceiptLongIcon />
                        </a>
                      </LightGallery>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="姓名"
              size="small"
              value={registration.name}
              onChange={(event) =>
                setRegistration({ ...registration, name: event.target.value })
              }
              error={errors.name}
              helperText={errors.name?.message}
              inputProps={{ readOnly: !!onSave || isArchived() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="電話"
              size="small"
              value={registration.phone}
              onChange={(event) =>
                setRegistration({ ...registration, phone: event.target.value })
              }
              error={errors.phone}
              helperText={errors.phone?.message}
              inputProps={{ readOnly: !!onSave || isArchived() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl size="small" error={!!errors.event} fullWidth>
              <InputLabel>法會</InputLabel>
              <Select
                label="法會"
                value={registration.event}
                onChange={(event) =>
                  setRegistration({
                    ...registration,
                    event: event.target.value,
                  })
                }
                inputProps={{ readOnly: isArchived() }}>
                {events.map((event) => (
                  <MenuItem key={event} value={event}>
                    {event}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.event?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl size="small" error={!!errors.itemName} fullWidth>
              <InputLabel>品項</InputLabel>
              <Select
                label="品項"
                value={registration.itemName}
                onChange={(event) =>
                  setRegistration({
                    ...registration,
                    itemName: event.target.value,
                  })
                }
                inputProps={{ readOnly: isArchived() }}>
                {items.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.itemName?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl size="small" error={!!errors.registry} fullWidth>
              <InputLabel>報名方式</InputLabel>
              <Select
                label="報名方式"
                value={registration.registry}
                onChange={(event) =>
                  setRegistration({
                    ...registration,
                    registry: event.target.value,
                  })
                }
                inputProps={{ readOnly: !!onSave || isArchived() }}>
                <MenuItem value="台北">台北</MenuItem>
                <MenuItem value="林口">林口</MenuItem>
                <MenuItem value="劃撥">劃撥</MenuItem>
                <MenuItem value="網路">網路</MenuItem>
                <MenuItem value="網路">預繳</MenuItem>
              </Select>
              <FormHelperText>{errors.registry?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="位置"
              size="small"
              value={registration.location || ''}
              onChange={(event) =>
                setRegistration({
                  ...registration,
                  location: event.target.value,
                })
              }
              inputProps={{ readOnly: isArchived() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              label="備註"
              size="small"
              value={registration.desc || ''}
              onChange={(event) =>
                setRegistration({ ...registration, desc: event.target.value })
              }
              inputProps={{ readOnly: isArchived() }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                mt: 2,
                border: '1px solid rgba(0,0,0,0.2)',
                borderRadius: 1,
                p: 3,
              }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    label="歿者(左)"
                    size="large"
                    value={registration.deceaseds.at(0) || ''}
                    onChange={(event) =>
                      setRegistration({
                        ...registration,
                        deceaseds: updateArray(
                          registration.deceaseds,
                          0,
                          event.target.value
                        ),
                      })
                    }
                    inputProps={{
                      style: {
                        fontSize: 20,
                        fontWeight: 'bold',
                      },
                      readOnly: isArchived(),
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    autoFocus
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    label="歿者(右)"
                    size="large"
                    value={registration.deceaseds.at(1) || ''}
                    onChange={(event) =>
                      setRegistration({
                        ...registration,
                        deceaseds: updateArray(
                          registration.deceaseds,
                          1,
                          event.target.value
                        ),
                      })
                    }
                    inputProps={{
                      style: { fontSize: 20, fontWeight: 'bold' },
                      readOnly: isArchived(),
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    label="陽上1"
                    size="large"
                    value={registration.family.at(0) || ''}
                    onChange={(event) =>
                      setRegistration({
                        ...registration,
                        family: updateArray(
                          registration.family,
                          0,
                          event.target.value
                        ),
                      })
                    }
                    inputProps={{
                      style: { fontSize: 20, fontWeight: 'bold' },
                      readOnly: isArchived(),
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    label="陽上2"
                    size="large"
                    value={registration.family.at(1) || ''}
                    onChange={(event) =>
                      setRegistration({
                        ...registration,
                        family: updateArray(
                          registration.family,
                          1,
                          event.target.value
                        ),
                      })
                    }
                    inputProps={{
                      style: { fontSize: 20, fontWeight: 'bold' },
                      readOnly: isArchived(),
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    label="陽上3"
                    size="large"
                    value={registration.family.at(2) || ''}
                    onChange={(event) =>
                      setRegistration({
                        ...registration,
                        family: updateArray(
                          registration.family,
                          2,
                          event.target.value
                        ),
                      })
                    }
                    inputProps={{
                      style: { fontSize: 20, fontWeight: 'bold' },
                      readOnly: isArchived(),
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    label="陽上4"
                    size="large"
                    value={registration.family.at(3) || ''}
                    onChange={(event) =>
                      setRegistration({
                        ...registration,
                        family: updateArray(
                          registration.family,
                          3,
                          event.target.value
                        ),
                      })
                    }
                    inputProps={{
                      style: { fontSize: 20, fontWeight: 'bold' },
                      readOnly: isArchived(),
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="standard"
                    label="陽上5"
                    size="large"
                    value={registration.family.at(4) || ''}
                    onChange={(event) =>
                      setRegistration({
                        ...registration,
                        family: updateArray(
                          registration.family,
                          4,
                          event.target.value
                        ),
                      })
                    }
                    inputProps={{
                      style: { fontSize: 20, fontWeight: 'bold' },
                      readOnly: isArchived(),
                    }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              elevation={1}
              sx={{ mt: 2 }}
              tabs={[
                {
                  title: '電子發票',
                  content: (
                    <Box sx={{ height: 200, p: 1 }}>
                      <Grid container spacing={5}>
                        {registration.receipts.length > 0 ? (
                          registration.receipts.map((receipt) => (
                            <Grid item key={receipt.sn}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td style={{ width: 100 }}>發票號碼</td>
                                    <td>
                                      {receipt.company} {receipt.sn}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>日期</td>
                                    <td>
                                      {DateTime.fromISO(receipt.date).toDate()}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>金額</td>
                                    <td>${receipt.amount.toLocaleString()}</td>
                                  </tr>
                                  <tr>
                                    <td>隨機碼</td>
                                    <td>{receipt.random}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </Grid>
                          ))
                        ) : (
                          <Grid item>
                            {registration.registry === '劃撥' ? (
                              <>
                                {registration.carrier && (
                                  <p>載具：{registration.carrier}</p>
                                )}
                                {registration.donation && (
                                  <p>捐贈碼：{registration.donation}</p>
                                )}
                                {!isArchived() && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                      registration.registry !== '劃撥' || true
                                    }
                                    onClick={() =>
                                      registrationUpdateRequest.mutate({
                                        id: original.regId,
                                        _id: original._id,
                                        issueReceipts: true,
                                      })
                                    }>
                                    開立發票
                                  </Button>
                                )}
                              </>
                            ) : (
                              <div>無發票紀錄</div>
                            )}
                          </Grid>
                        )}
                      </Grid>
                      {registration.receipts.length > 0 && (
                        <Box>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => setSMS(true)}
                            sx={{ mt: 1 }}>
                            重寄發票簡訊
                          </Button>
                          {/* <Button
                            variant="contained"
                            color="primary"
                            disabled={false}
                            onClick={() =>
                              registrationUpdateRequest.mutate({
                                id: original.regId,
                                _id: original._id,
                                issueReceipts: true,
                              })
                            }>
                            開立發票
                          </Button> */}
                        </Box>
                      )}
                    </Box>
                  ),
                },
                {
                  title: '修改紀錄',
                  content: (
                    <Box sx={{ height: 200, overflow: 'auto', p: 1 }}>
                      <p>---------- 品項修改紀錄 ----------</p>
                      {registration.itemHistory &&
                      registration.itemHistory.length > 0 ? (
                        registration.itemHistory.map((i) => <p key={i}>{i}</p>)
                      ) : (
                        <p>無修改紀錄</p>
                      )}
                      <p>---------- 報名修改紀錄 ----------</p>
                      {registration.history &&
                      registration.history.length > 0 ? (
                        registration.history.map((i) => <p key={i}>{i}</p>)
                      ) : (
                        <p>無修改紀錄</p>
                      )}
                      {!isArchived() && (
                        <div style={{ marginTop: 50 }}>
                          <Button
                            variant="contained"
                            color="error"
                            size="medium"
                            onClick={() => setWarningDialog(true)}>
                            單獨修改
                          </Button>
                        </div>
                      )}
                    </Box>
                  ),
                },
                {
                  title: '回條',
                  content: (
                    <Box sx={{ height: 200, overflow: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>法會</TableCell>
                            <TableCell>品項</TableCell>
                            <TableCell>位置</TableCell>
                            <TableCell>歿者</TableCell>
                            <TableCell>陽上</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {registration.records &&
                            registration.records
                              .filter(
                                (record) => record._id !== registration._id
                              )
                              .map((record) => (
                                <TableRow
                                  key={record._id}
                                  sx={{ ':hover': { bgcolor: blue[50] } }}
                                  onDoubleClick={() => handleJump(record)}>
                                  <TableCell>{record.event}</TableCell>
                                  <TableCell>{record.itemName}</TableCell>
                                  <TableCell>{record.location}</TableCell>
                                  <TableCell>
                                    {record.deceaseds.join(', ')}
                                  </TableCell>
                                  <TableCell>
                                    {record.family.join(', ')}
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </Box>
                  ),
                },
              ]}
            />
          </Grid>
          {!isArchived() && (
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleSave({ soleUpdate: false })}>
                  儲存
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
        <IconButton
          sx={{ borderRadius: 2, ml: 2 }}
          onClick={() => onLoadItem({ action: 'next' })}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
      {!isArchived() && (
        <Dialog open={warningDialog} onClose={() => setWarningDialog(false)}>
          <DialogTitle>單獨修改</DialogTitle>
          <DialogContent>
            <div>
              單獨修改只更新這個品項，客人如果報名多個品項，必須逐一檢查修改，除特殊情況外請按「儲存」進行自動修改。
            </div>
            <div style={{ textAlign: 'right', marginTop: 20 }}>
              <Button
                variant="contained"
                color="error"
                size="large"
                sx={{ fontSize: 16 }}
                onClick={() => handleSave({ soleUpdate: true })}>
                單獨修改
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <EditRegistrationDialog
        TransitionComponent={Transition}
        events={events}
        items={items}
        registration={jump}
        setRegistration={setJump}
        refetch={refetch}
        onSave={handleJumpSave}
        backButton={true}
        BackdropProps={{
          style: { backgroundColor: 'transparent', boxShadow: 'none' },
        }}
      />
      {sms && (
        <SMSDialog
          setOpen={setSMS}
          phone={registration.phone}
          receipts={registration.receipts}
        />
      )}
    </Dialog>
  );
}

export default EditRegistrationDialog;

function SMSDialog({ setOpen, phone: defaultPhone, receipts }) {
  const [phone, setPhone] = React.useState(defaultPhone);
  const sendSMSRequest = useMutation(sendSms, {
    onSuccess: () => {
      toast.success('成功發送簡訊');
      setOpen(false);
    },
  });
  function handleSendReceipts() {
    if (receipts.length > 0) {
      const messages = [];
      for (let receipt of receipts) {
        messages.push(
          `發票${receipt.sn} 日期${DateTime.fromISO(receipt.date).toFormat(
            'yyyy-MM-dd'
          )} 金額$${receipt.amount.toLocaleString()} 隨機碼${receipt.random}`
        );
      }
      sendSMSRequest.mutate({
        phone: phone,
        message: `【頂福陵園】${messages.join('｜')}`,
      });
    }
  }
  function handleSendTutorial() {
    sendSMSRequest.mutate({
      phone: phone,
      message:
        '用同手機號碼報名，會員載具只需歸戶一次，網址:https://www.dinfucem.com/tutorial',
    });
  }
  return (
    <Dialog
      open={true}
      onClose={() => {
        setOpen(false);
        setPhone(defaultPhone);
      }}
      maxWidth="xs"
      fullWidth>
      <DialogTitle>發送發票簡訊</DialogTitle>
      <Grid container spacing={2} sx={{ p: 2, pb: 3 }}>
        <Grid item xs={12}>
          <TextField
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
            fullWidth
          />
        </Grid>
        {phone.length === 10 && (
          <>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={handleSendTutorial}
                disabled={sendSMSRequest.isLoading}
                fullWidth>
                發送歸戶網址
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                onClick={handleSendReceipts}
                disabled={sendSMSRequest.isLoading}
                fullWidth>
                發送
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
}
