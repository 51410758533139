export default function getSearchParams(params) {
  const result = {};
  for (let [key, value] of params.entries()) {
    if (key !== 'range') result[key] = value;
  }
  const range = params.getAll('range');
  if (range.length > 0) {
    result.range = range;
  }
  return result;
}
