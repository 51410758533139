import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  buttonClasses,
  tableCellClasses,
} from '@mui/material';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import Detail from '../Detail';
import PrintableDialog from '../PrintableDialog';
import PrintableReceipt from '../PrintableReceipt';
import React from 'react';
import Section from '../Section';
import WarningIcon from '@mui/icons-material/Warning';
import companies from '../../utils/companies.const';
import { red } from '@mui/material/colors';
import useUser from '../../services/authentication/useUser';

function ReceiptInfo({ receipt, onDelete, state, setState }) {
  const [printDialog, setPrintDialog] = React.useState(false);
  const user = useUser();

  function handlePrint(mode) {
    setPrintDialog(mode);
  }
  function handleDeleteDialog(open, clear) {
    const newState = { ...state, open };
    if (clear) newState.desc = '';
    setState(newState);
  }

  return (
    <>
      <Box
        sx={{
          height: '30px',
          display: 'flex',
          [`& .${buttonClasses.root}`]: {
            display: 'none',
            mr: 1,
          },
          ...(!receipt.invalidated && receipt.print
            ? {
                '&:hover': {
                  height: '45px',
                  paddingBottom: '10px',
                  '& + .receipt-detail': {
                    maxHeight: '285px',
                  },
                  [`& .${buttonClasses.root}`]: {
                    display: 'inline-flex',
                    px: 3,
                  },
                },
              }
            : null),
        }}>
        {receipt.print && receipt.random && !receipt.invalidated && (
          <>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => handlePrint('original')}>
              證明聯
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => handlePrint('copy')}>
              補印聯
            </Button>
          </>
        )}
        {/* <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => handleDeleteDialog(true)}>
            折讓
          </Button> */}
        {receipt.print &&
          !receipt.invalidated &&
          DateTime.fromISO(receipt.date) > DateTime.now().startOf('month') &&
          DateTime.fromISO(receipt.date) < DateTime.now().endOf('month') && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => handleDeleteDialog(true)}>
              作廢
            </Button>
          )}
      </Box>
      <Box
        className="receipt-detail"
        sx={{
          overflow: 'auto',
          maxHeight: 300,
          '&::-webkit-scrollbar': { display: 'none' },
        }}>
        <div style={{ fontSize: 22 }}>
          {receipt.invalidated && <span style={{ color: red[700] }}>廢</span>}
          <span
            style={{
              color: receipt.invalidated
                ? '#707070'
                : companies[receipt.company].color,
              textDecoration: receipt.invalidated ? 'line-through' : null,
              marginRight: 5,
            }}>
            {receipt.company}
          </span>
          <span
            style={{
              color: receipt.invalidated ? '#707070' : null,
              textDecoration: receipt.invalidated ? 'line-through' : null,
            }}>
            {receipt.sn}
          </span>
        </div>
        <div style={{ marginTop: 20 }}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Detail
                width="40%"
                title="發票日期"
                value={receipt.date?.toDate()}
              />
            </Grid>
            {receipt.random && (
              <Grid item xs={12} sm={6}>
                <Detail width="40%" title="隨機碼" value={receipt.random} />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Detail
                width="40%"
                title="金額"
                value={receipt.amount.toLocaleString()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Detail
                width="40%"
                title="類型"
                value={receipt.print ? '紙本列印' : '載具'}
              />
            </Grid>
          </Grid>
        </div>
        <TableContainer
          sx={{
            pb: 2,
            [`.${tableCellClasses.root}`]: {
              paddingLeft: 0,
            },
          }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>品項</TableCell>
                <TableCell>數量</TableCell>
                <TableCell>單位</TableCell>
                <TableCell>單價</TableCell>
                <TableCell>金額</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receipt.items.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.unit}</TableCell>
                  <TableCell>{item.price.toLocaleString()}</TableCell>
                  <TableCell>
                    {(item.quantity * item.price).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Section title="買受人">
          <Detail width="40%" title="抬頭" value={receipt.customer?.name} />
          <Detail width="40%" title="統一編號" value={receipt.tax} />
        </Section>
        <Section title="載具">
          <Detail width="40%" title="載具" value={receipt.carrier} />
          <Detail width="40%" title="捐贈碼" value={receipt.donation} />
        </Section>
        <Section title="系統">
          <Detail
            width="40%"
            title="開立日期"
            value={receipt.issued.date?.toDateTime()}
          />
          <Detail width="40%" title="開立人" value={receipt.issued.by.name} />
          <Detail
            width="40%"
            title="作廢日期"
            value={receipt.invalidated?.date?.toDateTime()}
          />
          <Detail
            width="40%"
            title="作廢人"
            value={receipt.invalidated?.by?.name}
          />
          <Detail
            sm={12}
            width="101px"
            title="作廢原因"
            value={receipt.invalidated?.desc}
          />
        </Section>
      </Box>
      {receipt.print && !receipt.invalidated && (
        <>
          <Dialog
            open={state.open}
            maxWidth="xs"
            onClose={() => handleDeleteDialog(false)}
            fullWidth>
            <DialogTitle>作廢發票</DialogTitle>
            <div style={{ padding: '0 20px 20px 20px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon sx={{ color: red[600], mr: 1 }} />
                <p style={{ color: red[600] }}>
                  作廢紙本發票必須將發票證明連取回！
                </p>
              </div>
              <TextField
                variant="outlined"
                label="作廢原因"
                size="small"
                value={state.desc}
                onChange={(event) =>
                  setState({
                    ...state,
                    desc: event.target.value,
                  })
                }
                autoFocus
                fullWidth
              />
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  alignItems: 'flex-start',
                }}>
                <Checkbox
                  sx={{ p: 0, pr: 1 }}
                  value={state.check}
                  onChange={(event) =>
                    setState({
                      ...state,
                      check: event.target.checked,
                    })
                  }
                />
                <p
                  style={{
                    marginTop: 0,
                    fontSize: 14,
                    textAlign: 'justify',
                  }}>
                  <span
                    style={{
                      color: 'white',
                      backgroundColor: 'red',
                    }}></span>
                  如果沒有將發票證明聯取回，導致公司損失包括但不限於遭國稅局罰鍰、或該張發票中獎等，
                  <span style={{ color: 'red' }}>
                    本人 {`${user.name}`} 同意向公司負賠償責任
                  </span>
                  。
                </p>
              </div>
              {state.error && (
                <Alert
                  severity="error"
                  style={{ marginTop: 20, marginBottom: 20 }}>
                  {state.error}
                </Alert>
              )}
              <div style={{ marginTop: 30, textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleDeleteDialog(false, true);
                  }}>
                  取消
                </Button>
                {state.desc.length > 0 && state.check && (
                  <Button
                    variant="contained"
                    color="warning"
                    sx={{ ml: 2 }}
                    onClick={onDelete}>
                    確定作廢
                  </Button>
                )}
              </div>
            </div>
          </Dialog>
          <PrintableDialog
            open={printDialog}
            onClose={() => {
              setPrintDialog(null);
            }}
            maxWidth="xs">
            <PrintableReceipt receipt={receipt} mode={printDialog} />
          </PrintableDialog>
        </>
      )}
    </>
  );
}

export default ReceiptInfo;
