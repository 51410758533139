import { Box, Grid, Paper, TextField } from '@mui/material';

import { DateTime } from 'luxon';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { blue } from '@mui/material/colors';
import useUser from '../services/authentication/useUser';

function Memos({ memos, onSubmit, height = 367, disabled, sx }) {
  const scroll = React.useRef(null);
  const user = useUser();
  const [value, setValue] = React.useState('');
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    if (memos) {
      const array = memos.map((memo) => {
        memo.date = DateTime.fromISO(memo.date);
        memo.canDelete =
          (!memo.title || memo.title?.length === 0) &&
          memo.author._id.toString() === user._id.toString() &&
          DateTime.fromISO(memo.date) > DateTime.now().plus({ day: -1 });
        return memo;
      });
      array.sort((a, b) => (a.date > b.date ? -1 : 1));
      return setRecords(array);
    }
    setRecords([]);
  }, [memos]);

  function handleChange(e) {
    setValue(e.target.value);
  }
  async function handleKeyDown(e) {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      if (value.length === 0) return;
      const regex = /(?:\[(.+)\]\s)?(.+)/;
      const match = value.match(regex);
      const memo = {
        title: match[1],
        content: match[2],
        author: {
          _id: user._id,
          name: user.name,
        },
        date: Date.now(),
      };
      const success = await onSubmit(memo);
      if (success) {
        setValue('');
        e.target.blur();
        scroll.current.scrollTo(0, 0);
      }
    }
  }

  return (
    <Paper elevation={2} sx={{ height, ...sx }}>
      <Box
        ref={scroll}
        sx={{
          height: height - 60,
          overflow: 'auto',
          scrollBehavior: 'smooth',
        }}>
        {records.map((memo) => (
          <Box
            key={memo._id}
            sx={{
              margin: 2,
              mx: 3,
              pl: '30px',
              py: '5px',
              borderLeft: `4px solid ${blue[600]}`,
            }}>
            <Grid container>
              <Grid item xs={memo.canDelete ? 11.5 : 12}>
                {memo.title && memo.title.length > 0 && (
                  <h4 style={{ marginTop: 0, marginBottom: 10 }}>
                    {memo.title}
                  </h4>
                )}
                <p style={{ marginTop: 0 }}>{memo.content}</p>
                <p
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    color: '#808080',
                    fontStyle: 'italic',
                  }}>
                  {`${memo.date.toFormat('yyyy-MM-dd HH:mm')} by ${
                    memo.author?.name
                  }`}
                </p>
              </Grid>
              <Grid
                item
                xs={memo.canDelete ? 0.5 : 0}
                sx={{ textAlign: 'right' }}>
                {memo.canDelete && (
                  <IconButton
                    size="small"
                    onClick={() => onSubmit(memo._id.toString())}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
      {!disabled && (
        <Box sx={{ backgroundColor: '#E0E0E0', height: 60, padding: 1.2 }}>
          <TextField
            sx={{
              backgroundColor: 'white',
              borderRadius: 2,
              '& .Mui-focused fieldset, & .MuiInputBase-root:hover fieldset': {
                border: 'rgba(0,0,0,0.23)',
              },
            }}
            variant="outlined"
            size="small"
            fullWidth
            value={value}
            placeholder="新增備註..."
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Box>
      )}
    </Paper>
  );
}

export default Memos;
