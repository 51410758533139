import { Button, Dialog, DialogTitle, TextField } from '@mui/material';

import React from 'react';

export function InvalidateDialog({ state, setState, onInvalidate }) {
  return (
    <Dialog
      open={state.open}
      maxWidth="xs"
      onClose={() => setState({ ...state, open: false })}
      fullWidth>
      <DialogTitle>作廢報價單</DialogTitle>
      <div style={{ padding: '0 20px 20px 20px' }}>
        <TextField
          variant="outlined"
          label="作廢原因"
          size="small"
          value={state.desc}
          onChange={(event) =>
            setState({
              ...state,
              desc: event.target.value,
            })
          }
          autoFocus
          fullWidth
        />
        <div style={{ marginTop: 30, textAlign: 'right' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setState({ open: false, desc: '' });
            }}>
            取消
          </Button>
          {state.desc.length > 0 && (
            <Button
              variant="contained"
              color="warning"
              sx={{ ml: 2 }}
              onClick={onInvalidate}>
              確定作廢
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}
