import { serialize } from '../utils/serializeProduct';

const url = '/api/deceased';

export const createDeceased = (client) => (states) => {
  return client.post(url, states);
};

export const updateDeceased = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states);
};

export const queryTemps = (client) => () => {
  return client.get('/api/temp');
};

export const queryDeceaseds = (states) => (client) => () => {
  const params = new URLSearchParams();
  for (let key of Object.keys(states)) {
    if (!states[key]) continue;
    if (key === 'product') {
      params.append(key, serialize(states[key]));
    } else if (key !== 'range') {
      params.append(key, states[key]);
    }
  }
  if (states.range) {
    params.append(
      'range',
      `[${states.range[0].toMillis()},${states.range[1].toMillis()}]`
    );
  }
  return client.get(
    `${url}${params.toString() ? `?${params.toString()}` : ''}`
  );
};
