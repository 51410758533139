export function serialize(query) {
  const original = query;
  let product = '';
  if (!query) return query;
  if (typeof query !== 'string') return query;

  try {
    if (query.substring(0, 1) === '基') {
      query = query.substring(1).replace('.', '-');
      return query;
    } else if (
      ['福', '祿', '壽', '安', '泰', '吉', '祥', '如', '意'].some((s) =>
        query.includes(s)
      )
    ) {
      switch (query.substring(0, 1)) {
        case '福':
          query = 'BA' + query.substring(1);
          break;
        case '祿':
          query = 'BB' + query.substring(1);
          break;
        case '壽':
          query = 'BC' + query.substring(1);
          break;
        case '安':
          query = 'BD' + query.substring(1);
          break;
        case '泰':
          query = 'BE' + query.substring(1);
          break;
        case '吉':
          query = 'BF' + query.substring(1);
          break;
        case '祥':
          query = 'BG' + query.substring(1);
          break;
        case '如':
          query = 'FR' + query.substring(1);
          break;
        case '意':
          query = 'FY' + query.substring(1);
          break;
        default:
      }
      return query;
    } else {
      const queryArray = query.split('.');

      if (queryArray.length === 2 || queryArray.length === 3) {
        switch (queryArray[0]) {
          case '忠':
            product += 'EA';
            break;
          case '忠I':
            product += 'EB';
            break;
          case '忠1':
            product += 'EB';
            break;
          case '孝右':
            product += 'EC';
            break;
          case '孝':
            product += 'ED';
            break;
          case '孝左':
            product += 'EE';
            break;
          case '孝I':
            product += 'EF';
            break;
          case '孝II':
            product += 'EG';
            break;
          case '孝1':
            product += 'EF';
            break;
          case '孝2':
            product += 'EG';
            break;
          case '仁':
            product += 'EH';
            break;
          case '仁II':
            product += 'EI';
            break;
          case '仁2':
            product += 'EI';
            break;
          case '愛右':
            product += 'EJ';
            break;
          case '愛左':
            product += 'EK';
            break;
          case '愛I':
            product += 'EL';
            break;
          case '愛1':
            product += 'EL';
            break;
          case '罈A':
            product += 'EMA';
            break;
          case '罈B':
            product += 'EMB';
            break;
          case '罈C':
            product += 'EMC';
            break;
          case '愛IIA':
            product += 'ENA';
            break;
          case '愛2A':
            product += 'ENA';
            break;
          case '愛IIB':
            product += 'ENB';
            break;
          case '愛2B':
            product += 'ENB';
            break;
          case '愛IIC':
            product += 'ENC';
            break;
          case '愛2C':
            product += 'ENC';
            break;
          case '愛IID':
            product += 'END';
            break;
          case '愛2D':
            product += 'END';
            break;
          default:
            break;
        }
        if (queryArray.length === 2) {
          product += queryArray[1];
        } else {
          let first = parseInt(queryArray[1]);

          if (isNaN(first)) {
            product += queryArray[1];
          } else {
            product += queryArray[1].padStart(2, '0');
          }

          product += queryArray[2].padStart(3, '0');
        }
        return product;
      }
    }
    return query;
  } catch (error) {
    console.log(error);
    return original;
  }
}
