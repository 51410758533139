import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  amount,
  date,
  getErrors,
  paymentType,
} from '../validations/yupSchemas';
import { object, string } from 'yup';

import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import React from 'react';
import { createPayment } from '../fetchers/payment.fetch';
import registerState from '../utils/registerState';
import useMutation from '../services/httpClient/useMutation';

const schema = object({
  company: string().required('請輸入公司'),
  date: date.required('請輸入日期'),
  paymentType: paymentType,
  amount: amount,
  desc: string().required('請輸入內容'),
});
function initialState(payer, tag) {
  return {
    company: '福',
    date: DateTime.now(),
    paymentType: '現金',
    amount: 0,
    desc: '',
    payer: payer,
    tag,
  };
}
export function addPaymentDialogReducer(state, action) {
  switch (action.type) {
    case 'close':
      return { ...state, open: false };
    case 'open':
      return { ...state, open: true };
    case 'refresh':
      return { ...state, refresh: Date.now() };
    default:
      throw new Error('請檢查addPaymentTableReducer');
  }
}

function AddPaymentDialog({ payer, state, onSubmit, tag }) {
  const [payment, setPayment] = React.useState(initialState(payer, tag));
  const [errors, setErrors] = React.useState({});
  const createPaymentRequest = useMutation(createPayment, {
    onSuccess: () => {
      onSubmit();
      handleClose();
    },
  });
  const register = registerState(payment, setPayment, errors);
  function handleClose() {
    state.setOpen(false);
    setPayment(initialState(payer, tag));
  }
  function handleSave() {
    schema
      .validate(payment, { abortEarly: false })
      .then((res) => {
        createPaymentRequest.mutate(res);
      })
      .catch((err) => {
        setErrors(getErrors(err));
      });
  }

  return (
    <Dialog
      open={state.isOpen}
      onClose={() => state.setOpen(false)}
      fullWidth
      maxWidth="xs">
      <DialogTitle>新增付費記錄</DialogTitle>
      <div style={{ padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField select label="公司" {...register('company')} fullWidth>
              <MenuItem value="福">頂福事業</MenuItem>
              <MenuItem value="利">頂利事業</MenuItem>
              <MenuItem value="茂">辰茂事業</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="日期"
              inputFormat="yyyy-MM-dd"
              value={payment.date}
              onChange={(val) =>
                setPayment({ ...payment, date: val.startOf('day') })
              }
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  error={errors.date}
                  helperText={errors.date?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              label="付費方式"
              {...register('paymentType')}
              fullWidth>
              <MenuItem value="現金">現金</MenuItem>
              <MenuItem value="匯款">匯款</MenuItem>
              <MenuItem value="信用卡">信用卡</MenuItem>
              <MenuItem value="票據">票據</MenuItem>
              <MenuItem value="超商代碼">超商代碼</MenuItem>
              <MenuItem value="虛擬帳號">虛擬帳號</MenuItem>
              <MenuItem value="劃撥">劃撥</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              variant="outlined"
              label="金額"
              {...register('amount')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="內容"
              {...register('desc')}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: 20 }}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              sx={{ mr: 2 }}>
              取消
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={createPaymentRequest.isLoading}>
              儲存
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}

export default AddPaymentDialog;
