import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';

import { DateTime } from 'luxon';
import React from 'react';
import { grey } from '@mui/material/colors';
import useHttpClient from '../../services/httpClient/useHttpClient';

function ReferralTable({ summary, onLoaded, onToggle }) {
  const [referrals, setReferrals] = React.useState();
  const client = useHttpClient();

  React.useEffect(() => {
    const payload = { names: summary.names, phones: summary.phones };
    payload.deceaseds = summary.deceaseds
      .map((d) => d.name)
      .filter((d) => !['壽', '壽位'].includes(d));
    payload.ssns = summary.deceaseds
      .map((d) => (d.ssn ? d.ssn : null))
      .filter((d) => d !== null);
    client
      .post('/api/referral/query', payload)
      .then((response) => {
        response.forEach((r) => {
          r.date = DateTime.fromISO(r.date);
        });
        setReferrals(response.sort((a, b) => (a.date > b.date ? -1 : 1)));
        onLoaded(response);
      })
      .catch((error) => {
        alert(error);
      });
  }, []);

  if (!referrals) return <p>Loading...</p>;

  return (
    <TableContainer
      sx={{
        overflow: 'auto',
        height: '390px',
        [`.${tableCellClasses.root}`]: {
          fontSize: 16,
          py: 1.3,
        },
        [`& .${tableRowClasses.root}.group-header`]: {
          backgroundColor: grey[100],
        },
      }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>日期</TableCell>
            <TableCell>仲介</TableCell>
            <TableCell>姓名</TableCell>
            <TableCell>電話</TableCell>
            <TableCell>歿者</TableCell>
            <TableCell>身分證字號</TableCell>
            <TableCell>業務</TableCell>
            <TableCell>備註</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referrals.map((referral) => (
            <TableRow key={referral._id}>
              <TableCell>
                <Checkbox
                  checked={summary.referrals.includes(referral._id)}
                  onChange={() => onToggle(referral._id)}
                />
              </TableCell>
              <TableCell>{referral.date?.toDateTime()}</TableCell>
              <TableCell>{referral.broker?.name}</TableCell>
              <TableCell>{referral.name}</TableCell>
              <TableCell>{referral.phone}</TableCell>
              <TableCell>{referral.deceased}</TableCell>
              <TableCell>{referral.ssn}</TableCell>
              <TableCell>{referral.created.by?.name}</TableCell>
              <TableCell>{referral.desc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReferralTable;
