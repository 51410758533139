import { Breadcrumbs, Typography } from '@mui/material';

import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { NavigateNext } from '@mui/icons-material';
import React from 'react';
import crumbs from './crumbs.const';
import useCrumbs from './useCrumbs';

export function Crumbs(props) {
  const { crumbs: currentCrumbs } = useCrumbs();
  return (
    <Box
      sx={{
        ml: 2,
        '& a': {
          fontSize: (theme) => theme.typography.caption,
          color: (theme) => theme.palette.gray.light,
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
            color: (theme) => theme.palette.primary.main,
          },
        },
      }}>
      {currentCrumbs.length === 0 ? (
        <Link to="/">
          <Typography
            sx={{
              fontWeight: 'bold',
              color: (theme) => theme.palette.gray.main,
            }}>
            儀表板
          </Typography>
        </Link>
      ) : (
        <>
          <Breadcrumbs
            sx={{
              ml: 0.1,
              '& span': { fontSize: (theme) => theme.typography.caption },
              display: {
                xs: 'none',
                sm: 'block',
              },
            }}
            separator={<NavigateNext fontSize="20" />}>
            <Link to={crumbs.dashboard.link}>{crumbs.dashboard.label}</Link>
            {currentCrumbs.slice(0, -1).map((crumb) => (
              <Link key={crumb.id} to={crumb.link}>
                {crumb.label}
              </Link>
            ))}
          </Breadcrumbs>
          <Typography
            onClick={() => window.location.reload()}
            sx={{
              fontSize: (theme) => theme.typography.h6,
              fontWeight: 'bold',
              color: (theme) => theme.palette.gray.main,
              cursor: 'pointer',
              ':hover': {
                textDecoration: 'underline',
                color: (theme) => theme.palette.primary.main,
              },
            }}>
            {currentCrumbs.last()?.label}
          </Typography>
        </>
      )}
    </Box>
  );
}
