import { Alert, Grid, Paper } from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import { mobileMask, phoneMobileMask } from '../../validations/imask';
import { queryBrokerById, updateBroker } from '../../fetchers/broker.fetch';

import AddBrokerContactDialog from './AddBrokerContactDialog';
import AddReferralDialog from './AddReferralDialog';
import { BrokerInfo } from './BrokerInfo';
import { ContactsPanel } from './ContactsPanel';
import { DateTime } from 'luxon';
import ImageSlider from '../../components/ImageSlider';
import React from 'react';
import Spinner from '../../components/Spinner';
import { createReferral } from '../../fetchers/referral.fetch';
import crumbs from '../../services/crumbs/crumbs.const';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import { useParams } from 'react-router-dom';
import useQuery from '../../services/httpClient/useQuery';
import useUser from '../../services/authentication/useUser';

const initialStates = {
  contact: {
    name: '',
    position: '',
    mobile: '',
  },
  referral: {
    open: false,
    multiple: '',
    date: DateTime.now(),
    name: '',
    phone: '',
    deceased: '',
    ssn: '',
    desc: '',
    contact: '',
    error: null,
  },
};

function BrokerDetail(props) {
  useCrumbs([crumbs.brokerList, crumbs.brokerDetail]);
  const { id } = useParams();
  const user = useUser();
  const [selected, setSelected] = React.useState(0);
  const [insert, setInsert] = React.useState();
  const [referral, setReferral] = React.useState(initialStates.referral);
  const brokerUpdateRequest = useMutation(updateBroker, {
    onSuccess: () => {
      toast.success('成功儲存仲介來源紀錄');
      setInsert();
      brokerQuery.refetch();
    },
  });
  const referralCreateRequest = useMutation(createReferral, {
    onSuccess: () => {
      toast.success('成功新增通報紀錄');
      handleReferral('cancel');
    },
  });
  const brokerQuery = useQuery(['broker', id], queryBrokerById({ id }), {
    onSuccess: (data) => {
      setBroker(data);
      if (data.company) {
        setContact(data.company.contacts[0]);
      }
    },
  });
  const [broker, setBroker] = React.useState(brokerQuery.data);
  const [contact, setContact] = React.useState(
    brokerQuery.data?.company?.contacts[0]
  );

  function handleSave() {
    let updated = { ...broker };
    if (broker.company) {
      updated.company.contacts = broker.company.contacts.map((c) => {
        if (c._id === contact._id) {
          contact.phone = mobileMask.unmask(contact.mobile);
          return contact;
        }
        return c;
      });
    }
    brokerUpdateRequest.mutate({ id: broker._id, ...updated });
  }
  function handleClose() {
    const updated = {
      ...broker,
      closed: {
        by: {
          _id: user._id,
          name: user.name,
        },
        date: DateTime.now(),
      },
    };
    setBroker(updated);
    brokerUpdateRequest.mutate({ id: broker._id, ...updated });
  }
  function handleInsert() {
    const updated = { ...broker };
    updated.company.contacts = updated.company.contacts.concat(insert);
    brokerUpdateRequest.mutate({ id: broker._id, ...updated });
  }
  function handleReferral(op) {
    switch (op) {
      case 'open':
        setReferral({ ...referral, open: true });
        break;
      case 'clear':
        setReferral({
          ...initialStates.referral,
          open: referral.open,
        });
        break;
      case 'cancel':
        setReferral(initialStates.referral);
        break;
      case 'single': {
        const payload = {
          date: DateTime.now(),
          name: referral.name,
          phone: phoneMobileMask.unmask(referral.phone),
          deceased: referral.deceased,
          ssn: referral.ssn,
          desc: referral.desc,
          broker: {
            _id: broker._id,
            contactId: referral.contact._id ?? null,
            name: referral.contact.name
              ? `${broker.name}-${contact.name}`
              : broker.name,
          },
        };
        referralCreateRequest.mutate(payload);
        return;
      }
      case 'multiple': {
        // 姓名 電話 歿者 身分證字號 備註
        const lines = referral.multiple.split('\n');
        const payload = lines
          .map((line) => line.split(','))
          .map((record) => {
            return {
              date: DateTime.now(),
              name: record[0],
              phone: phoneMobileMask.unmask(record[1]),
              deceased: record[2],
              ssn: record[3],
              desc: record[4],
              broker: {
                _id: broker._id,
                contactId: referral.contact._id ?? null,
                name: referral.contact.name
                  ? `${broker.name}-${contact.name}`
                  : broker.name,
              },
            };
          });
        referralCreateRequest.mutate(payload);
        return;
      }
      default:
        return;
    }
  }

  if (brokerQuery.isLoading) return <Spinner />;

  return (
    <>
      <Paper sx={{ p: 2 }}>
        {broker.closed && (
          <Alert
            style={{ float: 'right' }}
            severity="info">{`仲介已於 ${broker.closed.date?.toDate()} 由 ${
            broker.closed.by.name
          } 標註為結束營業。`}</Alert>
        )}
        <Grid container spacing={2}>
          <BrokerInfo state={broker} setState={setBroker} />
        </Grid>
      </Paper>
      {!broker.company && (
        <Paper sx={{ mt: 2, p: 2 }}>
          <ImageSlider tag={broker._id} disabled={!!broker.closed} />
        </Paper>
      )}
      {broker.company && (
        <>
          {ContactsPanel(selected, setSelected, setContact, broker, contact)}
          <AddBrokerContactDialog
            state={insert}
            setState={setInsert}
            onSave={handleInsert}
          />
        </>
      )}
      <AddReferralDialog
        state={referral}
        setState={setReferral}
        onOperation={handleReferral}
        contacts={broker.company?.contacts}
      />
      {!broker.closed && (
        <SpeedDial
          actions={[
            createAction(speedDialIcons.main),
            createAction(speedDialIcons.save, '儲存', handleSave),
            createAction(speedDialIcons.addToList, '新增通報', () =>
              handleReferral('open')
            ),
            createAction(speedDialIcons.addContact, '新增聯絡人', () =>
              setInsert(initialStates.contact)
            ),
            createAction(speedDialIcons.close, '標記結束營業', handleClose),
          ]}
        />
      )}
    </>
  );
}

export default BrokerDetail;
