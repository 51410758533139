import Detail from '../../components/Detail';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import companies from '../../utils/companies.const';

export function PurchaseInfo({ purchase, sas }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="公司"
          value={
            <span
              style={{
                color: companies[purchase.company].color,
              }}>
              {companies[purchase.company].short}
            </span>
          }
          width="30%"
          marginless
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="廠商"
          value={
            <Link to={`/vendor/${purchase.vendor._id}`}>
              {purchase.vendor.name}
            </Link>
          }
          width="30%"
          marginless
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="日期"
          value={purchase.created.date?.toDate()}
          width="30%"
          marginless
        />
      </Grid>
      {purchase.sn && (
        <Grid item xs={12} sm={6} md={4}>
          <Detail width="30%" title="編號" value={purchase.sn} marginless />
        </Grid>
      )}
      {purchase.tags.length > 0 && (
        <Grid item xs={12} sm={6} md={4}>
          <Detail
            title="標籤"
            value={
              <Grid container spacing={1.5}>
                {purchase.tags.map((tag, index) => (
                  <Grid item key={`tag-${index}`}>
                    <Link key={tag._id} to={`/${tag.tagType}/${tag._id}`}>
                      {tag.label}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            }
            width="30%"
            marginless
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <Detail
          title="承辦人"
          value={purchase.created.by.name}
          width="30%"
          marginless
        />
      </Grid>
      {purchase.receipt?.photo && (
        <Grid item xs={12} sm={6} md={4}>
          <Detail
            title="到貨日期"
            value={
              <>
                {purchase.receipt?.date.toDate()}（
                <a
                  href={`${purchase.receipt?.photo.url}?${sas}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {purchase.receipt?.receiptType}
                </a>
                ）
              </>
            }
            width="30%"
            marginless
          />
        </Grid>
      )}
      {purchase.payment && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <Detail
              title="發票號碼"
              value={purchase.payment?.sn?.join(', ')}
              width="30%"
              marginless
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Detail
              title="付款日期"
              value={purchase.payment?.date?.toDate()}
              width="30%"
              marginless
            />
          </Grid>
        </>
      )}
      {purchase.invalidated && (
        <Grid item xs={12} sm={6} md={4}>
          <Detail
            title="作廢日期"
            value={purchase.invalidated?.date.toDate()}
            width="30%"
            marginless
          />
        </Grid>
      )}
    </Grid>
  );
}
