import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
} from '@mui/material';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import Detail from './Detail';
import { Link } from 'react-router-dom';
import React from 'react';
import { queryOrderById } from '../fetchers/order.fetch';
import { queryProductById } from '../fetchers/product.fetch';
import { tagPayment } from '../fetchers/payment.fetch';
import useMutation from '../services/httpClient/useMutation';
import useQuery from '../services/httpClient/useQuery';
import { useQueryClient } from 'react-query';

function TagPanel({ payment, onChange }) {
  const [insert, setInsert] = React.useState({
    tag: '',
    tagType: 'Quote',
    error: null,
  });
  const tagPaymentRequest = useMutation(tagPayment, {
    onSuccess: onChange,
    onError: (err) => {
      let msg = err;
      if (msg.includes('string of 12 bytes')) {
        msg = '標籤各式不符，請檢查標籤。';
      }
      setInsert({ ...insert, error: msg });
    },
  });

  const handleKeyDown = async (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      tagPaymentRequest.mutate({
        id: payment._id,
        tag: {
          _id: insert.tag,
          tagType: insert.tagType,
        },
      });
    }
  };

  if (!payment) return <p>Loading...</p>;

  return (
    <>
      {payment && !payment.deleted && !payment.tag && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label">類別</InputLabel>
              <Select
                label="類別"
                value={insert.tagType}
                onChange={(event) =>
                  setInsert({ ...insert, tagType: event.target.value })
                }>
                <MenuItem value="Quote">報價單</MenuItem>
                <MenuItem value="Order">訂單</MenuItem>
                <MenuItem value="Product">產品</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="新增標籤"
              size="small"
              value={insert.tag}
              onKeyDown={handleKeyDown}
              onChange={(event) =>
                setInsert({ ...insert, tag: event.target.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      {insert.error && (
        <div style={{ margin: '20px 0' }}>
          <Alert severity="error">{insert.error}</Alert>
        </div>
      )}
      {payment.tag && (
        <Box
          sx={{
            overflow: 'auto',
            maxHeight: 300,
            '&::-webkit-scrollbar': { display: 'none' },
          }}>
          {payment.tag.tagType === 'Order' && (
            <OrderInfo tag={payment.tag._id} />
          )}
          {payment.tag.tagType === 'Product' && (
            <ProductInfo tag={payment.tag._id} />
          )}
        </Box>
      )}
    </>
  );
}

function ProductInfo({ tag }) {
  const productQuery = useQuery(
    ['product', tag],
    queryProductById({ id: tag, deceaseds: true })
  );

  if (productQuery.isLoading) return <p>Loading...</p>;
  const product = productQuery.data;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Detail
          width="40%"
          title="產品編號"
          value={
            <Button
              variant="text"
              size="small"
              component={Link}
              to={`/product/${product._id}`}
              sx={{ fontSize: '15px' }}>
              {product.sn}
            </Button>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Detail width="40%" title="產品類別" value={product.productType} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Detail width="40%" title="權狀編號" value={product.certificate?.sn} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Detail
          width="40%"
          title="使用狀態"
          value={
            !product.certificate
              ? '空位'
              : !product.deceaseds.any()
              ? '售出'
              : '已使用'
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Detail width="40%" title="管理費" value={product.strata.status} />
      </Grid>
    </Grid>
  );
}

function OrderInfo({ tag }) {
  const queryClient = useQueryClient();
  const orderQuery = useQuery(['order', tag], queryOrderById({ id: tag }), {
    onSuccess: (data) => {
      if (data.commission?.date)
        data.commission.date = DateTime.fromISO(data.commission.date);
      for (let item of data.items) {
        if (item.certificate.date) {
          item.certificate.date = DateTime.fromISO(item.certificate.date);
        }
      }
      queryClient.setQueryData(['order', tag], data);
    },
  });

  if (orderQuery.isLoading) return <p>Loading...</p>;

  const order = orderQuery.data;
  const total = order?.items.reduce(
    (sum, item) =>
      sum + item.price + item.const + item.additional - item.discount,
    0
  );

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Detail
          width="40%"
          title="訂單編號"
          value={
            <Button
              variant="text"
              size="small"
              component={Link}
              to={`/order/${order._id}`}
              sx={{ fontSize: '15px' }}>
              {order.sn}
            </Button>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Detail width="40%" title="訂單日期" value={order.date?.toDate()} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Detail width="40%" title="類別" value={order.orderType} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Detail
          width="40%"
          title="總金額"
          value={total.toString().toLocaleString()}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          sx={{
            pb: 2,
            [`.${tableCellClasses.root}`]: {
              paddingLeft: 0,
            },
          }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>產品編號</TableCell>
                <TableCell>定價</TableCell>
                <TableCell>管理費</TableCell>
                <TableCell>備註</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <Link to={`/product/${item._id}`}>{item.sn}</Link>
                  </TableCell>
                  <TableCell>{item.price.toLocaleString()}</TableCell>
                  <TableCell>{item.strata}</TableCell>
                  <TableCell>{item.desc}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default TagPanel;
