import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';

import { Box } from '@mui/system';
import Detail from '../../components/Detail';
import React from 'react';
import { queryProductsBySerialNumber } from '../../fetchers/product.fetch';
import { serialize } from '../../utils/serializeProduct';
import useQuery from '../../services/httpClient/useQuery';

export function AddItemDialog({ items, setItems, open, setOpen }) {
  const [keyword, setKeyword] = React.useState('');
  const [query, setQuery] = React.useState({
    keyword: '',
    product: null,
    error: null,
  });
  useQuery(
    ['products', { keyword }],
    queryProductsBySerialNumber({ sn: keyword }),
    {
      onSuccess: (data) => {
        setQuery({ ...query, product: data[0], error: null });
      },
      staleTime: 0,
      enabled: keyword.length > 0,
    }
  );

  function handleKeyDown(event) {
    event.stopPropagation();
    if (event.key === 'Enter' && query.keyword.length > 0) {
      event.preventDefault();
      setKeyword(serialize(query.keyword));
    }
  }
  function handleAddItem() {
    const product = query.product;
    if (items.find((r) => r._id === product._id)) {
      return setQuery({ ...query, error: '產品重複' });
    }
    const insert = {
      _id: product._id,
      sn: product.sn,
      price: 0,
      const: 0,
      additional: 0,
      strata: 0,
      certificate: {
        sn: '',
        date: null,
      },
      discount: 0,
      desc: '',
    };
    setItems([...items, insert]);
    setQuery({ ...query, product: null, keyword: '' });
  }
  function handleClose() {
    setOpen(false);
    setQuery({ ...query, keyword: '', product: null, error: null });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>加入產品</DialogTitle>
      <Box sx={{ p: 2.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              placeholder="查詢產品..."
              value={query.keyword}
              onChange={(event) =>
                setQuery({ ...query, keyword: event.target.value })
              }
              onKeyDown={handleKeyDown}
              autoFocus
              fullWidth
            />
            {query.error && (
              <Alert sx={{ mt: 2 }} severity="error">
                {query.error}
              </Alert>
            )}
          </Grid>
        </Grid>
        <div style={{ marginTop: 30 }}>
          {query.product && (
            <Grid item xs={12}>
              <Detail title="產品編號" value={query.product.sn} />
              <Detail title="區域" value={query.product.area} />
              <Detail title="狀態" value={query.product.status} />
            </Grid>
          )}
        </div>
        <div style={{ textAlign: 'right', marginTop: 30 }}>
          {query.product && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                sx={{ mr: query.product.status !== '空位' ? 0 : 2 }}>
                取消
              </Button>
              {query.product.area === '頂福陵園' &&
                query.product.status === '空位' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddItem}>
                    儲存
                  </Button>
                )}
            </>
          )}
        </div>
      </Box>
    </Dialog>
  );
}
