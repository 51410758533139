import React from 'react';
import useStorageState from '../../utils/useStorageState';

export const AuthContext = React.createContext();

function AuthenticationProvider({ children }) {
  const [auth, setAuth] = useStorageState(localStorage, 'dashboard-auth', '');

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthenticationProvider;
