const url = '/api/payment';

export const queryPayments = (states) => (client) => () => {
  const params = new URLSearchParams();
  for (let key of Object.keys(states)) {
    if (!states[key]) continue;
    if (key !== 'range') {
      params.append(key, states[key]);
    }
  }
  if (states.range) {
    params.append(
      'range',
      `[${states.range[0].toMillis()},${states.range[1].toMillis()}]`
    );
  }
  return client.get(
    `${url}${params.toString() ? `?${params.toString()}` : ''}`
  );
};

export const queryPaymentsByTag = (states) => (client) => () => {
  return client.get(`${url}?tag=${states.tag}`);
};

export const createPayment = (client) => (states) => {
  return client.post(`${url}`, states);
};

export const addTagToPayment = (client) => (states) => {
  return client.post(`${url}/${states.id}`, states.tag);
};

export const deletePayment = (client) => (states) => {
  return client.delete(`${url}/${states.id}`, states.payload);
};

export const updatePayment = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states.payload);
};

export const deleteReceipt = (client) => (states) => {
  return client.delete(`${url}/receipts/${states.id}`, states);
};

export const bindReceiptToPayment = (client) => (states) => {
  return client.post(`${url}/bind/${states.id}`, states);
};

export const tagPayment = (client) => (states) => {
  return client.post(`${url}/${states.id}`, states);
};
