const url = '/api/vendor';

export const queryVendors = (states) => (client) => () => {
  if (states) return client.get(`${url}?query=${states}`);
  return client.get(url);
};

export const createVendor = (client) => (states) => {
  return client.post(url, states);
};

export const queryVendor = (states) => (client) => () => {
  return client.get(`${url}/${states.id}`);
};

export const updateVendor = (client) => (states) => {
  return client.put(`${url}/${states.id}`, states);
};
