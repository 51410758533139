import {
  useInitialState,
  useStorageListener,
  useStorageWriter,
} from '../services/persistentStorage';

import { useState } from 'react';

function useStorageState(storage, key, defaultState) {
  const [state, setState] = useState(
    useInitialState(storage, key, defaultState)
  );
  const writeError = useStorageWriter(storage, key, state);

  useStorageListener(storage, key, defaultState, setState);

  return [state, setState, writeError];
}

export default useStorageState;
