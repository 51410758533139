import { Box } from '@mui/system';
import React from 'react';
import { TableCell } from '@mui/material';
import { TempEdit } from './schema';
import { TempTable } from './TempTable';
import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material';

export const rates = {
  骨罈: {
    day: 120,
    month: 3600,
    year: 36000,
  },
  靈位: {
    day: 70,
    month: 2000,
    year: 20000,
  },
  香火袋: {
    day: 70,
    month: 2000,
    year: 20000,
  },
  骨灰: {
    day: 80,
    month: 2400,
    year: 24000,
  },
};

export const StyledTableCell = styled(TableCell)(
  ({ theme, highlight, hidden, status }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 'bold',
      borderBottom: '2px solid #C0C0C0',
      fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: highlight ? blue[200] : 'inherit',
      fontSize: 15,
      color:
        status === '逾期'
          ? theme.palette.error.main
          : status === '正常'
          ? theme.palette.success.main
          : null,
    },
    [theme.breakpoints.down(hidden ?? 'xs')]: {
      display: 'none',
    },
    [`&.${tableCellClasses.body} a`]: {
      color: theme.palette.primary.dark,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

function TempRecords({ deceased, records, onSave }) {
  const [mode, setMode] = React.useState('table');
  const [record, setRecord] = React.useState();

  return (
    <Box sx={{ height: 319, overflow: 'auto' }}>
      {mode === 'table' && (
        <TempTable
          deceased={deceased}
          setMode={setMode}
          setRecord={setRecord}
        />
      )}
      {mode === 'edit' && (
        <TempEdit
          deceased={deceased}
          record={record}
          setMode={setMode}
          onSave={onSave}
        />
      )}
    </Box>
  );
}

export default TempRecords;
